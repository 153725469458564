import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Row, Col, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GQL_LANDING_PAGE } from '../../gqls';
import { updatePaginatedData, simpleLandingPageDetailUrl } from '../../utils';
import { landingPageDetails } from '../../actions/landing-page';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const SimpleLandingPageDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const updateLandingPage = useSelector(state => state.updateLandingPages);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(GQL_LANDING_PAGE, { variables: { id } });

  const landingPage = _.get(data, 'getLandingPageUser');
  if (landingPage) dispatch(landingPageDetails(landingPage));

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getLandingPageUser', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updateLandingPage]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{ '/': currentUser?.name ?? ' ', '/simple-landing-pages': 'Simple Landing Page' }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1 className="mb-0">{landingPage?.name}</h1>
            {landingPage?.status !== 'archived' && (
              <a href={simpleLandingPageDetailUrl(landingPage?.link)} target="_blank" rel="noopener noreferrer">
                <small className="text-primary">{simpleLandingPageDetailUrl(landingPage?.link)} &rarr;</small>
              </a>
            )}
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default SimpleLandingPageDetail;
