import { gql } from '@apollo/client';

export const GQL_TOPUP_WHATSAPP_BALANCE = gql`
  mutation topUpWhatsappOfficial($input: TopUpWhatsappBalance) {
    topUpWhatsappOfficial(input: $input) {
      errorMessage
      status
      balance
    }
  }
`;

export const GET_HISTORY_WHATSAPP_PAGE = gql`
  query getHistoryWhatsappOfficial($page: Int, $pageSize: Int, $mobile: String, $startAt: String, $endAt: String, $type: HistoryWhatsappType!) {
    getHistoryWhatsappOfficial(page: $page, pageSize: $pageSize, mobile: $mobile, startAt: $startAt, endAt: $endAt, type: $type) {
      errorMessage
      nextPage
      items {
        id
        createdAt
        mobile
        message
        type
      }
    }
  }
`;
