import './style.scss';
import React from 'react';
import { useSelector } from 'react-redux';

import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';

const DiscountCoupons = () => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>Diskon dan Kupon</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default DiscountCoupons;
