import { gql } from '@apollo/client';

export const GQL_GET_SUPPORT = gql`
	query getSupportFilteredList($search: SupportSearchInput) {
		getSupportFilteredList(search: $search) {
			background
			description
			id
			isCopyrightVisible
			isSupporterVisible
			fontFamily
			buttonStyle
			cardStyle
			coverImage
			socialLinks
			unit
			unitIcon
			unitValue
			userId
		}
	}
`

export const GQL_CREATE_SUPPORT = gql`
	mutation insertSupport($input: SupportInput) {
		insertSupport(input: $input) {
			id
			userId
		}
	}
`;

export const GQL_EDIT_SUPPORT = gql`
	mutation editSupportProxy($input: SupportInput) {
		editSupportProxy(input: $input) {
			background
			description
			id
			isCopyrightVisible
			isSupporterVisible
			fontFamily
			buttonStyle
			cardStyle
			coverImage
			socialLinks
			unit
			unitIcon
			unitValue
			userId
		}
	}
`;

export const GQL_GET_SUPPORT_BY_LINK = gql`
  query getSupportByLinkPublic($link: String!){
    getSupportByLinkPublic(link: $link){
      id
      unit
      unitIcon
      unitValue
      description
      fontFamily
      buttonStyle
      cardStyle
      coverImage
      isSupporterVisible
      isCopyrightVisible
      themeId
      socialLinks
      background
      userId
    }
  }
`;
