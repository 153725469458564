import { gql } from '@apollo/client';

export const GQL_INSERT_NEWS = gql`
  mutation insertNews($input: NewsInput) {
    insertNews(input: $input) {
      createdAt
      description
      paymentLinkId
      status
      title
      updatedAt
      userId
      id
    }
  }
`;

export const GQL_UPDATE_NEWS = gql`
  mutation editNews($input: NewsInput) {
    editNews(input: $input) {
      createdAt
      description
      paymentLinkId
      status
      title
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_NEWS_FILTERED_LIST = gql`
  query getNewsFilteredList(
    $limit: Int = 500
    $search: NewsSearchInput = {}
    $searchAny: NewsSearchInput = {}
    $sortField: NewsSortField = createdAt
    $sortDirection: SortDirection = DESC
  ) {
    getNewsFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      createdAt
      description
      id
      status
      title
      updatedAt
    }
  }
`;
