import { gql } from '@apollo/client';
export const GQL_EDIT_EVENT = gql`
  mutation editEvent($input: EventInput) {
    editEvent(input: $input) {
      id
      attributes
      createdAt
      description
      event
      eventAddress
      eventAt
      isOnlineEvent
      maxTicket
      objectId
      objectType
      paymentLinkId
      updatedAt
      userId
    }
  }
`;

export const GET_EVENT_HISTORY_PAGE = gql`
  query getEventHistoryPage(
    $page: Int = 1
    $pageSize: Int = 10
    $search: EventHistorySearchInput
    $searchAny: EventHistorySearchInput
    $sortDirection: SortDirection = DESC
    $sortField: EventHistorySortField = createdAt
  ) {
    getEventHistoryPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      totalPage
      total
      prevPage
      page
      offset
      nextPage
      items {
        id
        barcode
        checkInAt
        createdAt
        paymentLinkTransactionId
        ticketId
        updatedAt
        userId
        ticket {
          name
        }
        paymentLinkTransaction {
          updatedAt
          invoiceCode
          isContentBlocked
          customer {
            name
            mobile
            email
          }
        }
      }
    }
  }
`;

export const INSERT_EVENT_CHECK_IN = gql`
  mutation insertEventCheckIn($input: [ID]!) {
    insertEventCheckIn(input: $input) {
      status
    }
  }
`;

export const GET_EVENT_HISTORY_PAGE_BY_QR_CODE = gql`
  query getEventHistoryPageByQrCode(
    $eventHistoryId: ID
    $qrString: String
    $paymentLinkId: ID!
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: EventHistorySortField
  ) {
    getEventHistoryPageByQrCode(
      eventHistoryId: $eventHistoryId
      qrString: $qrString
      paymentLinkId: $paymentLinkId
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      errorMessage
      totalPage
      total
      prevPage
      page
      offset
      nextPage
      items {
        id
        barcode
        checkInAt
        paymentLinkTransactionId
        ticketId
        userId
        ticket {
          name
        }
        paymentLinkTransaction {
          invoiceCode
          isContentBlocked
          customeField {
            data
          }
          customer {
            name
          }
        }
      }
    }
  }
`;

export const GET_EVENT_HISTORY_FILTERED_LIST = gql`
  query getEventHistoryFilteredList(
    $limit: Int
    $search: EventHistorySearchInput
    $searchAny: EventHistorySearchInput
    $sortDirection: SortDirection = DESC
    $sortField: EventHistorySortField = createdAt
  ) {
    getEventHistoryFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      id
      barcode
      checkInAt
      createdAt
      paymentLinkTransactionId
      ticketId
      updatedAt
      userId
      ticket {
        name
      }
      paymentLinkTransaction {
        invoiceCode
        customer {
          name
          email
        }
      }
    }
  }
`;

export const GQL_ACTIVE_TICKET_GET = gql`
  query getTicketFilteredList(
    $search: TicketSearchInput
    $searchAny: TicketSearchInput
    $sortField: TicketSortField
    $sortDirection: SortDirection
    $limit: Int
  ) {
    getTicketFilteredList(
      search: $search
      searchAny: $searchAny
      sortField: $sortField
      sortDirection: $sortDirection
      limit: $limit
    ) {
      id
      name
      eventId
    }
  }
`;

export const GET_STATUS_CHECKIN_LIST_EVENT_HISTORY = gql`
query getEventHistoryPage($page: Int, $pageSize: Int, $search: EventHistorySearchInput, $sortDirection: SortDirection, $sortField: EventHistorySortField) {
  getEventHistoryPage(page: $page, pageSize: $pageSize, search: $search, sortDirection: $sortDirection, sortField: $sortField) {
    items {
      id
      barcode
      checkInAt
      createdAt
      customerId
      paymentLinkId
      paymentLinkTransactionId
      ticketId
      paymentLinkTransaction {
        invoiceCode
      }
      customer {
        id
        name
        email
        mobile
      }
    }
    nextPage
  }
}
`;

export const GET_TOTAL_STATUS_CHECKIN_LIST_EVENT_HISTORY = gql`
query getEventHistoryPage($search: EventHistorySearchInput) {
  getEventHistoryPage(page: 1, pageSize: 1, search: $search) {
    total
  }
}
`;
