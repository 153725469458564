export const CURRENT_USER = 'CURRENT_USER';
export const currentUser = q => ({
  type: CURRENT_USER,
  payload: q
});

export const IS_HAVING_ACCESS_TO_CREATE_PRODUCT = 'IS_HAVING_ACCESS_TO_CREATE_PRODUCT';
export const isHavingAccessToCreateProduct = q => ({
  type: IS_HAVING_ACCESS_TO_CREATE_PRODUCT,
  payload: q
});
