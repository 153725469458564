import moment from 'moment-timezone';
export const translatePeriod = period => {
  if (period === 'monthly') {
    return 'Bulan';
  } else if (period === 'weekly') {
    return 'Minggu';
  }
  return '';
};

export const getSubscriptionFrequency = (billingCycle, billingCycleCustomPeriod, billingCycleCustomPeriodTime) => {
  let pertext = '';
  if (billingCycle === 'custom') {
    pertext = billingCycleCustomPeriodTime + ' ' + translatePeriod(billingCycleCustomPeriod);
  } else {
    pertext = translatePeriod(billingCycle);
  }
  return pertext;
};

export const getUserTimezone = () => moment.tz.guess();
export const convertDateToDays = (date = new Date(), isRemaining = false, translation) => {
  const sourceDate = moment(date);
  const currentDate = moment();
  const diff = sourceDate.diff(currentDate, 'days');

  if (isRemaining && diff >= 0) {
    return `${currentDate.to(sourceDate, true)} ${translation ? translation('word.concatDonate') : 'lagi'}`;
  }

  return isRemaining
    ? `${translation ? translation('fundraising.itsOver') : 'Sudah Berakhir'}`
    : `${currentDate.to(sourceDate, true)} ${translation ? translation('word.ago') : 'yang lalu'}`;
};

export const dateRange = (num, type) => {
  const array = [...Array(num).keys()].map(i => {
    return {
      waktu:
        type === 'months'
          ? moment()
              .subtract(num - i, type)
              .endOf('month')
              .add(1, 'days')
              .format('DD/MM/YYYY')
          : moment()
              .subtract(num - 1 - i, type)
              .format('DD/MM/YYYY'),
      total: 0
    };
  });

  return JSON.stringify(array);
};

export const addMinutesToDate = (objDate, intMinutes) => {
  const addMlSeconds = intMinutes * 60 * 1000;
  const newDateObj = objDate + addMlSeconds;

  return newDateObj;
};

export const secondsToHours = seconds => {
  const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return { hours, minutes, seconds: remainingSeconds % 60 };
};
