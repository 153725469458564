/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';

import { GQL_PAYMENTLINK_GET, GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { updatePaginatedData } from '../../utils';
import { paymentLinkDetails } from '../../actions/payment-links';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const BundleDetail = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const updatedPaymentLink = useSelector(state => state.updatePaymentLinks);

  const { id } = useParams();
  const gparam = { search: { id: [{ operator: 'eq', value: id }] } };
  const { loading, error, data, fetchMore } = useQuery(GQL_PAYMENTLINK_GET, {
    variables: gparam
  });

  const paymentLink = _.get(data, 'getPaymentLinkPage.items[0]');

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    if (paymentLink) {
      dispatch(paymentLinkDetails(paymentLink));
    }
  }, [paymentLink]);

  useEffect(() => {
    updateData();
  }, [updatedPaymentLink]);

  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  useEffect(() => {
    if (paymentLink?.id && !paymentLink?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: paymentLink?.id } });
    }
  }, [paymentLink]);

  if (error) return `Error! ${error.message}`;
  if (loading) return <Spinner />;
  if (!paymentLink?.id) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle className="d-none d-sm-block" breadcrumbs={{ '/': currentUser?.name || ' ', '/bundling': 'Bundling' }}>
        <Row className="mb-0">
          <Col md="4">
            <h1>{paymentLink?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default BundleDetail;
