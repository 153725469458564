/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

/**
 * Internal Dependencies
 */
import { updateAuth as actionUpdateAuth, updateSettings as actionUpdateSettings } from '../../actions';
import Icon from '../icon';
import { initNavbar } from '../../../assets/js/rootui-parts/initNavbar';
import NavigationMobile from '../navigation-mobile';

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

/**
 * Component
 */
class PageNavbar extends Component {
  constructor(props) {
    super(props);

    window.RootUI.initNavbar();

    this.state = {
      mobileMenuShow: false
    };
  }

  componentDidMount() {
    $(document).on('keydown.rui-navbar', e => {
      const { mobileMenuShow } = this.state;

      if (mobileMenuShow && e.keyCode === 27) {
        this.setState({
          mobileMenuShow: !mobileMenuShow
        });
      }
    });
  }

  componentWillUnmount() {
    $(document).off('keydown.rui-navbar');
  }

  render() {
    const { settings } = this.props;

    const { mobileMenuShow } = this.state;

    const closeModal = () => {
      this.setState({
        mobileMenuShow: false
      });
    }

    return (
      <>
        {/* Nav Menu */}
        <nav
          className={classnames(
            'd-sm-none rui-navbar rui-navbar-top',
            settings.nav_dark ? 'rui-navbar-dark' : '',
            settings.nav_sticky ? 'rui-navbar-sticky' : '',
            settings.nav_fixed ? 'rui-navbar-fixed' : '',
            settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : ''
          )}
        >
          <div className={`container${settings.nav_container_fluid ? '-fluid' : ''}`}>
            <div className="rui-navbar-content">
              <ul className="nav"></ul>
            </div>
          </div>
        </nav>

        {/* Mobile Menu */}
        <nav
          className={classnames(
            'd-sm-none rui-navbar rui-navbar-mobile',
            settings.nav_dark ? 'rui-navbar-dark' : '',
            settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
            mobileMenuShow ? 'rui-navbar-show' : ''
          )}
        >
          <div className="rui-navbar-head">
            {!mobileMenuShow ? (
              <button
                className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle"
                type="button"
                aria-label="Toggle side navigation"
              >
                <span />
              </button>
            ) : (
              <button
                className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle"
                type="button"
                aria-label="Toggle side navigation"
                disabled
              >
                <span />
              </button>
            )}
            {settings.logo_mobile ? (
              <Link to={settings.nav_logo_url} className="rui-navbar-logo mr-auto ml-auto">
                <img
                  src={settings.logo_mobile}
                  alt=""
                  width={settings.logo_mobile_width}
                  height={settings.logo_mobile_height}
                />
              </Link>
            ) : (
              ''
            )}

            <button
              className="navbar-toggler rui-navbar-toggle ml-5 button-primary"
              onClick={() => {
                this.setState({
                  mobileMenuShow: !mobileMenuShow
                });
              }}
            >
              <Icon name="plus-square" className="text-primary" />
            </button>
          </div>
          <Collapse isOpen={mobileMenuShow} className="navbar-collapse rui-navbar-collapse">
            <div className="rui-navbar-content">
              <ul className="nav">
                <NavigationMobile closeModal={closeModal} />
              </ul>
            </div>
          </Collapse>
        </nav>
        <div
          className="rui-navbar-bg"
          onClick={() => {
            this.setState({
              mobileMenuShow: !mobileMenuShow
            });
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
        />
      </>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings
  }),
  {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings
  }
)(PageNavbar);
