/* eslint-disable */
/**
 * Styles.
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, { useEffect } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col, Spinner } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { GQL_GET_BATCH_PAYMENT_PAGE } from '../../gqls/batch-payment-request';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { batchPaymentDetails } from '../../actions/batch-payment';
import _ from 'lodash';
import { updatePaginatedData } from '../../utils';

const BatchPaymentRequestDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const ubp = useSelector(state => state.updateBatchPayment);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = {
    page: 1,
    pageSize: 1,
    search: {
      id: [{ operator: 'eq', value: id }]
    }
  };
  
  const { loading, error, data, fetchMore } = useQuery(GQL_GET_BATCH_PAYMENT_PAGE, {
    variables: gparam
  });

  const bp = _.get(data, 'getBatchPaymentRequestPage.items[0]');
  if (bp) {
    dispatch(batchPaymentDetails(bp));
  }

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getBatchPaymentRequestPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [ubp, bp]);

  if (error) return `Error! ${error.message}`;

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  if (data && data.getBatchPaymentRequestPage && data.getBatchPaymentRequestPage.items.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/batch-payment-requests': 'Penagihan Masal'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>Proses Penagihan Massal: {bp?.title}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default BatchPaymentRequestDetail;
