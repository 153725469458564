import { gql } from '@apollo/client';

export const GQL_USER_FILTERED_LIST = gql`
  query getUserFilteredList($search: UserSearchInput) {
    getUserFilteredList(search: $search) {
      accountId
      balance
      email
      isInitPaymentPaid
      isProfileCompleted
      isReferral
      isSelfDomain
      lastLogin
      mobile
      name
      parentId
      paymeLink
      referralCode
      role
      roleId
      status
      account {
        email
        planType
        trialEndDate
        xenditStaticQris
        xenditUserId
      }
    }
  }
`;

export const GQL_GET_USER_URL_HOOK = gql`
  query getUserFilteredList($search: UserSearchInput) {
    getUserFilteredList(search: $search) {
      urlHook
    }
  }
`;

export const GQL_USER_PAGE = gql`
  query getUserPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: UserSortField
    $searchAny: UserSearchInput
    $search: UserSearchInput
  ) {
    getUserPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        accountId
        balance
        createdAt
        email
        isInitPaymentPaid
        isProfileCompleted
        lastLogin
        mobile
        name
        password
        paymeLink
        role
        status
        updatedAt
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      name
      status
    }
  }
`;

export const GQL_GET_MERCHANT_ANALYTICS = gql`
  query getTotalSold($startDate: Timestamp, $endDate: Timestamp, $userId: ID) {
    getTotalSold(startDate: $startDate, endDate: $endDate, userId: $userId) {
      month
      totalSold
    }
  }
`;
export const GET_AFFILIATE_REFERRAL_OWNER = gql`
  query getAffiliateReferralOwner($userId: String!) {
    getAffiliateReferralOwner(userId: $userId) {
      id
      affiliateUserId
      userId
    }
  }
`;

export const GQL_GET_USER_BY_REFERRAL_CODE = gql`
  query getUserByReferralCode($referralCode: String!) {
    getUserByReferralCode(referralCode: $referralCode) {
      id
      accountId
      balance
      createdAt
      email
      lastLogin
      mobile
      name
      password
      paymeLink
      role
      status
      updatedAt
      homePagePath
      account {
        name
        logo {
          url
        }
        planType
      }
      isAffiliateMax
      errorMessage
    }
  }
`;

export const GQL_USER_BY_ID = gql`
  query getUser($id: ID) {
    getUser(id: $id) {
      id
      balance
      balanceActive
      balancePending
      name
      email
      role
      status
      paymeLink
      accountId
      mobile
      isProfileCompleted
      isAffiliate
      isSelfDomain
      homePagePath
      linkTree {
        background
        buttonStyles
        createdAt
        fontFamily
        id
        isCopyrightVisible
        links
        profile
        socialLinks
        socialLinksPosition
        themeId
        updatedAt
        userId
      }
      referralCode
      address {
        address
        area
        district
        id
        name
        notes
        postcode
        province
        subdistrict
      }
      userAnalytics {
        googleAnalyticsId
        facebookPixelId
        trackToken
        tiktokPixelToken
      }
      account {
        id
        xenditUserId
        xenditStaticQris
        name
        planSubscriptionPeriod
        trialEndDate
        planType
        website
        logo {
          url
        }
      }
    }
  }
`;

export const GQL_USER_EDIT = gql`
  mutation editUser($input: UserInput) {
    editUser(input: $input) {
      id
      balance
      balanceActive
      balancePending
      name
      email
      role
      status
      paymeLink
      accountId
      mobile
      isProfileCompleted
      isAffiliate
      isSelfDomain
      homePagePath
      linkTree {
        background
        buttonStyles
        createdAt
        fontFamily
        id
        isCopyrightVisible
        links
        profile
        socialLinks
        socialLinksPosition
        themeId
        updatedAt
        userId
      }
      referralCode
      address {
        address
        area
        district
        id
        name
        notes
        postcode
        province
        subdistrict
      }
      userAnalytics {
        googleAnalyticsId
        facebookPixelId
        trackToken
        tiktokPixelToken
      }
      account {
        id
        xenditUserId
        xenditStaticQris
        name
        planSubscriptionPeriod
        trialEndDate
        planType
        website
        logo {
          url
        }
      }
    }
  }
`;

export const GQL_PROXY_USER_EDIT = gql`
  mutation newUpdateUserProxy($input: UserInputProxy) {
    newUpdateUserProxy(input: $input) {
      id
      balance
      balanceActive
      balancePending
      name
      email
      role
      status
      paymeLink
      accountId
      mobile
      isProfileCompleted
      isOnboardWeb
      isOnboardMobile
      isAffiliate
      isSelfDomain
      homePagePath
      publicAnnouncement
      isCatalogVisible
      linkTree {
        background
        buttonStyles
        createdAt
        fontFamily
        id
        isCopyrightVisible
        links
        profile
        socialLinks
        socialLinksPosition
        themeId
        updatedAt
        userId
      }
      referralCode
      address {
        address
        area
        district
        id
        name
        notes
        postcode
        province
        subdistrict
      }
      userAnalytics {
        googleAnalyticsId
        facebookPixelId
        trackToken
        tiktokPixelToken
      }
      account {
        id
        xenditUserId
        xenditStaticQris
        name
        planSubscriptionPeriod
        trialEndDate
        planType
        website
        logo {
          url
        }
      }
      ackeeAnalytics {
        analyticDomainId
      }
    }
  }
`;

export const GQL_USER_UPDATE = gql`
  mutation editUser($input: UserInput) {
    editUser(input: $input) {
      id
      mobile
      name
      accountId
      paymeLink
      urlHook
    }
  }
`;

export const GQL_USER_UPDATE_PAYME_LINK = gql`
  mutation updatePaymeLink($input: UserInput) {
    updatePaymeLink(input: $input) {
      accountId
      paymeLink
      errorMessage
    }
  }
`;

export const GQL_INSERT_USER_AFFILIATE = gql`
  mutation insertUserAffiliate($referralCode: String) {
    insertUserAffiliate(referralCode: $referralCode) {
      id
      errorType
    }
  }
`;

export const GQL_INSERT_MAYAR_PLAN = gql`
  mutation insertMayarPlan($coupon: String!, $type: PlanType!, $period: SubscriptionCustomerPeriod!) {
    insertMayarPlan(coupon: $coupon, type: $type, period: $period) {
      id
      invoiceUrl
      errorType
      errorMessage
    }
  }
`;

export const GQL_SEND_SUBACCOUNT_EMAIL = gql`
  mutation sendSubAccountMayarInvitation($email: String!, $roleName: String!, $path: String!) {
    sendSubAccountMayarInvitation(email: $email, roleName: $roleName, path: $path) {
      status
      response
    }
  }
`;

export const GQL_RESEND_EMAIL_SUBACCOUNT = gql`
  mutation resendSubAccountInvitation($email: String!) {
    resendSubAccountInvitation(email: $email) {
      status
      response
    }
  }
`;

export const GQL_DELETE_SUBACCOUNT = gql`
  mutation deleteSubAccount($id: ID!, $userId: ID!) {
    deleteSubAccount(id: $id, userId: $userId) {
      status
      response
    }
  }
`;

export const GQL_GET_ROLE = gql`
  query getRoleFilteredList($search: RoleSearchInput) {
    getRoleFilteredList(search: $search) {
      id
      name
      path
    }
  }
`;

export const GQL_UPDATE_ROLE = gql`
  mutation editRole($input: RoleInput) {
    editRole(input: $input) {
      createdAt
      name
      path
      updatedAt
      userId
    }
  }
`;

export const GQL_EDIT_SUBACCOUNT_ROLE = gql`
  mutation editRoleSubAccount($email: String!, $subAccountHistoryId: ID!, $path: String!, $roleName: String!) {
    editRoleSubAccount(email: $email, subAccountHistoryId: $subAccountHistoryId, path: $path, roleName: $roleName) {
      status
      response
    }
  }
`;

export const GQL_GET_SUBACCOUNTHISTORY_PAGE = gql`
  query getSubAccountHistoryPage(
    $page: Int
    $pageSize: Int
    $search: SubAccountHistorySearchInput
    $searchAny: SubAccountHistorySearchInput
    $sortDirection: SortDirection
    $sortField: SubAccountHistorySortField
  ) {
    getSubAccountHistoryPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        createdAt
        email
        roleId
        status
        updatedAt
        userId
        role {
          name
        }
        user {
          id
          name
        }
      }
      nextPage
    }
  }
`;

export const GET_BUNNY_LIBRARY_ID = gql`
  query getUser($id: ID) {
    getUser(id: $id) {
      bunnyLibraryId
    }
  }
`;

export const GQL_REORDER_CATALOG_PRODUCT = gql`
  mutation reorderProductCategoryCatalog($input: ReorderCatalogProductInput) {
    reorderProductCategoryCatalog(input: $input) {
      id
    }
  }
`;

export const GQL_GET_WHATSAPP_BALANCE = gql`
  query getUser($id: ID) {
    getUser(id: $id) {
      whatsappBalance
    }
  }
`;
