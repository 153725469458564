import { gql } from '@apollo/client';

export const GQL_INSERT_USER_CONTENT_FILE = gql`
  mutation insertMultipleMultipleFile($input: [MultipleFileInput]) {
    insertMultipleMultipleFile(input: $input) {
      id
      createdAt
      key
      updatedAt
      value
      files {
        id
        filename
        size
        mimetype
        createdAt
      }
      file {
        id
        filename
        size
        mimetype
        createdAt
        bunnyVideoId
      }
    }
  }
`;

export const GQL_UPDATE_USER_CONTENT_FILE = gql`
  mutation editMultipleMultipleFile($input: [MultipleFileInput]) {
    editMultipleMultipleFile(input: $input) {
      id
      createdAt
      key
      updatedAt
      value
      files {
        id
        filename
        size
        mimetype
        createdAt
      }
      file {
        id
        filename
        size
        mimetype
        createdAt
        bunnyVideoId
      }
    }
  }
`;

export const GQL_GET_CONTENT_FILE = gql`
  query getMultipleFileFilteredList($search: MultipleFileSearchInput) {
    getMultipleFileFilteredList(search: $search) {
      createdAt
      key
      updatedAt
      value
      id
    }
  }
`;

export const GQL_GET_USER_CONTENTS = gql`
  query getUserContents($limit: Int, $offset: Int, $format: String, $isBunnyVideo: Boolean) {
    getUserContents(limit: $limit, offset: $offset, format: $format, isBunnyVideo: $isBunnyVideo) {
      size
      createdAt
      encoding
      filename
      id
      mimetype
      tag
      updatedAt
    }
  }
`;

export const GQL_GET_PAYMENTLINK_CONTENT = gql`
  query getUserContentByPaymentLinkId($id: ID!) {
    getUserContentByPaymentLinkId(id: $id) {
      content {
        id
        file {
          url
        }
      }
    }
  }
`;

export const GQL_IMAGE_FILTERED_LIST = gql`
  query getImageFilteredListPublic($search: ImageSearchInput, $paymeLink: String) {
    getImageFilteredListPublic(search: $search, paymeLink: $paymeLink) {
      height
      title
      width
      url
    }
  }
`;

export const GQL_UPLOAD_IMAGE = gql`
  mutation uploadImage($base64: String, $fileType: String, $title: String) {
    uploadImage(base64: $base64, fileType: $fileType, title: $title) {
      description
      title
      url
      createdAt
      updatedAt
      id
    }
  }
`;

export const GQL_UPDATE_IMAGE = gql`
  mutation editImage($input: ImageInput) {
    editImage(input: $input) {
      id
      createdAt
      description
      fileType
      height
      imageId
      title
      updatedAt
      width
    }
  }
`;

export const GQL_USER_CONTENT_BY_SPENDING_ID = gql`
  query getSpendingContent($id: ID!) {
    getSpendingContent(id: $id) {
      content {
        file {
          url
          filename
          size
        }
      }
    }
  }
`;

export const GQL_USER_CONTENT_BY_COURSE_MODULE_ID = gql`
  query getUserContentByCourseModuleId($id: ID!) {
    getUserContentByCourseModuleId(id: $id) {
      content {
        file {
          url
          filename
          size
        }
      }
    }
  }
`;

export const GQL_DELETE_MULTIPLE_FILE = gql`
  mutation deleteMultipleMultipleFile($ids: [ID]) {
    deleteMultipleMultipleFile(ids: $ids) {
      id
    }
  }
`;

export const GQL_GET_MULTIPLE_CONTENT = gql`
  query getMultipleUserContentByKey($key: String!) {
    getMultipleUserContentByKey(key: $key) {
      createdAt
      id
      key
      updatedAt
      value
      file {
        createdAt
        encoding
        filename
        mimetype
        updatedAt
        size
        url
      }
    }
  }
`;

export const GET_TUS_PRESIGNED_SIGNATURE = gql`
  query getTusPresignedSignature($videoId: String!) {
    getTusPresignedSignature(videoId: $videoId) {
      signature
      expirationTime
      errorMessage
    }
  }
`;

export const CREATE_BUNNY_VIDEO = gql`
  mutation createBunnyVideo($filename: String!, $type: String!) {
    createBunnyVideo(filename: $filename, type: $type) {
      id
      bunnyVideoId
      bunnyLibraryId
      errorMessage
    }
  }
`;
