import { gql } from '@apollo/client';

export const GQL_GET_GENRES = gql`
query getGenreFilteredList($input: GenreSearchInput) {
  getGenreFilteredList(search: $input) {
    id
    name
  }
}
`;
