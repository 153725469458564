import './style.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';
import { rootUrl } from '../../utils';

const StoreFront = () => {
  const currentUser = useSelector(state => state.currentUser);

  return (
    <PageWrap>
      <PageTitle className="d-none d-sm-block" breadcrumbs={{ '/': currentUser?.name || ' ' }}>
        <Row className="mb-0">
          <Col md="4">
            <h1>Storefront - Halaman Utama</h1>
            <a
              href={rootUrl(currentUser.paymeLink)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary mt-5"
            >
              <small>{rootUrl(currentUser.paymeLink)} &rarr;</small>
            </a>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default StoreFront;
