import { gql } from '@apollo/client';

export const CREATE_DISBURSEMENT = gql`
  mutation createDisbursement($input: DisbursementInput!) {
    createDisbursement(input: $input) {
      status
      message
      spendingId
      blockTime
    }
  }
`;

export const NEW_CREATE_DISBURSEMENT = gql`
  mutation newCreateDisbursement($input: DisbursementInput!) {
    newCreateDisbursement(input: $input) {
      status
      message
      spendingId
      blockTime
    }
  }
`;

export const CREATE_BATCH_DISBURSEMENT = gql`
  mutation createBatchDisbursement($input: BatchDisbursementInput!) {
    createBatchDisbursement(input: $input) {
      status
      message
      blockTime
    }
  }
`;

export const NEW_CREATE_BATCH_DISBURSEMENT = gql`
  mutation newCreateBatchDisbursement($input: BatchDisbursementInput!) {
    newCreateBatchDisbursement(input: $input) {
      status
      message
      blockTime
    }
  }
`;
