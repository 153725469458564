import { gql } from '@apollo/client';

export const GET_WHATSAPP_STATE = gql`
  query getWhatsappState($sessionId: String!, $userId: ID!, $action: String!, $token: String!) {
    getWhatsappState(sessionId: $sessionId, userId: $userId, action: $action, token: $token) {
      status
      sessionId
      userId
      errorMessage
      qrcode
    }
  }
`;

export const GET_OR_CREATE_WHATSAPP = gql`
  query getOrCreateWhatsapp($userId: ID!) {
    getOrCreateWhatsapp(userId: $userId) {
      status
      qrcode
      sessionId
      token
    }
  }
`;

export const GET_WHATSAPP_TEMPLATE = gql`
  query getWhatsappTemplateFilteredList($search: WhatsappTemplateSearchInput) {
    getWhatsappTemplateFilteredList(search: $search) {
      id
      isActive
      name
      template
      userId
    }
  }
`;

export const CREATE_WHATSAPP_TEMPLATE = gql`
  mutation insertWhatsappTemplate($input: WhatsappTemplateInput) {
    insertWhatsappTemplate(input: $input) {
      id
    }
  }
`;

export const CREATE_MULTIPLE_WHATSAPP_TEMPLATE = gql`
  mutation insertMultipleWhatsappTemplate($input: [WhatsappTemplateInput]) {
    insertMultipleWhatsappTemplate(input: $input) {
      id
    }
  }
`;

export const EDIT_MULTIPLE_WHATSAPP_TEMPLATE = gql`
  mutation editMultipleWhatsappTemplate($input: [WhatsappTemplateInput]) {
    editMultipleWhatsappTemplate(input: $input) {
      id
    }
  }
`;

export const INSERT_WHATSAPP_TEMPLATE = gql`
  mutation insertTemplateWhatsapp($input: [WhatsappTemplateInput]!) {
    insertTemplateWhatsapp(input: $input) {
      status
      errorMessage
    }
  }
`;
