import { gql } from '@apollo/client';

export const INSERT_TAGS_TO_PAYMENT_LINK = gql`
  mutation insertTagsToPaymentLink($input: [TagInput]!, $paymentLinkId: ID!) {
    insertTagsToPaymentLink(input: $input, paymentLinkId: $paymentLinkId) {
      responseStatus
      errorMessage
    }
  }
`;

export const GET_TAG = gql`
  query getTagFilteredList($search: TagSearchInput, $limit: Int) {
    getTagFilteredList(search: $search, limit: $limit) {
      id
      name
    }
  }
`;

export const GET_PL_TAGS = gql`
  query getPaymentLinkTagFilteredList($search: PaymentLinkTagSearchInput, $limit: Int) {
    getPaymentLinkTagFilteredList(search: $search, limit: $limit) {
      tag {
        name
      }
    }
  }
`;

export const GET_TAGS_PAGE = gql`
  query getTagPage(
    $page: Int
    $pageSize: Int
    $search: TagSearchInput
    $sortDirection: SortDirection
    $sortField: TagSortField
  ) {
    getTagPage(
      page: $page
      pageSize: $pageSize
      search: $search
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        name
        createdAt
      }
      nextPage
    }
  }
`;

export const GET_PL_TAGS_PAGE = gql`
  query getPaymentLinkTagPage(
    $page: Int
    $pageSize: Int
    $search: PaymentLinkTagSearchInput
    $sortDirection: SortDirection
    $sortField: PaymentLinkTagSortField
  ) {
    getPaymentLinkTagPage(
      page: $page
      pageSize: $pageSize
      search: $search
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        createdAt
        paymentLink {
          id
          name
          type
        }
      }
      nextPage
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($input: TagInput!) {
    updateTag(input: $input) {
      responseStatus
      errorMessage
    }
  }
`;
