import { gql } from '@apollo/client';

export const GQL_CREATE_USER_ANALYTICS = gql`
  mutation createUserAnalyticsProxy($input: UserAnalyticsInput) {
    createUserAnalyticsProxy(input: $input) {
      facebookPixelId
      googleAnalyticsId
      trackToken
      tiktokPixelToken
      userId
    }
  }
`;

export const GQL_UPDATE_USER_ANALYTICS = gql`
  mutation updateUserAnalyticsProxy($input: UserAnalyticsInput) {
    updateUserAnalyticsProxy(input: $input) {
      id
      createdAt
      facebookPixelId
      googleAnalyticsId
      trackToken
      tiktokPixelToken
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_ANALYTICS = gql`
  query getAnalytics($input: AnalyticsInput) {
    getAnalytics(input: $input) {
      result
    }
  }
`;

export const GQL_GET_ANALYTICS_DASHBOARD = gql`
  query getAnalyticsDashboard($input: AnalyticsInput) {
    getAnalyticsDashboard(input: $input) {
      result
    }
  }
`;

export const GQL_GET_ANALYTICS_PRODUCT = gql`
  query getAnalyticsProduct($input: AnalyticsProductInput) {
    getAnalyticsProduct(input: $input) {
      result
    }
  }
`;

export const GQL_GET_ANALYTICS_PRODUCTS = gql`
  query getAnalyticsProducts($input: AnalyticsProductsInput) {
    getAnalyticsProducts(input: $input) {
      result
    }
  }
`;
