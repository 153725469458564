import {
  FILTER_SUBSCRIPTION_BY_Q,
  UPDATE_SUBSCRIPTIONS,
  FILTER_SUBSCRIPTION_BY_STATUS,
  SUBSCRIPTION_DETAILS,
} from '../actions/subscriptions';

export const filterSubscriptionByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_SUBSCRIPTION_BY_Q:
      return action.payload;
  }
  return state;
};

export const UpdateSubscriptionsReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS:
      return action.payload;
  }
  return state;
};

export const filterSubscriptionByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_SUBSCRIPTION_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const subscriptionDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case SUBSCRIPTION_DETAILS:
      return action.payload;
  }
  return state;
};
