import { gql } from '@apollo/client';

export const GQL_GET_SPENDING_PAGE = gql`
  query getSpendingPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: SpendingSortField
    $searchAny: SpendingSearchInput
    $search: SpendingSearchInput
  ) {
    getSpendingPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        accountName
        accountNumber
        accountType
        amount
        bank
        bankCountry
        bankReference
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        createdAt
        currency
        email
        invoiceCategory
        invoiceExpiredAt
        invoiceNumber
        isEmailSentAfterSuccess
        notes
        status
        tax
        transferDescription
        type
        updatedAt
        userId
        user {
          account {
            name
          }
        }
        id
        xenditTransactionId
      }
      nextPage
    }
  }
`;

export const GQL_GET_SPENDING = gql`
  query getSpending($id: ID = "") {
    getSpending(id: $id) {
      accountName
      accountNumber
      accountType
      amount
      bank
      bankCountry
      bankReference
      content {
        files {
          id
          filename
          size
          mimetype
          createdAt
        }
        file {
          id
          filename
          size
          mimetype
          createdAt
        }
        id
        createdAt
      }
      createdAt
      currency
      email
      invoiceCategory
      invoiceExpiredAt
      invoiceNumber
      isEmailSentAfterSuccess
      notes
      status
      tax
      transferDescription
      type
      updatedAt
      userId
      user {
        account {
          name
        }
      }
      id
    }
  }
`;

export const GQL_GET_SPENDING_FILTERED_LIST = gql`
  query getSpendingFilteredList(
    $limit: Int
    $offset: Int
    $search: SpendingSearchInput
    $searchAny: SpendingSearchInput
    $sortDirection: SortDirection
    $sortField: SpendingSortField
  ) {
    getSpendingFilteredList(
      limit: $limit
      offset: $offset
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      accountName
      email
      id
      bankCountry
      bankReference
      bank
      amount
      accountType
      accountNumber
      createdAt
      currency
      invoiceCategory
      invoiceExpiredAt
      invoiceNumber
      isEmailSentAfterSuccess
      notes
      status
      tax
      transferDescription
      type
      userId
      user {
        account {
          name
        }
      }
      xenditTransactionId
      updatedAt
    }
  }
`;
