export const FILTER_BROADCAST_BY_STATUS = 'FILTER_BROADCAST_BY_STATUS';
export const filterBroadcastByStatus = q => ({
  type: FILTER_BROADCAST_BY_STATUS,
  payload: q
});

export const FILTER_BROADCAST_BY_Q = 'FILTER_BROADCAST_BY_Q';
export const filterBroadcastByQ = q => ({
  type: FILTER_BROADCAST_BY_Q,
  payload: q
});

export const UPDATE_BROADCAST = 'UPDATE_BROADCAST';
export const updateBroadcast = q => ({
  type: UPDATE_BROADCAST,
  payload: q
});

export const BROADCAST_DETAILS = 'BROADCAST_DETAILS';
export const broadcastDetails = q => ({
  type: BROADCAST_DETAILS,
  payload: q
});
