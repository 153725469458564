import { gql } from '@apollo/client';

export const GQL_SHIPMENT_CHANNEL_CONFIG_PAGE = gql`
  query getShipmentChannelConfigPage($page: Int, $pageSize: Int, $search: ShipmentChannelConfigSearchInput) {
    getShipmentChannelConfigPage(page: $page, pageSize: $pageSize, search: $search) {
      items {
        id
        config
        createdAt
        updatedAt
        userId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_SHIPMENT_CHANNEL_CONFIG_CREATE = gql`
  mutation createShipmentChannelConfigProxy($input: ShipmentChannelConfigInput!) {
    createShipmentChannelConfigProxy(input: $input) {
      config
      createdAt
      updatedAt
      userId
    }
  }
`;

export const GQL_SHIPMENT_CHANNEL_CONFIG_UPDATE = gql`
  mutation updateShipmentChannelConfigProxy($input: ShipmentChannelConfigInput!) {
    updateShipmentChannelConfigProxy(input: $input) {
      config
      createdAt
      updatedAt
      userId
    }
  }
`;
