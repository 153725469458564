import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  CustomInput
} from 'reactstrap';
import { validator, paymentLinkUrl, REGEX_SLUG } from '../../../../utils';
import DatePicker from '../../../date-time-picker';
import RichEditor from '../../../rich-text-editor';
import { Controller } from 'react-hook-form';
import MultipleImageInput from "../../../multiple-image-input";

const WaqafForm = ({ data, isUpdate, hookForm, state, setState, setMultipleImageId, setIsRemoveAllProductImage, setDisableSubmitButton }) => {
  const { register, errors, control } = hookForm;
  const { link, date, invalidDescription, editorState } = state;
  const { setLink, setDate, setEditor } = setState;
  const { isTargetVisible } = data?.fundraisingSettings
    ? JSON.parse(data.fundraisingSettings)
    : { isTargetVisible: true };

  const handleEditor = value => setEditor(value);

  return (
    <div>
      <FormGroup>
        <Label for="name">Judul Wakaf*</Label>
        <Input
          type="text"
          name="name"
          id="name"
          invalid={Boolean(errors.name)}
          defaultValue={data.name}
          placeholder="Contoh: Wakaf Pembangunan Masjid"
          innerRef={register({
            required: validator.required,
            minLength: validator.minLength(5)
          })}
        />
        <FormFeedback>{errors.name?.message}</FormFeedback>
      </FormGroup>
      {isUpdate && (
        <FormGroup>
          <Label for="name">Slug*</Label>
          <Input
            type="text"
            name="link"
            id="link"
            invalid={errors.link}
            defaultValue={data.link}
            onChange={e => setLink(e.target.value)}
            innerRef={register({
              required: validator.required,
              pattern: validator.pattern(REGEX_SLUG)
            })}
          />
          <FormFeedback>{errors.link?.message}</FormFeedback>
          <small>{'contoh : ' + paymentLinkUrl(link)}</small>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="target">Target Wakaf*</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Rp</InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            name="target"
            id="target"
            invalid={Boolean(errors.target)}
            defaultValue={data.target}
            innerRef={register({
              required: validator.required,
              min: validator.min(1000),
              max: validator.max(10000000000)
            })}
          />
          <FormFeedback>{errors.target?.message}</FormFeedback>
        </InputGroup>
        <small>Wakaf menggunakan mata uang IDR(Rp)</small>
      </FormGroup>
      <FormGroup>
        <Label for="startAt">Waktu Mulai Penjualan</Label>
        <div className="mb-1">
          <Controller
            name="startAt"
            control={control}
            defaultValue={data.startAt || null}
            render={({ onChange, name, value }) => (
              <DatePicker
                id={name}
                placeholderText="Pilih Tanggal Mulai"
                showTimeSelect
                dateFormat="yyyy/MM/dd HH:mm"
                timeFormat="HH:mm"
                selected={value ? new Date(value) : null}
                minDate={new Date()}
                className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                onChange={onChange}
              />
            )}
          />
        </div>
        <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
        <small>
          Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk langsung
          membuka penjualan
        </small>
      </FormGroup>
      <FormGroup>
        <Label for="expiredDate">Batas Waktu Wakaf</Label>
        <div className="mb-1">
          <DatePicker
            id="expiredDate"
            placeholderText="Pilih tanggal atau kosongkan"
            dateFormat="yyyy/MM/dd"
            selected={date ? new Date(date) : null}
            minDate={new Date()}
            className="rui-datetimepicker form-control w-auto"
            onChange={d => setDate(d)}
          />
        </div>
        <small>Kami akan menutup wakaf setelah tanggal ini. Kosongkan untuk tidak terbatas</small>
      </FormGroup>
      <FormGroup>
        <Label for="reason">Tujuan Wakaf</Label>
        <Input
          type="text"
          name="reason"
          id="reason"
          placeholder="Contoh: Membantu biaya pembangunan Masjid"
          invalid={Boolean(errors.reason)}
          defaultValue={data.reason}
          innerRef={register()}
        />
        <FormFeedback>{errors.reason?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="receiver">Penerima Manfaat</Label>
        <Input
          type="text"
          name="receiver"
          id="receiver"
          placeholder="Contoh: Pak Abunawas dan Keluarganya"
          invalid={Boolean(errors.receiver)}
          defaultValue={data.receiver}
          innerRef={register()}
        />
        <FormFeedback>{errors.receiver?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="description">Cerita / Deskripsi Wakaf*</Label>
        <RichEditor onEditorStateChange={handleEditor} editorState={editorState} invalidState={invalidDescription} />
        <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
        <small>Ceritakan mengenai tujuan wakaf, rencana penggunaan dana, dll</small>
      </FormGroup>
      <FormGroup>
        <Label for="usage">Rincian Penggunaan Dana Jika Terkumpul</Label>
        <Input
          type="textarea"
          name="usage"
          id="usage"
          placeholder="Tulis sedetail mungkin. Contoh: Pembelian material bangunan, pembayaran tukang, dll"
          invalid={Boolean(errors.usage)}
          defaultValue={data.usage}
          innerRef={register()}
        />
        <FormFeedback>{errors.usage?.message}</FormFeedback>
      </FormGroup>

      <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />
      
      <FormGroup>
        <Label for="notes">Catatan</Label>
        <Input
          type="textarea"
          name="notes"
          id="notes"
          invalid={errors.notes}
          defaultValue={data.notes}
          innerRef={register()}
        />
        <FormFeedback>{errors.notes?.message}</FormFeedback>
        <small>Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional). </small>
      </FormGroup>
      <FormGroup>
        <Label for="redirectUrl">Redirect URL</Label>
        <Input
          type="text"
          name="redirectUrl"
          id="redirectUrl"
          placeholder="https://websiteanda.com/terimakasih"
          invalid={Boolean(errors.redirectUrl)}
          defaultValue={data.redirectUrl}
          innerRef={register({ pattern: validator.url })}
        />
        <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
        <small>Donatur akan dibawa kehalaman ini setelah membayar (opsional / bisa dikosongkan)</small>
      </FormGroup>
      <FormGroup>
        <h4 className="text-dark display-h4 mb-5">Opsi</h4>
        <CustomInput
          id="isTargetVisible"
          label="Tampilkan Target Wakaf"
          name="isTargetVisible"
          type="switch"
          color="primary"
          innerRef={register()}
          defaultChecked={isTargetVisible}
        />
      </FormGroup>
    </div>
  );
};

export default WaqafForm;
