import slugify from 'slug';

export const formatRp = value => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });
  return formatter.format(value).replace(/,00/, '');
};

export const delimiter = value => {
  const formatter = new Intl.NumberFormat('id-ID');
  return formatter.format(value).replace(/,00/, '');
};

export const volumetricWeight = (width = 0, length = 0, height = 0) => {
  return (parseInt(width) * parseInt(length) * parseInt(height)) / 6000;
};

export const roundDecimalNumber = number => (number < 1 ? 1 : Number(number.toFixed()));

export const truncateText = (source, maxLength) => {
  return source?.length > maxLength ? `${source.substring(0, maxLength).trim()}...` : source;
};

export const trimmedAndNoSpaces = str => str.trim().replace(/\s/g, '');

export const pathFormatterOfPaymentLink = ({ type, link, subType }) => {
  let path = '/';

  switch (type) {
    case 'generic_link':
      path = `/plink/${link}`;
      break;
    case 'digital_product':
      path = `/catalog/${link}`;
      break;
    case 'webinar':
      path = `/webinar/${link}`;
      break;
    case 'fundraising':
      switch (subType) {
        case 'FUNDRAISING_QURBAN':
          path = `/qurban/v2/${link}`;
          break;
        case 'FUNDRAISING_DONATION':
          path = `/galang-dana/v2/${link}`;
          break;
        case 'FUNDRAISING_WAQAF':
          path = `/wakaf/v2/${link}`;
          break;
        default:
          path = `/galang-dana/v2/${link}`;
          break;
      }
      break;
    case 'physical_product':
      path = `/product-catalog/${link}`;
      break;
    case 'event':
      path = `/event/${link}`;
      break;
    case 'coaching':
      path = `/coaching/${link}`;
      break;
    case 'course':
      path = `/course/${link}`;
      break;
    case 'cohort_based':
      path = `/bootcamp/${link}`;
      break;
    case 'audiobook':
      path = `/audiobook/${link}`;
      break;
    case 'ebook':
      path = `/ebook/${link}`;
      break;
    case 'podcast':
      path = `/podcast/${link}`;
      break;
    case 'bundling':
      path = `/bundling/${link}`;
      break;
    default:
      break;
  }

  return path;
};

export const youtubeUrlFormat = url => {
  const separator = url.includes('watch?v=') ? 'watch?v=' : '/';
  const urlSplitted = url.split(separator);
  return `https://www.youtube.com/embed/${urlSplitted[urlSplitted.length - 1]}`;
};

export const tiktokUrlFormat = url => {
  const urlparts = url.split('?');
  const urlSplitted = urlparts[0].split('/');
  return `https://www.tiktok.com/video/${urlSplitted[urlSplitted.length - 1]}`;
};

export const spotifyUrlFormat = url => {
  const urlparts = url.split('?');
  const urlSplitted = urlparts[0].split('/');
  return `https://open.spotify.com/embed/${urlSplitted[urlSplitted.length - 2]}/${
    urlSplitted[urlSplitted.length - 1]
  }?utm_source=generator`;
};

export const toCapitalizeEachText = (sentence = '') => {
  return sentence
    .split(' ')
    ?.map(text => `${text?.charAt(0).toUpperCase()}${text?.substring(1).toLowerCase()}`)
    ?.join(' ');
};

export const capitalizeFirstLetter = string => string?.charAt(0).toUpperCase() + string?.slice(1);

export const slug = (text = '') => slugify(text.normalize('NFKD'));

export const convertToIndonesia = value => {
  switch (value) {
    case 'employee':
      return 'Karyawan';
    case 'company':
      return 'Perusahaan';
    case 'not_paid':
      return 'Tidak Dibayar';
    case 'paid':
      return 'Dibayar';
    default:
      return 'value tidak terbaca';
  }
};

export const taxMethod = method => {
  switch (method) {
    case 'gross_up':
      return 'Gross Up';
    case 'gross':
      return 'Gross';
    case 'netto':
      return 'Nett';
    default:
      return 'Methode  pajak tidak terdeteksi';
  }
};

// function to format type of webhook
export const webhookType = (type = '') => {
  if (type === 'payment.received') {
    return 'Pembayaran masuk';
  } else if (type === 'payment.reminder') {
    return 'Reminder payment';
  } else if (type === 'shipper.status') {
    return 'Status Pengiriman';
  } else if (type === 'magic.link') {
    return 'Portal Login';
  } else if (type === 'testing') {
    return 'Testing URL';
  } else if (type === 'membership.newMemberRegistered') {
    return 'Member Baru Membership';
  } else if (type === 'membership.memberUnsubscribed') {
    return 'Member Unsubscribe Membership';
  } else if (type === 'membership.memberExpired') {
    return 'Member Expired';
  }

  return 'Unknown';
};

// function to filter audio file inside array of objects
export const filterContentFormat = (arr, format) => {
  let regex;

  if (format === 'audio') {
    regex = /\.(?:wav|mp3|flac|ogg|aac)$/i;
  } else if (format === 'ebook') {
    regex = /\.(?:epub|pdf|mobi|azw3)$/i;
  } else {
    throw new Error('Error! Please select or add new format.');
  }

  return arr.filter(item => (regex.test(item.label) ? item.label : null));
};

// check if a number is whole or have decimal
const isNumDecimal = n => {
  const result = n - Math.floor(n) !== 0;

  if (result) return n.toFixed(2);

  return n;
};

// use on db to dashboard for qurban quantity
export const formatQtyQurbanDivide = (qty, type, displayForMerchant) => {
  const qtyPerSeven = qty / 7;
  const qtyPerTen = qty / 10;

  if (displayForMerchant && type === 'SAPI') {
    // format the return value for merchant dashboard
    return `${isNumDecimal(qtyPerSeven)} ekor (${qty} orang / paket)`;
  } else if (type === 'SAPI') {
    return isNumDecimal(qtyPerSeven);
  }

  if (displayForMerchant && type === 'UNTA') {
    // format the return value for merchant dashboard
    return `${isNumDecimal(qtyPerTen)} ekor (${qty} orang / paket)`;
  } else if (type === 'UNTA') {
    return isNumDecimal(qtyPerTen);
  }

  return qty;
};

// use on dashboard to db for qurban quantity
export const formatQtyQurbanMultiply = (qty, type) => {
  if (type === 'SAPI') {
    const qtySevenPerOne = qty * 7;
    return qtySevenPerOne;
  }
  if (type === 'UNTA') {
    const qtyTenPerOne = qty * 10;
    return qtyTenPerOne;
  }

  return qty;
};

export const isJSON = data => {
  let ret = true;

  if (!data) {
    return false;
  }

  try {
    JSON.parse(data);
  } catch (e) {
    ret = false;
  }

  return ret;
};

export const getInitialDownloadableValue = data => {
  if (data && data?.isDownloadable !== null) {
    return data?.isDownloadable;
  }

  return true;
};

export const simplifyRupiah = amount => {
  const num = parseFloat(String(amount).replace(/,/g, ''));
  if (isNaN(num)) {
    return 'Invalid input';
  }

  if (num >= 1000000) {
    const juta = Math.floor(num / 1000000);
    return juta + 'jt';
  }

  const ribu = Math.floor(num / 1000);
  return ribu + 'rb';
};

export const formatIndonesianNumber = phoneNumber => {
  // Check if the number starts with '62'
  if (phoneNumber.startsWith('62')) {
    // Replace '62' at the start with '0'
    return '0' + phoneNumber.slice(2);
  }
  // If the number does not start with '62', return it as is
  return phoneNumber;
};

export const detectDelimiter = csv => {
  const firstLine = csv.split('\n')[0];
  const commaCount = (firstLine.match(/,/g) || []).length;
  const semicolonCount = (firstLine.match(/;/g) || []).length;

  // Use the delimiter that occurs more frequently, or defaults to comma
  return commaCount > semicolonCount ? ',' : ';';
};

export const formatNumberWithCommas = number => {
  if (!number) return number;

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// This is not necessary to use everytime.
// But sometime there is an exact value with different count returned by ackee (analytic)
export const aggregateAnalyticValue = arr => {
  if (!arr?.length) {
    return arr;
  }

  const data = arr.reduce((acc, item) => {
    if (acc[item.value]) {
      acc[item.value].count += item.count;
    } else {
      acc[item.value] = { ...item };
    }
    return acc;
  }, {});

  return Object.values(data);
};
