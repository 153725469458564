import { gql } from '@apollo/client';

export const GQL_INSERT_BROADCAST = gql`
  mutation insertBroadcastBySlug($input: BroadcastInput!) {
    insertBroadcastBySlug(input: $input) {
      campaign
      channel
      createdAt
      destination
      id
      isEmailSent
      message
      name
      recipientTotal
      sendDate
      status
      subject
      unlayerDesign
      updatedAt
      userId
    }
  }
`;

export const GQL_EDIT_BROADCAST = gql`
  mutation editBroadcastBySlug($input: BroadcastInput!) {
    editBroadcastBySlug(input: $input) {
      campaign
      channel
      createdAt
      destination
      id
      isEmailSent
      message
      name
      recipientTotal
      sendDate
      status
      subject
      unlayerDesign
      updatedAt
      userId
    }
  }
`;

export const GQL_BROADCAST_PAGE = gql`
  query getBroadcastPage(
    $page: Int
    $pageSize: Int
    $search: BroadcastSearchInput
    $searchAny: BroadcastSearchInput
    $sortDirection: SortDirection
    $sortField: BroadcastSortField
  ) {
    getBroadcastPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        campaign
        channel
        createdAt
        destination
        id
        isEmailSent
        message
        name
        recipientTotal
        sendDate
        status
        subject
        unlayerDesign
        updatedAt
        userId
      }
      nextPage
    }
  }
`;

export const GQL_BROADCAST_WITH_ANALYTICS = gql`
  query getBroadcastWithAnalytics($id: ID) {
    getBroadcastWithAnalytics(id: $id) {
      campaign
      channel
      createdAt
      destination
      id
      isEmailSent
      message
      name
      recipientTotal
      sendDate
      status
      subject
      unlayerDesign
      updatedAt
      userId
      sent
      open
      click
      bounced
    }
  }
`;

export const GQL_BROADCAST_RESULT_PAGE = gql`
  query getBroadcastResultPage($page: Int, $pageSize: Int, $id: ID, $status: String) {
    getBroadcastResultPage(page: $page, pageSize: $pageSize, id: $id, status: $status) {
      items {
        receiptTo
      }
      nextPage
    }
  }
`;

export const GQL_BROADCAST = gql`
  query getBroadcast($id: ID) {
    getBroadcast(id: $id) {
      campaign
      channel
      createdAt
      destination
      id
      isEmailSent
      message
      name
      recipientTotal
      sendDate
      status
      subject
      unlayerDesign
      updatedAt
      userId
    }
  }
`;

export const GQL_INSERT_MAILER_BLAST = gql`
  mutation insertMailerBlast($input: InputMailerBlast) {
    insertMailerBlast(input: $input) {
      status
      response
    }
  }
`;

export const GQL_CANCEL_SCHEDULE_MAILER_BLAST = gql`
  mutation cancelScheduledBroadcast($broadcastId: ID) {
    cancelScheduledBroadcast(broadcastId: $broadcastId) {
      status
      response
    }
  }
`;

export const GQL_ANALYTICS_MAILER_BLAST = gql`
  query getAnalyticsMailerBlast($campaign: String) {
    getAnalyticsMailerBlast(campaign: $campaign) {
      sent
      open
      click
      bounced
      sentDate
    }
  }
`;

export const GQL_PRODUCT_FILTERED_LIST = gql`
  query getProductFilteredList($name: String!) {
    getProductFilteredList(name: $name) {
      id
      name
      type
    }
  }
`;
