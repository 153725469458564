import {
  FILTER_PAYMENT_LINK_BY_Q,
  UPDATE_PAYMENT_LINKS,
  FILTER_PAYMENT_LINK_BY_STATUS,
  PAYMENT_LINK_DETAILS,
  FILTER_PAYMENT_LINK_START_DATE,
  FILTER_PAYMENT_LINK_END_DATE
} from '../actions/payment-links';

export const filterPaymentLinkByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_LINK_BY_Q:
      return action.payload;
  }
  return state;
};

export const UpdatePaymentLinksReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_LINKS:
      return action.payload;
  }
  return state;
};

export const filterPaymentLinkByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_LINK_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const paymentLinkDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case PAYMENT_LINK_DETAILS:
      return action.payload;
  }
  return state;
};


export const paymentLinkFilterStartDateReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_LINK_START_DATE:
      return action.payload;
  }
  return state;
};

export const paymentLinkFilterEndDateReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_LINK_END_DATE:
      return action.payload;
  }
  return state;
};
