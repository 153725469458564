import { gql } from '@apollo/client';

export const GQL_INSERT_WRITING_CHAPTER = gql`
  mutation insertWritingChapterWithCache($input: WritingChapterInput) {
    insertWritingChapterWithCache(input: $input) {
      id
    }
  }
`;

export const GQL_UPDATE_WRITING_CHAPTER = gql`
  mutation editWritingChapter($input: WritingChapterInput) {
    editWritingChapter(input: $input) {
      id
    }
  }
`;

export const GQL_UPDATE_MULTIPLE_WRITING_CHAPTER = gql`
mutation editMultipleWritingChapter($input: [WritingChapterInput]) {
  editMultipleWritingChapter(input: $input) {
    id
  }
}
`;

export const GQL_GET_ALL_WRITING_CHAPTER_WITH_DRAFT_STATUS = gql`
  query getWritingChapterFilteredListWithDraftStatus($search: WritingChapterSearchInput, $limit: Int, $sortField: WritingChapterSortField, $sortDirection: SortDirection) {
    getWritingChapterFilteredListWithDraftStatus(search: $search, limit: $limit, sortField: $sortField, sortDirection: $sortDirection) {
      id
      title
      paymentType
      amount
      releasedAt
      notes
      position
      updatedAt
      status
      title
      paymentLinkId
      isAnyDraft
      isPublished
      coverImage {
        url
        height
        width
      }
    }
  }
`;

export const GQL_INSERT_WRITING_CHAPTER_CONTENT = gql`
  mutation insertContentWritingChapter($input: WritingChapterContentInput) {
    insertContentWritingChapter(input: $input) {
      id
    }
  }
`;

export const GQL_UPDATE_WRITING_CHAPTER_CONTENT = gql`
  mutation updateContentWritingChapter($input: WritingChapterContentInput) {
    updateContentWritingChapter(input: $input) {
      id
    }
  }
`;

export const GQL_SAVE_WRITING_CHAPTER_CONTENT_DRAFT = gql`
  mutation saveDraftContentWritingChapter($input: WritingChapterContentInput) {
    saveDraftContentWritingChapter(input: $input) {
      id
    }
  }
`;

export const GQL_GET_WRITING_CONTENT = gql`
  query getContentWritingChapter($paymentLinkId: ID!, $writingChapterId: ID, $writingType: WritingType!) {
    getContentWritingChapter(paymentLinkId: $paymentLinkId, writingChapterId: $writingChapterId, writingType: $writingType) {
      id
      content
      draftContent
      updatedAt
      errorMessage
    }
  }
`;

export const GQL_DELETE_WRITING_CONTENT_WITH_CACHE = gql`
  mutation deleteWritingChapterWithCache($id: ID!) {
    deleteWritingChapterWithCache(id: $id) {
      id
      errorMessage
    }
  }
`;

export const GQL_REORDER_WRITING_CHAPTER = gql`
  mutation reorderWritingChapters($input: ReorderChapterInput) {
    reorderWritingChapters(input: $input) {
      id
    }
  }
`;
