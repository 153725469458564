import { gql } from '@apollo/client';

export const GQL_ALL_BALANCE_HISTORY_KBAR = gql`
  query getBalanceHistoryFilteredList($search: BalanceHistorySearchInput, $limit: Int = 10) {
    getBalanceHistoryFilteredList(search: $search, limit: $limit) {
      id
      createdAt
      paymentMethod
      status
      paymentLink {
        id
        name
      }
    }
  }
`;

export const GQL_ALL_BALANCE_HISTORY = gql`
  query getAllBalanceHistoryPage($input: BalanceHistoryInput!) {
    getAllBalanceHistoryPage(input: $input) {
      items {
        id
        affiliateUserId
        balance
        balanceHistoryType
        createdAt
        credit
        debit
        paymentLinkId
        paymentMethod
        broadcastId
        broadcast {
          name
        }
        subscriptionId
        updatedAt
        customerId
        givenCustomerId
        userId
        xenditTransactionId
        parentId
        status
        subscription {
          id
          name
          description
          link
        }
        paymentLink {
          id
          name
          status
          link
          description
          type
        }
        paymentLinkTransaction {
          orderVariant
          isAdminFeeBorneByCustomer
          isChannelFeeBorneByCustomer
          couponUsage {
            coupons {
              code
            }
          }
        }
        customer {
          id
          name
          email
          mobile
        }
        givenCustomer {
          id
          name
          email
          mobile
        }
        user {
          payChannelConfig {
            id
            isAdminFeeBorneByCustomer
            isChannelFeeBorneByCustomer
          }
        }
      }
      nextPage
    }
  }
`;

export const GQL_ALL_BALANCE_HISTORY_AFFILIATE = gql`
  query getAllBalanceHistoryPage($input: BalanceHistoryInput!) {
    getAllBalanceHistoryPage(input: $input) {
      items {
        id
        affiliateUserId
        balance
        balanceHistoryType
        createdAt
        credit
        debit
        paymentLinkId
        paymentMethod
        subscriptionId
        updatedAt
        customerId
        userId
        xenditTransactionId
        paymentLinkTransaction {
          couponUsage {
            coupons {
              code
            }
          }
        }
        parentId
        status
        subscription {
          id
          name
          description
          link
        }
        paymentLink {
          id
          name
          status
          link
          description
          type
        }
        customer {
          id
          name
          email
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_BALANCE_HISTORY_TOTAL = gql`
  query getBalanceHistoryPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
    $searchAny: BalanceHistorySearchInput
    $search: BalanceHistorySearchInput
  ) {
    getBalanceHistoryPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      total
    }
  }
`;

export const GET_BALANCE_HISTORY_PHYSICAL = gql`
  query getBalanceHistoryFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
    $searchAny: BalanceHistorySearchInput
    $search: BalanceHistorySearchInput
  ) {
    getBalanceHistoryFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      credit
      status
      updatedAt
      createdAt
      trackOrderId
      orderStatus
      paymentMethod
      customerId
      customeField {
        data
      }
      paymentLinkTransaction {
        orderVariant
        urlParamTrackingData
        couponUsage {
          coupons {
            code
          }
        }
      }
      customer {
        name
        email
        mobile
        customerAddress {
          area
          id
          district
          subdistrict
        }
      }
    }
  }
`;

// replacement of GQL_BALANCE_HISTORY_PAGE_WITH_USER
export const GET_BALANCE_HISTORY_WITH_FEES = gql`
  query getBalanceHistoryFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
    $searchAny: BalanceHistorySearchInput
    $search: BalanceHistorySearchInput
  ) {
    getBalanceHistoryFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      credit
      status
      updatedAt
      createdAt
      paymentMethod
      customerId
      givenCustomerId
      customeField {
        data
      }
      fee {
        balanceHistoryType
        debit
      }
      paymentLinkTransaction {
        orderVariant
        mudhohi
        qurbanType
        qty
        isAdminFeeBorneByCustomer
        isChannelFeeBorneByCustomer
        isCrossSell
        urlParamTrackingData
        couponUsage {
          coupons {
            code
          }
        }
      }
      affiliateUser {
        id
        name
      }
      customer {
        name
        email
        mobile
      }
      givenCustomer {
        name
        email
        mobile
      }
    }
  }
`;

export const GET_BALANCE_HISTORY_LIST_CERTIFICATE = gql`
  query getBalanceHistoryListWithCertificate(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
    $searchAny: BalanceHistorySearchInput
    $search: BalanceHistorySearchInput
    $ticketId: ID
    $cursor: Timestamp
  ) {
    getBalanceHistoryListWithCertificate(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
      ticketId: $ticketId
      cursor: $cursor
    ) {
      id
      credit
      status
      paymentMethod
      updatedAt
      createdAt
      customerId
      givenCustomerId
      paymentLinkTransactionId
      paymentLinkId
      attendance
      totalSubmissions
      totalGrade
      customeField {
        data
      }
      certificateHistory {
        balanceHistoryId
        createdAt
        customerId
        paymentLinkId
        paymentLinkTransactionId
        updatedAt
        id
        serialNumber
        code
      }
      paymentLink {
        name
        eventStartAt
        eventEndAt
        createdAt
        gradeThreshold
        assignments {
          status
          id
        }
        user {
          account {
            name
          }
        }
        certificate {
          layout
          name
          templateImage {
            url
            title
          }
        }
      }
      paymentLinkTransaction {
        id
        isAdminFeeBorneByCustomer
        isChannelFeeBorneByCustomer
        isCrossSell
        isContentBlocked
        invoiceCode
        orderVariant
        urlParamTrackingData
        eventHistory {
          checkInAt
          ticket {
            name
          }
        }
        couponUsage {
          coupons {
            code
          }
        }
      }
      affiliateUser {
        id
        name
      }
      customer {
        id
        name
        email
        mobile
      }
      givenCustomer {
        id
        name
        email
        mobile
      }
    }
  }
`;

// isolated query for all balance history product with no fees
export const GET_BALANCE_HISTORY_LIST = gql`
  query getBalanceHistoryFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
    $searchAny: BalanceHistorySearchInput
    $search: BalanceHistorySearchInput
  ) {
    getBalanceHistoryFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      status
      createdAt
      paymentMethod
      customerId
      givenCustomerId
      credit
      paymentLinkTransaction {
        id
        urlParamTrackingData
        qty
        isContentBlocked
        orderVariant
        couponUsage {
          coupons {
            code
          }
        }
      }
      customeField {
        data
      }
      affiliateUser {
        id
        name
      }
      customer {
        name
        email
        mobile
      }
      givenCustomer {
        name
        email
        mobile
      }
    }
  }
`;

export const RESEND_RECEIPT_BY_BALANCE_HISTORY_ID = gql`
  query resendReceiptByBalanceHistoryId($id: ID!) {
    resendReceiptByBalanceHistoryId(id: $id) {
      id
    }
  }
`;
