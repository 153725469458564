import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ModalBody, Form, FormGroup, Label, Input, FormFeedback, Alert } from 'reactstrap';
import { GQL_CUSTOMER_FILTERED_LIST, INSERT_CUSTOMER, UPDATE_CUSTOMER } from '../../gqls';
import { validator } from '../../utils';
import { getDefaultStatus, customerStatus } from '../../utils/statuses';
import { updateCustomers } from '../../actions/customers';
import SubmitButton from '../submit-button';

const ModalBodyCustomer = ({ close, data = {}, onSuccess }) => {
  const [insertCustomer, { loading: insertLoading }] = useMutation(INSERT_CUSTOMER);
  const [updateCustomer, { loading: updateLoading }] = useMutation(UPDATE_CUSTOMER);
  const [getCustomer] = useLazyQuery(GQL_CUSTOMER_FILTERED_LIST, {
    fetchPolicy: 'no-cache'
  });

  const { register, handleSubmit, errors } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isUpdate = Boolean(data?.id);

  const onSubmit = async values => {
    setLoading(true);

    const email = values.email.toLowerCase();
    const input = { ...values, email };

    try {
      if (isUpdate) {
        input.id = data.id;
        delete input.status;

        const updatedCustomer = await updateCustomer({
          variables: { input }
        });

        if (!updatedCustomer?.data?.editCustomer?.id) {
          throw new Error('Failed to update customer');
        }
      } else {
        // Get customer
        let customer = await getCustomer({
          variables: {
            limit: 1,
            search: {
              email: [{ operator: 'eq', value: input.email }]
            }
          }
        });

        customer = customer?.data?.getCustomerFilteredList?.[0] || {};

        // If exist, then just update the status
        if (customer.id && customer.status !== 'active') {
          await updateCustomer({
            variables: {
              input: {
                id: customer.id,
                status: 'active'
              }
            }
          });
        } else if (!customer.id) {
          await insertCustomer({
            variables: { input }
          });
        }

        dispatch(updateCustomers(Math.random()));
      }

      setSubmitError(false);
      setLoading(false);
      close();
      onSuccess('success=true');

      window.analytics.track('Create Customer', values);
    } catch (error) {
      setLoading(false);
      setSubmitError(true);
      window.analytics.track('Create Customer - ERROR', values);
    }
  };

  return (
    <ModalBody>
      {submitError && (
        <Alert color="danger" className="mb-20">
          Error! Email sudah terdaftar di akun anda. Silahkan gunakan email lain atau hubungi CS
        </Alert>
      )}
      <p>
        <small>
          Kami secara otomatis mengkoneksikan pembayaran dan data pelanggan kamu. Membuatmu bisa melihat histori
          pembayaran pelanggan dengan mudah.
        </small>
      </p>

      <hr />

      <Form onSubmit={handleSubmit(onSubmit)} id="create-customer">
        <FormGroup>
          <Label for="name">Nama*</Label>
          <Input
            type="text"
            name="name"
            id="name"
            defaultValue={data?.name}
            invalid={Boolean(errors.name)}
            innerRef={register({ required: validator.required, minLength: validator.minLength(3) })}
          />
          <FormFeedback>{errors.name?.message}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="email">Email*</Label>
          <Input
            type="email"
            name="email"
            id="email"
            defaultValue={data?.email}
            invalid={Boolean(errors.email)}
            innerRef={register({
              required: validator.required,
              pattern: validator.email
            })}
          />
          <FormFeedback>{errors.email?.message}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="mobile">Nomor HP/Telepon*</Label>
          <Input
            type="tel"
            name="mobile"
            id="mobile"
            defaultValue={data?.mobile}
            invalid={Boolean(errors.mobile)}
            innerRef={register({
              required: validator.required,
              pattern: validator.phone,
              minLength: validator.minLength(10),
              maxLength: validator.maxLength(15)
            })}
          />
          <FormFeedback>{errors?.mobile?.message}</FormFeedback>
        </FormGroup>
        <Input type="hidden" name="status" value={getDefaultStatus(customerStatus)[0]} innerRef={register}></Input>

        <SubmitButton
          size="lg"
          block
          color="brand"
          type="submit"
          className="text-center"
          text="Simpan"
          loading
          isLoading={loading || insertLoading || updateLoading}
        />
      </Form>
    </ModalBody>
  );
};

export default ModalBodyCustomer;
