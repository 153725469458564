import { UPDATE_BATCH_PAYMENT, FILTER_BATCH_PAYMENT_BY_STATUS, BATCH_PAYMENT_DETAILS, FILTER_BATCH_PAYMENT_BY_Q, FILTER_BATCH_PAYMENT_HISTORY_BY_Q, FILTER_BATCH_PAYMENT_HISTORY_BY_STATUS, UPDATE_BATCH_PAYMENT_HISTORY } from '../actions/batch-payment';

export const filterBatchPaymentByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BATCH_PAYMENT_BY_Q:
      return action.payload;
  }
  return state;
};

export const updateBatchPaymentReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_BATCH_PAYMENT:
      return action.payload;
  }
  return state;
};

export const filterBatchPaymentByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BATCH_PAYMENT_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const batchPaymentDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case BATCH_PAYMENT_DETAILS:
      return action.payload;
  }
  return state;
};

// BATCH PAYMENT HISTORY

export const updateBatchPaymentHistoryReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_BATCH_PAYMENT_HISTORY:
      return action.payload;
  }
  return state;
};

export const filterBatchPaymentHistoryByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BATCH_PAYMENT_HISTORY_BY_Q:
      return action.payload;
  }
  return state;
};

export const filterBatchPaymentHistoryByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BATCH_PAYMENT_HISTORY_BY_STATUS:
      return action.payload;
  }
  return state;
};

