import {
  ORDER_DETAILS,
  UPDATE_ORDER,
  FILTER_ORDER_BY_CUSTOMER,
  FILTER_ORDER_BY_STATUS,
  TRACKING_ORDER,
} from '../actions/order';

export const orderDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case ORDER_DETAILS:
      return action.payload;
  }
  return state;
};

export const updateOrderReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_ORDER:
      return action.payload;
  }
  return state;
};

export const filterOrderByCustomerReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_ORDER_BY_CUSTOMER:
      return action.payload;
  }
  return state;
};

export const filterOrderByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_ORDER_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const trackingOrderReducer = (state = '', action) => {
  switch (action.type) {
    case TRACKING_ORDER:
      return action.payload;
  }
  return state;
};
