export const FILTER_BATCH_PAYMENT_BY_Q = 'FILTER_BATCH_PAYMENT_BY_Q';
export const filterBatchPaymentByQ = (q) => ({
  type: FILTER_BATCH_PAYMENT_BY_Q,
  payload: q,
});

export const UPDATE_BATCH_PAYMENT = 'UPDATE_BATCH_PAYMENT';
export const updateBatchPayment = (q) => ({
  type: UPDATE_BATCH_PAYMENT,
  payload: q,
});

export const FILTER_BATCH_PAYMENT_BY_STATUS = 'FILTER_BATCH_PAYMENT_BY_STATUS';
export const filterBatchPaymentByStatus = (q) => ({
  type: FILTER_BATCH_PAYMENT_BY_STATUS,
  payload: q,
});

export const BATCH_PAYMENT_DETAILS = 'BATCH_PAYMENT_DETAILS';
export const batchPaymentDetails = (q) => ({
  type: BATCH_PAYMENT_DETAILS,
  payload: q,
});

// BATCH PAYMENT HISTORY

export const UPDATE_BATCH_PAYMENT_HISTORY = 'UPDATE_BATCH_PAYMENT_HISTORY';
export const updateBatchPaymentHistory = (q) => ({
  type: UPDATE_BATCH_PAYMENT_HISTORY,
  payload: q,
});

export const FILTER_BATCH_PAYMENT_HISTORY_BY_Q = 'FILTER_BATCH_PAYMENT_HISTORY_BY_Q';
export const filterBatchPaymentHistoryByQ = (q) => ({
  type: FILTER_BATCH_PAYMENT_HISTORY_BY_Q,
  payload: q,
});

export const FILTER_BATCH_PAYMENT_HISTORY_BY_STATUS = 'FILTER_BATCH_PAYMENT_HISTORY_BY_STATUS';
export const filterBatchPaymentHistoryByStatus = (q) => ({
  type: FILTER_BATCH_PAYMENT_HISTORY_BY_STATUS,
  payload: q,
});
