export const SUPPORT_TAB = 'SUPPORT_TAB';
export const supportTab = q => ({
  type: SUPPORT_TAB,
  payload: q
});

export const SUPPORT_DETAILS = 'SUPPORT_DETAILS';
export const supportDetails = (q) => ({
  type: SUPPORT_DETAILS,
  payload: q,
});
