import {
  FILTER_MEMBERSHIP_BY_Q,
  UPDATE_MEMBERSHIPS,
  FILTER_MEMBERSHIP_BY_STATUS,
  MEMBERSHIP_PAGES,
} from '../actions/memberships';

export const filterMembershipByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_MEMBERSHIP_BY_Q:
      return action.payload;
  }
  return state;
};

export const UpdateMembershipsReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_MEMBERSHIPS:
      return action.payload;
  }
  return state;
};

export const filterMembershipByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_MEMBERSHIP_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const membershipPagesReducer = (state = '', action) => {
  switch (action.type) {
    case MEMBERSHIP_PAGES:
      return action.payload;
  }
  return state;
};
