import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

import { validator } from '../../../../utils';
import UnlayerTemplates from '../../../unlayer-templates';

const EmailForm = ({ data, formState, isDuplicate, isUpdate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const { register, errors, setValue } = formState;
  const landingPageCollections = useSelector(state => state.landingPageCollections);

  const setCurrentTemplate = async () => {
    const currentCollection = landingPageCollections.find(collection => collection.title === selectedTemplate);
    setValue('unlayerDesign', JSON.stringify(currentCollection.design));
    setValue('message', JSON.parse(currentCollection.result));
  };

  useEffect(() => {
    if (selectedTemplate !== 'default') {
      register('unlayerDesign');
      register('message');
      setCurrentTemplate();
    }
  }, [selectedTemplate]);

  return (
    <div>
      {isUpdate && (
        <FormGroup>
          <Label for="campaign">Kampanye*</Label>
          <Input
            type="text"
            name="campaign"
            id="campaign"
            defaultValue={data?.campaign}
            invalid={!!errors.campaign}
            innerRef={register({ required: validator.required, minLength: validator.minLength(5) })}
          />
          <FormFeedback>{errors.campaign?.message}</FormFeedback>
          <small>Kampanye harus unik, berguna untuk mendapatkan statistik broadcast anda.</small>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="subject">Judul Email*</Label>
        <Input
          type="text"
          name="subject"
          id="subject"
          defaultValue={data?.subject}
          invalid={!!errors.subject}
          innerRef={register({ required: validator.required, minLength: validator.minLength(5) })}
        />
        <FormFeedback>{errors.subject?.message}</FormFeedback>
      </FormGroup>
      {!isDuplicate && (
        <>
          <hr />
          <UnlayerTemplates
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            isUpdate={isUpdate}
            isBroadcast={true}
            title="Template Email"
          />
        </>
      )}
      {/* <FormGroup>
        <Label for="message">Pesan Email</Label>
        <Input
          type="textarea"
          name="message"
          id="message"
          defaultValue={data?.message}
          invalid={!!errors.message}
          innerRef={register()}
        />
        <FormFeedback>{errors.name?.message}</FormFeedback>
        <small>
          Anda dapat mengabaikan ini, jika ingin mengirim pesan yang lebih kompleks menggunakan HTML builder.
        </small>
      </FormGroup> */}
    </div>
  );
};

EmailForm.defaultProps = {
  isUpdate: false,
  isDuplicate: false
};

export default EmailForm;
