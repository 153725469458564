import {
  FILTER_SUBSCRIPTION_CUSTOMER_BY_Q,
  UPDATE_SUBSCRIPTION_CUSTOMERS,
  FILTER_SUBSCRIPTION_CUSTOMER_BY_STATUS,
  SUBSCRIPTION_CUSTOMER_DETAILS,
} from '../actions/subscription-customers';

export const filterSubscriptionCustomerByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_SUBSCRIPTION_CUSTOMER_BY_Q:
      return action.payload;
  }
  return state;
};

export const UpdateSubscriptionCustomersReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_CUSTOMERS:
      return action.payload;
  }
  return state;
};

export const filterSubscriptionCustomerByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_SUBSCRIPTION_CUSTOMER_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const subscriptionCustomerDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case SUBSCRIPTION_CUSTOMER_DETAILS:
      return action.payload;
  }
  return state;
};
