/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';

import { GET_PL_WITH_TRANSACTIONS_EVENT } from '../../gqls/payment-links';
import { GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { paymentLinkDetails } from '../../actions/payment-links';
import { updatePaginatedData } from '../../utils';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const EventDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = { search: { id: [{ operator: 'eq', value: id }] } };
  const { loading, error, data, fetchMore } = useQuery(GET_PL_WITH_TRANSACTIONS_EVENT, {
    variables: gparam
  });
  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  const pl = _.get(data, 'getPaymentLinkPage.items[0]');
  if (pl) dispatch(paymentLinkDetails(pl));

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl, pl]);

  useEffect(() => {
    if (pl?.id && !pl?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: pl?.id } });
    }
  }, [pl]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  if (data && data.getPaymentLinkPage && data.getPaymentLinkPage.items.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser?.name || '',
          '/events': 'Event / Acara'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{pl?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default EventDetail;
