import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';

const getYtbId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const EmbedViewer = ({ embedUrl }) => {
  if (!embedUrl) return null;

  const isFromYoutube = /^(?:https?:\/\/)?(?:[^.]+\.)?youtube\.com|youtu\.be(\/.*)?$/.test(embedUrl);

  if (isFromYoutube) {
    return (
      <Row className="mt-10">
        <Col>
          <iframe
            src={`https://www.youtube.com/embed/${getYtbId(embedUrl)}`}
            title="Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="preview-embed-youtube small"
          />
        </Col>
      </Row>
    );
  }

  /* eslint-disable jsx-a11y/media-has-caption */
  if (embedUrl.includes('.spotlightr.com')) {
    return (
      <Row className="mt-10">
        <Col>
          <iframe
            src={embedUrl}
            title="Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="preview-embed-youtube"
          />
        </Col>
      </Row>
    );
  }

  if (embedUrl.includes('.amazonaws.com/')) {
    return (
      <Row className="mt-10">
        <Col>
          <video controls="controls" controlsList="nodownload" className="preview-embed-youtube">
            <source src={embedUrl} type="video/mp4" />
          </video>
        </Col>
      </Row>
    );
  }

  if (embedUrl.includes('api.vadoo.tv')) {
    const vadooVideoId = embedUrl.split('?vid=')[1];
    return (
      <Row className="mt-10">
        <Col>
          <iframe
            src={`https://api.vadoo.tv/iframe_test?id=${vadooVideoId}`}
            title="Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="preview-embed-youtube"
          />
        </Col>
      </Row>
    );
  }

  const wistiaId = embedUrl.split('/')[4];

  useEffect(() => {
    let script;
    let secondScript;

    if (wistiaId) {
      script = document.createElement('script');
      secondScript = document.createElement('script');

      script.src = `https://fast.wistia.com/embed/medias/${wistiaId}.jsonp`;
      script.async = true;

      secondScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      secondScript.async = true;

      document.body.appendChild(script);
      document.body.appendChild(secondScript);
    }

    return () => {
      if (wistiaId) {
        document.body.removeChild(script);
        document.body.removeChild(secondScript);
      }
    };
  }, [wistiaId]);

  if (embedUrl.includes('wistia.com')) {
    return (
      <Col>
        <div className="wistia_responsive_padding">
          <div className="wistia_responsive_wrapper">
            <div className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}>
              <div className="wistia_swatch" style={{ opacity: '1' }}>
                <img
                  src={`https://fast.wistia.com/embed/medias/${wistiaId}/swatch`}
                  alt=""
                  aria-hidden="true"
                  className="wistia__image"
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  if (embedUrl.includes('player.vdocipher.com')) {
    return (
      <Row className="mt-10">
        <Col>
          <iframe
            src={embedUrl}
            title="Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="preview-embed-youtube"
          />
        </Col>
      </Row>
    );
  }

  if (embedUrl.includes('video.bunnycdn.com')) {
    const url = embedUrl.split('/');
    const length = url.length;

    const constructedUrl = `https://iframe.mediadelivery.net/embed/${url[length - 2]}/${
      url[length - 1]
    }?autoplay=false`;

    return (
      <Row className="mt-10">
        <Col>
          <iframe
            src={constructedUrl}
            title="Preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="preview-embed-youtube"
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mt-10">
      <Col>
        <iframe
          src={embedUrl}
          title="Preview"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="preview-embed-youtube"
        />
      </Col>
    </Row>
  );
};

export default EmbedViewer;
