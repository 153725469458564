import { gql } from '@apollo/client';

export const GQL_CREATE_INSTRUCTOR = gql`
  mutation insertInstructor($input: InstructorInput) {
    insertInstructor(input: $input) {
      createdAt
      name
      description
      status
      paymentLinkId
      profile
      profileImageId
      updatedAt
      userId
    }
  }
`;
export const GQL_GET_INSTRUCTOR = gql`
  query getInstructorPage(
    $page: Int
    $pageSize: Int
    $sortField: InstructorSortField
    $sortDirection: SortDirection
    $search: InstructorSearchInput
  ) {
    getInstructorPage(
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
      search: $search
    ) {
      items {
        id
        createdAt
        description
        name
        profile
        status
        updatedAt
        profileImage {
          id
          url
        }
      }
    }
  }
`;

export const GQL_DELETE_COURSE_INSTRUCTOR = gql`
  mutation editInstructor($input: InstructorInput) {
    editInstructor(input: $input) {
      id
      createdAt
      description
      name
      paymentLinkId
      profile
      profileImageId
      status
      updatedAt
      userId
    }
  }
`;
