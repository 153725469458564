/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import { GQL_PHYSICAL_PRODUCT_GET, GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { paymentLinkDetails } from '../../actions/payment-links';
import { updatePaginatedData } from '../../utils';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const PhysicalProductDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const gparam = {
    search: {
      id: [{ operator: 'eq', value: id }]
    }
  };

  const currentUser = useSelector(state => state.currentUser);
  const updatePaymentLinks = useSelector(state => state.updatePaymentLinks);

  const { loading, error, data, fetchMore } = useQuery(GQL_PHYSICAL_PRODUCT_GET, {
    variables: gparam
  });

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkFilteredList[0]', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  const physicalProduct = _.get(data, 'getPaymentLinkFilteredList[0]');
  if (physicalProduct) dispatch(paymentLinkDetails(physicalProduct));

  useEffect(() => {
    updateData();
  }, [updatePaymentLinks]);

  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  useEffect(() => {
    if (physicalProduct?.id && !physicalProduct?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: physicalProduct?.id } });
    }
  }, [physicalProduct]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );
  if (data && data.getPaymentLinkFilteredList[0].id === null) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser?.name ?? ' ',
          '/physical-products': 'Produk Fisik'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{physicalProduct && physicalProduct.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default PhysicalProductDetail;
