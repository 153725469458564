import { gql } from '@apollo/client';

export const GQL_ORDER_PICKUP_REQUEST = gql`
  mutation pickupRequestOrder($isDropOff: Boolean!, $balanceHistoryIds: [ID]!) {
    pickupRequestOrder(isDropOff: $isDropOff, balanceHistoryIds: $balanceHistoryIds) {
      response
      status
    }
  }
`;

export const GQL_ORDER_CANCEL_PICKUP_REQUEST = gql`
  mutation cancelPickupRequestOrder($orderIds: [String]!, $balanceHistoryIds: [ID]!) {
    cancelPickupRequestOrder(orderIds: $orderIds, balanceHistoryIds: $balanceHistoryIds) {
      response
      status
    }
  }
`;

export const GQL_ORDER_PICKUP_REQUEST_TIMESLOT = gql`
  mutation pickupRequestTimeslotOrder(
    $startTime: String
    $endTime: String
    $timezone: String
    $balanceHistoryIds: [ID]!
  ) {
    pickupRequestTimeslotOrder(
      startTime: $startTime
      endTime: $endTime
      timezone: $timezone
      balanceHistoryIds: $balanceHistoryIds
    ) {
      response
      status
    }
  }
`;

export const GQL_GET_PICKUP_REQUEST_TIMESLOT = gql`
  query getAvailPickupTimeslot($timezone: String!) {
    getAvailPickupTimeslot(timezone: $timezone) {
      statusCode
      timeZone
      timeSlots {
        startTime
        endTime
      }
    }
  }
`;

export const GQL_ORDER_CANCEL_BY_ID = gql`
  mutation cancelOrderById($orderId: String!, $balanceHistoryId: ID!) {
    cancelOrderById(orderId: $orderId, balanceHistoryId: $balanceHistoryId) {
      response
      status
    }
  }
`;

export const GQL_ORDER_ACTIVATION = gql`
  mutation activationOrder($orderId: String!, $activation: Int, $balanceHistoryId: ID!) {
    activationOrder(orderId: $orderId, activation: $activation, balanceHistoryId: $balanceHistoryId) {
      response
      status
    }
  }
`;

export const GQL_GET_TOTAL_SOLD_ORDER = gql`
  query getTotalSoldOrder($id: ID!) {
    getTotalSoldOrder(id: $id) {
      total
      detail {
        sku
        qty
        id
      }
      detailEvent {
        id
        qty
        name
      }
    }
  }
`;
