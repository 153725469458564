import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE_GQL = gql`
  mutation insertEmployee($input: EmployeeInput) {
    insertEmployee(input: $input) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_GQL = gql`
  mutation editEmployee($input: EmployeeInput) {
    editEmployee(input: $input) {
      id
    }
  }
`;

export const GQL_EMPLOYEE_PAGE = gql`
  query getEmployeePage(
    $page: Int
    $pageSize: Int
    $search: EmployeeSearchInput
    $searchAny: EmployeeSearchInput
    $sortDirection: SortDirection
    $sortField: EmployeeSortField
  ) {
    getEmployeePage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        identityNumber
        startDate
        endDate
        fullName
        status
        createdAt
        mobile
        dataStatus
        email
        jobPosition
        basicSalary
        bankName
        bankAccountNumber
        bankAccountName
        npwp
        ptkp
        jhtPaidBy
        taxType
        bpjsKes
        bpjsTk
        bpjsPaidBy
        pensionInsurancePaidBy
        bonuses
        workRisk
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;
