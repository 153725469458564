/* eslint-disable */
/**
 * Styles.
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import { GQL_GET_BATCH_SPENDING_PAGE } from '../../gqls/batch-spending';
import { updatePaginatedData } from '../../utils';
import { batchPaymentDetails } from '../../actions/batch-payment';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const BatchPaymentRequestDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const updateBatchSpending = useSelector(state => state.updateBatchPayment);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = {
    page: 1,
    pageSize: 1,
    search: {
      id: [{ operator: 'eq', value: id }]
    }
  };

  const { loading, error, data, fetchMore } = useQuery(GQL_GET_BATCH_SPENDING_PAGE, {
    variables: gparam
  });

  const batchSpending = _.get(data, 'getBatchSpendingPage.items[0]');
  if (batchSpending) dispatch(batchPaymentDetails(batchSpending));

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getBatchSpendingPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updateBatchSpending, batchSpending]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );
  if (!batchSpending) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        breadcrumbs={{
          '/': currentUser?.name || ' ',
          '/mass-money-transfers': 'Kirim Uang / Top Up Massal'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>Proses Kirim Uang / Top Up Massal: {batchSpending?.title}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default BatchPaymentRequestDetail;
