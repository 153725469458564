/**
 * External Dependencies
 */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { useKBar } from 'kbar';

/**
 * Internal Dependencies
 */
import { getLatestRolePathConfig } from '../../utils';
import Icon from '../icon';
import BsDropdown from '../bs-dropdown';
import ModalBodyPaymentRequest from '../modals/modal-body-payment-request';
import ModalBodyPaymentLink from '../modals/modal-body-payment-link';
// import ModalBodyRecurringPlan from '../modals/modal-body-recurring-plan';
import ModalBodyDigitalProduct from '../modals/modal-body-digital-product';
import ModalBodyWebinar from '../modals/modal-body-webinar';
import ModalBodyDiscount from '../modals/modal-body-discount';
import ModalFundraising from '../modals/modal-fundraising';
import ModalBodyPhysical from '../modals/modal-body-physical-product';
import ModalBodyInvoice from '../modals/modal-body-invoice';
import ModalBodyEvent from '../modals/modal-body-event';
import ModalLandingPage from '../modals/modal-landing-page';
import ModalBodyCoaching from '../modals/modal-body-coaching';
import ModalBroadcast from '../modals/modal-broadcast';
import ModalOnlineCourse from '../modals/modal-online-course';
import ModalBillPayment from '../modals/modal-bill-payment';
import ModalMoneyTransfer from '../modals/modal-money-transfer';
import ModalInstallment from '../modals/modal-installment';
import ModalBodyPodcast from '../modals/modal-body-podcast';
import ModalBodyEbook from '../modals/modal-body-ebook';
import ModalBodyAudiobook from '../modals/modal-body-audiobook';
import ModalBundle from '../modals/modal-bundle';
import ModalBodyCohortCourse from '../modals/modal-body-cohort-course';
import ModalBodyMembership from '../modals/modal-body-membership';
import ModalBodyLicenseCode from '../modals/modal-body-license-code';
import ModalBodyWriting from '../modals/modal-body-writing';

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */

const NavigationTopRight = () => {
  const currentUser = useSelector(state => state.currentUser);
  const accountMode = useSelector(state => state.accountMode);
  const { query } = useKBar();

  const [isSubAccount, setSubAccount] = useState(false);
  const [subAccountAccess, setSubAccountAccess] = useState([]);

  useEffect(() => {
    if (currentUser?.role === 'admin') {
      setSubAccount(true);
      setSubAccountAccess(getLatestRolePathConfig(currentUser?.path, 'name'));
    }
  }, [currentUser]);

  const [modalOpen2, setModalOpen2] = useState(false); // Penagihan
  const [modalOpen3, setModalOpen3] = useState(false); // Link pembayaran
  // const [modalOpen4, setModalOpen4] = useState(false); // Paket pembayaran
  const [modalOpen5, setModalOpen5] = useState(false); // Produk digital
  const [modalOpen6, setModalOpen6] = useState(false); // Webinar
  const [modalOpen7, setModalOpen7] = useState(false); // Diskon
  const [modalOpen8, setModalOpen8] = useState(false); // Fundraising
  const [modalOpen9, setModalOpen9] = useState(false); // Physical product
  const [modalOpen10, setModalOpen10] = useState(false); // Invoicing
  const [modalOpen11, setModalOpen11] = useState(false); // Event
  const [modalOpen12, setModalOpen12] = useState(false); // Landing page
  const [modalOpen13, setModalOpen13] = useState(false); // Coaching
  const [modalOpen14, setModalOpen14] = useState(false); // broadcast
  const [modalOpen15, setModalOpen15] = useState(false); // Online Course
  const [modalOpen16, setModalOpen16] = useState(false); // Bill-Pay
  const [modalOpen17, setModalOpen17] = useState(false); // Send Money
  const [modalOpen18, setModalOpen18] = useState(false); // Installment
  const [modalOpen19, setModalOpen19] = useState(false); // E-book
  const [modalOpen20, setModalOpen20] = useState(false); // Podcast
  const [modalOpen21, setModalOpen21] = useState(false); // Audiobook
  const [modalOpen22, setModalOpen22] = useState(false); // Bundling
  const [modalOpen23, setModalOpen23] = useState(false); // Cohort-based Course
  const [modalOpen24, setModalOpen24] = useState(false); // Membership
  const [modalOpen25, setModalOpen25] = useState(false); // Writing/Tulisan
  const [modalOpen26, setModalOpen26] = useState(false); // License Code

  let isUnMounted = false;
  const history = useHistory();

  const toggle2 = () => {
    if (!isUnMounted) {
      setModalOpen2(!modalOpen2);
    }
  };

  const toggle3 = () => {
    if (!isUnMounted) {
      setModalOpen3(!modalOpen3);
    }
  };

  // const toggle4 = () => {
  //   if (!isUnMounted) {
  //     setModalOpen4(!modalOpen4);
  //   }
  // };

  const toggle5 = () => {
    if (!isUnMounted) {
      setModalOpen5(!modalOpen5);
    }
  };

  const toggle6 = () => {
    if (!isUnMounted) {
      setModalOpen6(!modalOpen6);
    }
  };

  const toggle7 = () => {
    if (!isUnMounted) {
      setModalOpen7(!modalOpen7);
    }
  };

  const toggle8 = () => {
    if (!isUnMounted) {
      setModalOpen8(!modalOpen8);
    }
  };

  const toggle9 = () => {
    if (!isUnMounted) {
      setModalOpen9(!modalOpen9);
    }
  };

  const toggle10 = () => {
    if (!isUnMounted) {
      setModalOpen10(!modalOpen10);
    }
  };

  const toggle11 = () => {
    if (!isUnMounted) {
      setModalOpen11(!modalOpen11);
    }
  };

  const toggle12 = () => {
    if (!isUnMounted) {
      setModalOpen12(!modalOpen12);
    }
  };

  const toggle13 = () => {
    if (!isUnMounted) {
      setModalOpen13(!modalOpen13);
    }
  };

  const toggle14 = () => {
    if (!isUnMounted) {
      setModalOpen14(!modalOpen14);
    }
  };

  const toggle15 = () => {
    if (!isUnMounted) {
      setModalOpen15(!modalOpen15);
    }
  };

  const toggle16 = () => {
    if (!isUnMounted) {
      setModalOpen16(!modalOpen16);
    }
  };

  const toggle17 = () => {
    if (!isUnMounted) {
      setModalOpen17(!modalOpen17);
    }
  };

  const toggle18 = () => {
    if (!isUnMounted) {
      setModalOpen18(!modalOpen18);
    }
  };

  const toggle19 = () => {
    if (!isUnMounted) {
      setModalOpen19(!modalOpen19);
    }
  };

  const toggle20 = () => {
    if (!isUnMounted) {
      setModalOpen20(!modalOpen20);
    }
  };

  const toggle21 = () => {
    if (!isUnMounted) {
      setModalOpen21(!modalOpen21);
    }
  };

  const toggle22 = () => {
    if (!isUnMounted) {
      setModalOpen22(!modalOpen22);
    }
  };

  const toggle23 = () => {
    if (!isUnMounted) {
      setModalOpen23(!modalOpen23);
    }
  };

  const toggle24 = () => {
    if (!isUnMounted) {
      setModalOpen24(!modalOpen24);
    }
  };

  const toggle25 = () => {
    if (!isUnMounted) {
      setModalOpen25(!modalOpen25);
    }
  }

  const toggle26 = () => {
    if (!isUnMounted) {
      setModalOpen26(!modalOpen26);
    }
  };

  const onPaymentRequestCreated = id => {
    setTimeout(() => {
      setModalOpen2(false);
      history.push('/payment-request-detail/' + id);
    }, 500);
  };

  const onPaymentLinkCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen3(false);
      history.push('/payment-link-detail/' + id);
    }, 500);
  };

  // const onSubscriptionCreated = id => {
  //   setTimeout(() => {
  //     setModalOpen4(false);
  //     history.push('/payment-plan-detail/' + id);
  //   }, 500);
  // };

  const onDigitalProductCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen5(false);
      history.push('/digital-product-detail/' + id);
    }, 500);
  };

  const onWebinarCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen6(false);
      history.push('/webinar-detail/' + id);
    }, 500);
  };

  const onDiscountCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen7(false);
      history.push('/discount-detail/' + id);
    }, 500);
  };

  const onFundraisingCreated = (id, subType) => {
    // TODO: investigate the error while not using timeout here
    const getSubType = fundraisingType => {
      switch (fundraisingType) {
        case 'FUNDRAISING_QURBAN':
          return '/qurban-detail/';
        case 'FUNDRAISING_WAQAF':
          return '/waqaf-detail/';
        default:
          return '/fundraising-detail/';
      }
    };

    setTimeout(() => {
      setModalOpen8(false);
      history.push(getSubType(subType) + id);
    }, 500);
  };

  const onPhysicalProductCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen9(false);
      history.push('/physical-product-detail/' + id);
    }, 500);
  };

  const onInvoiceCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen10(false);
      history.push('/invoice-detail/' + id);
    }, 500);
  };

  const onEventCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen11(false);
      history.push('/event-detail/' + id);
    }, 500);
  };

  const onMembershipCreated = id => {
    setTimeout(() => {
      setModalOpen24(false);
      history.push('/membership-detail/' + id);
    }, 500);
  };

  const onWritingCreated = id => {
    setTimeout(() => {
      setModalOpen25(false);
      history.push('/writing-detail/' + id);
    }, 500);
  };

  const onLicenseCodeCreated = id => {
    setTimeout(() => {
      setModalOpen26(false);
      history.push(`/license-code-detail/${id}`);
    }, 500);
  };

  const onLandingPageCreated = url => history.push(url);
  const onBroadcastCreated = id => history.push('/broadcast-detail/' + id);
  const onOnlineCourseCreated = id => history.push('/online-course-detail/' + id);
  const onBillPaymentCreated = id => history.push('/bill-payment-detail/' + id);
  const onMoneyTransferCreated = id => history.push('/money-transfer-detail/' + id);
  const onInstallmentCreated = id => history.push('/installment-detail/' + id);
  const onBundlingCreated = id => history.push('/bundling-detail/' + id);
  const onCohortCourseCreated = id => history.push('/cohort-course-detail/' + id);

  const onEbookCreated = id => {
    setTimeout(() => {
      setModalOpen19(false);
      history.push('/ebook-detail/' + id);
    }, 500);
  };

  const onPodcastCreated = id => {
    setTimeout(() => {
      setModalOpen20(false);
      history.push('/podcast-detail/' + id);
    }, 500);
  };

  const onAudiobookCreated = id => {
    setTimeout(() => {
      setModalOpen21(false);
      history.push('/audiobook-detail/' + id);
    }, 500);
  };

  const onCoachingCreated = id => {
    // TODO: investigate the error while not using timeout here
    setTimeout(() => {
      setModalOpen13(false);
      history.push('/coaching-detail/' + id);
    }, 500);
  };

  const activeUser = () => {
    if (accountMode === 'affiliator') {
      return (
        <>
          <BsDropdown className="dib" showTriangle>
            <DropdownToggle className="btn btn-brand btn-long" tag="button">
              <span className="text">
                <Icon name="plus-square" className="mr-5" /> Buat
              </span>
              <span className="icon">
                <Icon name="chevron-down" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="nav" tag="ul">
              {((isSubAccount && subAccountAccess.includes('Buat / Edit Marketing')) || !isSubAccount) && (
                <>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle12}>
                      <span>Landing Page</span>
                    </Link>
                  </li>
                </>
              )}
            </DropdownMenu>
          </BsDropdown>
          {modalOpen12 && <ModalLandingPage isOpen={modalOpen12} onSuccess={onLandingPageCreated} onClose={toggle12} />}
        </>
      );
    }

    return (
      <>
        {((isSubAccount && subAccountAccess.includes('Buat / Edit Produk')) || !isSubAccount) && (
          <BsDropdown className="dib" showTriangle>
            <DropdownToggle className="btn btn-long btn-outline-brand mr-8" tag="button">
              <span className="text">
                <Icon name="plus-square" className="mr-5" /> Produk
              </span>
              <span className="icon">
                <Icon name="chevron-down" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="nav" tag="ul">
              <li>
                <Link className="nav-link" to="#" onClick={toggle3}>
                  <span>Link Pembayaran</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle9}>
                  <span>
                    Produk Fisik <span className="text-primary">(Beta)</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle5}>
                  <span>Digital Download</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle15}>
                  <span>Kelas Online</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle23}>
                  <span>Kelas Cohort / Bootcamp</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle6}>
                  <span>Webinar</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle11}>
                  <span>Event / Acara</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle13}>
                  <span>Coaching / Mentoring</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle8}>
                  <span>Penggalangan Dana</span>
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link" to="#" onClick={toggle4}>
                  <span>Paket Berlangganan</span>
                </Link>
              </li> */}
              <li>
                <Link className="nav-link" to="#" onClick={toggle24}>
                  <span>Membership & SaaS</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle19}>
                  <span>E-Book</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle20}>
                  <span>Podcast</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle21}>
                  <span>Audiobook</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle26}>
                  <span>Lisensi Software</span>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="#" onClick={toggle25}>
                  <span>Tulisan</span>
                </Link>
              </li>
            </DropdownMenu>
          </BsDropdown>
        )}
        {((isSubAccount &&
          (subAccountAccess.includes('Buat / Edit Invoice dan Permintaan Pembayaran') ||
            subAccountAccess.includes('Buat / Edit Marketing'))) ||
          !isSubAccount) && (
          <BsDropdown className="dib" showTriangle>
            <DropdownToggle className="btn btn-brand btn-long" tag="button">
              <span className="text">
                <Icon name="plus-square" className="mr-5" /> Buat
              </span>
              <span className="icon">
                <Icon name="chevron-down" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="nav" tag="ul">
              {((isSubAccount && subAccountAccess.includes('Buat / Edit Invoice dan Permintaan Pembayaran')) ||
                !isSubAccount) && (
                <>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle2}>
                      <span>Penagihan</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle10}>
                      <span>Invoice</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle18}>
                      <span>Cicilan</span>
                    </Link>
                  </li>
                </>
              )}

              {((isSubAccount && subAccountAccess.includes('Buat / Edit Marketing')) || !isSubAccount) && (
                <>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle14}>
                      <span>Broadcast / Blast</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle12}>
                      <span>Landing Page</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle7}>
                      <span>Diskon</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle22}>
                      <span>Bundling</span>
                    </Link>
                  </li>
                </>
              )}

              {!isSubAccount && (
                <>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle16}>
                      <span>Bill Payment</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="#" onClick={toggle17}>
                      <span>Kirim Uang</span>
                    </Link>
                  </li>
                </>
              )}
            </DropdownMenu>
          </BsDropdown>
        )}

        {modalOpen2 && (
          <ModalBodyPaymentRequest isOpen={modalOpen2} onSuccess={onPaymentRequestCreated} onClose={toggle2} />
        )}

        {modalOpen3 && <ModalBodyPaymentLink isOpen={modalOpen3} onSuccess={onPaymentLinkCreated} onClose={toggle3} />}

        {/* {modalOpen4 && (
          <ModalBodyRecurringPlan isOpen={modalOpen4} onSuccess={onSubscriptionCreated} onClose={toggle4} />
        )} */}

        {modalOpen5 && (
          <ModalBodyDigitalProduct isOpen={modalOpen5} onSuccess={onDigitalProductCreated} onClose={toggle5} />
        )}

        {modalOpen6 && <ModalBodyWebinar isOpen={modalOpen6} onSuccess={onWebinarCreated} onClose={toggle6} />}

        {modalOpen7 && <ModalBodyDiscount isOpen={modalOpen7} onSuccess={onDiscountCreated} onClose={toggle7} />}

        {modalOpen8 && <ModalFundraising isOpen={modalOpen8} onSuccess={onFundraisingCreated} onClose={toggle8} />}

        {modalOpen9 && <ModalBodyPhysical isOpen={modalOpen9} onSuccess={onPhysicalProductCreated} onClose={toggle9} />}

        {modalOpen10 && <ModalBodyInvoice isOpen={modalOpen10} onSuccess={onInvoiceCreated} onClose={toggle10} />}

        {modalOpen11 && <ModalBodyEvent isOpen={modalOpen11} onSuccess={onEventCreated} onClose={toggle11} />}

        {modalOpen12 && <ModalLandingPage isOpen={modalOpen12} onSuccess={onLandingPageCreated} onClose={toggle12} />}

        {modalOpen13 && <ModalBodyCoaching isOpen={modalOpen13} onSuccess={onCoachingCreated} onClose={toggle13} />}

        {modalOpen14 && <ModalBroadcast isOpen={modalOpen14} onSuccess={onBroadcastCreated} onClose={toggle14} />}

        {modalOpen15 && <ModalOnlineCourse isOpen={modalOpen15} onSuccess={onOnlineCourseCreated} onClose={toggle15} />}

        {modalOpen16 && <ModalBillPayment isOpen={modalOpen16} onSuccess={onBillPaymentCreated} onClose={toggle16} />}
        {modalOpen17 && (
          <ModalMoneyTransfer isOpen={modalOpen17} onSuccess={onMoneyTransferCreated} onClose={toggle17} />
        )}
        {modalOpen18 && <ModalInstallment isOpen={modalOpen18} onSuccess={onInstallmentCreated} onClose={toggle18} />}

        {modalOpen19 && <ModalBodyEbook isOpen={modalOpen19} onSuccess={onEbookCreated} onClose={toggle19} />}

        {modalOpen20 && <ModalBodyPodcast isOpen={modalOpen20} onSuccess={onPodcastCreated} onClose={toggle20} />}

        {modalOpen21 && <ModalBodyAudiobook isOpen={modalOpen21} onSuccess={onAudiobookCreated} onClose={toggle21} />}

        {modalOpen22 && <ModalBundle isOpen={modalOpen22} onSuccess={onBundlingCreated} onClose={toggle22} />}

        {modalOpen23 && (
          <ModalBodyCohortCourse isOpen={modalOpen23} onSuccess={onCohortCourseCreated} onClose={toggle23} />
        )}

        {modalOpen24 && <ModalBodyMembership isOpen={modalOpen24} onSuccess={onMembershipCreated} onClose={toggle24} />}

        {modalOpen25 && (
          <ModalBodyWriting isOpen={modalOpen25} onSuccess={onWritingCreated} onClose={toggle25} />
        )}

        {modalOpen26 && (
          <ModalBodyLicenseCode isOpen={modalOpen26} onSuccess={onLicenseCodeCreated} onClose={toggle26} />
        )}
      </>
    );
  };

  const expiredUser = () => {
    return (
      <Link to="/billing">
        <Button color="danger" className="mr-8" outline>
          <Icon name="alert" className="mr-5" /> Paket mayar anda kadaluarsa. Perpanjang melalui halaman pengaturan -
          billing
        </Button>
      </Link>
    );
  };

  useEffect(() => {
    return () => {
      isUnMounted = true;
    };
  }, []);

  if (!currentUser?.isProfileCompleted) {
    return <div className="d-none d-sm-block"></div>;
  }

  if (!currentUser?.account?.planType && currentUser?.isAffiliate) {
    return (
      <div className="d-none d-sm-block">
        <BsDropdown className="dib" showTriangle>
          <DropdownToggle className="btn btn-brand btn-long" tag="button">
            <span className="text">
              <Icon name="plus-square" className="mr-5" /> Buat
            </span>
            <span className="icon">
              <Icon name="chevron-down" />
            </span>
          </DropdownToggle>
          <DropdownMenu className="nav" tag="ul">
            <li>
              <Link className="nav-link" to="#" onClick={toggle12}>
                <span>Landing Page</span>
              </Link>
            </li>
          </DropdownMenu>
        </BsDropdown>
        {modalOpen12 && <ModalLandingPage isOpen={modalOpen12} onSuccess={onLandingPageCreated} onClose={toggle12} />}
      </div>
    );
  }

  return (
    <div className="d-none d-sm-block row">
      <Button
        onClick={query.toggle}
        className="mr-15
      text-capitalize
      bg-light
      border-0
      "
        style={{ borderRadius: '5px', padding: '10px 15px', color: '#343a40' }}
      >
        <Icon name="command" className="mr-10" />
        Quick Actions &#40; Ctrl + k / Cmd + k &#41;
      </Button>
      {currentUser.account ? (
        <>{currentUser?.account?.isPlanStopped && currentUser.isProfileCompleted ? expiredUser() : activeUser()}</>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default NavigationTopRight;
