/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

/**
 * Internal Dependencies
 */
import '../../../assets/js/yaybar/yaybar';
import { initPluginYaybar } from '../../../assets/js/rootui-parts/initPluginYaybar';
//import Dropdown from '../bs-dropdown';
import Icon from '../icon';

import { updateAuth as actionUpdateAuth } from '../../actions';

window.RootUI.initPluginYaybar = initPluginYaybar;

/**
 * Component
 */
class PageYaybarAdmin extends Component {
  constructor(props) {
    super(props);

    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    window.RootUI.initPluginYaybar();
  }

  logOut() {
    //const { updateAuth } = this.props;

    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    window.location.href = process.env.REACT_APP_BASE_URL + '/sign-in';
  }

  renderSubmenus(nav, returnObject = false) {
    let thereIsActive = false;

    const result = Object.keys(nav).map((url) => {
      const data = nav[url];
      const isActive = window.location.hash === `#${url}`;
      let isOpened = false;

      if (isActive) {
        thereIsActive = true;
      }

      let sub = '';
      if (data.sub) {
        const subData = this.renderSubmenus(data.sub, true);

        sub = <ul className="yay-submenu dropdown-triangle">{subData.content}</ul>;

        if (subData.thereIsActive) {
          isOpened = true;
          thereIsActive = true;
        }
      }

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.label ? <li className="yay-label">{data.label}</li> : ''}
          <li
            className={classnames({
              'yay-item-active': isActive,
              'yay-submenu-open': isOpened,
            })}
          >
            {data.name ? (
              <NavLink to={data.sub ? '#' : url} className={data.sub ? 'yay-sub-toggle' : ''}>
                {data.icon ? (
                  <>
                    <span className="yay-icon">
                      <Icon name={data.icon} />
                    </span>
                    <span>{data.name}</span>
                    <span className="rui-yaybar-circle" />
                  </>
                ) : (
                  data.name
                )}
                {data.sub ? (
                  <span className="yay-icon-collapse">
                    <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                  </span>
                ) : (
                  ''
                )}
              </NavLink>
            ) : (
              ''
            )}
            {sub}
          </li>
        </React.Fragment>
      );
    });

    if (returnObject) {
      return {
        content: result,
        thereIsActive,
      };
    }

    return result;
  }

  render() {
    const { settings } = this.props;

    return (
      <>
        <div
          className={classnames(
            'yaybar rui-yaybar yay-hide-to-small yay-gestures',
            settings.sidebar_dark && !settings.night_mode ? '' : 'rui-yaybar-dark',
            settings.sidebar_static ? 'yay-static' : '',
            settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : '',
          )}
        >
          <div className="yay-wrap-menu">
            <div className="yaybar-wrap">
              <ul>
                {this.renderSubmenus(settings.navigation_sidebar_admin)}
                <li>
                  <NavLink to="#" onClick={this.logOut}>
                    <span className="yay-icon">
                      <Icon name="log-out" />
                    </span>
                    <span>Log Out</span>
                    <span className="rui-yaybar-circle"></span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="rui-yaybar-icons">
            <Row className="no-gutters justify-content-around">
              <Col xs="auto">
                <Link to={settings.home_url} className="btn btn-custom-round">
                  <Icon name="bell" />
                </Link>
              </Col>
              <Col xs="auto">
                <Link to={settings.home_url} className="btn btn-custom-round">
                  <Icon name="help-circle" />
                </Link>
              </Col>
              <Col xs="auto">
                <Link to="#" onClick={this.logOut} className="btn btn-custom-round">
                  <Icon name="log-out" />
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        <div className="rui-yaybar-bg" />
      </>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings,
  }),
  { updateAuth: actionUpdateAuth },
)(PageYaybarAdmin);
