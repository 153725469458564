export const SET_LANDING_PROMOTION = 'SET_LANDING_PROMOTION';
export const UPDATE_LANDING_PROMOTION = 'UPDATE_LANDING_PROMOTION';

export const setLandingPromotion = q => ({
  type: SET_LANDING_PROMOTION,
  payload: q
});

export const updateLandingPromotion = q => ({
  type: UPDATE_LANDING_PROMOTION,
  payload: q
});
