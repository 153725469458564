import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, ModalBody } from 'reactstrap';
import Confetti from '../../confetti';
import Icon from '../../../components/icon';

const ModalOnboardTutorial = ({ isOpen, onClose, isShowConfetti = false }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (isShowConfetti) {
      setDisplay(true);
      setTimeout(() => {
        setDisplay(false);
      }, 3000);
    }
  }, [isShowConfetti]);

  return (
    <Modal size="lg" isOpen={isOpen} fade>
      <Confetti display={display}>
        <ModalBody className="tutorial text-center">
          <h1 className="mt-30">Selamat Datang di Mayar.ID</h1>
          <p>
            Mayar adalah super platform untuk bisnis, entrepreneur dan kreator. Memungkinkan kamu untuk menerima
            pembayaran online dan menjual berbgai macam produk dan jasa online dengan mudah.
          </p>
          <h3>Tutorial Dasar</h3>
          <Row className="mt-20">
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=0jf3cEeb8ak" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Produk Digital Download
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=dJnzgt1NvPA" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Kelas Online
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=eBy3QSHiZMA" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Bootcamp
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=FYawkLb9ea0" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Webinar
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=iEDThb28p5E" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Tiket Event
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=D3p-sTfrCJQ" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menjual Membership
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=CxiWjDjGT6A" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Membuat Landing Page
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.youtube.com/watch?v=nSl0T-qxvDc" target="_blank" rel="noopener noreferrer">
                <Button outline block color="primary" className="text-center mb-20">
                  Menggalang Dana Online (Donasi)
                </Button>
              </a>
            </Col>
          </Row>
          <a href="https://tutorial.mayar.id" target="_blank" rel="noopener noreferrer">
            <Button block outline color="dark" className="text-center">
              <Icon name="help-circle" /> &nbsp; Lihat Tutorial Lengkap
            </Button>
          </a>

          <div className="mt-10">
            <a href="https://learning.myr.id/" target="_blank" rel="noopener noreferrer">
              <Button block outline color="dark" className="text-center">
                <Icon name="help-circle" /> &nbsp; Kelas Online Basic Mayar (Gratis)
              </Button>
            </a>
          </div>

          <center className="mt-30">
            <p>Download Aplikasi Mayar Lite dan dapatkan notifikasi setiap ada pembayaran masuk langsung ke hp anda</p>
            <p>
              <a
                href="https://play.google.com/store/apps/details?id=com.mayarlite&utm_source=mayardashboard&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Temukan di Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/id_badge_web_generic.png"
                  width="200"
                />
              </a>
            </p>
          </center>

          <Row className="mt-20">
            <Col md="12">
              <a href="https://t.me/mcngroup" target="_blank" rel="noopener noreferrer">
                <Button block outline color="dark" className="text-center">
                  <Icon name="award" /> &nbsp; Join Mayar Community on Telegram &rarr;
                </Button>
              </a>
            </Col>
          </Row>

          <Row className="mt-20">
            <Col md="6">
              <a href="https://www.instagram.com/mayar_id/" target="_blank" rel="noopener noreferrer">
                <Button block outline color="danger" className="text-center">
                  <Icon name="instagram" /> &nbsp; Follow Us
                </Button>
              </a>
            </Col>
            <Col md="6">
              <a href="https://www.linkedin.com/company/mayarid" target="_blank" rel="noopener noreferrer">
                <Button block outline color="primary" className="text-center">
                  <Icon name="linkedin" /> &nbsp; Join Us
                </Button>
              </a>
            </Col>
          </Row>

          <Button size="lg" onClick={onClose} block color="dark" className="text-center mt-20">
            Tutup
          </Button>
        </ModalBody>
      </Confetti>
    </Modal>
  );
};

export default ModalOnboardTutorial;
