import './style.scss';
import React from 'react';
import { useSelector } from 'react-redux';

import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col } from 'reactstrap';

const AdminRecurringPaymentDetail = () => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <PageWrap>
      <PageTitle
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/admin/recurring-payments': 'Berlangganan',
        }}
      >
        <Row className="mb-0">
          <Col lg="6">
            <h1>Langganan ID #99834HGD</h1>
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default AdminRecurringPaymentDetail;
