import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, Form, FormGroup, Label, Input, Button, Alert, FormFeedback } from 'reactstrap';

import { GQL_LANDING_PAGE_CREATE_BY_SLUG, GQL_LANDING_PAGE_UPDATE_BY_SLUG } from '../../gqls';
import { CustomStyles } from '../custom-styles/react-select-styles';
import { validator, landingPageLinkUrl, REGEX_SLUG, slug } from '../../utils';
import { updateLandingPages } from '../../actions/landing-page';
import Icon from '../icon';
import SubmitButton from '../submit-button';
import UnlayerTemplates from '../unlayer-templates';

const ModalLandingPage = ({ data, isOpen, isDuplicate, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const landingPageCollections = useSelector(state => state.landingPageCollections);
  const [submitError, setSubmitError] = useState(false);
  const [link, setLink] = useState(data?.link || '');
  const [visible, setVisible] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [userDesign, setUserDesign] = useState('');
  const [userResult, setUserResult] = useState('');
  const [landingType, setLandingType] = useState(data?.type || 'BASIC');

  const [insertLanding, { data: lpData, loading: insertLoading }] = useMutation(GQL_LANDING_PAGE_CREATE_BY_SLUG);
  const [editLanding, { data: lpUpdatedData, loading: editLoading }] = useMutation(GQL_LANDING_PAGE_UPDATE_BY_SLUG);

  const isUpdate = !!data;
  const isReopen = isUpdate && data?.status === 'archived';
  const isLoading = insertLoading || editLoading;

  const onDismiss = () => setVisible(false);

  const setCurrentTemplate = async () => {
    const currentCollection = landingPageCollections.find(collection => collection.title === selectedTemplate);
    setUserDesign(currentCollection.design);
    setUserResult(JSON.parse(currentCollection.result));
  };

  useEffect(() => {
    // Get the design from collections
    if (selectedTemplate !== 'default') {
      setCurrentTemplate();
    }
  }, [selectedTemplate]);

  const onSubmit = async values => {
    const variables = {
      name: values.name,
      metaDescription: values.metaDescription,
      metaKeyword: values.metaKeyword,
      metaTitle: values.metaTitle,
      status: 'active',
      type: landingType,
      ...(!isUpdate && {
        link: slug(values.name),
        design: userDesign === '' ? '' : JSON.stringify(userDesign),
        htmlResult: userResult === '' ? '' : userResult
      }),
      ...(!isDuplicate &&
        isUpdate && {
          id: data?.id,
          link: values.link,
          status: values.status === 'inactive' ? values.status : 'active',
          design: !userDesign ? data?.design : JSON.stringify(userDesign),
          htmlResult: !userResult ? data?.htmlResult : userResult
        }),
      ...(isDuplicate && { link: values.link, design: data?.design, htmlResult: data?.htmlResult })
    };

    const onLandingCreated = id => {
      onClose();
      onSuccess(`/${landingType === 'BASIC' ? 'simple-landing-page-editor' : 'landing-page-detail'}/${id}`);
      window.analytics.track('Create Landing Page', variables);
    };

    try {
      if (isDuplicate) {
        const res = await insertLanding({ variables: { input: variables } });
        onLandingCreated(res.data.insertLandingPageBySlug.id);
      } else if (isUpdate) {
        await editLanding({ variables: { input: variables } });
        dispatch(updateLandingPages(Math.random()));
      } else {
        const res = await insertLanding({ variables: { input: variables } });
        onLandingCreated(res.data.insertLandingPageBySlug.id);
      }
    } catch (err) {
      setSubmitError(true);
      window.analytics.track('Create Landing Page - ERROR', variables);
    }
  };

  const title = () => {
    if (isDuplicate) {
      return 'Duplikat';
    } else if (isReopen) {
      return 'Buka';
    } else if (isUpdate) {
      return 'Edit';
    }

    return 'Buat';
  };

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{title()} Landing Page</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            {isUpdate
              ? `Anda dapat mendesain
              dan melayout landing page setelah proses ini.`
              : `Isi detail awal landing page, untuk data meta dapat dikosongkan dan diperbarui nanti. Anda dapat mendesain
            dan melayout landing page setelah proses ini.`}
          </small>
        </p>
        <hr />
        {submitError && (
          <Alert color="danger" className="mb-10">
            Error! Silahkan coba lagi atau hubungi CS
          </Alert>
        )}

        {lpData && (
          <Alert color="success" className="mb-10" isOpen={visible} toggle={onDismiss}>
            Landing page {lpData.insertLandingPageBySlug.name} berhasil dibuat
          </Alert>
        )}

        {lpUpdatedData && (
          <Alert color="success" className="mb-10" isOpen={visible} toggle={onDismiss}>
            Landing page berhasil diupdate
          </Alert>
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          {!isUpdate && !isDuplicate && (
            <FormGroup>
              <Label>Tipe Landing Page*</Label>
              <Select
                options={[
                  { value: 'BASIC', label: 'Simple' },
                  // { value: 'ADVANCED', label: 'Advanced' }
                ]}
                styles={CustomStyles}
                placeholder="Pilih Tipe"
                onChange={({ value }) => setLandingType(value)}
                defaultValue={{ value: 'BASIC', label: 'Simple' }}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label for="name">Nama Landing Page*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={!!errors.name}
              defaultValue={data?.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>

          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={!!errors.link}
                defaultValue={data?.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + landingPageLinkUrl(link)}</small>
            </FormGroup>
          )}

          <hr />
          <h4 className="mb-2">Search Engine Optimization (SEO) - Metadata</h4>
          <FormGroup>
            <Label for="metaTitle">Title</Label>
            <Input
              type="text"
              name="metaTitle"
              id="metaTitle"
              invalid={!!errors.metaTitle}
              defaultValue={data?.metaTitle}
              innerRef={register({ minLength: validator.minLength(5) })}
            />
            <FormFeedback>{errors.metaTitle?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="metaDescription">Meta Description</Label>
            <Input
              type="textarea"
              name="metaDescription"
              id="metaDescription"
              invalid={!!errors.metaDescription}
              defaultValue={data?.metaDescription}
              innerRef={register()}
            />
            <FormFeedback>{errors.metaDescription?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="metaKeyword">Meta Keywords</Label>
            <Input
              type="text"
              name="metaKeyword"
              id="metaKeyword"
              invalid={errors.metaKeyword}
              defaultValue={data?.metaKeyword}
              innerRef={register()}
            />
            <FormFeedback>{errors.metaKeyword?.message}</FormFeedback>
          </FormGroup>

          {!isDuplicate && landingType === 'ADVANCED' && (
            <>
              <hr />
              <UnlayerTemplates
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                isUpdate={isUpdate}
              />
            </>
          )}

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center mt-20"
            loading
            isLoading={isLoading}
            text={`${title()} Landing Page`}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalLandingPage.defaultProps = {
  isOpen: false
};

export default ModalLandingPage;
