import { gql } from '@apollo/client';

export const GQL_CREATE_BATCH_PAYMENT_REQUEST = gql`
  mutation createBatchPaymentRequest($input: BatchPaymentRequestInputProxy) {
    createBatchPaymentRequest(input: $input) {
      status
    }
  }
`;

export const GQL_INSERT_BATCH_PAYMENT = gql`
  mutation insertBatchPaymentRequest($input: BatchPaymentRequestInput) {
    insertBatchPaymentRequest(input: $input) {
      id
      createdAt
      status
      title
      updatedAt
      userId
    }
  }
`;

export const GQL_UPDATE_BATCH_PAYMENT = gql`
  mutation editBatchPaymentRequest($input: BatchPaymentRequestInput) {
    editBatchPaymentRequest(input: $input) {
      id
      createdAt
      status
      title
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_BATCH_PAYMENT_PAGE = gql`
  query getBatchPaymentRequestPage(
    $page: Int
    $pageSize: Int
    $search: BatchPaymentRequestSearchInput
    $searchAny: BatchPaymentRequestSearchInput
    $sortDirection: SortDirection
    $sortField: BatchPaymentRequestSortField
  ) {
    getBatchPaymentRequestPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        createdAt
        status
        title
      }
      nextPage
      offset
      page
      prevPage
      total
      totalPage
    }
  }
`;

export const GQL_GET_BATCH_PAYMENT_HISTORY_PAGE = gql`
  query getBatchPaymentRequestHistoryPage(
    $pageSize: Int
    $page: Int
    $search: BatchPaymentRequestHistorySearchInput
    $searchAny: BatchPaymentRequestHistorySearchInput
    $sortDirection: SortDirection
    $sortField: BatchPaymentRequestHistorySortField
  ) {
    getBatchPaymentRequestHistoryPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        batchPaymentRequestId
        createdAt
        email
        id
        status
        updatedAt
      }
      nextPage
      offset
      page
      prevPage
      total
      totalPage
    }
  }
`;
