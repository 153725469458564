/**
 * External Dependencies
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

/**
 * Internal Dependencies
 */
import { getLatestRolePathConfig, parseJSON } from './utils';
import RoutesList from './pages';
import NotFoundPage from './pages/404';

/**
 * Component
 */

function Routes(props) {
  const { location } = props;
  const currentUser = useSelector(state => state.currentUser);

  const checkIfAnyPath = parseJSON(currentUser?.path, {});
  const pathCustom = Array.isArray(checkIfAnyPath)
    ? [...getLatestRolePathConfig(currentUser?.path, 'route').filter((v, i, a) => a.indexOf(v) === i), '/']
    : '';

  const isSubAccount = currentUser?.role === 'admin';
  const filteredRoute = isSubAccount
    ? Object.keys(RoutesList).filter(route => pathCustom.includes(route))
    : Object.keys(RoutesList);
  
  // to prevent sub-account got 404 page not found
  filteredRoute.unshift("/sign-in/referral/:id")
  
  return (
    <Switch location={location}>
      {filteredRoute.map(path => {
        const RouteInner = RoutesList[path];
        return <Route key={path} path={path} exact render={() => <RouteInner />} />;
      })}

      {/* 404 */}
      <Route render={() => <NotFoundPage />} />
    </Switch>
  );
}

export default Routes;
