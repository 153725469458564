import React, { useState, useRef } from 'react';
import { KBarPortal, KBarPositioner, KBarAnimator, KBarSearch } from 'kbar';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { RenderResults } from './components/renderResult';
import { useSearchActions } from './components/useSearchActions';

import './__kbar.scss';
import { GQL_PAYMENTLINK_KBAR, GQL_ALL_BALANCE_HISTORY_KBAR } from '../../gqls';
const gparam = {
  limit: 20
};
const gparamBalance = {
  limit: 10
};

function Kbar() {
  const client = useApolloClient();
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [transaction, setTransactions] = useState([]);

  const loadSuggestions = value => {
    const keyword = value;

    if (!keyword || keyword.length < 3) {
      return;
    }

    gparam.search = {
      name: [{ operator: 'like', value: '%' + keyword + '%' }]
    };
    client
      .query({
        query: GQL_PAYMENTLINK_KBAR,
        variables: gparam,
        context: {
          queryDeduplication: false
        }
      })
      .then(resp => setPaymentLinks(resp.data.getPaymentLinkFilteredList));

    gparamBalance.search = {
      id: [{ operator: 'eq', value: keyword }]
    };

    client
      .query({
        query: GQL_ALL_BALANCE_HISTORY_KBAR,
        variables: gparamBalance,
        context: {
          queryDeduplication: false
        }
      })
      .then(res => setTransactions(res.data.getBalanceHistoryFilteredList));
  };

  const setLoadSuggestions = useRef(_.debounce(loadSuggestions, 500));

  useSearchActions(paymentLinks, transaction);

  return (
    <KBarPortal>
      <KBarPositioner className="wrapPosition">
        <KBarAnimator className="wrapAnimatorStyle">
          <KBarSearch className="searchKbar" onChange={event => setLoadSuggestions.current(event.target.value)} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
}

export default Kbar;
