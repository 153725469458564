import { FILTER_AFFILIATE_BY_Q, FILTER_AFFILIATE_BY_STATUS } from '../actions/affiliate';

export const affiliateFilterQReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_AFFILIATE_BY_Q:
      return action.payload;
  }
  return state;
};

export const affiliateFilterStatusReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_AFFILIATE_BY_STATUS:
      return action.payload;
  }
  return state;
};
