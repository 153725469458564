export const ewalletCodes = [
  {
    BankCode: 'GOPAY',
    Bank: 'GOPAY'
  },
  {
    BankCode: 'OVO',
    Bank: 'OVO'
  },
  {
    BankCode: 'DANA',
    Bank: 'DANA'
  },
  {
    BankCode: 'LINKAJA',
    Bank: 'LINKAJA'
  },
  {
    BankCode: 'SHOPEEPAY',
    Bank: 'SHOPEEPAY'
  }
];
