import './style.scss';

import React from 'react';
import AsyncComponent from '../../components/async-component';

const Playground = () => {
  return <AsyncComponent component={() => import('./content')} />;
};

export default Playground;
