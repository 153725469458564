import { gql } from '@apollo/client';

export const GQL_INSERT_BATCH_SPENDING = gql`
  mutation insertBatchSpending($input: BatchSpendingInput) {
    insertBatchSpending(input: $input) {
      id
      createdAt
      status
      title
      type
      notes
      payrollMonth
      payrollYear
      updatedAt
      userId
    }
  }
`;

export const GQL_UPDATE_BATCH_SPENDING = gql`
  mutation editBatchSpending($input: BatchSpendingInput) {
    editBatchSpending(input: $input) {
      id
      createdAt
      status
      title
      type
      notes
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_BATCH_SPENDING_PAGE = gql`
  query getBatchSpendingPage(
    $page: Int
    $pageSize: Int
    $search: BatchSpendingSearchInput
    $searchAny: BatchSpendingSearchInput
    $sortDirection: SortDirection
    $sortField: BatchSpendingSortField
  ) {
    getBatchSpendingPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        createdAt
        id
        status
        title
        type
        updatedAt
        notes
        payrollMonth
        payrollYear
        userId
      }
      nextPage
      offset
      page
      prevPage
      total
      totalPage
    }
  }
`;
