import { gql } from '@apollo/client';

export const GQL_CUSTOMER_PAGE = gql`
  query getCustomerPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CustomerSortField
    $searchAny: CustomerSearchInput
    $search: CustomerSearchInput
  ) {
    getCustomerPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        userId
        status
        email
        mobile
        name
        createdAt
        updatedAt
      }
      nextPage
    }
  }
`;

export const GQL_CUSTOMER_FILTERED_LIST = gql`
  query getCustomerFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: CustomerSortField
    $searchAny: CustomerSearchInput
    $search: CustomerSearchInput
  ) {
    getCustomerFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      userId
      status
      email
      mobile
      name
      createdAt
      updatedAt
    }
  }
`;

export const GQL_GET_TOTAL_CUSTOMERS = gql`
  query getCustomerPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CustomerSortField
    $searchAny: CustomerSearchInput
    $search: CustomerSearchInput
  ) {
    getCustomerPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      total
    }
  }
`;

export const GQL_CUSTOMER_UPDATE = gql`
  mutation editCustomer($input: CustomerInput) {
    editCustomer(input: $input) {
      id
      mobile
      name
      status
    }
  }
`;

export const GQL_CUSTOMER_DELETE = gql`
  mutation deleteCustomer($id: String) {
    deleteCustomer(id: $id)
  }
`;

export const GQL_CUSTOMER_SELECT = gql`
  query getCustomerFilteredList(
    $search: CustomerSearchInput
    $searchAny: CustomerSearchInput
    $sortDirection: SortDirection
    $sortField: CustomerSortField
    $limit: Int
  ) {
    getCustomerFilteredList(
      search: $search
      searchAny: $searchAny
      limit: $limit
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      id
      email
      name
    }
  }
`;

export const GQL_GET_ACCESS_CUSTOMER_PORTAL = gql`
  query getAccessCustomerPortal($email: String, $userId: ID) {
    getAccessCustomerPortal(email: $email, userId: $userId) {
      email
      userId
    }
  }
`;

export const GQL_CREATE_CUSTOMER_BULK = gql`
  mutation insertMultipleCustomer($input: [CustomerInput]) {
    insertMultipleCustomer(input: $input) {
      createdAt
      email
      mobile
      name
      status
      updatedAt
      userId
    }
  }
`;

export const INSERT_CUSTOMER_BULK = gql`
  mutation insertMultipleCustomer($input: [CustomerInput]) {
    insertMultipleCustomer(input: $input) {
      id
    }
  }
`;

export const INSERT_CUSTOMER = gql`
  mutation insertCustomer($input: CustomerInput) {
    insertCustomer(input: $input) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation editCustomer($input: CustomerInput) {
    editCustomer(input: $input) {
      id
    }
  }
`;
