import { DISCOUNT_DETAILS, LIST_PRODUCT,
        FILTER_DISCOUNT_BY_Q, FILTER_DISCOUNT_BY_STATUS, 
        UPDATE_DISCOUNT, COUPONS_DETAILS} from '../actions/discount';

export const discountDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case DISCOUNT_DETAILS:
            return action.payload;
    }
    return state;
};

export const listProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_PRODUCT:
            return action.payload;
    }
    return state;
};

export const filterDiscountByQReducer = (state = '', action) => {
    switch (action.type) {
        case FILTER_DISCOUNT_BY_Q:
            return action.payload;
    }
    return state;
};
export const filterDiscountByStatusReducer = (state = '', action) => {
    switch (action.type) {
        case FILTER_DISCOUNT_BY_STATUS:
            return action.payload;
    }
    return state;
};

export const updateDiscountReducer = (state = '', action) => {
    switch (action.type) {
        case UPDATE_DISCOUNT:
            return action.payload;
    }
    return state;
};

export const couponsDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case COUPONS_DETAILS:
            return action.payload;
    }
    return state;
};
