import { gql } from '@apollo/client';

export const GQL_CREATE_TICKET = gql`
  mutation insertTicketProxy($input: TicketInput) {
    insertTicketProxy(input: $input) {
      id
      createdAt
      description
      eventId
      name
      quantity
      ticketEndDate
      ticketStartDate
      type
      updatedAt
    }
  }
`;

export const GQL_EDIT_TICKET = gql`
  mutation editTicketProxy($input: TicketInput) {
    editTicketProxy(input: $input) {
      id
      createdAt
      description
      eventId
      name
      quantity
      ticketEndDate
      ticketStartDate
      type
      updatedAt
    }
  }
`;

export const GET_TICKET_PAGE = gql`
  query getTicketPage(
    $page: Int = 1
    $pageSize: Int = 10
    $search: TicketSearchInput
    $searchAny: TicketSearchInput
    $sortDirection: SortDirection = ASC
    $sortField: TicketSortField = createdAt
  ) {
    getTicketPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      totalPage
      total
      prevPage
      page
      offset
      nextPage
      items {
        id
        name
        type
        amount
        ticketStartDate
        ticketEndDate
        description
        quantity
        isVisible
      }
    }
  }
`;

export const GET_TICKET_FILTERED_LIST = gql`
  query getTicketFilteredList($sortField: TicketSortField = position, $sortDirection: SortDirection = ASC, $search: TicketSearchInput) {
    getTicketFilteredList(sortField: $sortField, sortDirection: $sortDirection, search: $search) {
      id
      name
      type
      amount
      ticketStartDate
      ticketEndDate
      description
      quantity
      isVisible
      eventId
      updatedAt
      position
    }
  }
`;

export const GQL_REORDER_EVENT_TICKET = gql`
  mutation reorderEventTicket($input: ReorderEventTicketInput) {
    reorderEventTicket(input: $input) {
      id
      name
      type
      amount
      ticketStartDate
      ticketEndDate
      description
      quantity
      isVisible
      eventId
      updatedAt
      position
    }
  }
`;
