/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'reactstrap';

/**
 * Internal Dependencies
 */
import { updateAuth as actionUpdateAuth } from '../../actions';
import { currentUser as updateCurrentUser } from '../../actions/users';
import { changeAccountMode as changeModeAccount } from '../../actions/account-mode';
import { copyToClipboard, getLatestRolePathConfig, paymeUrl } from '../../utils';
import '../../../assets/js/yaybar/yaybar';
import { initPluginYaybar } from '../../../assets/js/rootui-parts/initPluginYaybar';
import Icon from '../icon';
import ModalBodyEmbedButton from '../modals/modal-body-embed-button';
import ModalTutorial from '../modals/modal-tutorial';
import AccountModeButton from './components/account-mode-button';
import { isPaidPlan } from '../../pages/Settings/configs/plan-config';

window.RootUI.initPluginYaybar = initPluginYaybar;

const specialAccessAffiliate = ['talentraderskapital@gmail.com'];

/**
 * Component
 */
class PageYaybar extends Component {
  constructor(props) {
    super(props);

    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.deleteCookies = this.deleteCookies.bind(this);

    this.toggle1 = this.toggle1.bind(this);
    this.toggleModalTutorial = this.toggleModalTutorial.bind(this);
    this.location = this.location.bind(this);
  }

  state = {
    buttonCopy: 'Copy Link Catalog',
    modalOpen1: false,
    modalTutorial: false,
    deferredPrompt: null
  };

  location() {
    const location = useLocation();
    return location;
  }

  toggle1() {
    this.setState(prevState => ({
      modalOpen1: !prevState.modalOpen1
    }));
  }

  toggleModalTutorial() {
    this.setState(prevState => ({ modalTutorial: !prevState.modalTutorial }));
  }

  handleCopy() {
    const txtId = 'profilelink';
    copyToClipboard(txtId);
    this.setState(() => ({
      buttonCopy: 'Copied!'
    }));
  }

  componentDidMount() {
    window.RootUI.initPluginYaybar();
    setTimeout(() => {}, 2000);
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.setState({ deferredPrompt: e });
    });
  }

  handleInstallPrompt = () => {
    const { deferredPrompt } = this.state;
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        // eslint-disable-next-line no-console
        console.log('User accepted the install prompt');
      } else {
        // eslint-disable-next-line no-console
        console.log('User dismissed the install prompt');
      }
      this.setState({ deferredPrompt: null });
    });
  };

  deleteCookies() {
    // const domain = window.location.host;
    // const path = "/";
    // document.cookie = "token=" +
    //   ((path) ? ";path=" + path : "") +
    //   ((domain) ? ";domain=" + domain : "") +
    //   ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    window.location.reload();
  }
  logOut() {
    //const { updateAuth } = this.props;
    const doDeleteCookies = this.deleteCookies;
    axios
      .post(process.env.REACT_APP_LOGOUT_URL)
      .then(() => {
        doDeleteCookies();
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          doDeleteCookies();
        }
      });
  }

  isSubMenuHidden(navName) {
    let hiddenSubMenuList = [];

    if (!isPaidPlan(this.props?.currentUser?.account?.planType) || this.props?.currentUser?.account?.isPlanStopped) {
      const hiddenWhenPlanStopped = [
        'Penagihan Massal',
        'Kirim Uang Massal',
        'Payroll',
        'Detail Analytics'
      ];

      hiddenSubMenuList.push(...hiddenWhenPlanStopped);
    }

    if (this?.props?.currentUser?.role === 'admin') {
      const sidebarMenu = getLatestRolePathConfig(this.props.currentUser?.path, 'sidebarName');
      const settingMenu = getLatestRolePathConfig(this.props.currentUser?.path, 'settingRoute');

      if (navName === 'Beranda' || (navName === 'Pengaturan' && settingMenu.length > 0)) {
        return false;
      }

      if (sidebarMenu.indexOf(navName) < 0) {
        return true;
      }
    }

    if (
      !this.props.accountMode ||
      (this.props.accountMode &&
        JSON.stringify(this.props.accountMode) !== '{}' &&
        this.props.accountMode === 'merchant')
    ) {
      const hiddenForMerchantMode = ['Produk Affiliate'];

      hiddenSubMenuList.push(...hiddenForMerchantMode);
    }

    // Hid for affiliator mode
    if (this.props.accountMode === 'affiliator') {
      const hiddenForAffiliatorMode = [
        'Pelanggan',
        'Transaksi',
        'Berlangganan',
        'Order',
        'Permintaan Pembayaran',
        'Penagihan',
        'Penagihan Massal',
        'Invoice',
        'Link Pembayaran',
        'Produk Fisik',
        'Produk Digital',
        'Webinar',
        'Event & Acara',
        'Coaching & Mentoring',
        'Penggalangan Dana',
        'Paket Berlangganan',
        'Halaman Utama',
        'My Qris',
        'Kritik dan Saran',
        'Kelas Online (OD)',
        'Auto Responder',
        'Broadcast',
        'Anggota Affiliates',
        'Diskon dan Kupon',
        'Payroll',
        'Kirim Uang',
        'Kirim Uang Massal',
        'Bill Payment',
        'Pembayaran Invoice',
        'Pembayaran Massal',
        'Capital / Pendanaan',
        'Kartu Debit',
        'Audio Book',
        'E-Book',
        'Podcast',
        'Bundling',
        'Kelas Cohort / Bootcamp',
        'Storefront',
        'Webhook',
        'Cross-sell',
        'Integrasi',
        'Refer a Friend',
        'Membership & SaaS',
        'Tulisan',
        'Affiliate',
        'Lisensi Software',
        'Tags'
      ];

      hiddenSubMenuList.push(...hiddenForAffiliatorMode);
    }

    if (!this.props?.currentUser?.account?.planType && this.props?.currentUser?.isAffiliate) {
      const hiddenAsAffiliate = [
        'Pelanggan',
        'Transaksi',
        'Berlangganan',
        'Order',
        'Permintaan Pembayaran',
        'Penagihan',
        'Penagihan Massal',
        'Invoice',
        'Link Pembayaran',
        'Produk Fisik',
        'Produk Digital',
        'Webinar',
        'Event & Acara',
        'Coaching & Mentoring',
        'Penggalangan Dana',
        'Paket Berlangganan',
        'Halaman Utama',
        'My Qris',
        'Kritik dan Saran',
        'Kelas Online (OD)',
        'Auto Responder',
        'Broadcast',
        'Anggota Affiliates',
        'Diskon dan Kupon',
        'Payroll',
        'Kirim Uang',
        'Kirim Uang Massal',
        'Bill Payment',
        'Pembayaran Invoice',
        'Pembayaran Massal',
        'Capital / Pendanaan',
        'Kartu Debit',
        'Audio Book',
        'E-Book',
        'Podcast',
        'Bundling',
        'Kelas Cohort / Bootcamp',
        'Storefront',
        'Webhook',
        'Cross-sell',
        'Integrasi',
        'Refer a Friend',
        'Membership & SaaS',
        'Lisensi Software',
        'Tags'
      ];
      hiddenSubMenuList.push(...hiddenAsAffiliate);
    }

    if (specialAccessAffiliate.includes(this.props?.currentUser?.email)) {
      hiddenSubMenuList = hiddenSubMenuList.filter(
        item => item !== 'Affiliate' && item !== 'Anggota Affiliates' && item !== 'Sebagai Affiliator'
      );
    }

    // Hid for account created after 6 mei 2023
    if (this.props.currentUser?.createdAt > 1683306001000) {
      hiddenSubMenuList.push('My Qris');
    }

    return hiddenSubMenuList.includes(navName);
  }

  renderSubmenus(nav, returnObject = false) {
    let thereIsActive = false;

    const result = Object.keys(nav).map(url => {
      if (this.isSubMenuHidden(nav[url].name)) {
        return;
      }

      const data = nav[url];
      const isActive = window.location.hash === `#${url}`;
      let isOpened = false;

      if (isActive) {
        thereIsActive = true;
      }

      let sub = '';
      if (data.sub) {
        const subData = this.renderSubmenus(data.sub, true);
        sub = <ul className="yay-submenu dropdown-triangle">{subData.content}</ul>;

        if (subData.thereIsActive) {
          isOpened = true;
          thereIsActive = true;
        }
      }

      if (!this.props.currentUser.isProfileCompleted) {
        return;
      }

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.label ? <li className="yay-label">{data.label}</li> : ''}
          <li
            className={classnames({
              'yay-item-active': isActive,
              'yay-submenu-open': isOpened
            })}
          >
            {data.name ? (
              <NavLink to={data.sub ? '#' : url} className={data.sub ? 'yay-sub-toggle' : ''}>
                {data.icon ? (
                  <>
                    {data.name === 'Upgrade ke Mayar Pro' ? (
                      <>
                        <span
                          className={
                            data.name === 'Upgrade ke Mayar Pro' ? 'text-success yay-icon' : 'text-dark yay-icon'
                          }
                        >
                          <Icon name={data.icon} />
                        </span>
                        <span className={data.name === 'Upgrade ke Mayar Pro' ? 'text-success' : 'text-dark'}>
                          {data.name}{' '}
                          {(data.name === 'Produk Fisik' || data.name === 'Analytics') && (
                            <span className="text-primary">(Beta)</span>
                          )}
                          {(data.name === 'Auto Responder' || data.name === 'Kartu Debit') && (
                            <span className="text-warning">(Segera)</span>
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className={data.name === 'Refer a Friend' ? 'text-primary yay-icon' : 'text-dark yay-icon'}
                        >
                          <Icon name={data.icon} />
                        </span>
                        <span className={data.name === 'Refer a Friend' ? 'text-primary' : 'text-dark'}>
                          {data.name}{' '}
                          {(data.name === 'Produk Fisik' || data.name === 'Analytics') && (
                            <span className="text-primary">(Beta)</span>
                          )}
                          {(data.name === 'Auto Responder' || data.name === 'Kartu Debit') && (
                            <span className="text-warning">(Segera)</span>
                          )}
                        </span>
                      </>
                    )}
                    <span className="rui-yaybar-circle" />
                  </>
                ) : (
                  <span>
                    {data.name}{' '}
                    {(data.name === 'Kirim Uang / Top Up Massal' || data.name === 'Whatsapp') && (
                      <span className="text-primary">(Beta)</span>
                    )}
                  </span>
                )}
                {data.sub ? (
                  <span className="yay-icon-collapse">
                    <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                  </span>
                ) : (
                  ''
                )}
              </NavLink>
            ) : (
              ''
            )}
            {sub}
          </li>
        </React.Fragment>
      );
    });

    if (returnObject) {
      return {
        content: result,
        thereIsActive
      };
    }

    return result;
  }

  render() {
    const { settings, location } = this.props;
    const { buttonCopy, deferredPrompt } = this.state;

    const navRoute = settings.navigation_sidebar;
    const emailMerchant = this.props.currentUser.email;

    /* whitelist email for using whatsapp official */
    const whitelistEmailForWhatsappFeature = [
      "teammayar@gmail.com",
      "teammayarx@gmail.com",
      "teammayarz@gmail.com",
      "mayarstaging@toroo.org",
      "mayartesting05@gmail.com",
      "talksmayar@gmail.com"
    ]

    if (emailMerchant && !whitelistEmailForWhatsappFeature.includes(emailMerchant)) {
      delete navRoute['/developer-tools'].sub['/whatsapp-official'];
    }

    window.analytics.identify(this.props.currentUser.id, {
      name: this.props.currentUser.name,
      email: this.props.currentUser.email,
      mobile: this.props.currentUser.mobile,
      paymeLink: this.props.currentUser.paymeLink,
      accountName: this.props.currentUser.account ? this.props.currentUser.account.name : null
    });
    window.analytics.page(location.pathname, location);

    return (
      <>
        <div
          className={classnames(
            'yaybar rui-yaybar yay-hide-to-small yay-gestures',
            settings.sidebar_dark && !settings.night_mode ? 'rui-yaybar-dark' : '',
            settings.sidebar_static ? 'yay-static' : '',
            settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : ''
          )}
        >
          <div className="yay-wrap-menu">
            <div className="yaybar-wrap">
              {/* <ul>
                                <li>
                                    <div className="rui-navbar-brand ml-5">
                                        { settings.nav_logo_path ? (
                                            <Link to={ settings.nav_logo_url } className="rui-navbar-logo">
                                                <img src={ settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path } alt="" width={ settings.nav_logo_width } />
                                            </Link>
                                        ) : '' }
                                    </div>
                                </li>
                            </ul> */}

              <ul>
                {/* <li>
                                    <a href="../dashboard.html">
                                        <span
                                            className="yay-icon"
                                            dangerouslySetInnerHTML={ { __html: require( '!svg-inline-loader!../../../assets/images/logo-html-inherit.svg' ) } }
                                        />
                                        <span>Switch to HTML</span>
                                        <span className="rui-yaybar-circle"></span>
                                    </a>
                                </li> */}

                {this.renderSubmenus(navRoute)}
                {/* {this.renderSubmenus(settings.navigation_sidebar)} */}

                {/* <li className="yay-label">Logout</li> */}
                <li>
                  <NavLink to="#" onClick={this.logOut}>
                    <span className="yay-icon">
                      <Icon name="log-out" />
                    </span>
                    <span>Log Out</span>
                    <span className="rui-yaybar-circle"></span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <AccountModeButton
            deferredPrompt={deferredPrompt}
            handleInstallPrompt={this.handleInstallPrompt}
            buttonCopy={buttonCopy}
            handleCopy={this.handleCopy}
          />

          <div className="rui-yaybar-icons">
            <Row className="no-gutters justify-content-around">
              <Col xs="auto">
                <a
                  href="https://feedback.mayar.id/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-custom-round"
                  data-sleek-changelog
                  data-badge-changelog
                >
                  <Icon name="bell" />
                </a>
              </Col>
              <Col xs="auto">
                <Button className="btn btn-custom-round" onClick={this.toggleModalTutorial}>
                  <Icon name="help-circle" />
                </Button>
              </Col>
              <Col xs="auto">
                <Link to="#" onClick={this.logOut} className="btn btn-custom-round">
                  <Icon name="log-out" />
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        <div className="rui-yaybar-bg" />
        <Modal isOpen={this.state.modalOpen1} fade>
          <div className="modal-header">
            <h5 className="modal-title h2">Tombol Overlay Embed</h5>
            <Button className="close" color="" onClick={this.toggle1}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBodyEmbedButton url={paymeUrl(this.props.currentUser.paymeLink)} />
        </Modal>
        <ModalTutorial isOpen={this.state.modalTutorial} onClose={this.toggleModalTutorial} />
      </>
    );
  }
}

export default connect(
  ({ settings, currentUser, accountMode }) => ({
    settings,
    currentUser,
    accountMode
  }),
  { updateAuth: actionUpdateAuth, updateCurUser: updateCurrentUser, changeModeAccount: changeModeAccount }
)(PageYaybar);
