import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';

const SubmitButton = ({
  id,
  block,
  outline,
  size,
  color,
  type,
  onClick,
  loading,
  loadingMessage = false,
  isDisabled,
  isLoading,
  text,
  className,
  innerRef
}) => {
  return (
    <Button
      innerRef={innerRef}
      id={id}
      size={size}
      block={block}
      outline={outline}
      disabled={isLoading || isDisabled}
      type={type}
      color={color}
      onClick={onClick}
      className={className}
    >
      {loading && isLoading ? (
        <>
          {loadingMessage ? (
            <strong className="col text-white display-5 text-center px-25  m-0 loading-text-ellipsis">
              {loadingMessage}
            </strong>
          ) : (
            <Spinner
              className="mx-10"
              style={size === 'lg' ? { width: '1.5rem', height: '1.5rem' } : { width: '1.2rem', height: '1.2rem' }}
            />
          )}
        </>
      ) : (
        text
      )}
    </Button>
  );
};

SubmitButton.propTypes = {
  block: PropTypes.bool,
  outline: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.any,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string
};

export default SubmitButton;
