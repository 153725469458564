/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col, Spinner } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { GET_PAYMENT_LINK_COMMERCE } from '../../gqls/payment-links';
import { GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { paymentLinkDetails } from '../../actions/payment-links';
import _ from 'lodash';
import { updatePaginatedData } from '../../utils';

const PodcastDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = {
    search: {
      id: [{ operator: 'eq', value: id }]
    }
  };
  const { loading, error, data, fetchMore } = useQuery(GET_PAYMENT_LINK_COMMERCE, {
    variables: gparam
  });
  const pl = _.get(data, 'getPaymentLinkPage.items[0]');

  if (pl) {
    dispatch(paymentLinkDetails(pl));
  }

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl, pl]);

  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  useEffect(() => {
    if (pl?.id && !pl?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: pl?.id } });
    }
  }, [pl]);

  if (error) return `Error! ${error.message}`;

  if (loading) {
    return (
      <div className="center-of-screen">
        <Spinner></Spinner>
      </div>
    );
  }

  if (data && data.getPaymentLinkPage && data.getPaymentLinkPage.items.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/podcasts': 'Podcast'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{pl && pl.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default PodcastDetail;
