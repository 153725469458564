/* eslint-disable no-mixed-operators */
import { workRisk } from './configs';

export function calculateGajiBruto(amount, bonuses, tunjanganPPH, bpjsKesPaidBy, JKKPercentage) {
  let bpjsJKK = 0;
  let bpjsJKM = 0;
  let bpjsKes = 0;

  if (bpjsKesPaidBy === 'paid') {
    bpjsJKK = Math.round(amount * JKKPercentage);
    bpjsJKM = (amount * 3) / 1000;
    bpjsKes = amount > 12000000 ? (12000000 * 4) / 100 : (amount * 4) / 100;
  }

  const tambahanGrossUp = tunjanganPPH || 0;

  const bpjs = bpjsJKK + bpjsJKM;
  const gajiBruto = amount + bpjsJKK + bpjsJKM + bpjsKes + bonuses + tambahanGrossUp;

  return { gajiBruto: Math.round(gajiBruto), bpjs, bpjsKes };
}

export function calculateKontribusiPegawai(amount, isBPJSPaid) {
  let bpjsKesehatan = 0;

  if (isBPJSPaid === 'paid') {
    bpjsKesehatan = amount > 12000000 ? (12000000 * 1) / 100 : (amount * 1) / 100;
  }

  return {
    bpjsKes: Math.round(bpjsKesehatan)
  };
}

export function calculateTotalPengurang(amount, gajiBruto, jhtPaidBy, jpPaidBy) {
  const biayaJabatan = (gajiBruto * 5) / 100 > 500000 ? 500000 : (gajiBruto * 5) / 100;
  let bpjsJHT = 0;
  let bpjsJP = 0;

  if (jhtPaidBy === 'paid') {
    bpjsJHT = (amount * 2) / 100;
  }

  if (jpPaidBy === 'paid') {
    bpjsJP = amount > 9077600 ? (9077600 * 1) / 100 : (amount * 1) / 100;
  }

  const totalPengurang = biayaJabatan + bpjsJHT + bpjsJP;

  const bpjs = {
    bpjsJHT,
    bpjsJP
  };

  return { totalPengurang: Math.round(totalPengurang), bpjs, biayaJabatan };
}

export function calculatePtkp(ptkpStatus) {
  let ptkp;

  switch (ptkpStatus) {
    case 'tk/0':
      ptkp = 54000000;
      break;
    case 'tk/1':
      ptkp = 58500000;
      break;
    case 'tk/2':
      ptkp = 63000000;
      break;
    case 'tk/3':
      ptkp = 67500000;
      break;
    case 'k/0':
      ptkp = 58500000;
      break;
    case 'k/1':
      ptkp = 63000000;
      break;
    case 'k/2':
      ptkp = 67500000;
      break;
    case 'k/3':
      ptkp = 72000000;
      break;
    default:
      ptkp = 0;
      break;
  }

  return ptkp;
}

export function calculatePPH21(salaryPerYear, ptkp, isHavingNPWP) {
  const criteria = [
    { taxPercentage: 5, batasAtas: 50000000 },
    { taxPercentage: 15, batasAtas: 250000000 },
    { taxPercentage: 25, batasAtas: 500000000 },
    { taxPercentage: 30, batasAtas: 500000001 }
  ];
  const PKP = salaryPerYear - ptkp;

  let tempPkp = PKP;
  let i = 0;
  let pph21 = 0;

  while (tempPkp > 0 && i <= criteria.length - 1) {
    if (tempPkp > criteria[i].batasAtas) {
      tempPkp = tempPkp - criteria[i].batasAtas;
      pph21 += criteria[i].batasAtas * (criteria[i].taxPercentage / 100);
    } else {
      pph21 += tempPkp * (criteria[i].taxPercentage / 100);
      tempPkp = 0;
    }
    i++;
  }

  let PPHPerMonth;

  if (isHavingNPWP) {
    PPHPerMonth = pph21 / 12;
  } else {
    PPHPerMonth = ((pph21 / 12) * 120) / 100;
  }

  return {
    pph21,
    PPHPerMonth: Math.round(PPHPerMonth),
    PKP
  };
}

export function compensation(bonuses) {
  if (bonuses && JSON.parse(bonuses).length > 0) {
    return JSON.parse(bonuses)
      .map(item => item.amount)
      .reduce((prev, curr) => prev + curr, 0);
  }

  return 0;
}

export function calculateTunjanganPPH(gajiPokok, bonuses, ptkp, bpjsKesPaidBy, jhtPaidBy, jpPaidBy, JKKPercentage) {
  const gajiBruto = calculateGajiBruto(gajiPokok, bonuses, 0, bpjsKesPaidBy, JKKPercentage).gajiBruto;
  const pengurangPenghasilan = calculateTotalPengurang(gajiPokok, gajiBruto, jhtPaidBy, jpPaidBy).totalPengurang;

  const kontribusiPegawai = calculateKontribusiPegawai(gajiPokok, bpjsKesPaidBy);

  const totalPengurangBruto = pengurangPenghasilan + kontribusiPegawai.bpjsKes;
  const salaryPerMonth = gajiBruto - totalPengurangBruto;
  const salaryPerYear = salaryPerMonth * 12;

  const PKP = salaryPerYear - ptkp;

  const kriteriaTunjanganPPH = [
    {
      batasMin: 1,
      batasMaks: 47500000,
      kelompok: 'Kelompok 1',
      rumus: ((PKP - 0) * 5) / 95 + 0
    },
    {
      batasMaks: 217500000,
      kelompok: 'Kelompok 2',
      rumus: ((PKP - 47500000) * 15) / 85 + 2500000
    },
    {
      batasMaks: 405000000,
      kelompok: 'Kelompok 3',
      rumus: ((PKP - 217500000) * 25) / 75 + 32500000
    },
    {
      batasMaks: null,
      kelompok: 'Kelompok 4',
      rumus: ((PKP - 405000000) * 30) / 70 + 95000000
    }
  ];

  let tunjanganPPHSetahun;
  let i = 0;

  while (i < kriteriaTunjanganPPH.length) {
    if (!kriteriaTunjanganPPH[i].batasMaks) {
      tunjanganPPHSetahun = kriteriaTunjanganPPH[i].rumus;
      break;
    } else if (PKP <= kriteriaTunjanganPPH[i].batasMaks) {
      tunjanganPPHSetahun = kriteriaTunjanganPPH[i].rumus;
      break;
    }
    i++;
  }

  tunjanganPPHSetahun = Math.round(tunjanganPPHSetahun) < 0 ? 0 : Math.round(tunjanganPPHSetahun);

  return {
    tunjanganPPHSetahun,
    tunjanganPPHSebulan: Math.round(tunjanganPPHSetahun / 12)
  };
}

export function calculateTakeHomePay(item) {
  if (item === {} || !item) {
    return {
      thpAmount: null,
      bpjs: null,
      bpjsJht: null,
      bpjsJp: null,
      pphPerMonth: null,
      bpjsKes: null,
      ptkp: null,
      pkpPertahun: null,
      pkpPerbulan: null,
      tunjanganPPH: null
    };
  }

  const PTKP = calculatePtkp(item.ptkp);
  const JKKPercentage = Number(workRisk.find(value => value.value === item?.workRisk)?.percentage);
  let gajiBruto = calculateGajiBruto(
    item.basicSalary,
    Number(compensation(item.bonuses)),
    0,
    item.bpjsPaidBy,
    JKKPercentage
  ).gajiBruto;
  const bpjsBruto = calculateGajiBruto(
    item.basicSalary,
    Number(compensation(item.bonuses)),
    0,
    item.bpjsPaidBy,
    JKKPercentage
  ).bpjs;

  let tunjanganPPH = 0;

  if (item.taxType === 'gross_up') {
    tunjanganPPH = calculateTunjanganPPH(
      item.basicSalary,
      Number(compensation(item.bonuses)),
      PTKP,
      item.bpjsPaidBy,
      item.jhtPaidBy,
      item.pensionInsurancePaidBy,
      JKKPercentage
    ).tunjanganPPHSebulan;

    gajiBruto = calculateGajiBruto(
      item.basicSalary,
      Number(compensation(item.bonuses)),
      tunjanganPPH,
      item.bpjsPaidBy,
      JKKPercentage
    ).gajiBruto;
  }

  const pengurangPenghasilan = calculateTotalPengurang(
    item.basicSalary,
    gajiBruto,
    item.jhtPaidBy,
    item.pensionInsurancePaidBy
  );

  const bpjs = calculateTotalPengurang(item.basicSalary, gajiBruto, item.jhtPaidBy, item.pensionInsurancePaidBy).bpjs;
  const biayaJabatan = calculateTotalPengurang(item.basicSalary, gajiBruto, item.jhtPaidBy, item.pensionInsurancePaidBy)
    .biayaJabatan;

  const kontribusiPegawai = calculateKontribusiPegawai(item.basicSalary, item.bpjsPaidBy);
  const totalPengurangBruto = pengurangPenghasilan.totalPengurang + kontribusiPegawai.bpjsKes;

  const salaryPerMonth = gajiBruto - totalPengurangBruto;
  const salaryPerYear = salaryPerMonth * 12;

  const pph21 = calculatePPH21(salaryPerYear, PTKP, item.npwp ? true : false).PPHPerMonth;
  const pkp = calculatePPH21(salaryPerYear, PTKP, item.npwp ? true : false).PKP;

  const takeHomePay = item.basicSalary - totalPengurangBruto + pengurangPenghasilan.biayaJabatan;

  let amount = 0;

  switch (item.taxType) {
    case 'netto':
      amount = takeHomePay;
      break;
    case 'gross':
      amount = takeHomePay - pph21;
      break;
    case 'gross_up':
      amount = takeHomePay - pph21 + tunjanganPPH;
      break;
    default:
      break;
  }

  return {
    thpAmount: amount,
    bpjs: bpjsBruto,
    bpjsJht: bpjs.bpjsJHT,
    bpjsJp: bpjs.bpjsJP,
    pphPerMonth: pph21,
    tunjanganPph: tunjanganPPH,
    bpjsKes: kontribusiPegawai.bpjsKes,
    ptkp: PTKP,
    pkpPertahun: pkp > 0 ? pkp : 0,
    pkpPerbulan: pkp / 12 > 0 ? Math.round(pkp / 12) : 0,
    biayaJabatan
  };
}
