import { gql } from '@apollo/client';

export const GQL_GET_CONVERSION_SETTING = gql`
  query getConversionSettingFilteredList($search: ConversionSettingSearchInput) {
    getConversionSettingFilteredList(search: $search) {
      id
      status
      trackingId
      paymentLinkId
      type
      conversionSettingEvents {
        id
        name
        page
      }
    }
  }
`;

export const GQL_INSERT_EDIT_CONVERSION_SETTING = gql`
  mutation insertOrEditConversionSetting($input: ConversionSettingInput) {
    insertOrEditConversionSetting(input: $input) {
      id
      paymentLinkId
      status
      trackingId
      type
    }
  }
`;

export const GQL_REMOVE_CONVERSION_SETTING = gql`
  mutation removeConversionSetting($id: ID!) {
    removeConversionSetting(id: $id) {
      errorMessage
    }
  }
`;

export const GQL_EDIT_CONVERSION_SETTING_EVENT = gql`
  mutation updateConversionSettingEvent($input: ConversionSettingEventInput) {
    updateConversionSettingEvent(input: $input) {
      conversionSettingId
      createdAt
      name
      page
      updatedAt
    }
  }
`;

export const GQL_INSERT_EDIT_CONVERSION_SETTING_LANDING = gql`
  mutation insertOrEditConversionSettingLanding($input: ConversionSettingInput) {
    insertOrEditConversionSettingLanding(input: $input) {
      id
      paymentLinkId
      status
      trackingId
      type
    }
  }
`;
