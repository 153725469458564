import { gql } from '@apollo/client';

export const TEST_URL_HOOK = gql`
    mutation testSendingUrlHook($input: UserInput) {
        testSendingUrlHook(input: $input) {
            responseCode
            response
            errorMessage
        }
    }
`;

export const RETRY_SENDING_HOOK = gql`
    mutation retrySendingHook($input: UserInput) {
        retrySendingHook(input: $input) {
            responseCode
            response
            errorMessage
        }
    }
`;

export const GET_OR_SET_WEBHOOK_TOKEN = gql`
    query getOrSetWebhookToken($id: ID!) {
        getOrSetWebhookToken(id: $id) {
            webhookToken
            operationStatus
            operationMessage
        }
    }
`;

export const SET_URL_HOOK_W_TOKEN = gql`
    mutation setUrlHookWithToken($input: UserInput) {
        setUrlHookWithToken(input: $input) {
            operationStatus
            operationMessage
        }
    }
`;

export const GET_WEBHOOK_HISTORY = gql`
    query getWebhookHistoryPage($page: Int, $pageSize: Int, $search: WebhookHistorySearchInput, $searchAny: WebhookHistorySearchInput, $sortDirection: SortDirection, $sortField: WebhookHistorySortField) {
        getWebhookHistoryPage(page: $page, pageSize: $pageSize, search: $search, searchAny: $searchAny, sortDirection: $sortDirection, sortField: $sortField) {
            items {
                id
                createdAt
                nextDelivery
                paymentLinkId
                payload
                status
                type
                updatedAt
                userId
                paymentLinkTransactionId
                urlDestination
                responsePayload
                source
            }
            total
            page
            offset
            totalPage
            prevPage
            nextPage
        }
    }
`;

export const GET_WEBHOOK_HISTORY_FILTERED = gql`
    query getWebhookHistoryFilteredList($search: WebhookHistorySearchInput, $sortField: WebhookHistorySortField) {
    getWebhookHistoryFilteredList(search: $search, sortField: $sortField) {
        createdAt
        nextDelivery
        payload
        paymentLinkId
        paymentLinkTransactionId
        source
        status
        type
        updatedAt
        urlDestination
        userId
    }
}
`;
