/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';

import _ from 'lodash';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Row, Col, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GET_PAYMENT_LINK } from './graphql';
import { updatePaginatedData } from '../../utils';
import { paymentLinkDetails } from '../../actions/payment-links';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const InstallmentDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);

  const gparam = { search: { id: [{ operator: 'eq', value: id }] } };

  const { loading, error, data, fetchMore } = useQuery(GET_PAYMENT_LINK, {
    variables: gparam
  });
  const pl = _.get(data, 'getPaymentLinkPage.items[0]');
  if (pl) dispatch(paymentLinkDetails(pl));

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl, pl]);

  if (error) return `Error! ${error.message}`;
  if (loading) return <Spinner />;

  if (data?.getPaymentLinkPage?.items?.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{ '/': currentUser?.name || ' ', '/installments': 'Cicilan' }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{pl?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default InstallmentDetail;
