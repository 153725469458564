import React, { useState } from 'react';
import { ModalBody, Input, Alert, Button, CustomInput, FormGroup } from 'reactstrap';
import { copyToClipboard } from '../../utils';

const embedSettingCheckoutPage = [
  { id: 'merchant-logo', label: 'Sembunyikan Logo', value: false },
  { id: 'product-images', label: 'Sembunyikan Gambar', value: false },
  { id: 'header', label: 'Sembunyikan Header Nama dan Harga', value: false },
  { id: 'badge-secure', label: 'Sembunyikan Badge Secure', value: false },
  { id: 'language', label: 'Sembunyikan Bahasa', value: false }
];

const ModalBodyEmbedButton = ({ url }) => {
  const [buttonText, setButtonText] = useState('Beli Sekarang');
  const [buttonCopy, setButtonCopy] = useState('Copy Kode');
  const [embedCopy, setEmbedCopy] = useState('Copy Kode');
  const [embedSetting, setEmbedSetting] = useState(embedSettingCheckoutPage);

  const handleChange = e => {
    setButtonText(e.target.value);
  };

  const handleCopy = textAreaId => {
    copyToClipboard(textAreaId);
    setButtonCopy('Copied!');
  };

  const handleEmbedCopy = textAreaId => {
    copyToClipboard(textAreaId);
    setEmbedCopy('Copied!');
  };

  const dataSetEmbed = embedSetting.map(({ id, value }) => (value ? `data-hide-${id}="${value}" ` : ''))?.join('');

  return (
    <ModalBody>
      <Alert color="light">
        <small>
          Tombol Embed adalah tombol yang bisa anda letakkan di website atau blog anda untuk memudahkan transaksi
          langsung diwebsite anda.
        </small>
      </Alert>
      <div className="mt-10">
        <Input type="text" name="button-text" value={buttonText} onChange={e => handleChange(e)} />
      </div>

      <div className="mt-20 text-center">
        <p className="mt-10">
          <a className="mayar-button">
            <span></span>
            {buttonText}
          </a>
        </p>
      </div>
      <div>
        <Input
          id="embedlink"
          type="textarea"
          value={`<a class="mayar-button iframe-lightbox-link" href="${url}?iframe=true" data-padding-bottom="30%" data-scrolling="true"><span></span>${buttonText}</a>
<script type="text/javascript" src="https://mayarembed.r2.mayar.id/mayar-new-min.js"></script>`}
          readOnly
          style={{ height: 100 }}
        />
        <Button block color="dark" className="mt-10 text-center" size="lg" onClick={() => handleCopy('embedlink')}>
          {buttonCopy}
        </Button>
      </div>
      <hr className="mt-25 mb-20" />
      <h3>Embed Halaman Checkout</h3>
      <Alert color="light">
        <small>
          Embed Halaman Checkout keseluruhan di halaman blog atau website anda. Anda dapat juga embed halaman checkout
          langsung di Notion, Ghost, Canva dll.
        </small>
      </Alert>
      <div className="mt-10">
        {embedSetting.map(({ id, label }) => {
          return (
            <FormGroup key={id}>
              <CustomInput
                id={id}
                name={id}
                label={label}
                type="switch"
                color="primary"
                onChange={e =>
                  setEmbedSetting(embedSetting.map(set => (set.id === id ? { ...set, value: e.target.checked } : set)))
                }
              />
            </FormGroup>
          );
        })}
      </div>
      <div>
        <Input
          id="embedpagelink"
          type="textarea"
          value={`<iframe allowfullscreen="" allowpaymentrequest="allowpaymentrequest" scrolling="no" frameborder="0" width="100%" height="100%" src="${url}" ${dataSetEmbed}></iframe>
<script type="text/javascript" src="https://mayarembed.r2.mayar.id/mayarEmbed.min.js"></script>`}
          readOnly
          style={{ height: 100 }}
        />
        <Button
          block
          color="dark"
          className="mt-10 text-center"
          size="lg"
          onClick={() => handleEmbedCopy('embedpagelink')}
        >
          {embedCopy}
        </Button>
      </div>
    </ModalBody>
  );
};

export default ModalBodyEmbedButton;
