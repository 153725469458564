import "./style.scss";
import React from 'react';
import Map from "./map";
import SearchBox from './search-box';

const StreetMapsInput = ({ state }) => {
  const { errors, control, selectPosition, setSelectPosition, data, fieldName } = state;

  return (
    <div>
        <SearchBox selectPosition={selectPosition} setSelectPosition={setSelectPosition} errors={errors} control={control} data={data} fieldName={fieldName} />
        <Map selectPosition={selectPosition} />
    </div>
  )
}

export default StreetMapsInput;
