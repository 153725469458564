import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation editUser($input: UserInput) {
    editUser(input: $input) {
      id
    }
  }
`;

export const UPDATE_PAYME_USER = gql`
  mutation updatePaymeLink($input: UserInput) {
    updatePaymeLink(input: $input) {
      errorMessage
    }
  }
`;
