import { gql } from '@apollo/client';

export const GET_PAYMENT_LINK = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        link
        status
        tax
        amount
        expiredAt
        createdAt
        description
        notes
        items
        transactions {
            id
        }
        customer {
            name
            email
        }
      }
    }
  }
`;
