import { gql } from '@apollo/client';

export const GQL_GET_ACCOUNT = gql`
  query getAccount($id: ID) {
    getAccount(id: $id) {
      address
      profile
      email
      id
      mobile
      name
      website
      planType
      xenditStaticQris
      planSubscriptionPeriod
      trialEndDate
      planType
      invoiceDuration
      enterprisePriceDeal
      bankAccounts {
        accountId
        accountName
        accountNumber
        bank
        id
      }
      logo {
        url
      }
      subDistrictId
      subDistrict {
        subDistrictId
        district {
          districtId
          provinceId
        }
      }
    }
  }
`;

export const GQL_ACCOUNT_UPDATE_PROXY = gql`
  mutation updateAccountProxy($input: AccountInput) {
    updateAccountProxy(input: $input) {
      id
      address
      profile
      email
      mobile
      name
      website
      planType
      subDistrictId
    }
  }
`;
