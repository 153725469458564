export const UPDATE_ORDER = 'UPDATE_ORDER';
export const updateOrder = (q) => ({
  type: UPDATE_ORDER,
  payload: q,
});

export const ORDER_DETAILS = 'ORDER_DETAILS';
export const orderDetails = (q) => ({
  type: ORDER_DETAILS,
  payload: q,
});

export const FILTER_ORDER_BY_CUSTOMER = 'FILTER_ORDER_BY_CUSTOMER';
export const filterOrderByCustomer = (q) => ({
  type: FILTER_ORDER_BY_CUSTOMER,
  payload: q,
});

export const FILTER_ORDER_BY_STATUS = 'FILTER_ORDER_BY_STATUS';
export const filterOrderByStatus = (q) => ({
  type: FILTER_ORDER_BY_STATUS,
  payload: q,
});

export const TRACKING_ORDER = 'TRACKING_ORDER';
export const trackingOrder = (q) => ({
  type: TRACKING_ORDER,
  payload: q,
});
