import { gql } from '@apollo/client';

export const GQL_FUNDRAISING_GET_WITH_USER = gql`
  query getFundraisingPage($search: FundraisingSearchInput) {
    getFundraisingPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        status
        category
        coverImageId
        createdAt
        description
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
        user {
          name
          email
        }
      }
    }
  }
`;

export const GQL_FUNDRAISING_GET = gql`
  query getFundraisingPage($search: FundraisingSearchInput) {
    getFundraisingPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        status
        category
        coverImageId
        createdAt
        description
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
      }
    }
  }
`;

export const GQL_FUNDRAISING_GET_WITH_TRANSACTIONS = gql`
  query getFundraisingPage($search: FundraisingSearchInput) {
    getFundraisingPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        status
        category
        coverImageId
        createdAt
        description
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
        user {
          account {
            name
          }
        }
        coverImage {
          url
          id
        }
      }
    }
  }
`;

export const GQL_FUNDRAISING_PAGE_WITH_CUSTOMER = gql`
  query getFundraisingPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: FundraisingSortField
    $searchAny: FundraisingSearchInput
    $search: FundraisingSearchInput
  ) {
    getFundraisingPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        status
        category
        coverImageId
        createdAt
        description
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_FUNDRAISING_PAGE = gql`
  query getFundraisingPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: FundraisingSortField
    $searchAny: FundraisingSearchInput
    $search: FundraisingSearchInput
  ) {
    getFundraisingPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        status
        category
        coverImageId
        createdAt
        description
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_FUNDRAISING_CREATE = gql`
  mutation insertFundraising($input: FundraisingInput) {
    insertFundraising(input: $input) {
      id
      status
      category
      coverImageId
      createdAt
      description
      expiredAt
      link
      name
      reason
      receiver
      redirectUrl
      target
      updatedAt
      usage
    }
  }
`;

export const GQL_FUNDRAISING_UPDATE = gql`
  mutation editFundraising($input: FundraisingInput) {
    editFundraising(input: $input) {
      id
      status
      category
      coverImageId
      createdAt
      description
      expiredAt
      link
      name
      reason
      receiver
      redirectUrl
      target
      updatedAt
      usage
    }
  }
`;

export const GQL_FUNDRAISING_DELETE = gql`
  mutation deleteFundraising($id: ID) {
    deleteFundraising(id: $id)
  }
`;

export const GQL_FUNDRAISING_HISTORY = gql`
  query getFundraisingHistoryProxy($paymentLinkId: ID) {
    getFundraisingHistoryProxy(paymentLinkId: $paymentLinkId) {
      total
    }
  }
`;

export const GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID = gql`
  query getTotalFundraisingsByPaymentLinkID($id: ID, $link: String) {
    getTotalFundraisingsByPaymentLinkID(id: $id, link: $link) {
      totalFundraising
      target
    }
  }
`;

export const GQL_CREATE_FUNDRAISING_SETTINGS = gql`
  mutation createFundraisingSettingsProxy($input: FundraisingSettingsInput) {
    createFundraisingSettingsProxy(input: $input) {
      id
      isZakatVisible
      isWaqafVisible
      isQurbanVisible
      isNewsVisible
      mainPrograms
      quickDonations
    }
  }
`;

export const GQL_UPDATE_FUNDRAISING_SETTINGS = gql`
  mutation updateFundraisingSettingsProxy($input: FundraisingSettingsInput) {
    updateFundraisingSettingsProxy(input: $input) {
      id
      isZakatVisible
      isWaqafVisible
      isQurbanVisible
      isNewsVisible
      mainPrograms
      quickDonations
    }
  }
`;
