import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { updatePaymentLinks } from '../../actions/payment-links';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  FormFeedback,
  Progress,
  CustomInput,
  Col
} from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from '../date-time-picker';
import {
  GQL_PAYMENTLINK_CREATE_BY_SLUG,
  GQL_PAYMENTLINK_UPDATE_BY_SLUG,
  GQL_UPLOAD_IMAGE,
  GQL_UPDATE_IMAGE
} from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  getUserTimezone,
  sanitizeDOM,
  REGEX_SLUG,
  slug,
  getBase64,
  isJSON,
  handleCompressImage
} from '../../utils';

import Icon from '../icon';
import moment from 'moment-timezone';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import { EditorState } from 'draft-js';
import StreetMapsInput from '../street-maps-input';

const ModalBodyEvent = ({ isOpen = false, onClose, onSuccess, data }) => {
  const [insertPaymentLink, { data: newpl, loading: submitCreateLoading }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLink, { loading: submitUpdateLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);
  const [editImage, { loading: loadingEditImage }] = useMutation(GQL_UPDATE_IMAGE);

  const today = new Date();
  const tomorrow = new Date(today);
  const { register, handleSubmit, errors, control } = useForm();
  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [submitError, setSubmitError] = useState(false);
  const [submitCoverSuccess, setSubmitCoverSuccess] = useState(false);

  const [submitCoverError, setSubmitCoverError] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [initialImagesFiles, setInitialImagesFiles] = useState([]);
  const [imageState, setImageState] = useState([]);

  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [eventStartAt, setEventStartAt] = useState(data ? data.eventStartAt : null);
  const [eventEndAt, setEventEndAt] = useState(data ? data.eventEndAt : null);
  const [link, setLink] = useState(data ? data.link : '');
  const [onlineEvent, setOnlineEvent] = useState(data?.event?.isOnlineEvent || false);
  const [selectPosition, setSelectPosition] = useState(null);

  const stateEdit = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const dispatch = useDispatch();
  const isUpdate = !data ? false : true;

  if (!data) data = {};

  useEffect(() => {
    if (!onlineEvent && data?.event?.mapLocation) {
      try {
        setSelectPosition(JSON.parse(data.event.mapLocation));
      } catch (e) {
        setSelectPosition(data.event.mapLocation);
      }
    }
  }, [data]);

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const setInitialImage = array => {
    const myArray = Array.from(array);
    /*eslint-disable-next-line no-nested-ternary*/
    const sortedRes = myArray.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setInitialImagesFiles(sortedRes);
  };

  useEffect(() => {
    if (isUpdate && data?.multipleImage?.length > 0) {
      const array = [];
      [...data.multipleImage]
        .sort((a, b) => moment(a.updatedAt).diff(b.updatedAt))
        .forEach((obj, index) => {
          const splitted = obj?.url.split('/');
          const last = splitted[splitted.length - 1];
          /* eslint-disable-next-line */
          fetch(`${process.env.REACT_APP_PROXY}/image/${last}`).then(res => {
            res.arrayBuffer().then(buf => {
              /* eslint-disable-next-line */
              const file = new File([buf], `${data.name}-${index}.jpg`, { type: 'image/jpeg' });
              array.push(file);

              if (array.length === data?.multipleImage?.length) {
                setInitialImage(array);
              }
            });
          });
        });
    }
  }, [isUpdate, data, imageState]);

  const mapState = {
    onlineEvent,
    errors,
    control,
    register,
    selectPosition,
    setSelectPosition,
    data,
    fieldName: 'mapLocation'
  };

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    if (date !== null) setDate(date);
    if (eventStartAt !== null) setEventStartAt(eventStartAt);
    if (eventEndAt !== null) setEventEndAt(eventEndAt);

    if (!values.isOnlineEvent && values.mapLocation?.label) {
      values.mapLocation = JSON.stringify(selectPosition);
    }

    const formValues = {
      ...values,
      description: sanitizeDOM(description),
      event: {
        eventAddress: values.eventAddress,
        isOnlineEvent: values.isOnlineEvent,
        maxTicket: Number(values.maxTicket),
        mapLocation: values.mapLocation
      }
    };

    delete formValues.isOnlineEvent;
    delete formValues.eventAddress;
    delete formValues.mapLocation;
    delete formValues.maxTicket;

    try {
      if (isUpdate) {
        let statusUpdate = 'closed';
        if (!date || new Date(date) > Date.now()) {
          statusUpdate = 'active';
        }
        const objeditPaymentLinkBySlug = {
          ...formValues,
          id: data.id,
          expiredAt: date ? new Date(date).setHours(23, 59, 59, 0) : eventStartAt,
          eventStartAt: eventStartAt,
          eventEndAt: eventEndAt,
          status: statusUpdate,
          eventUrl: values.eventAddress,
          event: {
            ...formValues.event
          }
        };

        if (multipleImageId) {
          objeditPaymentLinkBySlug.multipleImageId = multipleImageId;
        }
        if (isRemoveAllProductImage) {
          objeditPaymentLinkBySlug.multipleImageId = null;
        }
        await editPaymentLink({
          variables: {
            input: objeditPaymentLinkBySlug
          }
        });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        const objPaymentLink = {
          ...formValues,
          expiredAt: date ? new Date(date).setHours(23, 59, 59, 0) : eventStartAt,
          link: slug(values.name),
          type: 'event',
          status: 'active',
          eventStartAt: eventStartAt,
          eventEndAt: eventEndAt,
          eventUrl: values.eventAddress,
          event: {
            ...formValues.event
          }
        };

        if (multipleImageId) {
          objPaymentLink.multipleImageId = multipleImageId;
        }
        if (isRemoveAllProductImage) {
          objPaymentLink.multipleImageId = null;
        }
        await insertPaymentLink({
          variables: {
            input: objPaymentLink
          }
        });
        //segment tracking
        window.analytics.track('Create Webinar', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Webinar - ERROR', values);
    }
  };

  if (newpl && newpl.insertPaymentLinkBySlug) {
    onSuccess(newpl.insertPaymentLinkBySlug.id);
  }

  const [uploadImage, { loading: uploadLoading }] = useMutation(GQL_UPLOAD_IMAGE);

  function addMinutesToDate(objDate, intMinutes) {
    const addMlSeconds = intMinutes * 60 * 1000;
    const newDateObj = objDate + addMlSeconds;

    return newDateObj;
  }

  const uploadImageIteration = async (e, imageId) => {
    const values = {};
    const compressedImage = await handleCompressImage(e.file);
    const base64 = await getBase64(compressedImage);

    values.base64 = base64;
    values.fileType = compressedImage.type.split('/')[1];
    values.title = imageId;

    const res = await uploadImage({
      variables: values
    });

    return res;
  };

  const incrementUpdatedAtImage = async (file, index) => {
    const minutes = (index + 1) * 10;
    const newUpdatedAt = addMinutesToDate(file.data.uploadImage.updatedAt, minutes);
    const res = await editImage({
      variables: {
        input: {
          id: file.data.uploadImage.id,
          updatedAt: newUpdatedAt
        }
      }
    });

    return res;
  };

  const handleCoverUpload = async files => {
    setLoadingUpload(true);
    setSubmitCoverError(false);
    setSubmitCoverSuccess(false);

    const imageTitleId = uuidv4();

    const resultArray = await Promise.all(files.map(async i => uploadImageIteration(i, imageTitleId)));
    const updatedImageArray = await Promise.all(resultArray.map(async (i, index) => incrementUpdatedAtImage(i, index)));

    if (updatedImageArray.length === files.length) {
      setMultipleImageId(imageTitleId);
      setImageState(updatedImageArray);

      setSubmitCoverError(false);
      setSubmitCoverSuccess(true);
    } else {
      setSubmitCoverError(true);
      setSubmitCoverSuccess(false);
    }

    setLoadingUpload(false);
  };

  const SubmitButtonImage = props => {
    const { files, onSubmit: onSubmitForm, extra, disabled } = props;
    let isDisabled = disabled;
    const handleClick = React.useCallback(() => {
      if (typeof onSubmit !== 'function') {
        return;
      }
      onSubmitForm(files);
    }, [files, onSubmitForm]);

    const filesTooBig = files.filter(({ meta }) => meta.size > extra.maxSizeBytes);
    if (filesTooBig.length > 0) {
      isDisabled = true;
    }

    return (
      <Col className="px-3percent">
        <SubmitButton
          size="md"
          color="brand"
          type="button"
          isDisabled={isDisabled}
          className={`text-center float-left mb-10 py-6 text-capitalize font-13 ${files.length >= 5 ? 'mt-10' : ''}`}
          text="Unggah Gambar"
          loading
          isLoading={loadingUpload}
          onClick={handleClick}
        />
      </Col>
    );
  };

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Event / Acara</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Mengadakan event offline atau online, menerima pembelian tiket, pendaftaran dan pembayaran semakin mudah
            dengan Mayar
          </small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Event / Acara*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="formSwitch1"
              label="EVENT ONLINE"
              name="isOnlineEvent"
              type="switch"
              color="primary"
              defaultChecked={data?.event?.isOnlineEvent}
              innerRef={register()}
              onChange={e => setOnlineEvent(e.target.checked)}
            />
          </FormGroup>

          {onlineEvent ? (
            <FormGroup>
              <Label for="eventAddress">Event URL/Link (Zoom/Google Meet/Youtube Live, dll)</Label>
              <Input
                type="text"
                name="eventAddress"
                id="eventAddress"
                placeholder="https://us04web.zoom.us/j/XXXXXX?pwd=XXXXX (opsional)"
                invalid={errors.eventAddress}
                defaultValue={data?.event?.eventAddress && !selectPosition ? data?.event?.eventAddress : ''}
                innerRef={register({ pattern: validator.url })}
              />
              <FormFeedback>{errors?.eventAddress?.message}</FormFeedback>
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                <Label for="eventAddress">Lokasi / Alamat Venue</Label>
                <Input
                  type="textarea"
                  name="eventAddress"
                  id="eventAddress"
                  invalid={errors.eventAddress}
                  defaultValue={
                    isJSON(data?.event?.eventAddress)
                      ? JSON.parse(data?.event?.eventAddress)?.display_name
                      : data?.event?.eventAddress
                  }
                  innerRef={register()}
                />
                <FormFeedback>{errors.eventAddress?.message}</FormFeedback>
                <small>
                  Tulis lokasi acara selengkapnya, mulai dari nama gedung / venue, nama jalan, kota, dan provinsi
                </small>
              </FormGroup>

              <StreetMapsInput state={mapState} />
            </>
          )}

          <FormGroup>
            <Label for="eventStartAt">Waktu Mulai*</Label>
            <div className="mb-1 date-block">
              <DatePicker
                showTimeSelect
                placeholderText="Pilih Tanggal dan Jam"
                dateFormat="yyyy/MM/dd HH:mm"
                timeFormat="HH:mm"
                selected={eventStartAt ? new Date(eventStartAt) : null}
                minDate={new Date()}
                required
                className="rui-datetimepicker form-control"
                onChange={d => setEventStartAt(d)}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="eventEndAt">Waktu Selesai</Label>
            <div className="mb-1 date-block">
              <DatePicker
                showTimeSelect
                placeholderText="Pilih Tanggal dan Jam"
                dateFormat="yyyy/MM/dd HH:mm"
                timeFormat="HH:mm"
                selected={eventEndAt ? new Date(eventEndAt) : null}
                minDate={new Date()}
                className="rui-datetimepicker form-control"
                onChange={d => setEventEndAt(d)}
              />
            </div>
            <small>
              Timezone menggunakan {moment().tz(getUserTimezone()).format('z')} (GMT
              {moment().tz(getUserTimezone()).format('Z')}) - {moment.tz.guess()}
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="cover">Cover (gambar untuk promo)</Label>
            {submitCoverError ? <Alert color="danger">Upload cover gagal, silahkan coba lagi (png/jpg)</Alert> : null}
            {submitCoverSuccess ? <Alert color="light">Upload Gambar Cover Sukses!</Alert> : null}
            {loadingUpload ? (
              <Alert color="light">
                <Progress animated color="primary" value="100" />
                Sedang mengunggah...
              </Alert>
            ) : null}
            {!submitCoverSuccess && !loadingUpload && (
              <>
                <Dropzone
                  onSubmit={handleCoverUpload}
                  name="dropzone"
                  accept="image/*"
                  maxFiles={5}
                  maxSizeBytes={5242880}
                  canRemove={!loadingUpload}
                  SubmitButtonComponent={SubmitButtonImage}
                  initialFiles={initialImagesFiles}
                  onChangeStatus={(...args) => {
                    const files = args[2];
                    if (files.length === 1 && args[1] === 'removed') setIsRemoveAllProductImage(true);
                    else setIsRemoveAllProductImage(false)
                  }}
                  inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag Image Files')}
                  styles={{
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                    previewImage: { maxHeight: '140px' }
                  }}
                  classNames={{
                    dropzone: 'dzu-dropzone',
                    preview: 'dzu-previewContainer py-10',
                    inputLabelWithFiles: 'dzu-inputLabelWithFiles mt-10'
                  }}
                />
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="notes">Instruksi</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Instruksi adalah catatan yang akan dilihat oleh pendaftar setelah melakukan pendaftaran/membayar. Anda
              bisa memasukkan instruksi masuk ke zoom, passwordnya, formulir google, kontak cs dll disini jika event
              online
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="terms">Syarat dan Ketentuan</Label>
            <Input
              type="textarea"
              name="terms"
              id="terms"
              invalid={errors.terms}
              defaultValue={data.terms}
              innerRef={register()}
            />
            <FormFeedback>{errors.terms?.message}</FormFeedback>
          </FormGroup>
          <hr />
          <FormGroup>
            <CustomInput
              id="formSwitch2"
              label="1 akun email - 1 kali transaksi "
              name="oneAccountTransaction"
              type="switch"
              color="primary"
              defaultChecked={data?.oneAccountTransaction}
              innerRef={register()}
            />
          </FormGroup>
          <FormGroup>
            <Label for="startAt"> Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="kadaluarsa4">Batas Pendaftaran</Label>
            <div className="mb-1">
              <DatePicker
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>
              Kami akan menutup pendaftaran setelah tanggal ini (opsional), jika tidak diisi kami akan menutup event ini
              setelah acara dimulai.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="maxTicket">Jumlah maksimal tiket per transaksi</Label>
            <Input
              innerRef={register()}
              defaultValue={data?.event?.maxTicket || 50}
              id="maxTicket"
              name="maxTicket"
              type="select"
            >
              {Array.from(Array(50).keys()).map(item => (
                <option key={item} value={item + 1}>
                  {item + 1} Tiket
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websiteanda.com/terimakasih"
              invalid={errors.redirectUrl}
              defaultValue={data.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
            <small>Pelanggan akan dibawa kehalaman ini setelah membayar (opsional /bisa dikosongkan)</small>
          </FormGroup>

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            loading
            isLoading={submitCreateLoading || submitUpdateLoading}
            isDisabled={uploadLoading || loadingEditImage}
            text={`${isUpdate ? 'Update' : 'Buat'} Event`}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyEvent;
