/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { useParams } from 'react-router-dom';

const MembershipPaymentDetail = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const { id } = useParams();

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/memberships': 'Membership & SaaS',
        }}
      >
        <Row className="mb-0">
          <Col md="8">
            <h1>Langganan ID #{id.slice(0, 8)}</h1>
          </Col>
          <Col md="4" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default MembershipPaymentDetail;
