/**
 * External Dependencies
 */
import Loadable from 'react-loadable';
import React, { useCallback } from 'react';
import { Spinner } from 'reactstrap';

export default function AsyncComponent(props) {
  const LoadableComponent = useCallback(Loadable({
    loader: props.component,
    loading: () => {
      return (
        <Spinner color="secondary" type="grow">
          Loading...
        </Spinner>
      );
    },
  }), []);

  return <LoadableComponent />;
}
