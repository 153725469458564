import { gql } from '@apollo/client';

export const INSERT_CROSS_SELL_PAYMENT_LINKS = gql`
  mutation insertCrossSellPaymentLinks($crossSellPaymentLinks: [CrossSellPaymentLinkInput]!, $mainPaymentLinkId: ID!) {
    insertCrossSellPaymentLinks(crossSellPaymentLinks: $crossSellPaymentLinks, mainPaymentLinkId: $mainPaymentLinkId) {
      id
    }
  }
`;

export const GET_CROSS_SELL_PAYMENT_LINK_FILTERED_LIST = gql`
  query getCrossSellPaymentLinkFilteredList(
    $limit: Int
    $search: CrossSellPaymentLinkSearchInput
    $searchAny: CrossSellPaymentLinkSearchInput
    $sortDirection: SortDirection
    $sortField: CrossSellPaymentLinkSortField
  ) {
    getCrossSellPaymentLinkFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      createdAt
      id
      mainPaymentLinkId
      crossSellPaymentLinkId
      mainPaymentLink {
        id
        name
        type
      }
    }
  }
`;

export const GET_CROSS_SELL_PAYMENT_LINK_PAGE = gql`
  query getCrossSellPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $search: CrossSellPaymentLinkSearchInput
    $searchAny: CrossSellPaymentLinkSearchInput
    $sortDirection: SortDirection
    $sortField: CrossSellPaymentLinkSortField
  ) {
    getCrossSellPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      offset
      page
      prevPage
      total
      totalPage
      items {
        createdAt
        id
        mainPaymentLinkId
        crossSellPaymentLinkId
        discountType
        discountValue
        crossSellPaymentLink {
          id
          name
          type
          amount
        }
      }
    }
  }
`;

export const GET_CROSS_SELL_PAYMENT_LINK_TOTAL = gql`
  query getCrossSellPaymentLinkPage($search: CrossSellPaymentLinkSearchInput) {
    getCrossSellPaymentLinkPage(search: $search) {
      total
    }
  }
`;

export const EDIT_CROSS_SELL_PAYMENT_LINK = gql`
  mutation editCrossSellPaymentLink($input: CrossSellPaymentLinkInput!) {
    editCrossSellPaymentLink(input: $input) {
      id
      status
    }
  }
`;
