import { SUPPORT_DETAILS, SUPPORT_TAB } from "../actions/support";

export const supportTabReducer = (state = '', action) => {
  switch (action.type) {
    case SUPPORT_TAB:
      return action.payload;
  }
  return state;
};

export const supportDetailsReducer = (state = '', action) => {
	switch (action.type) {
		case SUPPORT_DETAILS:
		return action.payload;
	}
	return state;
};
