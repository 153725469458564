import { gql } from '@apollo/client';

export const GQL_PAY_CHANNEL_CONFIG_PAGE = gql`
  query getPayChannelConfigPage($page: Int, $pageSize: Int, $search: PayChannelConfigSearchInput) {
    getPayChannelConfigPage(page: $page, pageSize: $pageSize, search: $search) {
      items {
        id
        isAkulakuApproved
        isCreditCardApproved
        config
        createdAt
        updatedAt
        userId
        isAdminFeeBorneByCustomer
        isChannelFeeBorneByCustomer
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_PAY_CHANNEL_CONFIG_CREATE = gql`
  mutation createPayChannelConfigProxy($input: PayChannelConfigInput!) {
      createPayChannelConfigProxy(input: $input) {
      config
      createdAt
      updatedAt
      userId
      isAdminFeeBorneByCustomer
      isChannelFeeBorneByCustomer
    }
  }
`;

export const GQL_PAY_CHANNEL_CONFIG_UPDATE = gql`
  mutation updatePayChannelConfigProxy($input: PayChannelConfigInput!) {
    updatePayChannelConfigProxy(input: $input) {
      config
      createdAt
      updatedAt
      userId
      isAdminFeeBorneByCustomer
      isChannelFeeBorneByCustomer
    }
  }
`;
