/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col, Spinner } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { GQL_SUBSCRIPTION_GET_PROXY } from '../../gqls/subscriptions';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionDetails } from '../../actions/subscriptions';
import _ from 'lodash';
import { updatePaginatedData } from '../../utils';

/* eslint-disable */

let updatedAt = null;
const RecurringPaymentDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const updateSubscriptions = useSelector(state => state.updateSubscriptions);

  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = {
    search: {
      id: [{ operator: 'eq', value: id }]
    }
  };
  const { loading, error, data, fetchMore } = useQuery(GQL_SUBSCRIPTION_GET_PROXY, { variables: { id } });

  const sub = _.get(data, 'getSubscriptionDetail');
  if (sub && sub.updatedAt !== updatedAt) {
    dispatch(subscriptionDetails(sub));
    updatedAt = sub.updatedAt;
  }

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getSubscriptionDetail', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updateSubscriptions]);

  if (error) return `Error! ${error.message}`;

  if (loading) {
    return <Spinner></Spinner>;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/payment-plans': 'Paket Berlangganan'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{sub.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default RecurringPaymentDetail;
