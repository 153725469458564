import {
    FILTER_WEBHOOK_HISTORY_BY_Q,
    UPDATE_WEBHOOK_HISTORY,
    FILTER_WEBHOOK_HISTORY_BY_STATUS,
    WEBHOOK_HISTORY_DETAILS
} from '../actions/webhook-history';
  

export const filterWebhookHistoryByQReducer = (state = '', action) => {
    switch (action.type) {
        case FILTER_WEBHOOK_HISTORY_BY_Q:
        return action.payload;
    }
    return state;
};

export const updateWebhookHistoryReducer = (state = '', action) => {
    switch (action.type) {
        case UPDATE_WEBHOOK_HISTORY:
        return action.payload;
    }
    return state;
};

export const filterWebhookHistoryByStatusReducer = (state = '', action) => {
    switch (action.type) {
        case FILTER_WEBHOOK_HISTORY_BY_STATUS:
        return action.payload;
    }
    return state;
};

export const webhookHistoryDetailsReducer = (state = '', action) => {
    switch (action.type) {
        case WEBHOOK_HISTORY_DETAILS:
        return action.payload;
    }
    return state;
};
