export const FILTER_CUSTOMER_BY_Q = 'FILTER_CUSTOMER_BY_Q';
export const filterCustomerByQ = (q) => ({
  type: FILTER_CUSTOMER_BY_Q,
  payload: q,
});

export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';
export const updateCustomers = (q) => ({
  type: UPDATE_CUSTOMERS,
  payload: q,
});

export const FILTER_CUSTOMER_BY_STATUS = 'FILTER_CUSTOMER_BY_STATUS';
export const filterCustomerByStatus = (q) => ({
  type: FILTER_CUSTOMER_BY_STATUS,
  payload: q,
});

export const SELECT_CUSTOMERS = 'SELECT_CUSTOMERS';
export const selectCustomers = (ids) => ({
  type: SELECT_CUSTOMERS,
  payload: ids,
});
