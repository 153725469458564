import { gql } from '@apollo/client';

export const GQL_CREATE_LINK_TREE = gql`
  mutation insertLinkTree($input: LinkTreeInput) {
    insertLinkTree(input: $input) {
      background
      buttonStyles
      createdAt
      fontFamily
      id
      isCopyrightVisible
      links
      profile
      socialLinks
      socialLinksPosition
      themeId
      updatedAt
      userId
    }
  }
`;

export const GQL_UPDATE_LINK_TREE = gql`
  mutation editLinkTree($input: LinkTreeInput) {
    editLinkTree(input: $input) {
      background
      buttonStyles
      createdAt
      fontFamily
      id
      isCopyrightVisible
      links
      profile
      socialLinks
      socialLinksPosition
      themeId
      updatedAt
      userId
    }
  }
`;

export const GQL_PROXY_UPDATE_LINK_TREE = gql`
  mutation updateUserLinkTreeProxy($input: LinkTreeInput!) {
    updateUserLinkTreeProxy(input: $input) {
      background
      buttonStyles
      createdAt
      fontFamily
      id
      isCopyrightVisible
      links
      profile
      socialLinks
      socialLinksPosition
      themeId
      updatedAt
      userId
    }
  }
`;

export const GQL_PROXY_CREATE_LINK_TREE = gql`
  mutation createUserLinkTreeProxy($input: LinkTreeInput!) {
    createUserLinkTreeProxy(input: $input) {
      background
      buttonStyles
      createdAt
      fontFamily
      id
      isCopyrightVisible
      links
      profile
      socialLinks
      socialLinksPosition
      themeId
      updatedAt
      userId
    }
  }
`;
