import { gql } from '@apollo/client';

export const GQL_GET_REVIEW_PAGE = gql`
  query getReviewPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection = ASC
    $sortField: ReviewSortField = createdAt
    $search: ReviewSearchInput
  ) {
    getReviewPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
    ) {
      items {
        id
        createdAt
        customerId
        message
        paymentLinkId
        rating
        status
        updatedAt
        userId
        customer {
          name
          id
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_REVIEW_FILTERED_LIST = gql`
  query getReviewFilteredList($search: ReviewSearchInput, $searchAny: ReviewSearchInput, $limit: Int) {
    getReviewFilteredList(search: $search, searchAny: $searchAny, limit: $limit) {
      createdAt
      customerId
      message
      paymentLinkId
      rating
      status
      updatedAt
      userId
      id
      customer {
        email
        name
        mobile
      }
    }
  }
`;

export const GQL_UPDATE_REVIEW = gql`
  mutation editReview($input: ReviewInput) {
    editReview(input: $input) {
      id
      createdAt
      customerId
      message
      paymentLinkId
      rating
      status
      updatedAt
      userId
    }
  }
`;

export const GQL_BULK_UPDATE_REVIEW = gql`
  mutation editMultipleReview($input: [ReviewInput]) {
    editMultipleReview(input: $input) {
      id
      createdAt
      customerId
      message
      paymentLinkId
      rating
      status
      updatedAt
      userId
    }
  }
`;

export const EDIT_MULTIPLE_REVIEWS = gql`
  mutation editMultipleReviewsCustomer($input: [ReviewInput]!) {
    editMultipleReviewsCustomer(input: $input) {
      status
      errorMessage
    }
  }
`;
