import { gql } from '@apollo/client/core';

export const GET_LICENSE_CODE = gql`
  query getLicenseCodePage(
    $page: Int
    $pageSize: Int
    $search: LicenseCodeSearchInput
    $searchAny: LicenseCodeSearchInput
    $sortDirection: SortDirection
    $sortField: LicenseCodeSortField
  ) {
    getLicenseCodePage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        createdAt
        updatedAt
        customerId
        expiredAt
        licenseCode
        status
        useCount
        membershipCustomer {
          membershipTier {
            id
            name
          }
        }
        customer {
          email
        }
      }
      nextPage
    }
  }
`;

export const GET_TOTAL_LICENSE_CODE = gql`
  query getLicenseCodePage($search: LicenseCodeSearchInput) {
    getLicenseCodePage(search: $search) {
      total
    }
  }
`;

export const EDIT_LICENSE_CODE = gql`
  mutation editLicenseCode($input: LicenseCodeInput) {
    editLicenseCode(input: $input) {
      id
    }
  }
`;

export const EDIT_LICENSE_STATUS = gql`
  mutation editLicenseCodeStatus($input: LicenseCodeInput!) {
    editLicenseCodeStatus(input: $input) {
      id
    }
  }
`;
