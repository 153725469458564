import { gql } from '@apollo/client';

export const GQL_INSERT_REMINDER = gql`
  mutation insertReminder($input: ReminderInput) {
    insertReminder(input: $input) {
      paymentLinkId
      reminderUrl
      allowResend
      createdAt
      description
      id
      sendAt
      sendEmail
      sendTo
      title
      userId
    }
  }
`;

export const GQL_GET_REMINDER_PAGE = gql`
  query getReminderPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: ReminderSortField
    $search: ReminderSearchInput
  ) {
    getReminderPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
    ) {
      items {
        paymentLinkId
        reminderUrl
        allowResend
        createdAt
        description
        id
        sendAt
        sendEmail
        sendTo
        title
        userId
        isActive
      }
    }
  }
`;

export const GQL_EDIT_REMINDER = gql`
  mutation editReminder($input: ReminderInput) {
    editReminder(input: $input) {
      paymentLinkId
      reminderUrl
      allowResend
      createdAt
      description
      id
      sendAt
      sendEmail
      sendTo
      title
      userId
      isActive
    }
  }
`;

export const GQL_INSERT_DEFAULT_REMINDER = gql`
  mutation insertDefaultReminder($paymentLinkId: ID!) {
    insertDefaultReminder(paymentLinkId: $paymentLinkId) {
      status
      message
    }
  }
`;
