import { gql } from '@apollo/client';

export const GQL_INSERT_ASSIGNMENT = gql`
  mutation insertAssignment($input: AssignmentInput) {
    insertAssignment(input: $input) {
      id
      createdAt
      endAt
      isMandatory
      name
      paymentLinkId
      startAt
      task
      updatedAt
      userId
    }
  }
`;

export const GQL_EDIT_ASSIGNMENT = gql`
  mutation editAssignment($input: AssignmentInput) {
    editAssignment(input: $input) {
      id
      createdAt
      endAt
      isMandatory
      name
      paymentLinkId
      startAt
      task
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_ASSIGNMENT_FILTERED_LIST = gql`
  query getAssignmentFilteredList(
    $limit: Int
    $search: AssignmentSearchInput
    $searchAny: AssignmentSearchInput
    $sortDirection: SortDirection = DESC
    $sortField: AssignmentSortField = createdAt
  ) {
    getAssignmentFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      createdAt
      endAt
      id
      isMandatory
      name
      startAt
      paymentLinkId
      task
      updatedAt
      status
      files {
        createdAt
        updatedAt
        key
        value
        id
        file {
          createdAt
          filename
          mimetype
          size
          id
        }
      }
    }
  }
`;

export const GQL_GET_ASSIGNMENT_PAGE = gql`
  query getAssignmentPage(
    $page: Int = 10
    $pageSize: Int = 10
    $search: AssignmentSearchInput
    $searchAny: AssignmentSearchInput
    $sortDirection: SortDirection = DESC
    $sortField: AssignmentSortField = createdAt
  ) {
    getAssignmentPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      offset
      page
      prevPage
      total
      totalPage
      items {
        createdAt
        endAt
        id
        isMandatory
        name
        startAt
        paymentLinkId
        task
        updatedAt
        status
      }
    }
  }
`;

export const GQL_GET_SUBMISSION_FILTERED_LIST = gql`
  query getSubmissionFilteredList(
    $limit: Int
    $search: SubmissionSearchInput
    $searchAny: SubmissionSearchInput
    $sortDirection: SortDirection = DESC
    $sortField: SubmissionSortField = createdAt
  ) {
    getSubmissionFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      assignmentId
      createdAt
      customerId
      customer {
        id
        email
        name
        mobile
      }
      feedback
      grade
      id
      paymentLinkId
      solution
      status
      title
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_SUBMISSION_PAGE = gql`
  query getSubmissionPage(
    $page: Int = 10
    $pageSize: Int = 10
    $search: SubmissionSearchInput
    $searchAny: SubmissionSearchInput
    $sortDirection: SortDirection = DESC
    $sortField: SubmissionSortField = createdAt
  ) {
    getSubmissionPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      items {
        assignmentId
        createdAt
        customerId
        customer {
          id
          email
          name
          mobile
        }
        feedback
        grade
        id
        paymentLinkId
        solution
        title
        updatedAt
        userId
        assignment {
          name
        }
      }
    }
  }
`;

export const GQL_GET_SUBMISSION = gql`
  query getSubmission($id: ID!) {
    getSubmission(id: $id) {
      assignmentId
      createdAt
      customerId
      feedback
      grade
      id
      paymentLinkId
      solution
      title
      updatedAt
      userId
      customer {
        name
      }
      assignment {
        createdAt
        endAt
        id
        isMandatory
        name
        paymentLinkId
        startAt
        status
        task
        updatedAt
        userId
      }
      files {
        createdAt
        updatedAt
        key
        value
        id
        file {
          createdAt
          filename
          mimetype
          size
          id
        }
      }
    }
  }
`;

export const GQL_EDIT_SUBMISSION = gql`
  mutation editSubmission($input: SubmissionInput) {
    editSubmission(input: $input) {
      assignmentId
      createdAt
      customerId
      feedback
      grade
      paymentLinkId
      solution
      title
      updatedAt
      userId
    }
  }
`;
