import { gql } from '@apollo/client';

export const GET_PAYMENT_LINK = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        status
        amount
        description
        limit
        expiredAt
        startAt
        notes
        redirectUrl
        createdAt
        link
        type
        reminderWellcomeId
        isDownloadable
        paymentType
        content {
          file {
            id
          }
        }
        saasProductInfo {
          activationLimit
          licenseType
          activePeriod
        }
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        multipleImage {
          url
          updatedAt
        }
        checkoutSettings {
          id
          buttonName
          buttonNote
          couponOption
          termOption
          termUrl
        }
        conversionSettings {
          id
          trackingId
          type
          status
        }
      }
    }
  }
`;
