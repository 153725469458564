import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Row, Col, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GQL_BROADCAST_WITH_ANALYTICS } from '../../gqls';
import { updatePaginatedData } from '../../utils';
import { broadcastDetails } from '../../actions/broadcast';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const BroadcastDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const updateBroadcast = useSelector(state => state.updateBroadcast);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(GQL_BROADCAST_WITH_ANALYTICS, { variables: { id } });

  const broadcast = _.get(data, 'getBroadcastWithAnalytics');
  if (broadcast) dispatch(broadcastDetails(broadcast));

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getBroadcastWithAnalytics', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updateBroadcast]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser?.name ?? ' ',
          '/broadcasts': 'Broadcast'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1 className="mb-0">{broadcast?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default BroadcastDetail;
