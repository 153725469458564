/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';

import { GQL_DISCOUNT_BY_ID } from '../../gqls/discount';
import { updatePaginatedData } from '../../utils';
import { discountDetails } from '../../actions/discount';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const DiscountCouponDetail = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const updatedDiscount = useSelector(state => state.updateDiscount);

  const { id } = useParams();
  const { loading, error, data, fetchMore } = useQuery(GQL_DISCOUNT_BY_ID, {
    variables: { id }
  });

  const discount = _.get(data, 'getDiscount');
  if (discount) dispatch(discountDetails(discount));

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getDiscount', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updatedDiscount]);

  if (error) return `Error! ${error.message}`;
  if (loading) return <Spinner />;

  if (!discount?.id) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{ '/': currentUser?.name || ' ', '/diskon': 'Diskon dan Kupon' }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{discount?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default DiscountCouponDetail;
