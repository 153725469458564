export const actions = [
  {
    section: 'Cari..',
    id: 'searchProuctRootid',
    name: 'Cari Produk...',
    // shortcut: ["c", "p"],
    keywords: 'cari produk'
    // perform: () => (window.location.pathname = '/refer-a-friend')
  },
  {
    id: 'searchTransactionRoot',
    name: 'Cari Transaksi by ID... ',
    keywords: 'cari transaksi',
    section: 'Cari..'
    // perform: () => (window.location.pathname = '/refer-a-friend')
  },
  {
    id: 'berandaKbar',
    name: 'Beranda',
    shortcut: ['b'],
    keywords: 'beranda',
    icon: 'home',
    section: 'navigation',
    perform: () => (window.location.pathname = '/')
  },
  {
    id: 'pelangganKbar',
    name: 'Pelanggan',
    shortcut: ['p'],
    keywords: 'pelanggan',
    icon: 'users',
    section: 'navigation',

    perform: () => (window.location.pathname = '/customers')
  },
  {
    id: 'transaksiKbar',
    name: 'Transaksi',
    shortcut: ['t'],
    keywords: 'transaksi',
    icon: 'layers',
    section: 'navigation',

    perform: () => (window.location.pathname = '/payments')
  },
  {
    id: 'berlanggananKbar',
    name: 'Berlangganan',
    shortcut: ['bg'],
    keywords: 'berlangganan',
    icon: 'repeat',
    section: 'navigation',

    perform: () => (window.location.pathname = '/recurring-payments')
  },
  {
    id: 'ordersKbar',
    name: 'Order',
    shortcut: ['o'],
    keywords: 'orders',
    icon: 'shopping-bag',
    section: 'navigation',

    perform: () => (window.location.pathname = '/orders')
  },
  {
    section: 'produk',
    id: 'plKbar',
    name: 'Link Pembayaran',
    shortcut: ['l'],
    keywords: 'link pembayaran',
    icon: 'link',
    perform: () => (window.location.pathname = '/payment-link')
  },
  {
    section: 'produk',
    id: 'physicalProductKbar',
    name: 'Physical Product',
    icon: 'tag',
    shortcut: ['p', 'p'],
    keywords: 'physical product',
    perform: () => (window.location.pathname = '/physical-products')
  },
  {
    section: 'produk',
    id: 'productDigitalKbar',
    name: 'Produk Digital',
    icon: 'download-cloud',
    shortcut: ['p', 'd'],
    keywords: 'produk digital',
    perform: () => (window.location.pathname = '/digital-products')
  },
  {
    section: 'produk',
    id: 'kelasOnlineKbar',
    name: 'Kelas Online (OD)',
    icon: 'book-open',
    shortcut: ['k', 'o'],
    keywords: 'kelas online',
    perform: () => (window.location.pathname = '/online-courses')
  },
  {
    section: 'produk',
    id: 'cohorBasedKbar',
    name: 'Kelas Cohort-Based',
    icon: 'grid',
    shortcut: ['c', 'o'],
    keywords: 'Cohort-Based',
    perform: () => (window.location.pathname = '/cohort-courses')
  },
  {
    section: 'produk',
    id: 'webinarKbar',
    name: 'Webinar',
    icon: 'video',
    shortcut: ['w'],
    keywords: 'webinar',
    perform: () => (window.location.pathname = '/webinars')
  },
  {
    section: 'produk',
    id: 'eventsKbar',
    name: 'Event & Acara',
    icon: 'aperture',
    shortcut: ['e', 'a'],
    keywords: 'event-acara',
    perform: () => (window.location.pathname = '/events')
  },
  {
    section: 'produk',
    id: 'coachingKbar',
    name: 'Coaching / Mentoring',
    icon: 'watch',
    shortcut: ['c', 'm'],
    keywords: 'coaching mentoring',
    perform: () => (window.location.pathname = '/coachings')
  },
  {
    section: 'produk',
    id: 'fundraisingKbar',
    name: 'Penggalangan Dana',
    icon: 'heart',
    shortcut: ['p', 'd'],
    keywords: 'penggalangan dana',
    perform: () => (window.location.pathname = '/fundraisings')
  },
  {
    section: 'produk',
    id: 'paketKbar',
    name: 'Paket Berlangganan',
    icon: 'calendar',
    shortcut: ['p', 'b'],
    keywords: 'paket berlangganan',
    perform: () => (window.location.pathname = '/payment-plans')
  },
  {
    section: 'produk',
    id: 'ebooksKbar',
    name: 'E-books',
    icon: 'eye',
    shortcut: ['e'],
    keywords: 'ebooks',
    perform: () => (window.location.pathname = '/ebooks')
  },
  {
    section: 'produk',
    id: 'podcastKbar',
    name: 'Podcast',
    icon: 'mic',
    shortcut: ['p'],
    keywords: 'podcast',
    perform: () => (window.location.pathname = '/podcasts')
  },
  {
    section: 'produk',
    id: 'audiobooksKbar',
    name: 'Audiobooks',
    icon: 'headphones',
    shortcut: ['a'],
    keywords: 'audiobooks',
    perform: () => (window.location.pathname = '/audiobooks')
  },
  {
    section: 'produk',
    id: 'tulisanKbar',
    name: 'Tulisan',
    icon: 'pen-tool',
    shortcut: ['t', 'l'],
    keywords: 'tulisan',
    perform: () => (window.location.pathname = '/writings')
  },
  {
    section: 'produk',
    id: 'supportKbar',
    name: 'Support',
    icon: 'smile',
    shortcut: ['s', 'p'],
    keywords: 'support',
    perform: () => (window.location.pathname = '/support-page')
  },
  {
    section: 'produk',
    id: 'membershipKbar',
    name: 'Membership & SaaS',
    icon: 'award',
    shortcut: ['m', 'm'],
    keywords: 'membership',
    perform: () => (window.location.pathname = '/memberships')
  },
  {
    section: 'produk',
    id: 'lisensiSoftwareKbar',
    name: 'Lisensi Software',
    icon: 'key',
    shortcut: ['ls', 'ls'],
    keywords: 'lisensi software',
    perform: () => (window.location.pathname = '/license-codes')
  },
  // FINANSIAL SESI
  {
    section: 'finansial',
    id: 'debitCard',
    name: 'Kartu Debit',
    shortcut: ['k', 'd'],
    icon: 'credit-card',
    keywords: 'debit',
    perform: () => (window.location.pathname = '/debit-card')
  },
  {
    section: 'finansial',
    id: 'pendanaanKbar',
    name: 'Capital / Pendanaan',
    icon: 'briefcase',
    shortcut: ['c', 'p'],
    keywords: 'pendanaan',
    perform: () => (window.location.pathname = '/capital')
  },
  {
    section: 'marketing',
    id: 'mStorefront',
    name: 'Storefront',
    shortcut: ['sf'],
    icon: 'layout',
    keywords: 'storefront',
    perform: () => (window.location.pathname = '/storefront')
  },
  {
    section: 'tags',
    id: 'mTags',
    name: 'Tags',
    shortcut: ['tg'],
    icon: 'filter',
    keywords: 'tags',
    perform: () => (window.location.pathname = '/tags')
  },
  {
    section: 'marketing',
    id: 'mBroadcast',
    name: 'BroadCast',
    icon: 'rss',
    shortcut: ['b', 'c'],
    keywords: 'broadcast',
    perform: () => (window.location.pathname = '/broadcasts')
  },
  {
    section: 'marketing',
    id: 'mAutoResponders',
    name: 'Auto Responder',
    icon: 'target',
    shortcut: ['a', 'r'],
    keywords: 'auto responder',
    perform: () => (window.location.pathname = '/autoresponders')
  },
  {
    section: 'marketing',
    id: 'mLinkBio',
    name: 'Link Bio & Katalog',
    icon: 'bookmark',
    shortcut: ['l', 'b'],
    keywords: 'link bio katalog',
    perform: () => (window.location.pathname = '/homepage')
  },
  {
    section: 'marketing',
    id: 'mLandingPage',
    name: 'Landing Page',
    icon: 'airplay',
    shortcut: ['l', 'p'],
    keywords: 'landing Page',
    perform: () => (window.location.pathname = '/landing-pages')
  },
  {
    section: 'marketing',
    id: 'mSimpleLandingPage',
    name: 'Simple Landing Page',
    icon: 'airplay',
    shortcut: ['s', 'l', 'p'],
    keywords: 'simple landing Page',
    perform: () => (window.location.pathname = '/simple-landing-pages')
  },
  {
    section: 'marketing',
    id: 'mDisc',
    name: 'Diskon dan Kupon',
    icon: 'percent',

    shortcut: ['d', 'k'],
    keywords: 'Diskon dan Kupon',
    perform: () => (window.location.pathname = '/diskon')
  },
  {
    section: 'marketing',
    id: 'mBundling',
    name: 'Bundling',
    icon: 'box',
    shortcut: ['bl'],
    keywords: 'bundling',
    perform: () => (window.location.pathname = '/bundling')
  },
  {
    section: 'marketing',
    id: 'mCrossSell',
    name: 'Cross-sell',
    icon: 'arrow-up-circle',
    shortcut: ['c', 's'],
    keywords: 'cross sell',
    perform: () => (window.location.pathname = '/crosssells')
  },
  {
    section: 'akun saya',
    id: 'myAnalytics',
    name: 'Analytics',
    icon: 'bar-chart',
    shortcut: ['a', 'd'],
    keywords: 'analytics',
    perform: () => (window.location.pathname = '/detail-analytics')
  },
  {
    section: 'akun saya',
    id: 'myQris',
    name: 'My Qris',
    icon: 'maximize',
    shortcut: ['m', 'q'],
    keywords: 'qris',
    perform: () => (window.location.pathname = '/my-qris')
  },
  {
    section: 'akun saya',
    id: 'mySetting',
    name: 'Pengaturan',
    icon: 'settings',
    shortcut: ['s'],
    keywords: 'pengaturan',
    perform: () => (window.location.pathname = '/settings')
  },
  {
    section: 'akun saya',
    id: 'myBilling',
    name: 'Upgrade ke Mayar Pro',
    icon: 'zap',
    shortcut: ['u', 'pro'],
    keywords: 'upgrade mayar pro',
    perform: () => (window.location.pathname = '/billing')
  },
  {
    section: 'akun saya',
    id: 'myReferFriend',
    name: 'Refer a Friend',
    icon: 'gift',
    shortcut: ['r', 'f'],
    keywords: 'refer a friend',
    perform: () => (window.location.pathname = '/refer-a-friend')
  }
];
