/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';

const Invoice = () => {
  return (
    <div className="rui-main">
      <div className="rui-sign align-items-center justify-content-center">
        <AsyncComponent component={() => import('./content')} />
      </div>
    </div>
  );
};

export default Invoice;
