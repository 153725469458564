import { parseJSON } from './parser';

export const getSubAccAccess = (currentUser, access = '') => {
  if (!currentUser || JSON.stringify(currentUser) === '{}') return false;

  const subAccountAccess = parseJSON(currentUser.path, [])
    .filter(item => item.checked)
    .map(item => item.name)
    .includes(access);

  return currentUser.role === 'admin' ? subAccountAccess : true;
};
