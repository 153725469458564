import { gql } from '@apollo/client';

export const GQL_WITHDRAWAL_PAGE = gql`
  query getWithdrawalPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: WithdrawalSortField
    $searchAny: WithdrawalSearchInput
    $search: WithdrawalSearchInput
  ) {
    getWithdrawalPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        amount
        activeBalance
        pendingBalance
        createdAt
        status
        updatedAt
        userId
      }
      nextPage
    }
  }
`;

export const GQL_WITHDRAWAL_CREATE = gql`
  mutation insertWithdrawal($input: WithdrawalInput) {
    insertWithdrawal(input: $input) {
      id
      amount
      createdAt
      status
      updatedAt
      userId
    }
  }
`;
