export const CustomStyles = {
  control: (css, state) => {
    return {
      ...css,
      backgroundColor: state.isDisabled ? 'rgba(206, 217, 224, 0.5)' : '#fbfcfc',
      borderColor: state.isFocused ? 'rgba(0, 123, 255, 0.6)' : '#eaecf0',
      '&:hover': {
        borderColor: state.isFocused ? 'rgba(0, 123, 255, 0.6)' : '#eaecf0'
      },
      ...(state?.selectProps?.minHeight && { minHeight: state.selectProps.minHeight }),
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.2)' : ''
    };
  },
  option: (css, state) => {
    let bgc = '';

    if (state.isSelected) {
      bgc = 'rgba(0, 123, 255)';
    } else if (state.isFocused) {
      bgc = 'rgba(0, 123, 255, 0.2)';
    }

    return {
      ...css,
      ...(state.data.color && { color: state.data.color }),
      backgroundColor: bgc
    };
  },
  multiValue: (css, state) => {
    return state.data.isFixed ? { ...css, backgroundColor: 'gray' } : css;
  },
  multiValueLabel: (css, state) => {
    const base = {
      ...css,
      color: '#545b61',
      backgroundColor: '#eeeeef'
    };
    return state.data.isFixed ? { ...base, fontWeight: 'bold', paddingRight: 6 } : base;
  },
  multiValueRemove: (css, state) => {
    return state.data.isFixed ? { ...css, display: 'none' } : css;
  },
  singleValue: css => {
    return {
      ...css,
      color: '#495057'
    };
  },
  valueContainer: css => {
    return {
      ...css,
      paddingLeft: '1.15rem'
    };
  },
  placeholder: css => {
    return {
      ...css,
      color: '#A6A8AA'
    };
  }
};

export const CustomStylesInvalid = {
  ...CustomStyles,
  control: (css, state) => {
    let borderColor = '',
      boxShadow = '';

    if (!state.hasValue) {
      borderColor = 'rgba(220, 53, 69, 0.25)';
    } else if (state.isFocused) {
      borderColor = 'rgba(0, 123, 255, 0.6)';
    } else {
      borderColor = '#eaecf0';
    }

    if (state.isFocused && !state.hasValue) {
      boxShadow = '0 0 0 0.2rem rgba(220, 53, 69, 0.16)';
    } else if (state.isFocused) {
      boxShadow = '0 0 0 0.2rem rgba(0, 123, 255, 0.2)';
    }

    return {
      ...css,
      backgroundColor: '#FEF9FA',
      borderColor: borderColor,
      '&:hover': {
        borderColor: borderColor
      },
      ...(state?.selectProps?.minHeight && { minHeight: state.selectProps.minHeight }),
      boxShadow: boxShadow
    };
  },
  placeholder: css => {
    return {
      ...css,
      color: '#A6A8AA'
    };
  }
};
