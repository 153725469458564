import {
  FILTER_CUSTOMER_BY_Q,
  UPDATE_CUSTOMERS,
  FILTER_CUSTOMER_BY_STATUS,
  SELECT_CUSTOMERS,
} from '../actions/customers';

export const customerFilterQReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_CUSTOMER_BY_Q:
      return action.payload;
  }
  return state;
};

export const customerUpdateReducers = (state = '', action) => {
  switch (action.type) {
    case UPDATE_CUSTOMERS:
      return action.payload;
  }
  return state;
};

export const customerFilterStatusReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_CUSTOMER_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const selectCustomersReducers = (state = '', action) => {
  switch (action.type) {
    case SELECT_CUSTOMERS:
      return action.payload;
  }
  return state;
};
