import {
  INSERT_COHORT_SESSIONS,
  ADD_COHORT_SESSION,
  EDIT_COHORT_SESSION,
  REMOVE_COHORT_SESSION,
  COHORT_GRADE_UPDATED
} from '../actions/cohort-based';

export const cohortSessionsReducer = (state = [], action) => {
  switch (action.type) {
    case INSERT_COHORT_SESSIONS:
      return action.payload;
    case ADD_COHORT_SESSION:
      return [...state, action.payload];
    case EDIT_COHORT_SESSION:
      return state.map(session => (session.id === action.payload.id ? action.payload : session));
    case REMOVE_COHORT_SESSION:
      return state.filter(session => session.id !== action.payload.id);
  }
  return state;
};

export const cohortGradeUpdatedReducer = (state = null, action) => {
  switch (action.type) {
    case COHORT_GRADE_UPDATED:
      return action.payload;
  }
  return state;
};
