import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';
import {
  FormGroup,
  Label,
  FormFeedback
} from 'reactstrap';
import { Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import { CustomStyles, CustomStylesInvalid } from "../custom-styles/react-select-styles";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export default function SearchBox(props) {
  const { data, isLocationEmpty, selectPosition, setSelectPosition, errors, control, fieldName } = props;
  const [isSearchingError, setIsSearchingError] = useState(false);
  
  const mapOptionsToValues = options =>
    options.map(option => ({
      value: JSON.stringify(option),
      label: option.display_name
    }));

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword) {
      return callback([]);
    }

    const params = {
      q: keyword,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    /* eslint-disable-next-line */
    fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsSearchingError(false);
        try {
          callback(mapOptionsToValues(JSON.parse(result)))
        } catch (e) {
         return callback([]);
        }
      })
      .catch(() => {
        setIsSearchingError(true)
      });
  };
  const loadSuggestions = debounce(_loadSuggestions, 500);

  useEffect(() => {
    if (isLocationEmpty) {
      setSelectPosition(null)
    }
  }, [isLocationEmpty])
  
  return (
    <>
      <FormGroup>
        <Label for={fieldName}>Lokasi dalam Map (opsional)</Label>
        <Controller
          defaultValue={selectPosition && !data?.isOnlineEvent ? (
            {
              value: JSON.stringify(selectPosition),
              label: selectPosition?.display_name
            }
          ) : (
            null
          )}
          render={({ onChange, value }) => (
            <AsyncSelect
              className={Boolean(errors.eventAddress) ? 'is-invalid' : 'street-maps-input'}
              loadOptions={loadSuggestions}
              defaultOptions
              escapeClearsValue={true}
              openMenuOnClick={false}
              isSearchable
              isClearable={true}
              placeholder="Cari lokasi..."
              cacheOptions={false}
              styles={Boolean(errors.eventAddress) ? CustomStylesInvalid : CustomStyles}
              value={value}
              backspaceRemoves={true} 
              onChange={(e) => {
                if (e?.value) {
                  setSelectPosition(JSON.parse(e.value))
                } else {
                  setSelectPosition(null);
                }
                onChange(e);
              }}
            />
          )}
          name={fieldName}
          control={control}
        />
        <FormFeedback>{isSearchingError && 'Terjadi kesalahan saat mengambil data lokasi. Silahkan coba lagi atau hubungi customer service.'}</FormFeedback>
        <FormFeedback>{errors.eventAddress?.message}</FormFeedback>
      </FormGroup>
    </>
  );
}
