import { gql } from '@apollo/client';

export const GQL_DISCOUNT_PAGE = gql`
  query getDiscountPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: DiscountSortField
    $searchAny: DiscountSearchInput
    $search: DiscountSearchInput
  ) {
    getDiscountPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        coupon {
          code
          expiredAt
          isActive
          limit
          type
          userId
          id
        }
        createdAt
        expiredAt
        couponsId
        discountType
        eligibleCustomerType
        id
        isLifeTime
        name
        value
        status
        totalCoupons
      }
      nextPage
    }
  }
`;

export const GQL_DISCOUNT_CREATE = gql`
  mutation insertDiscount($input: DiscountInput) {
    insertDiscount(input: $input) {
      couponsId
      discountType
      eligibleCustomerType
      isLifeTime
      name
      createdAt
      expiredAt
      value
      id
    }
  }
`;

export const GQL_DISCOUNT_UPDATE = gql`
  mutation editDiscount($input: DiscountInput) {
    editDiscount(input: $input) {
      couponsId
      discountType
      eligibleCustomerType
      isLifeTime
      name
      updatedAt
      expiredAt
      value
      userId
    }
  }
`;

export const GQL_DISCOUNT_WITH_COUPONS_CREATE = gql`
  mutation insertDiscountWithCoupons(
    $discount: DiscountInput!
    $reusableCoupons: CouponsInput
    $onetimeCoupons: [CouponsInput]
    $products: [ProductDiscountInput]
  ) {
    insertDiscountWithCoupons(
      discount: $discount
      reusableCoupons: $reusableCoupons
      onetimeCoupons: $onetimeCoupons
      products: $products
    ) {
      couponsId
      discountType
      eligibleCustomerType
      isLifeTime
      name
      createdAt
      expiredAt
      value
      userId
      id
    }
  }
`;

export const IMPORT_DISCOUNT_WITH_COUPONS = gql`
  mutation importDiscountWithCoupons(
    $input: String
    $discount: DiscountInput
    $reusableCoupons: CouponsInput
    $onetimeCoupons: [CouponsInput]
    $products: [ProductDiscountInput]
  ) {
    importDiscountWithCoupons(
      input: $input
      discount: $discount
      reusableCoupons: $reusableCoupons
      onetimeCoupons: $onetimeCoupons
      products: $products
    ) {
      lambdaStatus
      couponsId
      discountType
      eligibleCustomerType
      isLifeTime
      name
      createdAt
      expiredAt
      value
      userId
      id
    }
  }
`;

export const GQL_DISCOUNT_WITH_COUPONS_UPDATE = gql`
  mutation editDiscountWithCoupons(
    $discount: DiscountInput!
    $coupons: CouponsInput!
    $products: [ProductDiscountInput]
    $deletedProducts: [ID]
  ) {
    editDiscountWithCoupons(
      discount: $discount
      coupons: $coupons
      products: $products
      deletedProducts: $deletedProducts
    ) {
      couponsId
      discountType
      eligibleCustomerType
      isLifeTime
      name
      updatedAt
      expiredAt
      value
      userId
      id
    }
  }
`;

export const GQL_PRODUCT_ALL_GET = gql`
  query myQuery($search: SubscriptionSearchInput, $searchPaymentLink: PaymentLinkSearchInput) {
    getPaymentLinkFilteredList(search: $searchPaymentLink) {
      id
      name
      status
      userId
    }
    getSubscriptionFilteredList(search: $search) {
      id
      name
      status
      userId
    }
  }
`;

export const GQL_ACTIVE_SUBSCRIPTION_GET = gql`
  query myQuery($search: SubscriptionSearchInput) {
    getSubscriptionFilteredList(search: $search) {
      id
      name
      status
      userId
    }
  }
`;

export const GQL_DISCOUNT_DELETE = gql`
  mutation deleteDiscount($id: String) {
    deleteDiscount(id: $id)
  }
`;

export const GQL_DISCOUNT_SELECT = gql`
  query getDiscountPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: DiscountSortField
    $searchAny: DiscountSearchInput
    $search: DiscountSearchInput
  ) {
    getDiscountPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        couponsId
        createdAt
        expiredAt
        discountType
        eligibleCustomerType
        id
        isLifeTime
        name
        value
        userId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_DISCOUNT_SELECT_LIST = gql`
  query getDiscountFilteredList(
    $sortDirection: SortDirection
    $sortField: DiscountSortField
    $searchAny: DiscountSearchInput
    $search: DiscountSearchInput
    $limit: Int
  ) {
    getDiscountFilteredList(
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
      limit: $limit
    ) {
      id
      value
      name
      discountType
      totalCoupons
    }
  }
`;

export const GQL_DISCOUNT_BY_ID = gql`
  query getDiscount($id: ID) {
    getDiscount(id: $id) {
      couponsId
      createdAt
      expiredAt
      discountType
      eligibleCustomerType
      discountProductType
      minimumPurchase
      id
      isLifeTime
      name
      updatedAt
      value
      userId
      status
      totalCoupons
      coupon {
        code
        createdAt
        expiredAt
        id
        isActive
        limit
        type
        updatedAt
      }
      productDiscount {
        id
        paymentLinkId
        productType
        subscriptionId
        paymentLink {
          id
          name
          type
        }
        subscription {
          name
        }
      }
    }
  }
`;
