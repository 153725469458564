/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import PageWrap from '../../components/page-wrap';
import PageContent from '../../components/page-content';

/**
 * Component
 */
class Suggestions extends Component {
  render() {
    return (
      <PageWrap>
        <PageContent>
          <iframe
            src="https://jrv.typeform.com/to/XuOK0eks"
            title="kotak saran mayar"
            width="100%"
            height="640"
            frameBorder="0"
          ></iframe>
        </PageContent>
      </PageWrap>
    );
  }
}

export default Suggestions;
