import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import Icon from '../../icon';
import { useDispatch, useSelector } from 'react-redux';
import { changeAccountMode } from '../../../actions/account-mode';
import { rootUrl } from '../../../utils';
import ModalActivateMerchantMode from '../../modals/modal-activate-merchant-mode';

const AccountModeButton = ({ deferredPrompt, handleInstallPrompt, buttonCopy, handleCopy }) => {
  const dispatch = useDispatch();
  const accountMode = useSelector(state => state.accountMode);
  const currentUser = useSelector(state => state.currentUser);

  const [activateModalOpen, setActivateModalOpen] = useState(false);

  useEffect(() => {
    if (currentUser.isProfileCompleted && !currentUser.account.isPlanStopped && JSON.stringify(accountMode) === '{}') {
      const getAccountModeLocalStorage = window.localStorage.getItem(`account-mode:${currentUser.id}`);

      dispatch(changeAccountMode(getAccountModeLocalStorage));
    }
  }, [currentUser]);

  const handleChangeMode = (force = null) => {
    if (force === 'merchant') {
      dispatch(changeAccountMode('merchant'));
      window.localStorage.setItem(`account-mode:${currentUser.id}`, 'merchant');
    } else if (accountMode === 'affiliator') {
      if (currentUser.isMerchantModeDisabled) {

        setActivateModalOpen(!activateModalOpen);
      } else {
        dispatch(changeAccountMode('merchant'));
        window.localStorage.setItem(`account-mode:${currentUser.id}`, 'merchant');
      }
    } else {
      dispatch(changeAccountMode('affiliator'));

      window.localStorage.setItem(`account-mode:${currentUser.id}`, 'affiliator');
    }
  };

  const handleButtonWording = () => {
    if (accountMode === 'affiliator') {
      return `${currentUser.isMerchantModeDisabled ? 'Aktifkan' : 'Ganti ke'}  Mode Merchant`;
    }
    return 'Ganti ke Mode Affiliate';
  };

  if (currentUser.isProfileCompleted && currentUser.isAffiliate && !currentUser.account.isPlanStopped) {
    return (
      <div className="rui-yaybar-icons">
        <ButtonGroup className="mt-10 mb-6">
          <Button color="dark" outline className="mb-10 text-center dark-outline-border" onClick={handleChangeMode}>
            {handleButtonWording()}
          </Button>
          {activateModalOpen && (
            <ModalActivateMerchantMode
              isOpen={activateModalOpen}
              handleToggle={() => setActivateModalOpen(!activateModalOpen)}
              handleChangeMode={handleChangeMode}
            />
          )}
        </ButtonGroup>
        {deferredPrompt ? (
          <Button outline color="primary" className="mb-10 text-center" onClick={handleInstallPrompt}>
            <Icon name="download" /> &nbsp; Install Mayar as an app
          </Button>
        ) : null}
      </div>
    );
  } else if (currentUser.isProfileCompleted && !currentUser.account.isPlanStopped) {
    return (
      <div className="rui-yaybar-icons">
        <Input className="mt-10 mb-6" type="text" value={rootUrl(currentUser.paymeLink)} readOnly id="profilelink" />
        <ButtonGroup>
          <Button color="dark" className="mb-10 text-center" onClick={handleCopy}>
            {buttonCopy}
          </Button>{' '}
          <Button
            color="dark"
            href={rootUrl(currentUser.paymeLink)}
            target="_blank"
            className="mb-10 text-center"
            id="embed-modal-link"
          >
            <Icon name="external-link" />
          </Button>
        </ButtonGroup>
        {deferredPrompt ? (
          <Button outline color="primary" className="mb-10 text-center" onClick={handleInstallPrompt}>
            <Icon name="download" /> &nbsp; Install Mayar as an app
          </Button>
        ) : null}
      </div>
    );
  }

  return null;
};

export default AccountModeButton;
