import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const MayarAlert = ({ message, isError, isSuccess = false, ...props }) => {
  // TODO: write better message based on the graphql response
  return (
    <>
      {isError && (
        <Alert color="danger" {...props}>
          {message || 'Error! Silahkan coba lagi atau hubungi CS'}
        </Alert>
      )}
      {isSuccess && (
        <Alert color="success" {...props}>
          {message || 'Berhasil memperbarui data'}
        </Alert>
      )}
    </>
  );
};

MayarAlert.propTypes = {
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  message: PropTypes.string
};

export default MayarAlert;
