import { FILTER_EMPLOYEE_BY_Q, UPDATE_EMPLOYEE, FILTER_EMPLOYEE_BY_STATUS, SELECT_EMPLOYEE } from '../actions/employee';

export const employeeFilterQReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_EMPLOYEE_BY_Q:
      return action.payload;
  }
  return state;
};

export const employeeUpdateReducers = (state = '', action) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE:
      return action.payload;
  }
  return state;
};

export const employeeFilterStatusReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_EMPLOYEE_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const selectEmployeeReducers = (state = '', action) => {
  switch (action.type) {
    case SELECT_EMPLOYEE:
      return action.payload;
  }
  return state;
};
