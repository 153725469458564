import { gql } from '@apollo/client';

export const GQL_SUBSCRIPTIONCUSTOMER_GET = gql`
  query getSubscriptionCustomerPage($search: SubscriptionCustomerSearchInput) {
    getSubscriptionCustomerPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        createdAt
        customerId
        customer {
          name
          email
        }
        status
        nextPayment
        subscriptionId
        subscription {
          amount
          billingCycle
          billingCycleAt
          billingCycleCustomPeriod
          billingCycleCustomPeriodTime
          createdAt
          initPayment
          initPaymentAmount
          limit
          link
          name
          description
          paymentAtStart
          redirectUrl
          updatedAt
        }
        updatedAt
      }
    }
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_PAGE = gql`
  query getSubscriptionCustomerPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: SubscriptionCustomerSortField
    $searchAny: SubscriptionCustomerSearchInput
    $search: SubscriptionCustomerSearchInput
  ) {
    getSubscriptionCustomerPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        createdAt
        customerId
        customer {
          name
          email
          mobile
        }
        status
        subscriptionId
        subscription {
          name
          amount
          billingCycle
          billingCycleAt
          billingCycleCustomPeriod
          billingCycleCustomPeriodTime
        }
        updatedAt
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_PROXY_SUBSCRIPTIONCUSTOMER_PAGE = gql`
  query getSubscriptionPageProxy($page: Int, $pageSize: Int, $id: ID!, $sortField: String, $sortDirection: String) {
    getSubscriptionPageProxy(
      page: $page
      pageSize: $pageSize
      id: $id
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        customerId
        customer {
          name
          email
          mobile
        }
        customeField {
          data
        }
        status
        subscriptionId
        subscription {
          name
          amount
          billingCycle
          billingCycleAt
          billingCycleCustomPeriod
          billingCycleCustomPeriodTime
        }
        updatedAt
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_UPDATE = gql`
  mutation editSubscriptionCustomer($input: SubscriptionCustomerInput) {
    editSubscriptionCustomer(input: $input) {
      id
      customerId
      status
      subscriptionId
    }
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_DELETE = gql`
  mutation deleteSubscriptionCustomer($id: ID) {
    deleteSubscriptionCustomer(id: $id)
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_BULK_DELETE = gql`
  mutation deleteMultipleSubscriptionCustomer($ids: [ID]) {
    deleteSubscriptionCustomer(ids: $ids)
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_GET_BY_USERID = gql`
  query getSubscriptionCustomerByUserId($userId: ID) {
    getSubscriptionCustomerByUserId(userId: $userId) {
      id
    }
  }
`;

export const GQL_SUBSCRIPTIONCUSTOMER_TOTAL = gql`
  query getSubscriptionCustomerPage($search: SubscriptionCustomerSearchInput) {
    getSubscriptionCustomerPage(search: $search) {
      items {
        id
      }
      total
    }
  }
`;
