import React, { useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MarkerIcon from "../../../public/map-point.png";

const icon = L.icon({
  iconUrl: MarkerIcon,
  iconSize: [38, 38],
});

const position = [-6.200000, 106.816666];

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (selectPosition) {
      map.setView(
        L.latLng(selectPosition?.lat, selectPosition?.lon),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}

export default function Map(props) {
  const { selectPosition } = props;
  let locationSelection;
  let locationValid = false;
  
  if (selectPosition && typeof selectPosition === "object") {
    locationSelection = [selectPosition?.lat, selectPosition?.lon];
    locationValid = true;
  } 

  return (
    <MapContainer
      center={position}
      zoom={8}
      style={{ width: "100%", height: "400px", marginBottom: "15px" }}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=dl3PZSmat2OYtPkGkhx2"
      />
      {selectPosition && locationValid && (
        <Marker position={locationSelection} icon={icon}>
          <Popup>
            {selectPosition?.display_name}
          </Popup>
        </Marker>
      )}
      {locationValid && <ResetCenterView selectPosition={selectPosition} locationValid={locationValid} />}
    </MapContainer>
  );
}
