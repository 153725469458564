import { FILTER_PAYMENT_BY_CUSTOMER, FILTER_PAYMENT_BY_STATUS, FILTER_PAYMENT_BY_TYPE, FILTER_PAYMENT_END_DATE, FILTER_PAYMENT_START_DATE } from '../actions/payments';

export const paymentFilterCustomerReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_BY_CUSTOMER:
      return action.payload;
  }
  return state;
};

export const paymentFilterStatusReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const paymentFilterTypeReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_BY_TYPE:
      return action.payload;
  }
  return state;
};

export const paymentFilterStartDateReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_START_DATE:
      return action.payload;
  }
  return state;
};

export const paymentFilterEndDateReducers = (state = '', action) => {
  switch (action.type) {
    case FILTER_PAYMENT_END_DATE:
      return action.payload;
  }
  return state;
};
