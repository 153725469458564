import 'react-dropzone-uploader/dist/styles.css';
import React from 'react';
import Cookies from 'js-cookie';
import DZ from 'react-dropzone-uploader';
import slug from 'slug';
import { parseJSON } from '../../utils';

const Dropzone = ({ maxFiles, multiple = false, accept, setFileId, isError, customLabel, fileId }) => {
  const disallowedChars = ['!', '*', "'", ',', '&', '$', '=', '"', ';', ':', '+', '?'];

  const fileAllowed = ({ meta, cancel }) => {
    meta.name = slug(meta.name.normalize('NFKD'), { lower: false, replacement: ' ', charmap: { '.': '.' } });

    if (disallowedChars.some(v => meta.name.indexOf(v) >= 0)) {
      cancel();
    }
  };

  const getUploadParams = ({ file, meta, cancel, remove }) => {
    if (disallowedChars.some(v => meta.name.indexOf(v) >= 0)) {
      cancel();
      remove();
      return {};
    }

    if (file.size > 1000000000) {
      cancel();
      remove();
      return {};
    }

    const headers = { Authorization: 'Bearer ' + Cookies.get('token') };
    /* eslint-disable no-undef */
    const body = new FormData();
    body.append(
      'operations',
      '{ "query": "mutation ($file: Upload!) { uploadUserContent(file: $file) { id } }", "variables": { "file": null } }'
    );
    body.append('map', '{ "0": ["variables.file"] }');
    body.append('0', file, meta.name);

    return { url: process.env.REACT_APP_PROXY, body, headers };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ xhr }, status) => {
    const currentFileId = parseJSON(xhr?.response, {})?.data?.uploadUserContent?.id;

    if (currentFileId) {
      if (status === 'done') {
        setFileId(multiple ? [...(fileId || []), currentFileId] : currentFileId);
      }

      if (status === 'removed') {
        setFileId(multiple ? (fileId || [])?.filter(id => id !== currentFileId) : null);
      }
    }
  };

  return (
    <DZ
      maxFiles={maxFiles}
      multiple={multiple}
      accept={accept}
      validate={fileAllowed}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      inputContent={(files, extra) => (extra.reject ? '' : customLabel || 'Drag Files or Click to Browse')}
      classNames={{ dropzone: `${isError ? 'dzu-dropzone is-invalid' : 'dzu-dropzone'}` }}
      styles={{
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})
      }}
    />
  );
};

export default Dropzone;
