export const copyToClipboard = id => {
  /* Get the text field */
  const copyText = document.getElementById(id);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand('copy');
};

export const copyToClipboardByValue = (value = '') => {
  const textField = document.createElement('textarea');
  textField.innerText = value;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const getWindowDimensions = () => ({ width: window.innerWidth, height: window.innerHeight });
