import {
  FILTER_BROADCAST_BY_Q,
  UPDATE_BROADCAST,
  FILTER_BROADCAST_BY_STATUS,
  BROADCAST_DETAILS
} from '../actions/broadcast';

export const filterBroadcastByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BROADCAST_BY_Q:
      return action.payload;
  }
  return state;
};

export const updateBroadcastReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_BROADCAST:
      return action.payload;
  }
  return state;
};

export const filterBroadcastByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_BROADCAST_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const broadcastDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case BROADCAST_DETAILS:
      return action.payload;
  }
  return state;
};
