import { subscriptionCustomerStatus, validator } from '.';

export const donationCategory = [
  'Bantuan Pendidikan',
  'Bencana Alam',
  'Difabel',
  'Infrastruktur Umum',
  'Karya Kreatif / Modal Usaha',
  'Kegiatan Sosial',
  'Kemanusiaan',
  'Lingkungan',
  'Menolong Hewan',
  'Panti Asuhan',
  'Rumah Ibadah',
  'Lainnya'
];

export const cohortCategory = [
  'Business',
  'Sales & Marketing',
  'Professional & Personal Development',
  'Technology & Software',
  'Test Preparation',
  'Mentoring',
  'Language',
  'Office Productivity',
  'Hobbies & Lifestyle',
  'Design',
  'Multimedia',
  'Finance & Accounting'
];

export const publicPageCategory = [
  { label: 'Link Bio + Katalog (Default)', value: 'bio' },
  { label: 'PayMe', value: 'payme' },
  { label: 'Katalog Produk', value: '' },
  // { label: 'Produk Fisik Katalog', value: 'product-catalog' },
  // { label: 'Produk Digital Download Katalog', value: 'catalog' },
  // { label: 'Kelas Online Katalog', value: 'course' },
  // { label: 'Webinar Katalog', value: 'webinar' },
  // { label: 'Event Katalog', value: 'event' },
  // { label: 'Coaching Katalog', value: 'coaching' },
  // { label: 'Audiobook', value: 'audiobook' },
  // { label: 'E-book', value: 'ebook'},
  // { label: 'Podcast', value: 'podcast' },
  { label: 'Donasi V1', value: 'galang-dana' },
  { label: 'Donasi V2', value: 'galang-dana/v2' },
  { label: 'Donasi Generic', value: 'donate-now' },
  { label: 'Landing Page', value: 'landing' }
];

export const categoryCatalog = [
  // { label: 'Link Pembayaran Katalog', value: '/plink' },
  // { label: 'Produk Fisik Katalog', value: '/product-catalog' },
  // { label: 'Produk Digital Download Katalog', value: '/catalog' },
  // { label: 'Kelas Online Katalog', value: '/course' },
  // { label: 'Kelas Cohort / Bootcamp Katalog', value: '/bootcamp' },
  // { label: 'Webinar Katalog', value: '/webinar' },
  // { label: 'Event Katalog', value: '/event' },
  // { label: 'Coaching Katalog', value: '/coaching' },
  // { label: 'Audiobook Katalog', value: '/audiobook' },
  // { label: 'E-book Katalog', value: '/ebook' },
  // { label: 'Podcast Katalog', value: '/podcast' },
  // { label: 'Bundling Katalog', value: '/bundling' },
  // { label: 'Donasi V1', value: '/galang-dana' },
  // { label: 'Donasi V2', value: '/galang-dana/v2' }
  { label: 'Halaman Katalog - Versi Lama', value: '/profile' }
];

export const linksType = [
  { label: 'Katalog', type: 'CATALOG', icon: 'folderPlus' },
  { label: 'Produk', type: 'PRODUCT', icon: 'tag' },
  { label: 'Teks', type: 'TEXT', icon: 'type' },
  { label: 'URL / Link Website', type: 'URL', placeholder: 'Url / Link', pattern: validator.url, icon: 'link' },
  { label: 'Subscription', type: 'SUBSCRIPTION', icon: 'repeat' },
  { label: 'Support', type: 'SUPPORT', icon: 'smile' },
  { label: 'Kode Promo', type: 'COUPON', icon: 'percent' },
  {
    label: 'Marketplace',
    type: 'MARKETPLACE',
    placeholder: 'Url / Link Marketplace',
    pattern: validator.url,
    icon: ['fas', 'store'],
    vendor: 'fa'
  },
  {
    label: 'HTML Embed',
    type: 'HTML',
    placeholder: 'Kode HTML',
    icon: 'code'
  },
  {
    label: 'Iframe',
    type: 'IFRAME',
    placeholder: 'https://www.youtube.com/watch?v=XXXXXXXXXX',
    pattern: validator.url,
    icon: 'tv'
  },
  {
    label: 'Youtube',
    type: 'YOUTUBE',
    placeholder: 'https://www.youtube.com/watch?v=XXXXXXXXXX',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?((youtube\.com)||(youtu\.be))?\/(embed\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: 'youtube'
  },
  {
    label: 'Tiktok',
    type: 'TIKTOK',
    placeholder: 'https://www.tiktok.com/@username/video/XXXXXXXXXX',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(tiktok\.com)?(((?:\/@[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))||\/)(video\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ['fab', 'tiktok'],
    vendor: 'fa'
  },
  {
    label: 'Tweet',
    type: 'TWITTER',
    placeholder: 'https://twitter.com/username/status/tweetid',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(twitter\.com)\/(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/(status\/))((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: 'twitter'
  },
  {
    label: 'Spotify',
    type: 'SPOTIFY',
    placeholder: 'https://open.spotify.com/type/xxxxxxxx',
    pattern: validator.pattern(
      /(https:\/\/(open\.)?(spotify\.com)?(\/embed\/? || \/)(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ['fab', 'spotify'],
    vendor: 'fa'
  },
  {
    label: 'Banner',
    type: 'BANNER',
    placeholder: 'Url / Link Banner',
    pattern: validator.url,
    icon: 'image'
  }
];

export const componentsLandingType = [
  { label: 'Katalog', type: 'CATALOG', icon: 'folderPlus' },
  { label: 'Produk', type: 'PRODUCT', icon: 'tag' },
  { label: 'Teks', type: 'TEXT', icon: 'type' },
  { label: 'Email Subscribe', type: 'SUBSCRIPTION', icon: 'repeat' },
  { label: 'Support', type: 'SUPPORT', icon: 'smile' },
  { label: 'Tombol', type: 'URL', placeholder: 'Url / Link', pattern: validator.url, icon: 'link' },
  {
    label: 'HTML Embed',
    type: 'HTML',
    placeholder: 'Kode HTML',
    icon: 'code'
  },
  {
    label: 'Iframe',
    type: 'IFRAME',
    placeholder: 'https://www.youtube.com/watch?v=XXXXXXXXXX',
    pattern: validator.url,
    icon: 'tv'
  },
  { label: 'Kode Promo', type: 'COUPON', icon: 'percent' },
  { label: 'Form', type: 'FORM', icon: 'table' },
  {
    label: 'Youtube',
    type: 'YOUTUBE',
    placeholder: 'https://www.youtube.com/watch?v=XXXXXXXXXX',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?((youtube\.com)||(youtu\.be))?\/(embed\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: 'youtube'
  },
  {
    label: 'Tiktok',
    type: 'TIKTOK',
    placeholder: 'https://www.tiktok.com/@username/video/XXXXXXXXXX',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(tiktok\.com)?(((?:\/@[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))||\/)(video\/)?)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ['fab', 'tiktok'],
    vendor: 'fa'
  },
  {
    label: 'WhatsApp Chat',
    type: 'WHATSAPP',
    placeholder: 'Nomor WhatsApp',
    pattern: validator.phoneNotStrict,
    icon: ['fab', 'whatsapp'],
    vendor: 'fa'
  },
  {
    label: 'Tweet',
    type: 'TWITTER',
    placeholder: 'https://twitter.com/username/status/tweetid',
    pattern: validator.pattern(
      /(https:\/\/(www\.)?(twitter\.com)\/(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/(status\/))((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: 'twitter'
  },
  {
    label: 'Spotify',
    type: 'SPOTIFY',
    placeholder: 'https://open.spotify.com/type/xxxxxxxx',
    pattern: validator.pattern(
      /(https:\/\/(open\.)?(spotify\.com)?(\/embed\/? || \/)(?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*)\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    ),
    icon: ['fab', 'spotify'],
    vendor: 'fa'
  },
  {
    label: 'Social Media Icon',
    type: 'SOCIAL_MEDIA_ICON',
    icon: 'share'
  },
  {
    label: 'FAQ',
    type: 'FAQ',
    icon: 'help-circle'
  },
  {
    label: 'Banner',
    type: 'BANNER',
    placeholder: 'Url / Link Banner',
    pattern: validator.url,
    icon: 'image'
  },
  {
    label: 'Countdown',
    type: 'COUNTDOWN',
    icon: 'clock'
  },
  {
    label: 'Divider',
    type: 'DIVIDER',
    icon: 'divide-square'
  }
];

export const socialMediaList = [
  { label: 'Email', type: 'EMAIL', placeholder: 'anda@alamatemail.com', pattern: validator.email },
  { label: 'WhatsApp', type: 'WHATSAPP', placeholder: '+620000000000', pattern: validator.phoneNotStrict },
  {
    label: 'Instagram Username',
    type: 'INSTAGRAM',
    placeholder: '@instagramusername',
    pattern: validator.pattern(/(\()?(\@)(\d{1})?\)?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,29}$/im)
  },
  {
    label: 'Tiktok Username',
    type: 'TIKTOK',
    placeholder: '@tiktokusername',
    pattern: validator.pattern(/(\()?(\@)(\d{1})?\)?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,29}$/im)
  },
  {
    label: 'Twitter Username',
    type: 'TWITTER',
    placeholder: '@twitterusername',
    pattern: validator.pattern(/(\()?(\@)(\d{1})?\)?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,29}$/im)
  },
  {
    label: 'Facebook Page URL',
    type: 'FACEBOOK',
    placeholder: 'https://facebook.com/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?facebook\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Telegram URL',
    type: 'TELEGRAM',
    placeholder: 'https://t.me/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?t\.me\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'YouTube URL',
    type: 'YOUTUBE',
    placeholder: 'https://youtube.com/youtubechannelurl',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?youtube\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'LinkedIn URL',
    type: 'LINKEDIN',
    placeholder: 'https://linkedin.com/in/username',
    pattern: validator.pattern(
      /(https?:\/\/?(www\.)?linkedin\.com\/(in|company|school|groups|jobs|feed\/update|learning)\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/
    )
  },
  {
    label: 'Tokopedia URL',
    type: 'TOKOPEDIA',
    placeholder: 'https://tokopedia.com/domainname',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?tokopedia\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Shopee URL',
    type: 'SHOPEE',
    placeholder: 'https://shopee.co.id/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?shopee\.co\.id\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'BukaLapak URL',
    type: 'BUKALAPAK',
    placeholder: 'https://bukalapak.com/domainname',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?bukalapak\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Lazada URL',
    type: 'LAZADA',
    placeholder: 'https://lazada.co.id/shop/domainname',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?lazada\.co\.id\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Spotify URL',
    type: 'SPOTIFY',
    placeholder: 'https://open.spotify.com/spotifyurl',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?open\.spotify\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'SoundCloud URL',
    type: 'SOUNDCLOUD',
    placeholder: 'https://soundcloud.com/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?soundcloud\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Signal URL',
    type: 'SIGNAL',
    placeholder: 'https://signal.group/username|id',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?signal\.group\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Twitch URL',
    type: 'TWITCH',
    placeholder: 'https://twitch.tv/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?twitch\.tv\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Patreon URL',
    type: 'PATREON',
    placeholder: 'https://patreon.com/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?patreon\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  },
  {
    label: 'Pinterest URL',
    type: 'PINTEREST',
    placeholder: 'https://pinterest.com/username',
    pattern: validator.pattern(
      /(https:\/\/?(www\.)?pinterest\.com\/)((?:[-a-zA-Z0-9()@:%_\+.~#?&//=][-a-zA-Z0-9()@:%_\+.~#?&//=]*))/i
    )
  }
];

export const physicalProductCategory = [
  'Pakaian',
  'Handphone & Aksesoris',
  'Komputer & Aksesoris',
  'Perawatan & Kecantikan',
  'Perlengkapan Rumah',
  'Elektronik',
  'Kesehatan',
  'Hobi & Koleksi',
  'Makanan & Minuman',
  'Olahraga & Outdoor',
  'Otomotif',
  'Buku & Alat Tulis',
  'Lainnya'
];

export const broadcastOptions = [
  { label: 'EMAIL', value: 'email' },
  { label: 'SMS', value: 'sms', isDisabled: true },
  { label: 'WHATSAPP', value: 'whatsapp', isDisabled: true },
  { label: 'PUSH NOTIFICATION', value: 'push', isDisabled: true }
];

export const paymentLinkTransactionOptions = [
  { label: 'Semua', value: 'all' },
  { label: 'Sudah Bayar', value: 'paid' },
  { label: 'Belum Bayar', value: 'created' }
];

export const subscriptionCustomerOptions = [
  { label: 'Semua', value: 'all' },
  ...Object.keys(subscriptionCustomerStatus).map(key => ({
    label: subscriptionCustomerStatus[key][1],
    value: subscriptionCustomerStatus[key][0]
  }))
];

export const membershipTypes = {
  MEMBERSHIP: 'Membership',
  SAAS: 'SaaS (Software as a Service) / Subscription'
};

export const disclaimerMailerBlast = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <title>Disclaimer - Mayar.ID</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style type="text/css">
      /**
   * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
   */
      @media screen {
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
            url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff)
              format('woff');
        }

        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 700;
          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
            url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff)
              format('woff');
        }
      }

      /**
   * Avoid browser level font resizing.
   * 1. Windows Mobile
   * 2. iOS / OSX
   */
      body,
      table,
      td,
      a {
        -ms-text-size-adjust: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
      }

      /**
   * Remove extra space added to tables and cells in Outlook.
   */
      table,
      td {
        mso-table-rspace: 0pt;
        mso-table-lspace: 0pt;
      }

      /**
   * Better fluid images in Internet Explorer.
   */
      img {
        -ms-interpolation-mode: bicubic;
      }

      /**
   * Remove blue links for iOS devices.
   */
      a[x-apple-data-detectors] {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
        text-decoration: none !important;
      }

      /**
   * Fix centering issues in Android 4.4.
   */
      div[style*='margin: 16px 0;'] {
        margin: 0 !important;
      }

      body {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: auto !important;
      }

      /**
   * Collapse table borders to avoid space between cells.
   */
      table {
        border-collapse: collapse !important;
      }

      a {
        color: #1a82e2;
      }

      img {
        height: auto;
        line-height: 100%;
        text-decoration: none;
        border: 0;
        outline: none;
      }
    </style>
  </head>
  <body>
    <table width="100%">
      <!-- start footer -->
      <tr>
        <td align="center" style="padding: 24px">
          <!--[if (gte mso 9)|(IE)]>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
        <td align="center" valign="top" width="600">
        <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <!-- start permission -->
            <tr>
              <td
                align="center"
                style="
                  padding: 12px 24px;
                  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
                  font-size: 12px;
                  line-height: 20px;
                  color: #666;
                "
              >
                <p style="margin: 0">
                  Anda mendapatkan email ini karena anda bertransaksi dengan {{var:merchant_name:"Toko"}} yang menggunakan Mayar.ID sebagai platform bisnis dan pembayaran.
                </p>
              </td>
            </tr>
            <!-- end permission -->

            <!-- start unsubscribe -->
            <tr>
              <td
                align="center"
                style="
                  padding: 12px 24px;
                  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
                  font-size: 12px;
                  line-height: 20px;
                  color: #666;
                "
              >
                <p style="margin: 0">
                  Ada pertanyaan? email ke
                  <a href="mailto:bantuan@mayar.id" target="_blank"
                    >bantuan@mayar.id</a
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td
                align="center"
                style="
                  padding: 12px 24px;
                  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
                  font-size: 12px;
                  line-height: 20px;
                  color: #666;
                "
              >
                <p style="margin: 0">
                  Untuk berhenti menerima email ini, klik
                  <a href="{{URLUNSUB}}" target="_blank"
                    >Berhenti berlangganan</a
                  >
                </p>
              </td>
            </tr>
            <!-- end unsubscribe -->
          </table>
          <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
        </td>
      </tr>
      <!-- end footer -->
    </table>
    <!-- end body -->
  </body>
</html>
`;

export const balanceHistoryTypes = {
  payme: { type: 'payme', text: 'PayMe' },
  generic_link: { type: 'generic_link', text: 'Link Pembayaran' },
  subscription: { type: 'subscription', text: 'Paket Berlangganan' },
  payment_request: { type: 'payment_request', text: 'Penagihan' },
  invoice: { type: 'invoice', text: 'Invoice' },
  withdraw: { type: 'withdraw', text: 'Penarikan' },
  physical_product: { type: 'physical_product', text: 'Produk Fisik' },
  digital_product: { type: 'digital_product', text: 'Produk Digital' },
  webinar: { type: 'webinar', text: 'Webinar' },
  event: { type: 'event', text: 'Event' },
  course: { type: 'course', text: 'Kelas Online' },
  coaching: { type: 'coaching', text: 'Coaching' },
  cohort_based: { type: 'cohort_based', text: 'Kelas Cohort / Bootcamp' },
  fundraising: { type: 'fundraising', text: 'Penggalangan Dana' },
  zakat: { type: 'zakat', text: 'Zakat' },
  affiliate_fee: { type: 'affiliate_fee', text: 'Komisi Affiliate' },
  referral_fee: { type: 'referral_fee', text: 'Komisi Referral' },
  shipment_fee: { type: 'shipment_fee', text: 'Ongkir' },
  spending: { type: 'spending', text: 'Pengeluaran' },
  ebook: { type: 'ebook', text: 'E-Book' },
  podcast: { type: 'podcast', text: 'Podcast' },
  audiobook: { type: 'audiobook', text: 'Audio Book' },
  bundling: { type: 'bundling', text: 'Bundling' },
  support: { type: 'support', text: 'Support' },
  membership: { type: 'membership', text: 'Membership' },
  saas: { type: 'saas', text: 'Software & Saas' },
  membership_payment: { type: 'membership_payment', text: 'Penagihan Membership' },
  topup_whatsapp: { type: 'topup_whatsapp', text: 'Saldo Whatsapp' },
  writing: { type: 'writing', text: 'Tulisan' }
};

export const courseModuleTypes = {
  text: {
    type: 'text',
    text: 'Teks',
    icon: 'file-text',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  embed: {
    type: 'embed',
    text: 'Embed',
    icon: 'youtube',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  iframe: {
    type: 'iframe',
    text: 'Embed Iframe',
    icon: 'square',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  video_drm: {
    type: 'video_drm',
    text: 'Video DRM',
    icon: 'video',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  quiz: {
    type: 'quiz',
    text: 'Kuis',
    icon: 'help-circle',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  coaching: {
    type: 'coaching',
    text: 'Coaching',
    icon: 'watch',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  files: {
    type: 'files',
    text: 'Files',
    icon: 'paperclip',
    placeholder: 'This just some placeholder if you want to add some later.'
  },
  audio: {
    type: 'audio',
    text: 'Audio',
    icon: 'headphones',
    placeholder: 'This just some placeholder if you want to add some later.'
  }
};

export const paymentTypeOptions = [
  { label: 'Produk Berbayar', value: 'paid' },
  { label: 'Produk Gratis', value: 'free' },
  { label: 'Produk Bayar Semaunya', value: 'voluntary' }
];

export const paymentTypeOptionsWebinar = [
  { label: 'Webinar Berbayar', value: 'paid' },
  { label: 'Webinar Gratis', value: 'free' },
  { label: 'Webinar Bayar Semaunya', value: 'voluntary' }
];

export const defaultCertificateTemplates = [
  { label: 'Unggah Desain', value: 'upload' },
  { label: 'Template Sertifikat 1', value: 'certificate-1' },
  { label: 'Template Sertifikat 2', value: 'certificate-2' },
  { label: 'Template Sertifikat 3', value: 'certificate-3' },
  { label: 'Template Sertifikat 4', value: 'certificate-4' },
  { label: 'Template Sertifikat 5', value: 'certificate-5' },
  { label: 'Template Sertifikat 6', value: 'certificate-6' },
  { label: 'Template Sertifikat 7', value: 'certificate-7' },
  { label: 'Template Sertifikat 8', value: 'certificate-8' },
  { label: 'Template Sertifikat 9', value: 'certificate-9' },
  { label: 'Template Sertifikat 10', value: 'certificate-10' }
];

export const defaultTicketTemplates = [
  { label: 'Unggah Desain', value: 'upload' },
  { label: 'Blank', value: 'blank' },
  { label: 'Template Tiket 1', value: 'pdf-1' },
  { label: 'Template Tiket 2', value: 'pdf-2' },
  { label: 'Template Tiket 3', value: 'pdf-3' },
  { label: 'Template Tiket 4', value: 'pdf-4' },
  { label: 'Template Tiket 5', value: 'pdf-5' },
  { label: 'Template Tiket 6', value: 'pdf-6' },
  { label: 'Template Tiket 7', value: 'pdf-7' },
  { label: 'Template Tiket 8', value: 'pdf-8' },
  { label: 'Template Tiket 9', value: 'pdf-9' },
  { label: 'Template Tiket 10', value: 'pdf-10' }
];

export const kycTypeOptions = [
  { label: 'Individu', value: 'individual' },
  { label: 'Badan Usaha / Bisnis', value: 'business' },
  { label: 'Yayasan', value: 'foundation' }
];

export const ewalletCodes = [
  {
    EwalletCode: 'GOPAY',
    Ewallet: 'GOPAY'
  },
  {
    EwalletCode: 'OVO',
    Ewallet: 'OVO'
  },
  {
    EwalletCode: 'DANA',
    Ewallet: 'DANA'
  },
  {
    EwalletCode: 'LINKAJA',
    Ewallet: 'LINKAJA'
  },
  {
    EwalletCode: 'SHOPEEPAY',
    Ewallet: 'SHOPEEPAY'
  }
];

export const currencyOptions = [{ label: 'IDR - Indonesian Rupiah', value: 'IDR' }];

export const bankCountryOptions = [{ label: 'Indonesia', value: 'indonesia' }];

export const bankAccountTypeOptions = [
  { label: 'Individu', value: 'individual' },
  { label: 'Bisnis', value: 'business' }
];

export const paymentCategoryOptions = [
  { label: 'Investasi', value: 'investation' },
  { label: 'Inventori', value: 'inventory' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Gaji', value: 'salary' },
  { label: 'Ritel', value: 'retail' },
  { label: 'Service', value: 'service' },
  { label: 'Pemerintah', value: 'government' },
  { label: 'Travel', value: 'travel' },
  { label: 'Sewa', value: 'rent' },
  { label: 'Tagihan', value: 'bill' },
  { label: 'Sales', value: 'sales' },
  { label: 'Software', value: 'software' },
  { label: 'Lainnya', value: 'others' }
];

export const months = [
  ['Januari', 'January'],
  ['Februari', 'February'],
  ['Maret', 'March'],
  ['April', 'April'],
  ['Mei', 'May'],
  ['Juni', 'June'],
  ['Juli', 'July'],
  ['Agustus', 'August'],
  ['September', 'September'],
  ['Oktober', 'October'],
  ['November', 'November'],
  ['Deesmber', 'December']
];

export const workRisk = [
  { label: 'Sangat Rendah', value: 'very_low', percentage: 24 / 10_000 },
  { label: 'Rendah', value: 'low', percentage: 54 / 10_000 },
  { label: 'Sedang', value: 'medium', percentage: 89 / 10_000 },
  { label: 'Tinggi', value: 'high', percentage: 127 / 10_000 },
  { label: 'Sangat Tinggi', value: 'very_high', percentage: 174 / 10_000 }
];

export const productOptionsHeader = [
  { value: 'generic_link', label: 'Link Pembayaran', isDisabled: true },
  { value: 'physical_product', label: 'Produk Fisik', isDisabled: true },
  { value: 'digital_product', label: 'Produk Digital', isDisabled: true },
  { value: 'course', label: 'Kelas Online', isDisabled: true },
  { value: 'cohort_based', label: 'Kelas Cohort / Bootcamp', isDisabled: true },
  { value: 'webinar', label: 'Webinar', isDisabled: true },
  { value: 'event', label: 'Event', isDisabled: true },
  { value: 'coaching', label: 'Coaching', isDisabled: true },
  { value: 'ebook', label: 'E-Book', isDisabled: true },
  { value: 'podcast', label: 'Podcast', isDisabled: true },
  { value: 'audiobook', label: 'Audio Book', isDisabled: true },
  { value: 'bundling', label: 'Bundling', isDisabled: true },
  { value: 'membership', label: 'Membership', isDisabled: true }
];

export const customerType3rdEmailMarketing = [
  { label: 'Pelanggan Sudah Bayar', value: 'PAID_CUSTOMER' },
  { label: 'Pelanggan Belum Bayar', value: 'UNPAID_CUSTOMER' }
];

export const productAccessMembershipType = [
  { name: 'digital_product', icon: 'download-cloud', link: 'digital-product-detail' },
  { name: 'course', icon: 'book-open', link: 'online-course-detail' },
  { name: 'ebook', icon: 'eye', link: 'ebook-detail' },
  { name: 'podcast', icon: 'mic', link: 'podcast-detail' },
  { name: 'audiobook', icon: 'headphones', link: 'audiobook-detail' }
];

export const productDetailLinks = {
  generic_link: 'payment-link-detail',
  physical_product: 'physical-product-detail',
  digital_product: 'digital-product-detail',
  webinar: 'webinar-detail',
  event: 'event-detail',
  course: 'online-course-detail',
  coaching: 'coaching-detail',
  cohort_based: 'cohort-course-detail',
  ebook: 'ebook-detail',
  podcast: 'podcast-detail',
  audiobook: 'audiobook-detail',
  fundraising: {
    FUNDRAISING_QURBAN: 'qurban-detail',
    FUNDRAISING_WAQAF: 'waqaf-detail',
    FUNDRAISING_DONATION: 'fundraising-detail'
  },
  bundling: 'bundling-detail',
  membership: 'membership-detail',
  subscription: 'payment-plan-detail',
  invoice: 'invoice-detail',
  payment_request: 'payment-request-detail',
  payme: 'payment-request-detail',
  membership_payment: 'membership-payment',
  writing: 'writing-detail'
  // zakat: { type: 'zakat', text: 'Zakat' },
  // withdraw: { type: 'withdraw', text: 'Penarikan' },
  // affiliate_fee: { type: 'affiliate_fee', text: 'Komisi Affiliate' },
  // referral_fee: { type: 'referral_fee', text: 'Komisi Referral' },
  // shipment_fee: { type: 'shipment_fee', text: 'Ongkir' },
  // spending: { type: 'spending', text: 'Pengeluaran' },
  // support: { type: 'support', text: 'Support' },
};

export const productCatalog = [
  {
    label: 'Produk Fisik',
    value: 'physical_product'
  },
  {
    label: 'Produk Digital',
    value: 'digital_product'
  },
  {
    label: 'Kelas Online',
    value: 'course'
  },
  {
    label: 'Webinar',
    value: 'webinar'
  },
  {
    label: 'Membership',
    value: 'membership'
  },
  {
    label: 'Event',
    value: 'event'
  },
  {
    label: 'Coaching',
    value: 'coaching'
  },
  {
    label: 'Kelas Cohort / Bootcamp',
    value: 'cohort_based'
  },
  {
    label: 'Audiobook',
    value: 'audiobook'
  },
  {
    label: 'E-book',
    value: 'ebook'
  },
  {
    label: 'Podcast',
    value: 'podcast'
  },
  {
    label: 'Link Pembayaran',
    value: 'generic_link'
  },
  {
    label: 'Lisensi Software',
    value: 'saas'
  },
  {
    label: 'Tulisan / Writing',
    value: 'writing'
  }
];

export const bannerCatalog = [
  {
    label: 'Iya',
    value: true
  },
  {
    label: 'Tidak',
    value: false
  }
];

const prefixAwsAuthAssets = 'https://mayar-web.r2.mayar.id';

export const merchantsLogo = {
  'Digital Skola': `${prefixAwsAuthAssets}/merchants-logo/digital-skola.png`,
  Dilatih: `${prefixAwsAuthAssets}/merchants-logo/dilatih.png`,
  Greenpeace: `${prefixAwsAuthAssets}/merchants-logo/greenpeace.png`,
  Dnvb: `${prefixAwsAuthAssets}/merchants-logo/dnvb.png`,
  Ebizmark: `${prefixAwsAuthAssets}/merchants-logo/ebizmark.png`,
  'Hope Worldwide': `${prefixAwsAuthAssets}/merchants-logo/hope-worldwide.png`,
  Algoritma: `${prefixAwsAuthAssets}/merchants-logo/algoritma.png`,
  'Belajar Lagi': `${prefixAwsAuthAssets}/merchants-logo/belajar-lagi.png`,
  Pacman: `${prefixAwsAuthAssets}/merchants-logo/pacman.png`,
  Ijec: `${prefixAwsAuthAssets}/merchants-logo/ijec.png`,
  'Cipta Aliansi': `${prefixAwsAuthAssets}/merchants-logo/cipta-aliansi.png`,
  'Griya Yatim': `${prefixAwsAuthAssets}/merchants-logo/griya-yatim.png`
};

export const newsCoverageMedia = {
  'Liputan 6': `${prefixAwsAuthAssets}/news-coverage/liputan-6.webp`,
  Kompas: `${prefixAwsAuthAssets}/news-coverage/kompas.webp`,
  Suara: `${prefixAwsAuthAssets}/news-coverage/suara.webp`,
  Viva: `${prefixAwsAuthAssets}/news-coverage/viva.webp`,
  MedCom: `${prefixAwsAuthAssets}/news-coverage/medcom.webp`,
  Jpnn: `${prefixAwsAuthAssets}/news-coverage/jpn.png`,
  'Info Komputer': `${prefixAwsAuthAssets}/news-coverage/info-komputer.webp`,
  'Media Indonesia': `${prefixAwsAuthAssets}/news-coverage/media-indonesia.png`,
  Bisnis: `${prefixAwsAuthAssets}/news-coverage/bisnis.webp`,
  Republika: `${prefixAwsAuthAssets}/news-coverage/republika.webp`
};

export const licenseTypeOptions = {
  PURCHASE_FOREVER: {
    value: 'Sekali Beli - Aktif Selamanya',
    formatted: 'Aktif Selamanya'
  },
  PURCHASE_LIMITED: {
    value: 'Sekali Beli - Masa Aktif Terbatas',
    formatted: 'Masa Aktif Terbatas'
  }
};

export const fileSourceOptions = [
  { label: 'File Lama', value: 'existing' },
  { label: 'Unggah Baru', value: 'new' }
];

export const plTypeSupportGift = [
  'digital_product',
  'course',
  'cohort_based',
  'ebook',
  'podcast',
  'audiobook',
  'coaching',
  'webinar',
  'bundling'
];

export const simpleLandingPageCategory = [
  { label: 'Kelas Online', values: ['ONLINE_COURSE'] },
  { label: 'Webinar / Workshop', values: ['WEBINAR', 'WORKSHOP'] },
  { label: 'Coaching', values: ['COACHING'] },
  { label: 'Produk Digital', values: ['DIGITAL_PRODUCT'] },
  { label: 'Membership / SAAS', values: ['MEMBERSHIP', 'SAAS'] },
  { label: 'Kode Lisensi', values: ['LICENSE_CODE'] },
  { label: 'Link Pembayaran', values: ['GENERIC_LINK'] }
];

export const writingTypeOptions = [
  { label: 'One-Shot (Cerpen, Blog, Essay)', value: 'ONE_SHOT' },
  { label: 'Chapter (Buku, Cerbung, Antologi)', value: 'CHAPTER' }
];

export const writingPaymentMethodOptions = [
  { label: 'Sekali Bayar', value: 'ALL' },
  { label: 'Per-Chapter', value: 'CHAPTER' },
  { label: 'Semua Chapter (Paket) & Per-Chapter', value: 'PACKAGE' }
];

export const defaultSubmitState = {
  isError: false,
  isSuccess: false,
  message: ''
};

export const dropzoneDefaultStyleConfig = {
  styles: {
    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
    previewImage: { maxHeight: '140px' }
  },
  classNames: {
    dropzone: 'dzu-dropzone',
    preview: 'dzu-previewContainer py-10',
    inputLabelWithFiles: 'dzu-inputLabelWithFiles mt-10'
  }
};
