export const FILTER_AFFILIATE_BY_Q = 'FILTER_AFFILIATE_BY_Q';
export const filterAffiliateByQ = (q) => ({
  type: FILTER_AFFILIATE_BY_Q,
  payload: q,
});

export const FILTER_AFFILIATE_BY_STATUS = 'FILTER_AFFILIATE_BY_STATUS';
export const filterAffiliateByStatus = (q) => ({
  type: FILTER_AFFILIATE_BY_STATUS,
  payload: q,
});
