import _ from 'lodash';

export const customerStatus = {
  active: ['active', 'Aktif'],
  deactivated: ['deactivated', 'Tidak Aktif'],
  newcomer: ['newcomer', 'Anggota Baru']
};

export const teamStatus = {
  active: ['active', 'Aktif'],
  inactive: ['inactive', 'Tidak Aktif'],
  deleted: ['deleted', 'Dihapus']
};

export const referralUserStatus = {
  active: ['active', 'Aktif'],
  inactive: ['inactive', 'Tidak Aktif']
};

export const paymentlinkStatus = {
  active: ['active', 'Published'],
  unpaid: ['unpaid', 'Belum dibayar'],
  paid: ['paid', 'Paid'],
  closed: ['closed', 'Unpublished'],
  unlisted: ['unlisted', 'Unlisted'],
  archived: ['archived', 'Arsip'],
  inactive: ['inactive', 'Tidak Aktif'],
  deleted: ['deleted', 'Dihapus']
};

export const discountStatus = {
  active: ['active', 'Aktif'],
  inactive: ['inactive', 'Tutup']
};

export const fundraisingStatus = {
  active: ['active', 'Published'],
  unlisted: ['unlisted', 'Unlisted'],
  closed: ['closed', 'Unpublished']
};

export const paymentlinkTypeStatus = {
  membership_payment: ['membership_payment', 'Penagihan Membership'],
  payment_request: ['payment_request', 'Penagihan'],
  payme: ['payme', 'Pay Me'],
  subscription: ['subscription', 'Berlangganan'],
  generic_link: ['generic_link', 'Link Pembayaran'],
  digital_product: ['digital_product', 'Produk Digital'],
  webinar: ['webinar', 'Webinar'],
  invoice: ['invoice', 'Invoice'],
  installment: ['installment', 'Cicilan']
};

export const paymentlinkTransactionStatus = {
  created: ['created', 'Belum dibayar'],
  verifying: ['verifying', 'Sedang diverifikasi'],
  paid: ['paid', 'Paid'],
  failed: ['failed', 'Pembayaran Gagal']
};

export const subscriptionStatus = {
  active: ['active', 'Aktif'],
  inactive: ['inactive', 'Tidak Aktif'],
  closed: ['closed', 'Tutup']
};

export const subscriptionCustomerStatus = {
  active: ['active', 'Aktif'],
  inactive: ['inactive', 'Tidak Aktif'],
  stopped: ['stopped', 'Berhenti'],
  finished: ['finished', 'Selesai']
};

export const getDefaultStatus = obj => {
  return obj[_.keys(obj)[0]];
};

export const getStatusLabel = (obj, value) => {
  return obj[value] ? obj[value][1] : getDefaultStatus(obj)[1];
};

export const paymentStatus = {
  paid: ['paid', 'Paid'],
  settled: ['settled', 'Settled'],
  unpaid: ['unpaid', 'Unpaid'],
  pending: ['unpaid', 'Unpaid'],
  created: ['created', 'Aktif'],
  expired: ['expired', 'Expired']
};

export const membershipTierPeriodStatus = {
  ACTIVE: ['ACTIVE', 'Active'],
  INACTIVE: ['INACTIVE', 'Tidak Aktif']
};

export const physicalProductStatus = {
  active: ['active', 'Published'],
  unlisted: ['unlisted', 'Unlisted'],
  closed: ['closed', 'Unpublished']
};

export const orderStatus = {
  PACKED: ['PACKED', 'Dikemas'],
  WAITING: ['WAITING', 'Menunggu Kurir'],
  SHIPMENT: ['SHIPMENT', 'Dalam Pengiriman'],
  FINISH: ['FINISH', 'Selesai'],
  CANCELED: ['CANCELED', 'Dibatalkan']
};

export const landingPageStatus = {
  active: ['active', 'Aktif'],
  archived: ['archived', 'Arsip'],
  inactive: ['inactive', 'Tidak Aktif']
};

export const broadcastStatus = {
  drafted: ['drafted', 'Draft'],
  scheduled: ['scheduled', 'Scheduled'],
  finished: ['finished', 'Selesai'],
  archived: ['archived', 'Arsip'],
  failed: ['failed', 'Gagal']
};

export const batchPaymentHistoryStatus = {
  NOT_FOUND: ['not found', 'Tidak ditemukan'],
  SUCCESS: ['success', 'Sukses']
};

export const spendingStatus = {
  FAILED: ['FAILED', 'Gagal'],
  ON_PROCESS: ['ON_PROCESS', 'Diproses'],
  SETTLED: ['SETTLED', 'Terkirim']
};

export const employeeStatus = {
  PERMANENT: ['PERMANENT', 'Permanen'],
  CONTRACT: ['CONTRACT', 'Kontrak'],
  PROBATION: ['PROBATION', 'Masa Percobaan']
};

export const employeeDataStatus = {
  ACTIVE: ['ACTIVE', 'Aktif'],
  ARCHIVED: ['ARCHIVED', 'Dihapus']
};

export const batchSpendingStatus = {
  ACTIVE: ['ACTIVE', 'Draft'],
  INACTIVE: ['INACTIVE', 'Selesai']
};
export const generalStatus = {
  ACTIVE: ['ACTIVE', 'Tampil'],
  INACTIVE: ['INACTIVE', 'Tidak Tampil']
};

export const installmentStatus = {
  paid: ['paid', 'Lunas'],
  unpaid: ['unpaid', 'Belum Lunas']
};

export const paymentRequestStatus = {
  paid: ['paid', 'Dibayar'],
  unpaid: ['unpaid', 'Belum Dibayar'],
  closed: ['closed', 'Ditutup']
};

export const periodType = {
  monthly: ['monthly', 'Bulanan'],
  annually: ['annually', 'Tahunan'],
  custom: ['custom', 'Tahunan']
};

export const licenseCodeStatus = {
  ACTIVE: {
    formatted: 'Aktif',
    color: 'success'
  },
  INACTIVE: {
    formatted: 'Tidak Aktif',
    color: 'danger'
  },
  EXPIRED: {
    formatted: 'Expired',
    color: 'info'
  },
  DISABLED: {
    formatted: 'Dinonaktifkan',
    color: 'danger'
  },
  ISSUED: {
    formatted: 'Diterbitkan',
    color: 'grey'
  }
};

export const variantStatus = {
  ACTIVE: ['ACTIVE', 'Aktif'],
  INACTIVE: ['INACTIVE', 'Dihapus']
};
