export const FILTER_LANDING_PAGE_BY_STATUS = 'FILTER_LANDING_PAGE_BY_STATUS';
export const filterLandingPageByStatus = q => ({
  type: FILTER_LANDING_PAGE_BY_STATUS,
  payload: q
});

export const FILTER_LANDING_PAGE_BY_Q = 'FILTER_LANDING_PAGE_BY_Q';
export const filterLandingPageByQ = q => ({
  type: FILTER_LANDING_PAGE_BY_Q,
  payload: q
});

export const UPDATE_LANDING_PAGES = 'UPDATE_LANDING_PAGES';
export const updateLandingPages = q => ({
  type: UPDATE_LANDING_PAGES,
  payload: q
});

export const LANDING_PAGE_DETAILS = 'LANDING_PAGE_DETAILS';
export const landingPageDetails = q => ({
  type: LANDING_PAGE_DETAILS,
  payload: q
});

export const UPDATE_LANDING_PAGES_THUMBNAIL = 'UPDATE_LANDING_PAGES_THUMBNAIL';
export const updateLandingPagesThumbnail = q => ({
  type: UPDATE_LANDING_PAGES_THUMBNAIL,
  payload: q
});

export const ADD_LANDING_PAGE_COLLECTION = 'ADD_LANDING_PAGE_COLLECTION';
export const addLandingPageCollection = q => ({
  type: ADD_LANDING_PAGE_COLLECTION,
  payload: q
});

export const EDITOR_LANDING_TAB = 'EDITOR_LANDING_TAB';
export const editorLandingTab = q => ({
  type: EDITOR_LANDING_TAB,
  payload: q
});

export const EDITOR_CONTENT_IS_CHANGED = 'EDITOR_CONTENT_IS_CHANGED';
export const editorContentIsChanged = q => ({
  type: EDITOR_CONTENT_IS_CHANGED,
  payload: q
});
