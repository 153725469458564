import moment from 'moment';
import { CURRENT_USER, IS_HAVING_ACCESS_TO_CREATE_PRODUCT } from '../actions/users';

export const currentUserReducers = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_USER:
      const isPlanStopped =
        !action?.payload?.account?.trialEndDate || moment(action.payload.account.trialEndDate).isBefore(moment());
      action.payload.account.isPlanStopped = isPlanStopped;

      return action.payload;
  }
  return state;
};

export const isHavingAccessToCreateProductReducer = (state = true, action) => {
  switch (action.type) {
    case IS_HAVING_ACCESS_TO_CREATE_PRODUCT:
      return action.payload;
  }
  return state;
};
