import { parseJSON } from './parser';
import defaultConfig from '../pages/Settings/configs/role-config';

export const sumArraysInObjectOfArray = (array = [], arrayObjectKey = '') => {
  return array.map(object => object[arrayObjectKey].length).reduce((a, b) => a + b, 0);
};

export const checkEmptyArraysInObjectOfArray = (arrays, arrayObjectKey) => {
  return sumArraysInObjectOfArray(arrays, arrayObjectKey) === 0 ? true : false;
};

export const arraysEqual = (a = [], b = []) => {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  a.forEach((v, i) => {
    if (a[i] !== b[i]) return false;
  });

  return true;
};

export const membershipPricings = {
  1: 'Per Bulan',
  3: 'Per 3 Bulan',
  6: 'Per 6 Bulan',
  12: 'Per 12 Bulan',
  'isLifetime': "Seumur hidup"
};

export const aggregateArrayOfObject = (array = [], objectKey = '', formattingFunction = null) => {
  const resultArray = [];
  array.forEach(obj => {
    let match = false;
    resultArray.forEach(objResult => {
      if (formattingFunction) {
        if (formattingFunction(obj[objectKey]) === formattingFunction(objResult[objectKey])) {
          match = true;
        }
      } else if (obj[objectKey] === objResult[objectKey]) {
        match = true;
      }
    });

    if (!match) {
      const newObj = {
        [objectKey]: obj[objectKey],
        values: [obj]
      };
      resultArray.push(newObj);
    } else {
      resultArray.forEach(objResult => {
        if (formattingFunction) {
          if (formattingFunction(obj[objectKey]) === formattingFunction(objResult[objectKey])) {
            objResult.values.push(obj);
          }
        } else if (obj[objectKey] === objResult[objectKey]) {
          objResult.values.push(obj);
        }
      });
    }
  });

  return resultArray;
};

export const reorderDragNDrop = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const alphabet = Array.from(Array(5)).map((e, i) => String.fromCharCode(i + 65));

export const convertToArrayOfObjects = data => {
  const keys = data.shift();
  let i = 0,
    k = 0,
    obj = null;
  const output = [];

  for (i = 0; i < data.length; i++) {
    obj = {};

    for (k = 0; k < keys.length; k++) {
      obj[keys[k]] = data[i][k];
    }

    output.push(obj);
  }

  return output;
};

export const getTotalAmount = arrayOfObjects => arrayOfObjects.reduce((curr, { amount }) => curr + amount, 0);

export const getLatestRolePathConfig = (userPath, property = 'route') => {
  const pathConfig = parseJSON(userPath, []);
  const latestConfig = pathConfig
    .filter(item => item.checked)
    .map(item => defaultConfig.find(config => config.name === item.name)[property])
    .filter(item => item !== undefined);

  return ['route', 'sidebarName'].includes(property) ? latestConfig.flat() : latestConfig;
};

export const getMinMaxNumberList = (list, objKey = null) => {
  // work for array or list of obj. just insert objKey for execute list of obj
  const minToMax = [0, 0];

  if (objKey) {
    list.forEach(item => {
      minToMax[0] = minToMax[0] || item[objKey];
      minToMax[0] = item[objKey] < minToMax[0] ? item[objKey] : minToMax[0];
      minToMax[1] = item[objKey] > minToMax[1] ? item[objKey] : minToMax[1];
    });
    return minToMax;
  }

  list.forEach(item => {
    minToMax[0] = item < minToMax[0] ? item : minToMax[0];
    minToMax[1] = item > minToMax[1] ? item : minToMax[1];
  });

  return minToMax;
};
