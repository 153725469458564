import './globals';
import './methods';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import moment from 'moment';
import idLocale from 'moment/locale/id';

moment.locale('id');
moment.updateLocale('id', [idLocale]);

ReactDOM.render(<App />, document.getElementById('app'));
