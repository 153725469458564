import { gql } from '@apollo/client';

export const GET_PAYMENT_LINK = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        amount
        status
        description
        createdAt
        customer {
          name
          email
        }
        installment {
          id
          interest
          invoices {
            id
            status
            updatedAt
          }
        }
      }
    }
  }
`;

// Get payment link for table installment
export const GET_PAYMENT_LINK_INSTALLMENT = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        index
        link
        status
        expiredAt
        amount
        interestAmount
        remainingAmount
      }
      nextPage
    }
  }
`;
