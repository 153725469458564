export const parseJSON = (value, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};
