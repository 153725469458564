import { SET_LANDING_PROMOTION, UPDATE_LANDING_PROMOTION } from '../actions/landing-promotion';

export const landingPromotionReducer = (state = null, action) => {
  switch (action.type) {
    case SET_LANDING_PROMOTION:
      return action.payload;
    case UPDATE_LANDING_PROMOTION:
      if (state) {
        return { ...state, ...action.payload };
      }
      return action.payload;
    default:
      return state;
  }
};
