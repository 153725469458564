import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addLandingPageCollection } from '../../actions/landing-page';
const previewDefault = `${process.env.REACT_APP_MAYAR_USERKV_S3_ENDPOINT}/landing-page/images/blank-template.jpg`;

const UnlayerTemplates = ({ selectedTemplate, setSelectedTemplate, isUpdate, title, isBroadcast }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);

  const getAllCollections = async () => {
    setLoading(true);
    const { data: allCollections } = await axios.get(
      `${process.env.REACT_APP_MAYAR_USERKV_S3_ENDPOINT}/landing-page/index.json`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    );
    const pathCollections = allCollections.filter(collection =>
      collection.flag.includes(isBroadcast ? 'BROADCAST' : 'LANDING_PAGE')
    );
    await Promise.all(
      pathCollections.map(async collection => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_MAYAR_USERKV_S3_ENDPOINT}/landing-page/${collection.title}.json`,
          {
            headers: { 'Cache-Control': 'no-cache' }
          }
        );

        setCollections(prevState => [...prevState, data]);
        dispatch(addLandingPageCollection(data));
        setLoading(false);

        return data;
      })
    );
  };

  useEffect(() => {
    getAllCollections();
  }, [isBroadcast]);

  return (
    <div>
      <h4 className="mb-10 mt-15">{title}</h4>
      {loading ? (
        <Row className="d-flex flex-column align-items-center">
          <Spinner className="mt-20 mb-10" />
          <h5 className="mb-20">Sedang memuat template...</h5>
        </Row>
      ) : (
        <Row className='overflow-auto' style={{"maxHeight" : "400pt"}}>
          {!isUpdate && (
            <Col xs="6" className="mb-25">
              <div>
                <img src={previewDefault} alt="thumb" className="mt-5 mb-5 img-fluid img-thumbnail rounded" />
              </div>
              <div>
                <Button
                  block
                  color="primary"
                  className="text-center"
                  onClick={() => setSelectedTemplate('default')}
                  disabled={selectedTemplate === 'default'}
                >
                  {selectedTemplate === 'default' ? 'Terpilih' : 'Pilih'}
                </Button>
              </div>
            </Col>
          )}

          {collections.map((item, index) => (
            <Col xs="6" key={index} className="mb-25">
              <div>
                <img src={item.img} alt="thumb" className="mt-5 mb-5 img-fluid img-thumbnail rounded" />
              </div>
              <div>
                <Button
                  block
                  color="primary"
                  className="text-center mb-0"
                  onClick={() => setSelectedTemplate(item.title)}
                  disabled={selectedTemplate === item.title}
                >
                  {selectedTemplate === item.title ? 'Terpilih' : 'Pilih'}
                </Button>

                <a
                  href={item.linkPreview}
                  className="btn btn-outline-secondary btn-block text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lihat
                </a>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

UnlayerTemplates.defaultProps = {
  title: 'Template',
  isUpdate: false
};

export default UnlayerTemplates;
