export const FILTER_PAYMENT_BY_CUSTOMER = 'FILTER_PAYMENT_BY_CUSTOMER';
export const filterPaymentByCustomer = q => ({
  type: FILTER_PAYMENT_BY_CUSTOMER,
  payload: q
});

export const FILTER_PAYMENT_BY_STATUS = 'FILTER_PAYMENT_BY_STATUS';
export const filterPaymentByStatus = q => ({
  type: FILTER_PAYMENT_BY_STATUS,
  payload: q
});

export const FILTER_PAYMENT_BY_TYPE = 'FILTER_PAYMENT_BY_TYPE';
export const filterPaymentByType = q => ({
  type: FILTER_PAYMENT_BY_TYPE,
  payload: q
});


export const FILTER_PAYMENT_START_DATE = 'FILTER_PAYMENT_START_DATE';
export const filterPaymentStartDate = q => ({
  type: FILTER_PAYMENT_START_DATE,
  payload: q
});

export const FILTER_PAYMENT_END_DATE = 'FILTER_PAYMENT_END_DATE';
export const filterPaymentEndDate = q => ({
  type: FILTER_PAYMENT_END_DATE,
  payload: q
});
