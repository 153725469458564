export const FILTER_MEMBERSHIP_BY_Q = 'FILTER_MEMBERSHIP_BY_Q';
export const filterMembershipByQ = (q) => ({
  type: FILTER_MEMBERSHIP_BY_Q,
  payload: q,
});

export const UPDATE_MEMBERSHIPS = 'UPDATE_MEMBERSHIPS';
export const updateMemberships = (q) => ({
  type: UPDATE_MEMBERSHIPS,
  payload: q,
});

export const FILTER_MEMBERSHIP_BY_STATUS = 'FILTER_MEMBERSHIP_BY_STATUS';
export const filterMembershipByStatus = (q) => ({
  type: FILTER_MEMBERSHIP_BY_STATUS,
  payload: q,
});

export const MEMBERSHIP_PAGES = 'MEMBERSHIP_PAGES';
export const membershipPages = (q) => ({
  type: MEMBERSHIP_PAGES,
  payload: q,
});
