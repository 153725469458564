import { gql } from '@apollo/client';

export const GET_VA_FILTERED = gql`
    query getVirtualAccountFilteredList($search: VirtualAccountSearchInput) {
        getVirtualAccountFilteredList(search: $search) {
            status
            vaNumber
        }
    }
`;
