import { gql } from '@apollo/client';

export const GQL_EMAIL_SETTING = gql`
  query getNotificationSettingPage(
    $page: Int
    $pageSize: Int
    $searchAny: NotificationSettingSearchInput
    $search: NotificationSettingSearchInput
  ) {
    getNotificationSettingPage(page: $page, pageSize: $pageSize, searchAny: $searchAny, search: $search) {
      items {
        id
        userId
        enabled
        key
      }
    }
  }
`;
export const GQL_EMAIL_SETTING_UPDATE = gql`
  mutation editNotificationSetting($input: NotificationSettingInput) {
    editNotificationSetting(input: $input) {
      id
      enabled
    }
  }
`;

export const GQL_EMAIL_SETTING_INSERT = gql`
  mutation insertNotificationSetting($input: NotificationSettingInput) {
    insertNotificationSetting(input: $input) {
      id
      enabled
    }
  }
`;
