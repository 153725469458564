/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';
import LoadingAnimation from '../../components/loading-animation';

const Dashboard = () => {
  const currentUser = useSelector(state => state.currentUser) || {};
  const { search } = useLocation();
  const history = useHistory();
  const isAccountIncomplete = !(currentUser?.id && currentUser?.account && currentUser?.account?.xenditUserId);

  useEffect(() => {
    if (currentUser?.isSignUpFailed) {
      history.push('sign-up-failed');
    } else if (currentUser?.id && isAccountIncomplete) {
      history.push(`/billing${search}`);
    }
  }, [currentUser]);

  if (!currentUser.id) return <LoadingAnimation />;

  return (
    <PageWrap>
      <PageTitle className="d-none d-sm-block" breadcrumbs={{ '/': currentUser?.name || ' ' }}>
        <Row className="mb-0">
          <Col md="4">
            <h1>Beranda</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default Dashboard;
