import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  Modal,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Button
} from 'reactstrap';
import { GQL_KYC_UPDATE, GQL_UPDATE_XENDIT_ACCOUNT, GQL_UPLOAD_IMAGE, GQL_ACCOUNT_UPDATE_PROXY } from '../../../gqls';
import { UPDATE_PAYME_USER, UPDATE_USER } from '../modal-onboard/graphql';

import { validator, slug, getBase64 } from '../../../utils';
import { currentUser as updateCurrentUser } from '../../../actions/users';
import SubmitButton from '../../submit-button';
import MayarAlert from '../../alert/alert';

const ModalActivateMerchantMode = ({ isOpen, handleToggle, handleChangeMode }) => {
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();
  const [editUser, { loading: userLoading }] = useMutation(UPDATE_USER);
  const [editAccount, { loading: accountLoading }] = useMutation(GQL_ACCOUNT_UPDATE_PROXY);
  const [updateKYC] = useMutation(GQL_KYC_UPDATE);
  const [updateXendit, { loading: xenditLoading }] = useMutation(GQL_UPDATE_XENDIT_ACCOUNT);
  const [updatePaymeLink, { loading: updatePaymeLoading }] = useMutation(UPDATE_PAYME_USER, {
    refetchQueries: []
  });
  const [uploadImage, { loading: uploadLoading }] = useMutation(GQL_UPLOAD_IMAGE);

  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async values => {
    const paymeLink = values.paymeLink.split(' ').join('');
    let resLogo = {};

    try {
      const variablesUser = {
        input: {
          name: values.name,
          id: currentUser.id,
          isMerchantModeDisabled: false
        }
      };
      const variablesPayme = {
        input: { id: currentUser.id, paymeLink: slug(paymeLink, { custom: { _: '-' } }), isCreateAnyway: true }
      };

      if (values.logo?.length > 0) {
        const base64 = await getBase64(values.logo[0]);
        const variablesLogo = { base64, fileType: values.logo[0].type.split('/')[1], title: currentUser.accountId };

        //upload logo
        resLogo = await uploadImage({ variables: variablesLogo });
      }

      delete values.logo;
      delete values.paymeLink;
      const variablesAccount = {
        input: { ...values, imageId: resLogo?.data?.uploadImage?.id, id: currentUser.accountId }
      };

      //segment tracking
      window.analytics.track('Upload Logo');

      await updatePaymeLink({ variables: variablesPayme });
      await editUser({ variables: variablesUser });
      await editAccount({ variables: variablesAccount });
      await updateXendit({ variables: { name: values.name } });
      if (currentUser.kyc) {
        await updateKYC({
          variables: {
            input: {
              id: currentUser.kyc.id,
              status: 'revision',
              type: null
            }
          }
        });
      }

      const updatedUser = await axios.get(`${process.env.REACT_APP_PROXY}/me`, { withCredentials: true });
      dispatch(updateCurrentUser({ ...updatedUser.data }));

      // go to verification
      handleChangeMode('merchant');
      window.location.href = '/settings?active=verification';
      handleToggle();
    } catch (err) {
      setSubmitError(true);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} fade>
      <ModalBody>
        <div className="text-center">
          <img src="./mayar-ss1.png" className="img-fluid imgwelcome" alt="mayar ss" />
          <h1 className="h2 mt-25">Ingin bergabung menjadi merchant di Mayar ?</h1>

          <p>
            Anda dapat membuat berbagai macam product dan fitur yang ada pada mode Merchant, perlu diketahui anda harus
            melakukan proses KYC kembali untuk bisa berjualan.
          </p>
          <p>Lengkapi detail bisnis atau institusi anda dibawah ini:</p>
        </div>

        <MayarAlert isError={submitError} />

        <Form onSubmit={handleSubmit(onSubmit)} className="mt-15">
          <FormGroup>
            <Input
              id="name"
              name="name"
              bsSize="lg"
              type="text"
              placeholder={'Nama Bisnis atau Institusi'}
              invalid={Boolean(errors.name)}
              innerRef={register({ required: validator.required, minLength: validator.minLength(3) })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <Input
                defaultValue={currentUser?.paymeLink}
                type="text"
                bsSize="lg"
                name="paymeLink"
                placeholder="Username-bisnis"
                innerRef={register({
                  required: validator.required,
                  minLength: validator.minLength(5),
                  pattern: validator.pattern(/^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/)
                })}
                onChange={() => {
                  setErrorMessage('');
                }}
                invalid={Boolean(errors.paymeLink) || errorMessage !== '' ? true : false}
              />
              <InputGroupText className="input-group-append">.myr.id</InputGroupText>
            </InputGroup>
            <FormFeedback>{errors.paymeLink?.message || errorMessage}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Input
              id="monthlyTurnover"
              name="monthlyTurnover"
              type="select"
              innerRef={register({ required: validator.required })}
              invalid={Boolean(errors.monthlyTurnover)}
            >
              <option value="">-- Omzet Bulanan--</option>
              <option value="0 - 10jt">0 - 10jt</option>
              <option value="10jt - 50jt">10jt - 50jt</option>
              <option value="50jt - 100jt">50jt - 100jt</option>
              <option value="100jt - 250jt">100jt - 250jt</option>
              <option value="250jt - 500jt">250jt - 500jt</option>
              <option value=">500jt">{`>500jt`}</option>
            </Input>
            <FormFeedback>{errors.monthlyTurnover?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              id="offeredService"
              name="offeredService"
              bsSize="lg"
              type="text"
              placeholder="Produk/Jasa yang ditawarkan"
              invalid={Boolean(errors.offeredService)}
              innerRef={register({ required: validator.required })}
            />
            <FormFeedback>{errors.offeredService?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              id="totalEmployees"
              name="totalEmployees"
              type="select"
              invalid={Boolean(errors.totalEmployees)}
              innerRef={register({ required: validator.required })}
            >
              <option value="">-- Jumlah Karyawan--</option>
              <option value="1-5">1-5</option>
              <option value="6-10">6-10</option>
              <option value="11-50">11-50</option>
              <option value="51-100">51-100</option>
              <option value="101-300">101-300</option>
              <option value=">300">{`>300`}</option>
            </Input>
            <FormFeedback>{errors.totalEmployees?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Upload Logo (opsional)</Label>
            <Input
              type="file"
              name="logo"
              id="logo"
              className="form-control-file"
              bsSize="lg"
              innerRef={register()}
              invalid={Boolean(errors.logo)}
            />
            <FormFeedback>{errors.logo?.message}</FormFeedback>
          </FormGroup>
          <div className="d-flex gap-5 mt-30">
            <Button className="text-center" onClick={handleToggle} size="lg" block outline color="danger">
              Batal
            </Button>
            <SubmitButton
              size="lg"
              type="submit"
              outline
              color="primary"
              className="text-center mt-0"
              block
              text="Simpan"
              loading
              isLoading={userLoading || accountLoading || xenditLoading || updatePaymeLoading || uploadLoading}
            />
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalActivateMerchantMode;
