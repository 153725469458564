import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { EditorState } from 'draft-js';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
} from 'reactstrap';
import _ from 'lodash';
import {
  GQL_PAYMENTLINK_CREATE_BY_SLUG,
  GQL_PAYMENTLINK_UPDATE_BY_SLUG,
  GQL_GET_GENRES
} from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  sanitizeDOM,
  REGEX_SLUG,
  REGEX_BAHASA,
  paymentTypeOptions,
  slug,
  getInitialDownloadableValue,
  writingTypeOptions,
  writingPaymentMethodOptions,
  toCapitalizeEachText
} from '../../utils';
import { updatePaymentLinks } from '../../actions/payment-links';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import Icon from '../icon';
import DatePicker from '../date-time-picker';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import MultipleImageInput from "../multiple-image-input";

const ModalBodyWriting = ({ isOpen = false, onClose, onSuccess, data }) => {
  const stateEdit = EditorState.createEmpty();
  const [paymentType, setPaymentType] = useState(data?.paymentType || 'paid');
  const [isDownloadable] = useState(() => getInitialDownloadableValue(data));

  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const [writingType, setWritingType] = useState(data?.writingType || "");
  const [writingPaymentMethod, setWritingPaymentMethod] = useState(data?.writingPaymentMethod || "");
  const [genreId, setGenreId] = useState(data?.metadata?.genreId || "")

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);
  
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newpl }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: updateLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);

  const { data: getGenres } = useQuery(GQL_GET_GENRES, {
    variables: {
      search: {
        name: [{operator: "notEmpty"}]
      }
    },
    fetchPolicy: 'network-only'
  });

  const genres = _.get(getGenres, 'getGenreFilteredList', []);
  let genreOptions = []
  if (genres.length) {
    genreOptions = genres.map((opt) => {
      return {
        value: opt.id,
        label: toCapitalizeEachText(opt.name)
      }
    })
  }

  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [publish] = useState(null);
  const [link, setLink] = useState(data ? data.link : '');
  const dispatch = useDispatch();
  const isUpdate = !data ? false : true;

  if (!data) data = {};

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    delete values.fileSource;
    if (!values.limit) {
      delete values.limit;
    } else {
      values.limit = parseInt(values.limit);
    }
    if (values.pages) values.pages = parseInt(values.pages);
    if (values.amount) values.amount = parseFloat(values.amount);
    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) values.amount = 0;

    if (date !== null) setDate(date);
    const metadata = {
      pages: values.pages || null,
      author: values.author,
      ISBN: values.isbn,
      format: values.format,
      language: values.language,
      publishedAt: publish,
      ...(genreId && { genreId })
    };
    delete values.author;
    delete values.pages;
    delete values.isbn;
    delete values.format;
    delete values.language;
    delete values.genreId;
    try {
      if (isUpdate) {
        values.id = data.id;

        await editPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              ...(isRemoveAllProductImage && { multipleImageId: null }),
              metadata,
              status: 'active',
              expiredAt: date,
              description: sanitizeDOM(description),
              isDownloadable: isDownloadable
            }
          }
        });

        dispatch(updatePaymentLinks(Math.random()));
      } else {
        await insertPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              metadata,
              expiredAt: date,
              link: slug(values.name),
              type: 'writing',
              status: 'active',
              description: sanitizeDOM(description),
              isDownloadable: isDownloadable
            }
          }
        });

        //segment tracking
        window.analytics.track('Create Writing', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Writing - ERROR', values);
    }
  };

  useEffect(() => {
    if (newpl && newpl.insertPaymentLinkBySlug) onSuccess(newpl.insertPaymentLinkBySlug.id);
  }, [newpl]);

  const isCouldHavePrice = (writingType === "ONE_SHOT") || (["PACKAGE", "ALL"].includes(writingPaymentMethod));
  const priceWordingChapter = writingPaymentMethod === "ALL" ? "Satu Paket" : "Semua Chapter"

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Tulisan</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>Menjual karya / tulisan anda semakin mudah dengan sistem otomatis Mayar</small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Judul Tulisan*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          {!isUpdate && (
            <FormGroup>
              <Label for="writingType">Tipe Tulisan</Label>
              <Controller
                name="writingType"
                control={control}
                defaultValue={data?.writingType}
                rules={{ required: validator.required }}
                render={({ onChange, name, value: defaultPaymentType }) => (
                  <Select
                    id={name}
                    name={name}
                    options={writingTypeOptions}
                    defaultValue={writingTypeOptions?.find(obj => obj.value === defaultPaymentType)}
                    placeholder="Pilih Tipe Tulisan"
                    styles={!!errors.writingType ? CustomStylesInvalid : CustomStyles}
                    className={`${!!errors.writingType ? 'is-invalid' : ''}`}
                    onChange={({ value }) => {
                      setWritingType(value);
                      onChange(value);
                    }}
                  />
                )}
              />
              <FormFeedback>{errors.writingType?.message}</FormFeedback>
            </FormGroup>
          )}
          {/* JIKA TIDAK GRATIS DAN JIKA PILIH TIPE CHAPTER, MAKA MUNCUL MEKANISME BAYAR */}
          {writingType === "CHAPTER" && !isUpdate && (
            <FormGroup>
              <Label for="writingPaymentMethod">Mekanisme Bayar</Label>
              <Controller
                name="writingPaymentMethod"
                control={control}
                defaultValue={writingPaymentMethod}
                rules={{ required: validator.required }}
                render={({ onChange, name, value: defaultWritingPaymentMethod }) => (
                  <Select
                    id={name}
                    name={name}
                    options={writingPaymentMethodOptions}
                    defaultValue={writingPaymentMethodOptions?.find(obj => obj.value === defaultWritingPaymentMethod)}
                    placeholder="Pilih Mekanisme Bayar"
                    styles={!!errors.writingPaymentMethod ? CustomStylesInvalid : CustomStyles}
                    className={`${!!errors.writingPaymentMethod ? 'is-invalid' : ''}`}
                    onChange={({ value }) => {
                      setWritingPaymentMethod(value);
                      onChange(value);
                    }}
                  />
                )}
              />
              <FormFeedback>{errors.writingPaymentMethod?.message}</FormFeedback>
            </FormGroup>
          )}
          {isCouldHavePrice && (
            <>
              <FormGroup>
                <Label for="paymentType">Tipe Pembayaran</Label>
                <Controller
                  name="paymentType"
                  control={control}
                  defaultValue={paymentType}
                  render={({ onChange, name, value: defaultPaymentType }) => (
                    <Select
                      id={name}
                      name={name}
                      options={paymentTypeOptions}
                      defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                      placeholder="Pilih Tipe Pembayaran"
                      styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                      className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                      onChange={({ value }) => {
                        setPaymentType(value);
                        onChange(value);
                      }}
                    />
                  )}
                />
              </FormGroup>
              {paymentType === 'paid' && (
                <FormGroup>
                  <Label for="amount">
                    Harga{writingType === "CHAPTER" && " " + priceWordingChapter}*
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      invalid={errors.amount}
                      defaultValue={data.amount}
                      innerRef={register({
                        required: validator.required,
                        min: validator.min(1000),
                        max: validator.max(100_000_000)
                      })}
                    />
                    <FormFeedback>{errors.amount?.message}</FormFeedback>
                  </InputGroup>
                  <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small><br />
                  {writingPaymentMethod === "PACKAGE" && (
                    <small>Harga Per-chapter dapat anda atur saat pembuatan chatper nanti</small>
                  )}
                </FormGroup>
              )}
              {paymentType === 'voluntary' && (
                <FormGroup>
                  <Label for="amount">Minimal Harga</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      invalid={errors.amount}
                      placeholder="0+"
                      defaultValue={data.amount}
                      innerRef={register({
                        // required: validator.required,
                        min: validator.min(0),
                        max: validator.max(100000000)
                      })}
                    />
                    <FormFeedback>{errors.amount?.message}</FormFeedback>
                  </InputGroup>
                  <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small><br />
                  {writingPaymentMethod === "PACKAGE" && (
                    <small>Harga Per-chapter dapat anda atur saat pembuatan chatper nanti</small>
                  )}
                </FormGroup>
              )}
            </>
          )}
          <FormGroup>
            <Label for="description">Deskripsi / Teaser*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />
          
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="kadaluarsa4">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <DatePicker
                placeholder="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>
          {((writingType === "CHAPTER" && writingPaymentMethod === "ALL") || writingType === "ONE_SHOT") && (
            <FormGroup>
              <Label for="limit">Maks. Jumlah Pembayaran (Kuota Untuk Pembayaran Satu Paket)</Label>
              <Input
                type="number"
                name="limit"
                id="limit"
                invalid={errors.limit}
                defaultValue={data.limit}
                innerRef={register()}
              />
              <FormFeedback>{errors?.limit?.message}</FormFeedback>
              <small>
                Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
                jumlah (unlimited)
              </small>
            </FormGroup>
          )}

          <hr />
          <h4 className="mt-15">Detail Tambahan (opsional)</h4>

          <FormGroup>
            <Label for="genreId">Genre</Label>
            <Controller
              name="genreId"
              control={control}
              defaultValue={data?.metadata?.genreId}
              // rules={{ required: validator.required }}
              render={({ onChange, name, value: defaultGenre }) => (
                <Select
                  id={name}
                  name={name}
                  options={genreOptions}
                  defaultValue={genreOptions?.find(obj => obj.value === defaultGenre)}
                  placeholder="Pilih Genre Tulisan"
                  styles={!!errors.genreId ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.genreId ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setGenreId(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="author">Author</Label>
            <Input
              type="text"
              name="author"
              id="author"
              invalid={errors.author}
              defaultValue={data.metadata?.author}
              innerRef={register({
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.author?.message}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="language">Bahasa</Label>
            <Input
              type="text"
              name="language"
              id="language"
              invalid={errors.language}
              defaultValue={data.metadata?.language}
              innerRef={register({
                minLength: validator.minLength(4),
                pattern: validator.pattern(REGEX_BAHASA)
              })}
            />
            <FormFeedback>{errors.language?.message}</FormFeedback>
          </FormGroup>
          
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Tulisan`}
            loading
            isLoading={insertLoading || updateLoading}
            isDisabled={disableSubmitButton}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyWriting;
