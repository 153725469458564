import { LINKTREE_TAB, LINK_TREE } from '../actions/linktree';

export const linkTreeTabReducer = (state = '', action) => {
  switch (action.type) {
    case LINKTREE_TAB:
      return action.payload;
  }
  return state;
};

export const linkTreeReducer = (state = '', action) => {
  switch (action.type) {
    case LINK_TREE:
      return action.payload;
  }
  return state;
};
