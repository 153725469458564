import { gql } from '@apollo/client';

export const GQL_CAPITAL_CREATE = gql`
  mutation insertCapital($input: CapitalInput) {
    insertCapital(input: $input) {
      id
      amount
      status
      createdAt
      updatedAt
      userId
      loanPeriod
    }
  }
`;

export const SEND_CAPITAL_MAILER_QUERY = gql`
  query sendCapitalMailer($capitalId: ID) {
    sendCapitalMailer(capitalId: $capitalId) {
      id
      amount
      status
      loanPeriod
    }
  }
`;

export const GQL_KYC_UPDATE = gql`
  mutation editCapital($input: CapitalInput) {
    editCapital(input: $input) {
      id
      amount
      status
    }
  }
`;
