import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { Button, Alert, Row, Col } from 'reactstrap';
import { bankCodes, formatRp, getTotalAmount, getDisbursementFee } from '../../utils';
import { ewalletCodes } from '../../utils/ewallet-codes';
import MayarAlert from '../alert/alert';
import SubmitButton from '../submit-button';

const textByDisbursementType = {
  BATCH_PAYROLL: 'Payroll',
  TRANSFER: 'Kirim Uang /Top Up',
  BATCH_TRANSFER: 'Kirim Uang /Top Up',
  INVOICE: 'Pembayaran Tagihan'
};

const bankConfigs = bankCodes.concat(ewalletCodes);

const OTPConfirmation = ({
  data,
  onClose,
  onSubmitOTP,
  onRequestOTP,
  disbursementType,
  submitError,
  resetSubmitError
}) => {
  // const disbursementFee = parseFloat(`${process.env.REACT_APP_MAYAR_DISBURSEMENT_FEE}`);
  const [disbursementFee] = useState(() => getDisbursementFee());

  /* OTP STATE */
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [otpInput, setOtpInput] = useState('');
  const [sentOtpSuccess, setSentOtpSuccess] = useState(false);
  const [counter, setCounter] = useState(900); // 15 minutes
  const [resendCounter, setResendCounter] = useState(180); // 3 minutes
  const [startCounting, setStartCounting] = useState(false);
  /* TIMER */
  const [restartTimer, setRestartTimer] = useState(false);
  const [displayCountdown, setDisplayCountdown] = useState('');
  const [displayResendCounter, setDisplayResendCounter] = useState('');

  const formatSecond = s => Math.floor((s % 3600) % 60);
  const formatMinutes = m => Math.floor((m % 3600) / 60);

  /* 15 minutes counter */
  useEffect(() => {
    if (startCounting) {
      let minutes = 0;
      let formattedSeconds = 0;

      // Format seconds to minutes
      if (counter > 60) {
        minutes = formatMinutes(counter);
      } else {
        minutes = 0;
      }

      if (counter < 60) {
        formattedSeconds = counter;
      } else {
        formattedSeconds = formatSecond(counter);
      }

      let timer;
      if (counter >= 0) {
        if (restartTimer) {
          setCounter(900);
          setRestartTimer(false);
        }
        timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if (counter % 60 === 0) {
          setDisplayCountdown(`${counter / 60} menit`);
        } else if (counter > 59) {
          setDisplayCountdown(`${minutes} menit ${formattedSeconds} detik`);
        } else {
          setDisplayCountdown(`${formattedSeconds} detik`);
        }
      }
      return () => clearInterval(timer);
    }
  }, [counter, startCounting, restartTimer]);

  // 3 Minutes counter
  useEffect(() => {
    if (startCounting) {
      let minutes = 0;
      let formattedSeconds = 0;

      if (resendCounter > 60) {
        minutes = formatMinutes(resendCounter);
      } else {
        minutes = 0;
      }

      if (resendCounter < 60) {
        formattedSeconds = resendCounter;
      } else {
        formattedSeconds = formatSecond(resendCounter);
      }

      let timer;
      if (resendCounter >= 0) {
        if (restartTimer) {
          setResendCounter(180);
          setRestartTimer(false);
        }
        timer = resendCounter > 0 && setInterval(() => setResendCounter(resendCounter - 1), 1000);
        if (resendCounter % 60 === 0) {
          setDisplayResendCounter(`${resendCounter / 60} menit`);
        } else if (resendCounter > 59) {
          setDisplayResendCounter(`${minutes} menit ${formattedSeconds} detik`);
        } else {
          setDisplayResendCounter(`${formattedSeconds} detik`);
        }
      }
      return () => clearInterval(timer);
    }
  }, [resendCounter, startCounting, restartTimer]);

  const initFocus = document.querySelector(`input[id=input0]`);

  useEffect(() => {
    if (initFocus) initFocus.focus();
  }, [initFocus]);

  useEffect(() => {
    setStartCounting(true);

    // Dismiss alert after 4 seconds
    setSentOtpSuccess(true);
    setTimeout(() => {
      setSentOtpSuccess(false);
    }, 6000);
  }, []);

  const handleConfirmOTP = async () => {
    setIsLoading(true);

    try {
      const response = await onSubmitOTP(otpInput);

      // disable confirm button when otp is blocked because brute attack
      if (response?.status === 'BLOCKED') {
        setIsDisabledButton(true);
        setTimeout(() => {
          resetSubmitError();
          setIsDisabledButton(false);
        }, response?.blockTime * 1000);
      }
    } catch (err) {
      setOtpInput('');
    }

    setIsLoading(false);
  };

  const handleResendOTP = () => {
    onRequestOTP();
    setRestartTimer(true);

    setSentOtpSuccess(true);
    setTimeout(() => {
      setSentOtpSuccess(false);
    }, 4000);
  };

  return (
    <div>
      <span>Berikut adalah detail dari {textByDisbursementType[disbursementType]} Anda:</span>
      <table>
        {/^(TRANSFER|INVOICE)$/.test(disbursementType) ? (
          <tbody>
            <tr>
              <td>Jumlah</td>
              <td>:</td>
              <td>
                <b>{formatRp(data[0]?.amount)}</b>
              </td>
            </tr>
            <tr>
              <td>Biaya</td>
              <td>:</td>
              <td>
                <b>{formatRp(disbursementFee)}</b>
              </td>
            </tr>
            <tr>
              <td>Nama Bank/E-Wallet</td>
              <td>:</td>
              <td>
                <b>{bankConfigs.find(b => b.BankCode === data[0]?.bank)?.Bank || '-'}</b>
              </td>
            </tr>
            <tr>
              <td>No Rekening/E-Wallet</td>
              <td>:</td>
              <td>
                <b>{data[0]?.accountNumber}</b>
              </td>
            </tr>
            <tr>
              <td>Nama Penerima</td>
              <td>:</td>
              <td>
                <b>{data[0]?.accountName}</b>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Total Transfer</td>
              <td>:</td>
              <td>
                <b>{formatRp(getTotalAmount(data))}</b>
              </td>
            </tr>
            <tr>
              <td>Total Biaya Transfer</td>
              <td>:</td>
              <td>
                <b>{formatRp(disbursementFee * data.length)}</b>
              </td>
            </tr>
            <tr>
              <td>Jumlah Transfer</td>
              <td>:</td>
              <td>
                <b>
                  {data.length} {disbursementType === 'BATCH_PAYROLL' ? 'Karyawan' : ''}
                </b>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <br />

      {/* Show it for 4 seconds when otp is sent */}
      {sentOtpSuccess && (
        <Alert color="success" className="mb-20">
          Kode berhasil dikirim. Silahkan periksa Email Anda.
        </Alert>
      )}
      <MayarAlert isError={Boolean(submitError?.status)} message={submitError?.message} className="mb-20" />

      <p>
        Kami telah mengirimkan 6 digit kode ke email Anda. Batas kode berlaku dalam{' '}
        <span className="font-weight-bold">{displayCountdown}</span>.
      </p>

      <p>
        [!!] Harap Periksa kembali Bank, Nama Pemilik Rekening dan Nomor Rekening, Mayar tidak bertanggung jawab atas
        salah transfer yang diakibatkan dari salah input detail penerima.
      </p>

      {
        /* Check if otp is expired [15 minutes timer] */
        counter > 0 ? (
          <div className="container mb-25">
            <div className="w-90">
              <OtpInput
                value={otpInput}
                onChange={setOtpInput}
                numInputs={6}
                inputStyle={{ width: '75%', height: '40px' }}
                shouldAutoFocus={true}
                isInputNum={true}
              />
            </div>
          </div>
        ) : (
          <Alert color="danger" className="mb-20">
            Waktu habis. Silahkan kirim ulang kode.
          </Alert>
        )
      }

      {/* Only allow user to resend after 3 minutes waiting */}
      <p>
        Tidak mendapatkan kode ?{' '}
        <Button
          className={`p-0 ${resendCounter > 0 ? 'cursor-not-allowed text-danger' : 'text-primary'}`}
          color="link"
          onClick={handleResendOTP}
          disabled={resendCounter > 0}
        >
          Kirim ulang.
        </Button>
        {resendCounter > 0 && (
          <span>
            dalam <span className="font-weight-bold">{displayResendCounter}</span>.
          </span>
        )}
      </p>

      <Row className="pt-5">
        <Col xs="6">
          <SubmitButton
            size="lg"
            block
            loading
            color="primary"
            onClick={handleConfirmOTP}
            isLoading={isLoading}
            isDisabled={isDisabledButton}
            className={`text-center ${isLoading || isDisabledButton ? 'cursor-not-allowed' : ''}`}
            text="Konfirmasi"
          />
        </Col>
        <Col xs="6">
          <SubmitButton
            size="lg"
            block
            outline
            color="primary"
            onClick={onClose}
            isDisabled={isLoading}
            className={`text-center ${isLoading ? 'cursor-not-allowed' : ''}`}
            text={disbursementType === 'BATCH_PAYROLL' ? 'Batal' : 'Kembali'}
          />
        </Col>
      </Row>
    </div>
  );
};

OTPConfirmation.defaultProps = {
  data: [],
  disbursementType: 'TRANSFER',
  submitError: { status: false, message: '' },
  resetSubmitError: () => {}
};

export default OTPConfirmation;
