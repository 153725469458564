/* eslint-disable no-unused-expressions */
import React from 'react';
import moment from 'moment';
import { isJSON } from './formatter';
import { parseJSON } from './parser';

const getValueByKey = (customField, key) => {
  if (!customField) return '-';

  const customFields = JSON.parse(customField);
  const currentField = customFields?.find(obj => obj.key === key) || {};
  const { fieldType, value } = currentField;
  let response = '-';

  const options = Array.isArray(value) ? value : [];

  if (options.length > 0) {
    response = '';

    if (fieldType === 'checkbox') {
      options?.forEach(option => {
        response = response + `${option.selected ? 'Ya' : 'Tidak'}`;
      });
    } else {
      options?.forEach(option => {
        if (option.selected) response = response + `${option.name}`;
      });
    }
  } else {
    response = value || '-';
  }

  return response;
};

/**
 * Function to append columns on custom field data to table
 * @param {Array} columns columns data from table
 * @param {Array} customForm custom form structure
 * @param {Boolean} pltPath special path
 * @param {number} indexSplice custom index field number
 */
export const addCustomFormColumns = (columns = [], customForm = [], pltPath, indexSplice) => {
  customForm.forEach(({ name, key, type }, idx) => {
    const findExistingField = columns.find(col => col.key === key);
    if (!findExistingField) {
      const column = {
        name,
        // selector: name,
        key,
        cell: row => {
          let fieldData = row.customeField?.data;

          if (pltPath) {
            fieldData = row.paymentLinkTransaction.customeField?.data;
          }

          const value = getValueByKey(fieldData, key);
          let response = value;

          switch (type) {
            case 'url':
              response =
                value !== '-' ? (
                  <div data-tag="___react-data-table-allow-propagation___" className="text-primary">
                    <a href={value} target="_blank" rel="noopener noreferrer" className="text-primary">
                      {value}
                    </a>
                  </div>
                ) : (
                  value
                );
              break;
            case 'datetime':
              response = value !== '-' ? moment(value).format('D MMM YYYY') : value;
              break;
            default:
              break;
          }

          return response;
        },
        cellExport: row => {
          let fieldData = row.customeField?.data;

          if (pltPath) {
            fieldData = row.paymentLinkTransaction.customeField?.data;
          }

          const value = getValueByKey(fieldData, key);
          let response = value;

          switch (type) {
            case 'datetime':
              response = value !== '-' ? moment(value).format('D MMM YYYY') : value;
              break;
            default:
              break;
          }

          return response;
        }
      };

      if (indexSplice) columns?.splice(indexSplice + idx, 0, column);
      else columns?.push(column);
    }
  });
};

export const addUrlTrackingColumns = (columns, urlParamTracking) => {
  if (isJSON(urlParamTracking)) {
    parseJSON(urlParamTracking, []).forEach(param => {
      const column = {
        name: param,
        cell: row => {
          const trackingData = row.paymentLinkTransaction
            ? row.paymentLinkTransaction.urlParamTrackingData
            : row.urlParamTrackingData;

          let value = parseJSON(trackingData, {})?.[param] || '-';
          if (!value) value = '-';
          return value;
        },
        cellExport: row => {
          const trackingData = row.paymentLinkTransaction
            ? row.paymentLinkTransaction.urlParamTrackingData
            : row.urlParamTrackingData;

          let value = parseJSON(trackingData, {})?.[param] || '-';
          if (!value) value = '-';
          return value;
        }
      };

      columns?.push(column);
    });
  }
};

/**
 * Function to filter data table by date
 * @param {Array} data data table
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Object} queryVariables query variables
 * @returns {Array} result
 */
export const filterDataByDate = ({ data, startDate, endDate, queryVariables }) => {
  let result = data;
  if (startDate && endDate) {
    const fixEndDate = new Date(endDate);

    result = data.filter(item =>
      queryVariables.sortField === 'createdAt' && queryVariables.sortDirection === 'ASC'
        ? item.createdAt <= new Date(fixEndDate.setDate(fixEndDate.getDate() + 1)).valueOf()
        : item.createdAt >= new Date(startDate).valueOf()
    );
  }

  return result;
};

/**
 * Function to change date on query variables
 * @param {String} startDate start date
 * @param {String} endDate end date
 * @param {Object} queryVariables query variables
 * @returns {Boolean} isDateChanged
 */
export const onChangeVariablesDate = ({ startDate, endDate, queryVariables }) => {
  let isDateChanged = false;

  if (startDate && endDate) {
    isDateChanged = true;

    if (queryVariables.sortField === 'createdAt' && queryVariables.sortDirection === 'ASC') {
      queryVariables.search.createdAt = [{ operator: 'gt', value: new Date(startDate) }];
    } else {
      const fixEndDate = new Date(endDate);
      queryVariables.search.createdAt = [
        { operator: 'lt', value: new Date(fixEndDate.setDate(fixEndDate.getDate() + 1)) }
      ];
    }
  }

  if (!startDate && !endDate) {
    isDateChanged = true;

    delete queryVariables.search.createdAt;
  }

  return isDateChanged;
};
