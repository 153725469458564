import React from 'react';
import './styles.scss';

const Confetti = ({ children, display }) => {
  return (
    <div className="myrconfetti-wrapper">
      <div className="myrconfetti-wohoo">{children}</div>
      {display &&
        Array.from(Array(301).keys())
          .reverse()
          .map(i => <div key={i} className={`confetti-${i}`} />)}
    </div>
  );
};

export default Confetti;
