import { gql } from '@apollo/client';

export const GQL_COUNTRY_SELECT = gql`
  query getCountryPage($page: Int, $pageSize: Int) {
    getCountryPage(
      page: $page
      pageSize: $pageSize
    ) {
      items {
        code
        countryId
        name
        value
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_PROVINCE_SELECT = gql`
  query getProvincePage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: ProvinceSortField
    $searchAny: ProvinceSearchInput
  ) {
    getProvincePage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
    ) {
      items {
        countryId
        name
        provinceId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_DISTRICT_SELECT = gql`
  query getDistrictPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: DistrictSortField
    $searchAny: DistrictSearchInput
  ) {
    getDistrictPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
    ) {
      items {
        districtId
        name
        provinceId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_SUBDISTRICT_SELECT = gql`
  query getSubDistrictPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: SubDistrictSortField
    $searchAny: SubDistrictSearchInput
  ) {
    getSubDistrictPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
    ) {
      items {
        id
        districtId
        name
        subDistrictId
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;
