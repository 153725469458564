import {
  FILTER_LANDING_PAGE_BY_Q,
  UPDATE_LANDING_PAGES,
  FILTER_LANDING_PAGE_BY_STATUS,
  LANDING_PAGE_DETAILS,
  UPDATE_LANDING_PAGES_THUMBNAIL,
  ADD_LANDING_PAGE_COLLECTION,
  EDITOR_LANDING_TAB
} from '../actions/landing-page';

export const filterLandingPageByQReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_LANDING_PAGE_BY_Q:
      return action.payload;
  }
  return state;
};

export const UpdateLandingPagesReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_LANDING_PAGES:
      return action.payload;
  }
  return state;
};

export const filterLandingPageByStatusReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_LANDING_PAGE_BY_STATUS:
      return action.payload;
  }
  return state;
};

export const landingPageDetailsReducer = (state = '', action) => {
  switch (action.type) {
    case LANDING_PAGE_DETAILS:
      return action.payload;
  }
  return state;
};

export const UpdateLandingPagesThumbnailReducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE_LANDING_PAGES_THUMBNAIL:
      return action.payload;
  }
  return state;
};

export const LandingPageCollectionsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_LANDING_PAGE_COLLECTION:
      return [...state, action.payload];
  }
  return state;
};

export const editorLandingTabReducer = (state = '', action) => {
  switch (action.type) {
    case EDITOR_LANDING_TAB:
      return action.payload;
  }
  return state;
};

export const editorContentIsChangedReducer = (state = false, action) => {
  switch (action.type) {
    case 'EDITOR_CONTENT_IS_CHANGED':
      return action.payload;
  }
  return state;
};
