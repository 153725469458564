import React from 'react';
import { Col } from 'reactstrap';
import SubmitButton from '../submit-button';

const SubmitCoverButton = ({ props, loading }) => {
  const { files, onSubmit, extra, disabled } = props;
  let isDisabled = disabled;

  const exceededFiles = files.filter(({ meta }) => meta.size > extra.maxSizeBytes);
  if (exceededFiles.length > 0) isDisabled = true;

  return (
    <Col className="px-3percent">
      <SubmitButton
        size="md"
        color="brand"
        type="button"
        isDisabled={isDisabled}
        className={`text-center float-left mb-10 py-6 text-capitalize font-13 ${files.length >= 5 ? 'mt-10' : ''}`}
        text="Unggah Gambar"
        loading
        isLoading={loading}
        onClick={() => onSubmit(files)}
      />
    </Col>
  );
};

export default SubmitCoverButton;
