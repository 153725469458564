export const LINKTREE_TAB = 'LINKTREE_TAB';
export const linkTreeTab = q => ({
  type: LINKTREE_TAB,
  payload: q
});

export const LINK_TREE = 'LINK_TREE';
export const linkTree = q => ({
  type: LINK_TREE,
  payload: q
});
