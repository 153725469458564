import '../custom-styles/dropzone.scss';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import {
  GQL_PHYSICAL_PRODUCT_CREATE,
  GQL_PHYSICAL_PRODUCT_UPDATE,
} from '../../gqls';
import DatePicker from '../date-time-picker';
import Icon from '../icon';
import {
  validator,
  paymentLinkUrl,
  REGEX_SLUG,
  checkSKU,
  generateSKU,
  physicalProductCategory,
  sanitizeDOM,
  slug,
} from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import { isAddressRegistered } from '../../actions/physical-product';
import { updatePaymentLinks } from '../../actions/payment-links';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import { EditorState } from 'draft-js';
import MultipleImageInput from "../multiple-image-input";

const ModalBodyPhysical = ({ isOpen = false, onClose, onSuccess, data, historyOrder }) => {
  const currentUser = useSelector(state => state.currentUser);
  const { register, handleSubmit, errors, control } = useForm();
  const dispatch = useDispatch();
  const isUpdate = data ? true : false;

  if (currentUser?.address?.length < 1) {
    dispatch(isAddressRegistered(true));
    return <Redirect to={{ pathname: '/settings' }} />;
  }
  const stateEdit = EditorState.createEmpty();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [link, setLink] = useState(data?.link ? data.link : '');

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const categoryOptions = physicalProductCategory.map(value => ({ label: value, value }));

  const [createPhysicalProduct, { data: newPhysicalProduct, loading: createLoading }] = useMutation(
    GQL_PHYSICAL_PRODUCT_CREATE
  );
  const [editPhysicalProduct, { loading: updateLoading }] = useMutation(GQL_PHYSICAL_PRODUCT_UPDATE);

  if (!data) data = {};
  if (newPhysicalProduct && newPhysicalProduct.insertPaymentLinkBySlug)
    onSuccess(newPhysicalProduct.insertPaymentLinkBySlug.id);

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    const formValues = {
      ...values,
      amount: parseFloat(values.amount),
      qty: parseInt(values.qty),
      description: sanitizeDOM(description),
      order: {
        discountAmount: parseFloat(values.discountAmount),
        wholesaleAmount: parseFloat(values.wholesaleAmount),
        length: parseInt(values.length),
        width: parseInt(values.width),
        height: parseInt(values.height),
        weight: parseInt(values.weight),
        vendor: values.vendor
      }
    };

    // const volumetricWeight = (formValues.length * formValues.height * formValues.width) / 6000;
    const kiloWeight = formValues.weight / 1000;
    // const finalWeight = kiloWeight > volumetricWeight ? kiloWeight * 1000 : volumetricWeight * 1000;

    delete formValues.discountAmount;
    delete formValues.wholesaleAmount;
    delete formValues.length;
    delete formValues.width;
    delete formValues.height;
    delete formValues.weight;
    delete formValues.vendor;
    delete formValues.sku;
    delete formValues.displayQty;

    try {
      if (!isUpdate) {
        const objPhysicalProduct = {
          ...formValues,
          expiredAt: date,
          link: slug(formValues.name),
          status: 'active',
          type: 'physical_product',
          order: {
            ...formValues.order,
            weight: kiloWeight * 1000,
            sku: values.sku ? values.sku : generateSKU(values)
          }
        };

        if (multipleImageId) {
          objPhysicalProduct.multipleImageId = multipleImageId;
        }

        await createPhysicalProduct({
          variables: {
            input: objPhysicalProduct
          }
        });

        //segment tracking
        window.analytics.track('Create Physical Product', formValues);
      } else {
        const objPhysicalProduct = {
          ...formValues,
          id: data.id,
          qty: data.qty,
          expiredAt: date,
          status: 'active',
          order: {
            ...formValues.order,
            weight: kiloWeight * 1000,
            sku: checkSKU(values, {
              name: data.name,
              category: data.category,
              vendor: data.order.vendor,
              sku: data.order.sku
            })
          }
        };

        if (formValues.qty) {
          objPhysicalProduct.qty = data.qty ? data.qty + formValues.qty : historyOrder.total + formValues.qty;
        }

        // if (formValues.subqty) {
        //   objPhysicalProduct.qty -= formValues.subqty;
        // }
        // delete objPhysicalProduct.subqty;
        if (multipleImageId) {
          objPhysicalProduct.multipleImageId = multipleImageId;
        }
        if (isRemoveAllProductImage) {
          objPhysicalProduct.multipleImageId = null;
        }

        await editPhysicalProduct({
          variables: {
            input: objPhysicalProduct
          }
        });

        dispatch(updatePaymentLinks(Math.random()));
        window.reload();
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Physical Product - ERROR', formValues);
    }
  };

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Produk Fisik</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Jual produk fisik semakin mudah dengan one-click checkout, integrasi pengiriman dan integrasi pembayaran.
          </small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="name">Nama Produk*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              defaultValue={data.name}
              invalid={Boolean(errors.name)}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(4)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={Boolean(errors.link)}
                defaultValue={link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{`Contoh : ${paymentLinkUrl(link)}`}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="category">Kategori Produk*</Label>
            <Controller
              name="category"
              control={control}
              defaultValue={data.category}
              rules={{ required: validator.required }}
              render={({ onChange, name, value }) => (
                <Select
                  id="category"
                  inputRef={register}
                  name={name}
                  options={categoryOptions}
                  styles={Boolean(errors.category) ? CustomStylesInvalid : CustomStyles}
                  className={Boolean(errors.category) ? 'is-invalid' : ''}
                  placeholder="Pilih kategori..."
                  onChange={e => onChange(e.value)}
                  defaultValue={categoryOptions.find(obj => obj.value === value)}
                />
              )}
            />
            <FormFeedback>{errors.category?.message}</FormFeedback>
          </FormGroup>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="amount">Harga*</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="amount"
                    id="amount"
                    defaultValue={data.amount}
                    invalid={Boolean(errors.amount)}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1000),
                      max: validator.max(100000000)
                    })}
                  />
                  <FormFeedback>{errors.amount?.message}</FormFeedback>
                  <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="discountAmount">Harga Coret (opsional)</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="discountAmount"
                    id="discountAmount"
                    defaultValue={data.order?.discountAmount}
                    invalid={Boolean(errors.discountAmount)}
                    innerRef={register({
                      min: validator.min(1000),
                      max: validator.max(100000000)
                    })}
                  />
                  <FormFeedback>{errors.discountAmount?.message}</FormFeedback>
                  <small>Harga coret harus lebih besar dari harga utama.</small>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label for="length">Ukuran Paket*</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <FormGroup className="m-0">
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Panjang"
                    name="length"
                    id="length"
                    className="hide-arrow-number"
                    defaultValue={data.order?.length}
                    invalid={Boolean(errors.length)}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1),
                      max: validator.max(1000000)
                    })}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>cm</InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>{errors.length?.message}</FormFeedback>
                </InputGroup>
                <Label for="length">
                  <small>Panjang</small>
                </Label>
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup className="m-0">
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Lebar"
                    name="width"
                    id="width"
                    className="hide-arrow-number"
                    invalid={Boolean(errors.width)}
                    defaultValue={data.order?.width}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1),
                      max: validator.max(1000000)
                    })}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>cm</InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>{errors.width?.message}</FormFeedback>
                </InputGroup>
                <Label for="width">
                  <small>Lebar</small>
                </Label>
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Tinggi"
                    name="height"
                    id="height"
                    className="hide-arrow-number"
                    invalid={Boolean(errors.height)}
                    defaultValue={data.order?.height}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1),
                      max: validator.max(1000000)
                    })}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>cm</InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>{errors.height?.message}</FormFeedback>
                </InputGroup>
                <Label for="height">
                  <small>Tinggi</small>
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="weight">Berat Produk*</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="weight"
                    id="weight"
                    invalid={Boolean(errors.weight)}
                    defaultValue={data.order?.weight}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1),
                      max: validator.max(400000)
                    })}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>gram</InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>{errors.weight?.message}</FormFeedback>
                  <small>
                    Jika ukuran paket lebih besar dari berat produk, maka kami akan merubah berat produk dengan ukuran
                    paket.
                  </small>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="vendor">Merek (brand)</Label>
                <Input
                  type="text"
                  name="vendor"
                  id="vendor"
                  invalid={Boolean(errors.vendor)}
                  defaultValue={data.order?.vendor}
                  innerRef={register({
                    minLength: validator.minLength(3),
                    maxLength: validator.maxLength(50)
                  })}
                />
                <FormFeedback>{errors.vendor?.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>

          {isUpdate && !data.order.variant ? (
            <Row>
              <Col>
                <FormGroup>
                  <Label for="qty">
                    {isUpdate && !data.order.variant
                      ? `${
                          data.qty
                            ? `Tambah Stok (QTY) - Sisa (${data.qty - historyOrder?.total})`
                            : `Batasi Stok (QTY) - Tidak Terbatas`
                        }`
                      : 'Stok Tersedia (QTY)'}
                  </Label>
                  <Input
                    type="number"
                    name={isUpdate && data.order.variant ? 'displayQty' : 'qty'}
                    id="qty"
                    disabled={isUpdate && data.order.variant}
                    invalid={Boolean(errors.qty)}
                    defaultValue={isUpdate && data.qty && data.order.variant ? data.qty - historyOrder?.total : null}
                    innerRef={register({ min: validator.min(1) })}
                  />
                  <FormFeedback>{errors.qty?.message}</FormFeedback>
                  {/* <small>Kami akan menutup penjualan setelah stok habis. Kosongkan untuk stok tidak terbatas</small> */}
                </FormGroup>
              </Col>
              {/* <Col sm="6">
                <FormGroup>
                  <Label for="subqty">
                    {data.qty
                      ? `Kurangi Stok (QTY) - Sisa (${data.qty - historyOrder?.total})`
                      : `Batasi Stok (QTY) - Tidak Terbatas`}
                  </Label>
                  <Input
                    type="number"
                    name="subqty"
                    id="subqty"
                    invalid={Boolean(errors.subqty)}
                    max
                    innerRef={register({
                      min: validator.min(1),
                      max: validator.max(data.qty - historyOrder?.total - 1)
                    })}
                  />
                  <FormFeedback>{errors.subqty?.message}</FormFeedback>
                </FormGroup>
              </Col> */}
            </Row>
          ) : (
            <FormGroup>
              <Label for="qty">
                {isUpdate && !data.order.variant
                  ? `${
                      data.qty
                        ? `Tambah Stok (QTY) - Sisa (${data.qty - historyOrder?.total})`
                        : `Batasi Stok (QTY) - Tidak Terbatas`
                    }`
                  : 'Stok Tersedia (QTY)'}
              </Label>
              <Input
                type="number"
                name={isUpdate && data.order.variant ? 'displayQty' : 'qty'}
                id="qty"
                disabled={isUpdate && data.order.variant}
                invalid={Boolean(errors.qty)}
                defaultValue={isUpdate && data.qty && data.order.variant ? data.qty - historyOrder?.total : null}
                innerRef={register({ min: validator.min(1) })}
              />
              <FormFeedback>{errors.qty?.message}</FormFeedback>
              <small>Kami akan menutup penjualan setelah stok habis. Kosongkan untuk stok tidak terbatas</small>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="sku">SKU (opsional)</Label>
            <Input
              type="text"
              name="sku"
              id="sku"
              disabled={isUpdate}
              invalid={Boolean(errors.sku)}
              defaultValue={data.order?.sku}
              innerRef={register({
                minLength: validator.minLength(6),
                maxLength: validator.maxLength(16)
              })}
            />
            <FormFeedback>{errors.sku?.message}</FormFeedback>
          </FormGroup>

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />

          <hr />
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="expiredAt">Tanggal Batas Pembelian</Label>
            <div className="mb-1">
              <DatePicker
                id="expiredAt"
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={new Date().setDate(new Date().getDate() + 1)}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup penjualan setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="wholesaleAmount">Harga Grosir</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Rp</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                name="wholesaleAmount"
                id="wholesaleAmount"
                invalid={Boolean(errors.wholesaleAmount)}
                defaultValue={data.order?.wholesaleAmount}
                innerRef={register({
                  min: validator.min(1000),
                  max: validator.max(100000000)
                })}
              />
              <FormFeedback>{errors.wholesaleAmount?.message}</FormFeedback>
            </InputGroup>
            <small>Anda bisa menghitung unit profit jika mengisi data ini (opsional)</small>
          </FormGroup>

          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websiteanda.com/terimakasih"
              invalid={Boolean(errors.redirectUrl)}
              defaultValue={data.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
            <small>Pelanggan akan dibawa kehalaman ini setelah membayar (opsional)</small>
          </FormGroup>

          <Alert color="light" className="text-center mb-10">
            <strong>
              Jika produk ini memiliki varian (warna, ukuran, tipe), anda bisa mengaturnya nanti dari halaman detail
              produk
            </strong>
          </Alert>

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Edit' : 'Buat'} Produk Fisik`}
            loading
            isDisabled={disableSubmitButton}
            isLoading={createLoading || updateLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyPhysical;
