import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { updatePaginatedData } from '../../utils';
import { GQL_LANDING_PAGE } from '../../gqls';
import { landingPageDetails } from '../../actions/landing-page';
import AsyncComponent from '../../components/async-component';

const SimpleLandingPageEditor = () => {
  const updateLandingPage = useSelector(state => state.updateLandingPages);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(GQL_LANDING_PAGE, { variables: { id } });

  const landingPage = _.get(data, 'getLandingPageUser');

  if (landingPage) dispatch(landingPageDetails(landingPage));

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getLandingPageUser', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updateLandingPage]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  if (data && !landingPage) window.location.href = process.env.REACT_APP_BASE_URL;

  return <AsyncComponent component={() => import('./content')} />;
};

export default SimpleLandingPageEditor;
