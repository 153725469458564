export const isPaidPlan = (planType = '') => {
  return ['premium', 'enterprise'].some(type => planType?.includes(type));
};

export const getPlanType = (planType = '') => {
  if (planType?.includes('premium')) {
    return 'premium';
  } else if (planType?.includes('enterprise')) {
    return 'enterprise';
  }
  return 'standard';
};
