export const INSERT_COHORT_SESSIONS = 'INSERT_COHORT_SESSIONS';
export const insertCohortSessions = q => ({
  type: INSERT_COHORT_SESSIONS,
  payload: q
});

export const ADD_COHORT_SESSION = 'ADD_COHORT_SESSION';
export const addCohortSession = q => ({
  type: ADD_COHORT_SESSION,
  payload: q
});

export const EDIT_COHORT_SESSION = 'EDIT_COHORT_SESSION';
export const editCohortSession = q => ({
  type: EDIT_COHORT_SESSION,
  payload: q
});

export const REMOVE_COHORT_SESSION = 'REMOVE_COHORT_SESSION';
export const removeCohortSession = q => ({
  type: REMOVE_COHORT_SESSION,
  payload: q
});

export const COHORT_GRADE_UPDATED = 'COHORT_GRADE_UPDATED';
export const updateCohortGrade = q => ({
  type: COHORT_GRADE_UPDATED,
  payload: q
});
