import React, { useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
  InputGroup,
  FormFeedback,
  Alert
} from 'reactstrap';

import {
  validator,
  bankCodes,
  currencyOptions,
  bankCountryOptions,
  paymentCategoryOptions,
  // bankAccountTypeOptions,
  ewalletCodes
} from '../../../../utils';
import { CustomStyles, CustomStylesInvalid } from '../../../custom-styles/react-select-styles';
import SubmitButton from '../../../submit-button';

import RichEditor from '../../../rich-text-editor';

const ModalBodyForm = ({ useForm, onSubmit, isError, validation, isLoading, loadingFetchBalance, ...props }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const [transferType, setTransferType] = useState(null);
  const [invoiceCategory, setInvoiceCategory] = useState(false);

  const bankOptions = bankCodes.map(({ Bank, BankCode }) => ({ value: BankCode, label: Bank }));
  const ewalletOptions = ewalletCodes.map(({ Ewallet, EwalletCode }) => ({ value: EwalletCode, label: Ewallet }));
  const transferTypeOptions = [
    { value: 'BANK', label: 'Bank' },
    { value: 'EWALLET', label: 'E-Wallet' }
  ];
  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormGroup>
        <Label for="accountName">Nama Penerima*</Label>
        <Input
          type="text"
          name="accountName"
          id="accountName"
          invalid={Boolean(errors.accountName)}
          innerRef={register({ required: validator.required, minLength: validator.minLength(3) })}
        />
        <FormFeedback>{errors.accountName?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="currency">Mata Uang*</Label>
        <Controller
          name="currency"
          control={control}
          defaultValue={currencyOptions[0]}
          rules={{ required: validator.required }}
          render={({ onChange, value }) => (
            <Select
              inputId="currency"
              value={value}
              options={currencyOptions}
              styles={Boolean(errors.currency) ? CustomStylesInvalid : CustomStyles}
              className={Boolean(errors.currency) ? 'is-invalid' : ''}
              placeholder="Pilih Mata Uang"
              onChange={onChange}
            />
          )}
        />
        <FormFeedback>{errors.currency?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="bankCountry">Negara {transferType?.label || 'Bank/E-Wallet'} Penerima*</Label>
        <Controller
          name="bankCountry"
          control={control}
          defaultValue={bankCountryOptions[0]}
          rules={{ required: validator.required }}
          render={({ onChange, value }) => (
            <Select
              inputId="bankCountry"
              value={value}
              options={bankCountryOptions}
              styles={Boolean(errors.bankCountry) ? CustomStylesInvalid : CustomStyles}
              className={Boolean(errors.bankCountry) ? 'is-invalid' : ''}
              placeholder="Pilih Negara Bank"
              onChange={onChange}
            />
          )}
        />
        <FormFeedback>{errors.bankCountry?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="transferType">Kirim Ke*</Label>
        <Controller
          name="transferType"
          control={control}
          defaultValue=""
          rules={{ required: validator.required }}
          render={({ onChange, value }) => (
            <Select
              inputId="transferType"
              value={value}
              options={transferTypeOptions}
              styles={Boolean(errors.transferType) ? CustomStylesInvalid : CustomStyles}
              className={Boolean(errors.transferType) ? 'is-invalid' : ''}
              placeholder="Pilih Jenis Akun Penerima"
              onChange={e => {
                onChange(e);
                setTransferType(e);
              }}
            />
          )}
        />
        <FormFeedback>{errors.transferType?.message}</FormFeedback>
      </FormGroup>
      {transferType && (
        <FormGroup>
          <Label for="bank">Nama {transferType?.label} Penerima*</Label>
          <Controller
            name="bank"
            control={control}
            defaultValue=""
            rules={{ required: validator.required }}
            render={({ onChange, value }) => (
              <Select
                inputId="bank"
                value={value}
                options={transferType?.value === 'BANK' ? bankOptions : ewalletOptions}
                styles={Boolean(errors.bank) ? CustomStylesInvalid : CustomStyles}
                className={Boolean(errors.bank) ? 'is-invalid' : ''}
                placeholder={`Pilih ${transferType?.label}`}
                onChange={onChange}
              />
            )}
          />
          <FormFeedback>{errors.bank?.message}</FormFeedback>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="accountNumber">Nomor {transferType?.label || 'Rekening/E-Wallet'} Penerima*</Label>
        <Input
          type="text"
          name="accountNumber"
          id="accountNumber"
          invalid={Boolean(errors.accountNumber)}
          innerRef={register({
            required: validator.required,
            pattern: validator.bank,
            minLength: validator.minLength(5)
          })}
        />
        <FormFeedback>{errors.accountNumber?.message}</FormFeedback>
      </FormGroup>
      {/* {transferType?.value === 'BANK' && (
        <FormGroup>
          <Label for="accountType">Tipe Rekening*</Label>
          <Controller
            name="accountType"
            control={control}
            defaultValue=""
            rules={{ required: validator.required }}
            render={({ onChange, value }) => (
              <Select
                inputId="accountType"
                value={value}
                options={bankAccountTypeOptions}
                styles={Boolean(errors.accountType) ? CustomStylesInvalid : CustomStyles}
                className={Boolean(errors.accountType) ? 'is-invalid' : ''}
                placeholder="Pilih Tipe Rekening"
                onChange={onChange}
              />
            )}
          />
          <FormFeedback>{errors.accountType?.message}</FormFeedback>
        </FormGroup>
      )} */}

      {/* <FormGroup>
        <Label for="transferDescription">Deskripsi</Label>
        <Input
          type="textarea"
          name="transferDescription"
          id="transferDescription"
          invalid={Boolean(errors.transferDescription)}
          innerRef={register()}
        />
        <FormFeedback>{errors.transferDescription?.message}</FormFeedback>
        <small>
          Bank akan memotong deskripsi sesuai jumlah karakter maksimal yang diperkenankan oleh masing-masing bank
        </small>
      </FormGroup> */}
      <FormGroup>
        <Label for="description">Deskripsi</Label>
        <Controller
          name="transferDescription"
          control={control}
          defaultValue=""
          // rules={{ required: validator.required }}
          render={({ onChange, value }) => (
            <RichEditor
              onEditorStateChange={onChange}
              editorState={value}
              invalidState={Boolean(errors.transferDescription)}
            />
          )}
        />
        <small>
          Bank akan memotong deskripsi sesuai jumlah karakter maksimal yang diperkenankan oleh masing-masing bank
        </small>
      </FormGroup>
      <FormGroup>
        <Label for="amount">Jumlah*</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Rp</InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            name="amount"
            id="amount"
            invalid={Boolean(errors.amount)}
            innerRef={register({
              required: validator.required,
              min: validator.min(1000),
              max: validator.max(500000000)
            })}
          />
          <FormFeedback>{errors.amount?.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <hr />
      <FormGroup>
        <Label for="email">Email Penerima (opsional)</Label>
        <Input
          type="email"
          name="email"
          id="email"
          invalid={Boolean(errors.email)}
          innerRef={register({ pattern: validator.email })}
        />
        <FormFeedback>{errors.email?.message}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="invoiceCategory">Kategori (opsional)</Label>
        <Controller
          name="invoiceCategory"
          control={control}
          defaultValue={invoiceCategory || ''}
          render={({ onChange, value }) => (
            <Select
              inputId="invoiceCategory"
              value={value}
              options={paymentCategoryOptions}
              styles={Boolean(errors.invoiceCategory) ? CustomStylesInvalid : CustomStyles}
              className={Boolean(errors.invoiceCategory) ? 'is-invalid' : ''}
              placeholder="Pilih Kategori"
              onChange={e => {
                onChange(e);
                setInvoiceCategory(e);
              }}
            />
          )}
        />
        <FormFeedback>{errors.invoiceCategory?.message}</FormFeedback>
      </FormGroup>

      {/* <FormGroup>
        <Label for="tax">Pajak (opsional)</Label>
        <Input type="number" name="tax" id="tax" invalid={Boolean(errors.tax)} innerRef={register()} />
        <FormFeedback>{errors.tax?.message}</FormFeedback>
      </FormGroup> */}
      <FormGroup>
        <Label for="notes">Catatan Internal (opsional)</Label>
        <Input type="textarea" name="notes" id="notes" invalid={Boolean(errors.notes)} innerRef={register()} />
        <FormFeedback>{errors.notes?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <CustomInput
          id="isEmailSentAfterSuccess"
          name="isEmailSentAfterSuccess"
          label="Kirim Email Pemberitahuan Setelah Pembayaran Sukses"
          type="switch"
          color="primary"
          innerRef={register()}
        />
      </FormGroup>
      {isError && (
        <Alert color="danger" className="mb-20">
          Terjadi kesalahan saat berusaha mengirim kode konfirmasi. Silahkan refresh atau periksa jaringan Anda.
        </Alert>
      )}
      {validation && (
        <Alert color="danger" className="mb-20">
          {validation}
        </Alert>
      )}
      <SubmitButton
        size="lg"
        block
        color="brand"
        className="text-center"
        type="submit"
        loading
        isLoading={isLoading || loadingFetchBalance}
        text="Kirim Uang / Top Up"
      />
    </Form>
  );
};

ModalBodyForm.defaultProps = {
  useForm: () => ({})
};

export default ModalBodyForm;
