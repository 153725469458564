import { gql } from '@apollo/client';

export const GQL_GET_KYC_FILTERED = gql`
  query getKycByParams($idNumber: String) {
    getKycByParams(idNumber: $idNumber) {
      id
      idNumber
      userId
    }
  }
`;

export const GQL_GET_USER_KYC = gql`
  query getKnownYourCustomerPage($page: Int, $pageSize: Int, $search: KnownYourCustomerSearchInput) {
    getKnownYourCustomerPage(page: $page, pageSize: $pageSize, search: $search) {
      items {
        id
        bankAccountImageId
        fullName
        idNumber
        identityImageId
        selfieImageId
        status
        updatedAt
        userId
        businessLicenseImageId
        businessNumberImageId
        certificateKemenkumhamImageId
        companyDeedImageId
        companyRegistrationCertificateImageId
        type
        businessName
        businessAddress
        businessDescription
        businessSite
        identitySelfieImageId
        affiliateFrom
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_KYC_CREATE = gql`
  mutation insertKnownYourCustomer($input: KnownYourCustomerInput) {
    insertKnownYourCustomer(input: $input) {
      id
      bankAccountImageId
      fullName
      idNumber
      identityImageId
      selfieImageId
      status
      updatedAt
      userId
      businessLicenseImageId
      businessNumberImageId
      certificateKemenkumhamImageId
      companyDeedImageId
      companyRegistrationCertificateImageId
      type
      businessName
      businessAddress
      businessDescription
      businessSite
      identitySelfieImageId
    }
  }
`;

export const GQL_KYC_UPDATE = gql`
  mutation editKnownYourCustomer($input: KnownYourCustomerInput) {
    editKnownYourCustomer(input: $input) {
      id
      bankAccountImageId
      fullName
      idNumber
      identityImageId
      selfieImageId
      status
      userId
      businessLicenseImageId
      businessNumberImageId
      certificateKemenkumhamImageId
      companyDeedImageId
      companyRegistrationCertificateImageId
      type
      businessName
      businessAddress
      businessDescription
      businessSite
      identitySelfieImageId
      affiliateFrom
    }
  }
`;

export const SEND_KYC_MAILER_QUERY = gql`
  mutation sendKycMailer($kycId: ID!) {
    sendKycMailer(kycId: $kycId) {
      id
    }
  }
`;
