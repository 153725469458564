import { gql } from '@apollo/client';

export const GQL_CREATE_INSTALLMENT_INVOICES = gql`
  mutation createInstallmentInvoices($id: ID!) {
    createInstallmentInvoices(id: $id) {
      status
      invoices {
        id
        customer {
          email
        }
      }
    }
  }
`;

export const GQL_VOID_INSTALLMENT_INVOICE = gql`
  mutation voidInstallmentInvoice($id: ID!) {
    voidInstallmentInvoice(id: $id) {
      status
    }
  }
`;
