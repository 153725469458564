import { gql } from '@apollo/client';

export const GQL_BANK_ACCOUNT_CREATE = gql`
  mutation insertBankAccount($input: BankAccountInput) {
    insertBankAccount(input: $input) {
      id
      accountName
      accountNumber
      bank
    }
  }
`;
export const GQL_BANK_ACCOUNT_UPDATE = gql`
  mutation editBankAccount($input: BankAccountInput) {
    editBankAccount(input: $input) {
      id
      accountName
      accountNumber
      bank
    }
  }
`;
