export const removeEmpty = obj => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = removeEmpty(obj[key]); // recurse
    } else if (obj[key] !== null) {
      newObj[key] = obj[key]; // copy value
    }
  });

  return newObj;
};

export const handleChangeObjectInArray = ({ value, index, keys, arrayData, func }) => {
  const arrayCopy = [...arrayData];
  arrayCopy[index][keys] = value;
  func(arrayCopy);
  return arrayCopy;
};

// Product list URLs being differentiated based on their type
// Structure: { paymentLinkType: url }
export const listProductUrl = {
  membership: 'memberships',
  digital_product: 'digital-products',
  generic_link: 'payment-link',
  physical_product: 'physical-products',
  course: 'online-courses',
  cohort_based: 'cohort-courses',
  webinar: 'webinars',
  event: 'events',
  coaching: 'coachings',
  fundraising: 'fundraisings',
  ebook: 'ebooks',
  podcast: 'podcasts',
  audiobook: 'audiobooks',
  saas: 'license-codes',
  bundling: 'bundling'
};

export const statusProductState = {
  active: 'Published',
  closed: 'Unpublished',
  unlisted: 'Unlisted',
  deleted: 'Hapus'
};

export const actionableEditStatusProduct = ['active', 'closed', 'unlisted', 'deleted'];
