export const FILTER_WEBHOOK_HISTORY_BY_STATUS = 'FILTER_WEBHOOK_HISTORY_BY_STATUS';
export const filterWebhookHistoryByStatus = (q) => ({
  type: FILTER_WEBHOOK_HISTORY_BY_STATUS,
  payload: q,
});

export const FILTER_WEBHOOK_HISTORY_BY_Q = 'FILTER_WEBHOOK_HISTORY_BY_Q';
export const filterWebhookHistoryByQ = (q) => ({
  type: FILTER_WEBHOOK_HISTORY_BY_Q,
  payload: q,
});

export const UPDATE_WEBHOOK_HISTORY = 'UPDATE_WEBHOOK_HISTORY';
export const updateWebhookHistory = (q) => ({
  type: UPDATE_WEBHOOK_HISTORY,
  payload: q,
});

export const WEBHOOK_HISTORY_DETAILS = 'WEBHOOK_HISTORY_DETAILS';
export const webhookHistoryDetails = (q) => ({
  type: WEBHOOK_HISTORY_DETAILS,
  payload: q,
});
