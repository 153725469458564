import { FILTER_BALANCE_END_DATE, FILTER_BALANCE_START_DATE, FILTER_BALANCE_PICKUP_END_DATE, FILTER_BALANCE_PICKUP_START_DATE } from '../actions/balance-history';

export const balanceFilterStartDateReducers = (state = '', action) => {
    switch (action.type) {
      case FILTER_BALANCE_START_DATE:
        return action.payload;
    }
    return state;
};

export const balanceFilterEndDateReducers = (state = '', action) => {
    switch (action.type) {
        case FILTER_BALANCE_END_DATE:
        return action.payload;
    }
    return state;
};

export const balanceFilterPickupStartDateReducers = (state = '', action) => {
    switch (action.type) {
      case FILTER_BALANCE_PICKUP_START_DATE:
        return action.payload;
    }
    return state;
};

export const balanceFilterPickupEndDateReducers = (state = '', action) => {
    switch (action.type) {
        case FILTER_BALANCE_PICKUP_END_DATE:
        return action.payload;
    }
    return state;
};
