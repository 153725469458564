/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';

import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col } from 'reactstrap';

class AdminUsers extends Component {
  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            '/admin': 'Admin',
          }}
        >
          <Row className="mb-0">
            <Col lg="6">
              <h1>Pengguna / Users</h1>
            </Col>
          </Row>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import('./content')} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default AdminUsers;
