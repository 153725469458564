import { gql } from '@apollo/client';

// query for order page detail
export const GET_BALANCE_HISTORY_ORDER = gql`
  query getBalanceHistory($id: ID!) {
    getBalanceHistory(id: $id) {
      id
      credit
      orderStatus
      createdAt
      trackOrderId
      user {
        name
        mobile
        address {
          address
          area
          district
          province
          subdistrict
          postcode
        }
      }
      orderDetail {
        awbNumber
        isHubless
        labelChecksum
      }
      customer {
        name
        email
        mobile
        customerAddress {
          id
          district
          subdistrict
          area
        }
      }
      paymentLink {
        id
        name
        status
        amount
        order {
          variant
          sku
        }
        multipleImage {
          url
        }
      }
      paymentLinkTransaction {
        orderVariant
        couponUsage {
          coupons {
            discount {
              value
            }
          }
        }
      }
    }
  }
`;
