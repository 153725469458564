import React from 'react';

import { KBarResults, useMatches } from 'kbar';

import Icon from '../../icon';

const ResultItem = React.forwardRef(({ action, active, currentRootActionId }, ref) => {
  const ancestors = React.useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex(ancestor => ancestor.id === currentRootActionId);
    // +1 removes the currentRootAction; e.g.
    // if we are on the "Set theme" parent action,
    // the UI should not display "Set theme… > Dark"
    // but rather just "Dark"
    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);

  return (
    <div
      ref={ref}
      className="kbarList"
      style={{
        background: active ? '#eee' : 'transparent',
        borderLeft: `2px solid ${active ? 'rgb(28 28 29)' : 'transparent'}`
      }}
    >
      <div className="kbarList__name">
        {action.icon ? <Icon name={action.icon} /> : null}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {ancestors.length > 0 &&
              ancestors.map((ancestor, index) => (
                <React.Fragment key={index}>
                  <span
                    style={{
                      opacity: 0.5,
                      marginRight: 8
                    }}
                  >
                    {ancestor.name}
                  </span>
                  <span
                    style={{
                      marginRight: 8
                    }}
                  >
                    &rsaquo;
                  </span>
                </React.Fragment>
              ))}
            <span>{action.name}</span>
          </div>
          {action.subtitle && <span style={{ fontSize: 12 }}>{action.subtitle}</span>}
        </div>
      </div>
      {action.shortcut?.length ? (
        <div aria-hidden>
          {action.shortcut.map((sc, index) => (
            <kbd className="mr-5" key={index}>
              {sc}
            </kbd>
          ))}
        </div>
      ) : null}
    </div>
  );
});

export function RenderResults() {
  const { results, rootActionId } = useMatches();
  // console.log("res", results)
  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div
            style={{
              padding: '8px 16px',
              fontSize: '10px',
              textTransform: 'uppercase',
              opacity: 0.5
            }}
          >
            {item}
          </div>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId} />
        )
      }
    />
  );
}
