import { gql } from '@apollo/client';

export const GQL_UPDATE_XENDIT_ACCOUNT = gql`
  mutation updateXenditAccount($name: String!) {
    updateXenditAccount(name: $name) {
      id
    }
  }
`;

export const GQL_CREATE_XENDIT_QRCODE = gql`
  mutation createXenditQRCode($amount: Int!, $link: String!) {
    createXenditQRCode(amount: $amount, link: $link) {
      xenditResponse
    }
  }
`;
