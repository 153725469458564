/* eslint-disable no-undef */
import Cookies from 'js-cookie';
import { toCapitalizeEachText, broadcastOptions, generateRandomString, addMinutesToDate, parseJSON } from '.';
import imageCompression from 'browser-image-compression';

export const handleCompressImage = async (imageFile, setProgressState, formatFile = 'image/jpeg') => {
  const options = {
    maxSizeMB: 0.5,
    onProgress: setProgressState,
    alwaysKeepResolution: true,
    fileType: formatFile,
    maxWidthOrHeight: 1920
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    return error;
  }
};

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const getFileFromURL = (url, fileName = 'filename', isLocal = false) =>
  fetch(isLocal ? url : `${url}?noCache=${generateRandomString(5)}`, { mode: 'cors' }).then(async response => {
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  });

export const uploadImageCallback = async (
  file,
  uploadImageMutation,
  compressingProgress,
  formatFile = 'image/jpeg'
) => {
  const compressedImage = compressingProgress
    ? await handleCompressImage(file, compressingProgress, formatFile)
    : await handleCompressImage(file, compressingProgress, formatFile);
  const base64 = await getBase64(compressedImage);
  const image = { base64, fileType: compressedImage.type.split('/')[1], title: file.name };

  if (compressingProgress) compressingProgress(0);

  const res = await uploadImageMutation({ variables: image });

  return new Promise((resolve, reject) => {
    if (res.data.uploadImage.url) {
      const response = { data: { link: res.data.uploadImage.url, id: res.data.uploadImage.id } };
      resolve(response);
    } else {
      if (compressingProgress) compressingProgress(0);
      reject('Error');
    }
  });
};

export const uploadImageIteration = async (e, imageTitleId, uploadImage, handleChangeImage) => {
  const values = {};
  const compressedImage = await handleCompressImage(e.file);

  const base64 = await getBase64(compressedImage);

  values.base64 = base64;
  values.fileType = compressedImage.type.split('/')[1];
  values.title = imageTitleId;

  const res = await uploadImage({
    variables: values
  });

  handleChangeImage(res.data.uploadImage.id);

  return res;
};

export const incrementUpdatedAtImage = async (file, index, editImage) => {
  const minutes = (index + 1) * 10;
  const newUpdatedAt = addMinutesToDate(file.data.uploadImage.updatedAt, minutes);
  const res = await editImage({
    variables: {
      input: {
        id: file.data.uploadImage.id,
        updatedAt: newUpdatedAt
      }
    }
  });

  return res;
};

export const getBroadcastChannel = channel => {
  return toCapitalizeEachText(broadcastOptions.find(({ value }) => value === channel).label);
};

export const reduceImageSize = (base64, MAX_WIDTH = 650, MAX_HEIGHT = 450) => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
};

export const calculateImageSize = image => {
  const ySize = image.endsWith('==') ? 2 : 1;
  const xSize = image.length * (3 / 4);
  return Math.round((xSize - ySize) / 1024);
};

export const uploadFileKYCAWS = async file => {
  const body = new FormData();
  const headers = { Authorization: `Bearer ${Cookies.get('token')}` };
  body.append(
    'operations',
    '{ "query": "mutation uploadFileKYC($file: Upload!) { uploadFileKYC(file: $file) { id filename mimetype encoding } }", "variables": { "file": null} }'
  );
  body.append('map', '{ "0": ["variables.file"] }');
  body.append('0', file);

  const res = await fetch(`${process.env.REACT_APP_PROXY}`, {
    body,
    method: 'POST',
    headers
  });

  const result = await res.json();
  return result.data.uploadFileKYC;
};

export const uploadFilesKYCAWS = async files => {
  const body = new FormData();
  const headers = { Authorization: `Bearer ${Cookies.get('token')}` };

  body.append(
    'operations',
    `{ "query": "mutation uploadFilesKYC($files: [Upload]!) { uploadFilesKYC(files: $files) { id filename mimetype encoding } }", "variables": { "files": [${files.map(
      () => 'null'
    )}] } }`
  );

  const mapValue = {};
  files.forEach((v, i) => {
    mapValue[i] = [`variables.files.${i}`];
  });

  body.append('map', JSON.stringify(mapValue));

  files.forEach((file, i) => {
    body.append(i, file);
  });

  const res = await fetch(`${process.env.REACT_APP_PROXY}`, {
    body,
    method: 'POST',
    headers
  });

  const result = await res.json();
  return result.data.uploadFilesKYC;
};

export const findCurrentVariantProduct = ({ orderVariant, variant }) => {
  const parseOrderVariant = parseJSON(orderVariant, []);
  const parseVariant = parseJSON(variant, []);

  return parseVariant.find(item => item.id === parseOrderVariant[0]?.variantId);
};

export const getCurrentVariant = paymentLinkTransaction => {
  let currVariant = null;

  if (
    paymentLinkTransaction.orderVariant &&
    paymentLinkTransaction.paymentLink &&
    paymentLinkTransaction.paymentLink.variant
  ) {
    const variants = parseJSON(paymentLinkTransaction.paymentLink.variant, []);
    const orderVariant = parseJSON(paymentLinkTransaction.orderVariant, []);
    currVariant = variants.find(variant => variant.id === orderVariant[0].variantId);
  }

  return currVariant;
};
