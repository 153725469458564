import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import moment from 'moment-timezone';
import { FormGroup, Label, Alert, Progress } from 'reactstrap';
import {
  defaultSubmitState,
  dropzoneDefaultStyleConfig,
  handleCompressImage,
  getBase64,
  addMinutesToDate
} from '../../utils';
import MayarAlert from '../alert/alert';
import { v4 as uuidv4 } from 'uuid';
import { GQL_UPDATE_IMAGE, GQL_UPLOAD_IMAGE } from '../../gqls';
import { useMutation } from '@apollo/client';
import SubmitCoverButton from './submit-cover-button';
/* eslint-disable */
const MultipleImageInput = ({ productName, multipleImage, setMultipleImageId, setIsRemoveAllProductImage, onLoading }) => {
  const [submitState, setSubmitState] = useState(defaultSubmitState);
  const [loading, setLoading] = useState(false);
  const [imageState, setImageState] = useState([]);
  const [initialImagesFiles, setInitialImagesFiles] = useState([]);

  const [uploadImage] = useMutation(GQL_UPLOAD_IMAGE);
  const [editImage] = useMutation(GQL_UPDATE_IMAGE);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const setInitialImage = param => {
    const array = [...param];

    const sortedInitialImages = array.sort((a, b) => {
      if (a.name > b.name) return 1;
      else if (b.name > a.name) return -1;
      return 0;
    });

    setInitialImagesFiles(sortedInitialImages);
  };

  useEffect(() => {
    // Display initial existing image on dropzone
    if (multipleImage?.length > 0) {
      const array = [];
      const images = [...multipleImage];

      const sortedImages = images.sort((a, b) => moment(a.updatedAt).diff(b.updatedAt));

      sortedImages.forEach((obj, itemIndex) => {
        const splitted = obj?.url.split('/');
        const imageId = splitted[splitted.length - 1];

        /* eslint-disable-next-line no-undef */
        fetch(`${process.env.REACT_APP_PROXY}/image/${imageId}`).then(res => {
          res.arrayBuffer().then(buf => {
            /* eslint-disable-next-line no-undef */
            const file = new File([buf], `${productName}-${itemIndex}.jpg`, { type: 'image/jpeg' });
            array.push(file);

            if (array.length === multipleImage.length) {
              setInitialImage(array);
            }
          });
        });
      });
    }
  }, [multipleImage, imageState]);

  const uploadImageIteration = async (e, imageId) => {
    const values = {};
    const compressedImage = await handleCompressImage(e.file);
    const base64 = await getBase64(compressedImage);

    values.base64 = base64;
    values.fileType = compressedImage.type.split('/')[1];
    values.title = imageId;

    const res = await uploadImage({
      variables: values
    });

    return res;
  };

  const incrementUpdatedAtImage = async (file, indexItem) => {
    const minutes = (indexItem + 1) * 10;
    const newUpdatedAt = addMinutesToDate(file.data.uploadImage.updatedAt, minutes);

    const res = await editImage({
      variables: {
        input: {
          id: file.data.uploadImage.id,
          updatedAt: newUpdatedAt
        }
      }
    });

    return res;
  };

  const handleCoverUpload = async files => {
    setLoading(true);
    setSubmitState(defaultSubmitState);

    const imageTitleId = uuidv4();

    const resultArray = await Promise.all(files.map(async i => uploadImageIteration(i, imageTitleId)));
    const updatedImageArray = await Promise.all(
      resultArray.map(async (i, indexRes) => incrementUpdatedAtImage(i, indexRes))
    );

    if (updatedImageArray.length === files.length) {
      setMultipleImageId(imageTitleId);
      setImageState(updatedImageArray);

      setSubmitState({
        isError: false,
        isSuccess: true,
        message: 'Gambar berhasil diupload!'
      });
    } else {
      setSubmitState({
        isError: true,
        isSuccess: false,
        message: 'Upload gagal, silahkan coba lagi (png/jpg)'
      });
    }

    setLoading(false);
  };

  return (
    <FormGroup>
      <Label for="cover">Cover (gambar untuk promo)</Label>
      <MayarAlert isError={submitState.isError} isSuccess={submitState.isSuccess} message={submitState.message} />

      {loading && (
        <Alert color="light">
          <Progress animated color="primary" value="100" />
          Sedang mengunggah...
        </Alert>
      )}

      {!submitState.isSuccess && !loading && (
        <Dropzone
          onSubmit={handleCoverUpload}
          name="dropzone"
          accept="image/*"
          maxFiles={5}
          maxSizeBytes={5242880}
          canRemove={!loading}
          SubmitButtonComponent={props => <SubmitCoverButton props={props} loading={loading} />}
          initialFiles={initialImagesFiles}
          onChangeStatus={(...args) => {
            const files = args[2];
            if (files.length === 1 && args[1] === 'removed') setIsRemoveAllProductImage(true);
            else setIsRemoveAllProductImage(false)
          }}
          inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag & drop image')}
          styles={dropzoneDefaultStyleConfig.styles}
          classNames={dropzoneDefaultStyleConfig.classNames}
        />
      )}
    </FormGroup>
  );
};

MultipleImageInput.defaultProps = {
  onLoading: () => {}
};

export default MultipleImageInput;
