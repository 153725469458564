import DOMPurify from 'dompurify';

export const sanitizeDOM = dirty => {
  return DOMPurify.sanitize(dirty, {
    ADD_TAGS: ['iframe'],
    ALLOWED_ATTR: [
      'href',
      'src',
      'style',
      'allow',
      'allowfullscreen',
      'frameborder',
      'scrolling',
      'target',
      'height',
      'width'
    ]
  });
};

export const isFitForURLParam = string => {
  // First, encode the string using encodeURIComponent
  const encodedString = encodeURIComponent(string);

  // If they are the same, the string is fit for a URL parameter
  if (encodedString === string) {
    return true;
  }

  // Otherwise, the string contains characters that need encoding for URL parameters
  return false;
};

export const cleanYouTubeEmbedUrl = embedUrl => {
  // Use a regular expression to remove the 't' parameter
  const cleanedUrl = embedUrl.replace(/&t=\d+[smh]?/, '');

  return cleanedUrl;
};

export const sanitizeWhatsAppLink = whatsappLink => {
  const match = whatsappLink.match(/(https:\/\/api.whatsapp.com\/send\?phone=)(\+\d+)/);
  if (match) {
    const sanitizedLink = match[1] + match[2].replace('+', ''); // Remove the plus sign
    return sanitizedLink;
  }
  return whatsappLink; // Return the original link if it doesn't match the pattern
};

export const sanitizeStrict = dirty => {
  return DOMPurify.sanitize(dirty);
};
