/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { Row, Col, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { GET_PL_WITH_TRANSACTIONS } from './graphql';
import { paymentLinkDetails } from '../../actions/payment-links';
import { updatePaginatedData } from '../../utils';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const FundraisingDetail = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const updatePaymentLink = useSelector((state) => state.updatePaymentLinks);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = { search: { id: [{ operator: 'eq', value: id }] } };

  const { loading, error, data, fetchMore } = useQuery(GET_PL_WITH_TRANSACTIONS, {
    variables: gparam,
  });

  const paymentLink = _.get(data, 'getPaymentLinkPage.items[0]');
  if (paymentLink) dispatch(paymentLinkDetails(paymentLink));

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true,
    });
  };

  useEffect(() => {
    updateData();
  }, [updatePaymentLink, paymentLink]);

  if (error) return `Error! ${error.message}`;
  if (loading)
    return (
      <div className="center-of-screen">
        <Spinner></Spinner>
      </div>
    );
  if (data && !paymentLink) window.location.href = process.env.REACT_APP_BASE_URL;

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/fundraisings': 'Penggalangan Dana',
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{paymentLink.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default FundraisingDetail;
