import { gql } from '@apollo/client';

export const GQL_SUBSCRIPTION_GET = gql`
  query getSubscriptionPage($search: SubscriptionSearchInput) {
    getSubscriptionPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        amount
        billingCycle
        billingCycleAt
        billingCycleCustomPeriod
        billingCycleCustomPeriodTime
        createdAt
        initPayment
        initPaymentAmount
        limit
        link
        name
        description
        gracePeriod
        paymentAtStart
        redirectUrl
        updatedAt
        status
      }
      total
    }
  }
`;

export const GQL_SUBSCRIPTION_GET_PROXY = gql`
  query getSubscriptionDetail($id: ID!) {
    getSubscriptionDetail(id: $id) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      createdAt
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      form {
        fieldObject
      }
      gracePeriod
      paymentAtStart
      redirectUrl
      updatedAt
      status
    }
  }
`;

export const GQL_SUBSCRIPTION_PAGE = gql`
  query getSubscriptionPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: SubscriptionSortField
    $searchAny: SubscriptionSearchInput
    $search: SubscriptionSearchInput
  ) {
    getSubscriptionPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        amount
        billingCycle
        billingCycleAt
        billingCycleCustomPeriod
        billingCycleCustomPeriodTime
        createdAt
        initPayment
        initPaymentAmount
        limit
        link
        name
        description
        gracePeriod
        paymentAtStart
        redirectUrl
        updatedAt
        status
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_SUBSCRIPTION_PAGE_WITH_CUSTOMERS = gql`
  query getSubscriptionPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: SubscriptionSortField
    $searchAny: SubscriptionSearchInput
    $search: SubscriptionSearchInput
  ) {
    getSubscriptionPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        amount
        billingCycle
        billingCycleAt
        billingCycleCustomPeriod
        billingCycleCustomPeriodTime
        createdAt
        initPayment
        initPaymentAmount
        limit
        link
        name
        description
        gracePeriod
        paymentAtStart
        redirectUrl
        updatedAt
        status
        subscriptionCustomers {
          id
        }
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_SUBSCRIPTION_CREATE = gql`
  mutation insertSubscription($input: SubscriptionInput) {
    insertSubscription(input: $input) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      gracePeriod
      paymentAtStart
      redirectUrl
    }
  }
`;

export const GQL_SUBSCRIPTION_UPDATE = gql`
  mutation editSubscription($input: SubscriptionInput) {
    editSubscription(input: $input) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      gracePeriod
      paymentAtStart
      redirectUrl
    }
  }
`;

export const GQL_SUBSCRIPTION_UPDATE_BY_SLUG = gql`
  mutation editSubscriptionBySlug($input: SubscriptionInput!) {
    editSubscriptionBySlug(input: $input) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      gracePeriod
      paymentAtStart
      redirectUrl
    }
  }
`;

export const GQL_SUBSCRIPTION_CREATE_BY_SLUG = gql`
  mutation insertSubscriptionBySlug($input: SubscriptionInput!) {
    insertSubscriptionBySlug(input: $input) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      gracePeriod
      paymentAtStart
      redirectUrl
    }
  }
`;

export const GQL_SUBSCRIPTION_DELETE = gql`
  mutation deleteSubscription($id: ID) {
    deleteSubscription(id: $id)
  }
`;

export const GQL_UNSUBSCRIBE = gql`
  mutation unsubscribe($id: ID!) {
    unsubscribe(id: $id) {
      success
      errorMessage
    }
  }
`;
