export const FILTER_SUBSCRIPTION_CUSTOMER_BY_Q = 'FILTER_SUBSCRIPTION_CUSTOMER_BY_Q';
export const filterSubscriptionCustomerByQ = (q) => ({
  type: FILTER_SUBSCRIPTION_CUSTOMER_BY_Q,
  payload: q,
});

export const UPDATE_SUBSCRIPTION_CUSTOMERS = 'UPDATE_SUBSCRIPTION_CUSTOMERS';
export const updateSubscriptionCustomers = (q) => ({
  type: UPDATE_SUBSCRIPTION_CUSTOMERS,
  payload: q,
});

export const FILTER_SUBSCRIPTION_CUSTOMER_BY_STATUS = 'FILTER_SUBSCRIPTION_CUSTOMER_BY_STATUS';
export const filterSubscriptionCustomerByStatus = (q) => ({
  type: FILTER_SUBSCRIPTION_CUSTOMER_BY_STATUS,
  payload: q,
});

export const SUBSCRIPTION_CUSTOMER_DETAILS = 'SUBSCRIPTION_CUSTOMER_DETAILS';
export const subscriptionCustomerDetails = (q) => ({
  type: SUBSCRIPTION_CUSTOMER_DETAILS,
  payload: q,
});
