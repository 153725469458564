import { getUsername, getPlanType } from './url';

/*
1. premium
2. harus set website
3. apakah website nya buat request ke Mayar / cm buat company profile mereka
*/

export const propsRedux = () => next => action => {
  if (action.type === 'CURRENT_USER') {
    getUsername(action.payload.paymeLink);
    getPlanType(action.payload.account.planType, action.payload.isSelfDomain);
  }
  const returnValue = next(action);
  return returnValue;
};

export default propsRedux;
