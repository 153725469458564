import { gql } from '@apollo/client';

export const GQL_GET_DOMAIN_STATISTIC_ACKEE = gql`
  query getStatisticsDomainAckee($input: AckeeStatisticsInput) {
    getStatisticsDomainAckee(input: $input) {
      statistics {
        durations {
          id
          count
        }
        views {
          id
          value
          count
        }
      }
    }
  }
`;

export const GQL_GET_DOMAIN_PRODUCT_STATISTIC_ACKEE = gql`
  query getStatisticsDomainProductAckee($input: AckeeProductInput) {
    getStatisticsDomainProductAckee(input: $input) {
      result
    }
  }
`;
export const GQL_GET_ANALYTICS_STATISTIC_ACKEE = gql`
  query getStatisticsAnalyticsAckee($input: AckeeStatisticsInput) {
    getStatisticsAnalyticsAckee(input: $input) {
      statistics {
        durations {
          id
          value
          count
        }
        views {
          id
          value
          count
        }
        languages {
          id
          value
          count
        }
        browsers {
          id
          value
          count
        }
        devices {
          id
          value
          count
        }
        pages {
          id
          value
          count
        }
        referrers {
          id
          value
          count
        }
        sizes {
          id
          value
          count
        }
        systems {
          id
          value
          count
        }
      }
    }
  }
`;

export const GET_ANALYTIC_PRODUCT = gql`
  query getAnalyticProduct($input: PaymentLinkInput!) {
    getAnalyticProduct(input: $input) {
      errorMessage
      urlParams {
        detail {
          id
          value
          count
        }
        checkout {
          id
          value
          count
        }
      }
      locations {
        id
        value
        count
      }
			facts {
				activeVisitors
				averageViews {
					count
					change
				}
				averageDuration {
					count
					change
				}
				viewsToday
				viewsMonth
				viewsYear
			}
      statistics {
        durations {
          id
          value
          count
        }
        views {
          id
          value
          count
        }
        languages {
          id
          value
          count
        }
        browsers {
          id
          value
          count
        }
        pages {
          id
          value
          count
        }
        referrers {
          id
          value
          count
        }
        sizes {
          id
          value
          count
        }
        systems {
          id
          value
          count
        }
      }
    }
  }
`;
