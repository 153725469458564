import { gql } from '@apollo/client';

export const GQL_COUPONS_USAGE_FILTERED_LIST = gql`
  query getCouponUsageFilteredList($search: CouponUsageSearchInput) {
    getCouponUsageFilteredList(search: $search) {
      id
      customerId
      createdAt
      coupons {
        code
        isActive
        limit
        createdAt
        expiredAt
      }
      customer {
        name
        createdAt
        email
      }
      paymentLinkTransaction {
        status
      }
    }
  }
`;

export const GQL_COUPONS_USAGE_PAGE = gql`
  query getCouponUsagePage(
    $page: Int
    $pageSize: Int
    $search: CouponUsageSearchInput
    $searchAny: CouponUsageSearchInput
    $sortDirection: SortDirection
    $sortField: CouponUsageSortField
  ) {
    getCouponUsagePage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      offset
      page
      prevPage
      total
      totalPage
      items {
        createdAt
        customerId
        paymentLinkTransactionId
        updatedAt
        userId
        customer {
          name
          status
          email
        }
        paymentLinkTransaction {
          status
        }
      }
    }
  }
`;

export const GQL_GET_REFERRAL_MEMBER = gql`
  query getReferralMember(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CouponUsageSortField
    $search: CouponUsageSearchInput
  ) {
    getReferralMember(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
    ) {
      items {
        couponsId
        createdAt
        referralUserId
        updatedAt
        userId
        referralUser {
          kyc {
            status
          }
          email
          name
          isReferral
          createdAt
          account {
            planType
          }
        }
      }
      total
      nextPage
    }
  }
`;
