import { gql } from '@apollo/client';

export const CREATE_MEMBERSHIP_BY_SLUG = gql`
  mutation insertPaymentLinkBySlug($input: PaymentLinkInput!) {
    insertPaymentLinkBySlug(input: $input) {
      id
    }
  }
`;

export const UPDATE_MEMBERSHIP_BY_SLUG = gql`
  mutation editPaymentLinkBySlug($input: PaymentLinkInput!) {
    editPaymentLinkBySlug(input: $input) {
      id
    }
  }
`;

export const GET_MEMBERSHIP_TOTAL = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $search: PaymentLinkSearchInput
    $searchAny: PaymentLinkSearchInput
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      total
    }
  }
`;

export const GET_MEMBERSHIP_DETAIL = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkSortField
    $searchAny: PaymentLinkSearchInput
    $search: PaymentLinkSearchInput
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        status
        link
        redirectUrl
        description
        name
        amount
        startAt
        createdAt
        urlParamTracking
        isDownloadable
        type
        checkoutSettings {
          id
          buttonName
          buttonNote
          discountButton
          couponOption
          paymentLinkId
          termOption
          termUrl
        }
        content {
          file {
            id
          }
        }
        user {
          bunnyLibraryId
        }
        form {
          fieldObject
          id
          name
        }
        coverImage {
          url
        }
        multipleImage {
          url
          updatedAt
        }
        membershipInfo {
          id
          type
          showMembers
        }
        membershipTier {
          id
          description
          limit
          name
          status
          paymentAtStart
          paymentLinkId
          finishMembershipAt
          membershipCustomer {
            id
            status
            customer {
              name
              email
            }
          }
          membershipTierProducts {
            id
            paymentLinkId
            membershipTierId
            status
            position
            variantId
            paymentLink {
              id
              name
              type
            }
          }
        }
        courseSections {
          description
          id
          name
          position
          link
          status
          isPublicPreview
          isBlurCoverImage
          coverImage {
            id
            url
          }
          membershipTierContents {
            courseSectionId
            membershipTierId
            id
            status
          }
          courseModules {
            id
            name
            type
            position
            status
            link
            coachingUrl
            description
            embedUrl
            isPreview
            isVisible
            isContentNotProtected
            quiz
            startAt
            endAt
            content {
              file {
                id
                filename
                size
                mimetype
                createdAt
                bunnyVideoId
              }
              id
              createdAt
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_MEMBERSHIP_TIER_PERIODS = gql`
  query getMembershipTierFilteredList($search: MembershipTierSearchInput, $limit: Int) {
    getMembershipTierFilteredList(search: $search, limit: $limit) {
      id
      membershipTierPeriod {
        id
        monthPeriod
        amount
        status
      }
    }
  }
`;

export const INSERT_MULTIPLE_MEMBERSHIP_TIER_PERIOD = gql`
  mutation insertMultipleMembershipTierPeriod($input: [MembershipTierPeriodInput]) {
    insertMultipleMembershipTierPeriod(input: $input) {
      id
    }
  }
`;

export const UPDATE_MULTIPLE_MEMBERSHIP_TIER_PERIOD = gql`
  mutation editMultipleMembershipTierPeriod($input: [MembershipTierPeriodInput]) {
    editMultipleMembershipTierPeriod(input: $input) {
      id
    }
  }
`;

export const GQL_INSERT_MEMBERSHIP_TIER = gql`
  mutation insertMembershipTier($input: MembershipTierInput) {
    insertMembershipTier(input: $input) {
      id
    }
  }
`;

export const GQL_EDIT_MEMBERSHIP_TIER = gql`
  mutation editMembershipTier($input: MembershipTierInput) {
    editMembershipTier(input: $input) {
      id
    }
  }
`;

export const EDIT_MEMBERSHIP_TIER = gql`
  mutation editMembershipTierDashboard($input: MembershipTierInput!) {
    editMembershipTierDashboard(input: $input) {
      id
    }
  }
`;

export const GET_MEMBERSHIP_CUSTOMER_TOTAL = gql`
  query getMembershipCustomerPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: MembershipCustomerSortField
    $search: MembershipCustomerSearchInput
    $searchAny: MembershipCustomerSearchInput
  ) {
    getMembershipCustomerPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
      searchAny: $searchAny
    ) {
      total
    }
  }
`;

export const GQL_MEMBER_TABLE = gql`
  query getMembershipCustomerPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: MembershipCustomerSortField
    $search: MembershipCustomerSearchInput
    $searchAny: MembershipCustomerSearchInput
  ) {
    getMembershipCustomerPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
      searchAny: $searchAny
    ) {
      items {
        id
        createdAt
        customerId
        membershipTierId
        nextPayment
        status
        updatedAt
        userId
        memberId
        membershipTier {
          name
          gracePeriodInDays
        }
        customer {
          name
          mobile
          email
        }
      }
      nextPage
    }
  }
`;

export const GQL_MEMBER_LIST_TABLE = gql`
  query getMemberListPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: MembershipCustomerSortField
    $paymentLinkId: ID
    $startDate: String
    $endDate: String
  ) {
    getMemberListPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      paymentLinkId: $paymentLinkId
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        id
        createdAt
        customerId
        membershipTierId
        nextPayment
        status
        updatedAt
        userId
        memberId
        membershipTier {
          name
          gracePeriodInDays
        }
        customer {
          name
          mobile
          email
        }
        customeField {
          data
        }
      }
      nextPage
    }
  }
`;

export const GET_ACTIVE_MEMBERSHIP_INVOICE = gql`
  query getPaymentLinkPage(
    $page: Int
    $pageSize: Int
    $search: PaymentLinkSearchInput = {}
    $searchAny: PaymentLinkSearchInput = {}
    $sortDirection: SortDirection = ASC
    $sortField: PaymentLinkSortField = amount
  ) {
    getPaymentLinkPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        name
        membershipTierId
        membershipCustomerId
        status
        amount
        customerId
        link
        updatedAt
        customer {
          name
          email
          mobile
        }
      }
      nextPage
    }
  }
`;

export const GET_MEMBERSHIP_TIER_OPTIONS = gql`
  query getMembershipTierFilteredList(
    $search: MembershipTierSearchInput
    $limit: Int
    $searchAny: MembershipTierSearchInput
  ) {
    getMembershipTierFilteredList(search: $search, limit: $limit, searchAny: $searchAny) {
      id
      name
      userId
    }
  }
`;

export const ASSIGN_MEMBER_TO_TIER = gql`
  mutation assigningTierForMember(
    $id: ID!
    $membershipTierIdDestination: ID!
    $sendNotifToCustomer: Boolean
    $monthlyPaymentPeriod: Int!
  ) {
    assigningTierForMember(
      id: $id
      membershipTierIdDestination: $membershipTierIdDestination
      sendNotifToCustomer: $sendNotifToCustomer
      monthlyPaymentPeriod: $monthlyPaymentPeriod
    ) {
      id
      errorMessage
    }
  }
`;

export const EDIT_MEMBERSHIP_CUSTOMER_PERIOD = gql`
  mutation editMembershipCustomer($input: MembershipCustomerInput) {
    editMembershipCustomer(input: $input) {
      id
    }
  }
`;

export const INSERT_MEMBERSHIP_TIER_CONTENT = gql`
  mutation insertMembershipTierContent($input: MembershipTierContentInput) {
    insertMembershipTierContent(input: $input) {
      courseSectionId
      createdAt
      membershipTierId
      status
      updatedAt
      id
    }
  }
`;

export const UPDATE_MEMBERSHIP_TIER_CONTENT = gql`
  mutation editMembershipTierContent($input: MembershipTierContentInput) {
    editMembershipTierContent(input: $input) {
      courseSectionId
      createdAt
      membershipTierId
      status
      updatedAt
      id
    }
  }
`;

export const GET_MEMBERSHIP_TIER_CONTENT_FILTER = gql`
  query getMembershipTierContentFilteredList($input: MembershipTierContentSearchInput) {
    getMembershipTierContentFilteredList(search: $input) {
      courseSectionId
      createdAt
      membershipTierId
      status
      updatedAt
      id
    }
  }
`;

export const REORDER_LIST_POSITION = gql`
  mutation reorderList($input: ReorderList) {
    reorderList(input: $input) {
      id
    }
  }
`;

export const GET_TIER_PRODUCTS = gql`
  query getMembershipTierProductFilteredList(
    $search: MembershipTierProductSearchInput
    $sortDirection: SortDirection
    $sortField: MembershipTierProductSortField
    $limit: Int
  ) {
    getMembershipTierProductFilteredList(
      search: $search
      sortDirection: $sortDirection
      sortField: $sortField
      limit: $limit
    ) {
      id
      paymentLinkId
      membershipTierId
      status
      position
      variantId
      paymentLink {
        id
        name
        type
        variant
      }
    }
  }
`;
