import { gql } from '@apollo/client';

export const GQL_LANDING_PAGE_PAGE = gql`
  query getLandingPagePage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: LandingPageSortField
    $searchAny: LandingPageSearchInput
    $search: LandingPageSearchInput
  ) {
    getLandingPagePage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        id
        link
        name
        status
        type
        createdAt
        updatedAt
        userId
      }
      nextPage
    }
  }
`;

export const GQL_LANDING_PAGE_UPDATE_BY_SLUG = gql`
  mutation editLandingPageBySlug($input: LandingPageInput!) {
    editLandingPageBySlug(input: $input) {
      id
      createdAt
      design
      htmlResult
      link
      metaDescription
      metaKeyword
      metaTitle
      name
      status
      updatedAt
      userId
      coverImageId
    }
  }
`;

export const GQL_LANDING_PAGE_CREATE_BY_SLUG = gql`
  mutation insertLandingPageBySlug($input: LandingPageInput!) {
    insertLandingPageBySlug(input: $input) {
      id
      createdAt
      design
      htmlResult
      link
      metaDescription
      metaKeyword
      metaTitle
      name
      status
      updatedAt
      userId
    }
  }
`;

export const GQL_LANDING_PAGE = gql`
  query getLandingPageUser($id: ID!) {
    getLandingPageUser(id: $id) {
      createdAt
      design
      htmlResult
      id
      link
      metaDescription
      metaKeyword
      metaTitle
      name
      status
      type
      updatedAt
      userId
      updatedAt
      coverImageId
      isUnlayerDesignAmp
      coverImage {
        url
        id
      }
      user {
        account {
          name
        }
      }
    }
  }
`;

export const GET_VISITOR_FORM_LANDING_PAGE = gql`
  query getVisitorFormLandingPage($landingPageId: ID!) {
    getVisitorFormLandingPage(landingPageId: $landingPageId) {
      status
      items {
        id
        form
        visitors
      }
    }
  }
`;
