export const bankCodes = [
  {
    BankCode: 'ACEH',
    Bank: 'BPD Aceh'
  },
  {
    BankCode: 'ACEH_UUS',
    Bank: 'BPD Aceh UUS'
  },
  {
    BankCode: 'AGRIS',
    Bank: 'Bank Agris'
  },
  {
    BankCode: 'AGRONIAGA',
    Bank: 'Bank BRI Agroniaga'
  },
  {
    BankCode: "ALLO",
    Bank: "Allo Bank Indonesia (formerly Bank Harda Internasional)",
  },
  {
    BankCode: 'AMAR',
    Bank: 'Bank Amar Indonesia (formerly Anglomas International Bank)'
  },
  {
    BankCode: 'ANZ',
    Bank: 'Bank ANZ Indonesia'
  },
  {
    BankCode: 'ARTA_NIAGA_KENCANA',
    Bank: 'Bank Arta Niaga Kencana'
  },
  {
    BankCode: 'ARTHA',
    Bank: 'Bank Artha Graha International'
  },
  {
    BankCode: 'ARTOS',
    Bank: 'Bank Artos Indonesia'
  },
  {
    BankCode: 'BALI',
    Bank: 'BPD Bali'
  },
  {
    BankCode: 'BAML',
    Bank: 'Bank of America Merill-Lynch'
  },
  {
    BankCode: 'BANGKOK',
    Bank: 'Bangkok Bank'
  },
  {
    BankCode: 'BANTEN',
    Bank: 'BPD Banten (formerly Bank Pundi Indonesia)'
  },
  {
    BankCode: 'BCA',
    Bank: 'Bank Central Asia (BCA)'
  },
  {
    BankCode: 'BCA_SYR',
    Bank: 'Bank Central Asia (BCA) Syariah'
  },
  {
    BankCode: 'BCA_DIGITAL',
    Bank: 'Bank Central Asia Digital (BluBCA)'
  },
  {
    BankCode: 'BENGKULU',
    Bank: 'BPD Bengkulu'
  },
  {
    BankCode: 'BISNIS_INTERNASIONAL',
    Bank: 'Bank Bisnis Internasional'
  },
  {
    BankCode: 'BJB',
    Bank: 'Bank BJB'
  },
  {
    BankCode: 'BJB_SYR',
    Bank: 'Bank BJB Syariah'
  },
  {
    BankCode: 'BNI',
    Bank: 'Bank Negara Indonesia (BNI)'
  },
  // {
  //   BankCode: 'BNI_SYR',
  //   Bank: 'Bank BNI Syariah'
  // },
  {
    BankCode: 'BNP_PARIBAS',
    Bank: 'Bank BNP Paribas'
  },
  {
    BankCode: 'BOC',
    Bank: 'Bank of China (BOC)'
  },
  {
    BankCode: 'BRI',
    Bank: 'Bank Rakyat Indonesia (BRI)'
  },
  {
    BankCode: 'BRI_SYR',
    Bank: 'Bank Syariah BRI'
  },
  {
    BankCode: 'BTN',
    Bank: 'Bank Tabungan Negara (BTN)'
  },
  {
    BankCode: 'BTN_UUS',
    Bank: 'Bank Tabungan Negara (BTN) UUS'
  },
  {
    BankCode: 'BTPN_SYARIAH',
    Bank: 'BTPN Syariah (formerly Bank Sahabat Purba Danarta and Bank Tabungan Pensiunan Nasional UUS)'
  },
  {
    BankCode: 'BUKOPIN',
    Bank: 'Bank Bukopin'
  },
  {
    BankCode: 'BUKOPIN_SYR',
    Bank: 'Bank Syariah Bukopin'
  },
  {
    BankCode: 'BSI',
    Bank: 'Bank Syariah Indonesia (BSI)'
  },
  {
    BankCode: 'BUMI_ARTA',
    Bank: 'Bank Bumi Arta'
  },
  {
    BankCode: 'CAPITAL',
    Bank: 'Bank Capital Indonesia'
  },
  {
    BankCode: 'CCB',
    Bank: 'China Construction Bank Indonesia (formerly Bank Antar Daerah and Bank Windu Kentjana International)'
  },
  {
    BankCode: 'CENTRATAMA',
    Bank: 'Centratama Nasional Bank'
  },
  {
    BankCode: 'CHINATRUST',
    Bank: 'Bank Chinatrust Indonesia'
  },
  {
    BankCode: 'CIMB',
    Bank: 'Bank CIMB Niaga'
  },
  {
    BankCode: 'CIMB_UUS',
    Bank: 'Bank CIMB Niaga UUS'
  },
  {
    BankCode: 'CITIBANK',
    Bank: 'Citibank'
  },
  {
    BankCode: 'COMMONWEALTH',
    Bank: 'Bank Commonwealth'
  },
  {
    BankCode: 'DAERAH_ISTIMEWA',
    Bank: 'BPD Daerah Istimewa Yogyakarta (DIY)'
  },
  {
    BankCode: 'DAERAH_ISTIMEWA_UUS',
    Bank: 'BPD Daerah Istimewa Yogyakarta (DIY) UUS'
  },
  {
    BankCode: 'DANAMON',
    Bank: 'Bank Danamon'
  },
  {
    BankCode: 'DANAMON_UUS',
    Bank: 'Bank Danamon UUS'
  },
  {
    BankCode: 'DBS',
    Bank: 'Bank DBS Indonesia'
  },
  {
    BankCode: 'DEUTSCHE',
    Bank: 'Deutsche Bank'
  },
  {
    BankCode: 'DINAR_INDONESIA',
    Bank: 'Bank Dinar Indonesia'
  },
  {
    BankCode: 'DKI',
    Bank: 'Bank DKI'
  },
  {
    BankCode: 'DKI_UUS',
    Bank: 'Bank DKI UUS'
  },
  {
    BankCode: 'EXIMBANK',
    Bank: 'Indonesia Eximbank (formerly Bank Ekspor Indonesia)'
  },
  {
    BankCode: 'FAMA',
    Bank: 'Bank Fama International'
  },
  {
    BankCode: 'GANESHA',
    Bank: 'Bank Ganesha'
  },
  {
    BankCode: 'HANA',
    Bank: 'Bank Hana'
  },
  {
    BankCode: 'HARDA_INTERNASIONAL',
    Bank: 'Bank Harda Internasional'
  },
  {
    BankCode: 'HSBC',
    Bank: 'Hongkong and Shanghai Bank Corporation (HSBC) (formerly Bank Ekonomi Raharja)'
  },
  {
    BankCode: 'ICBC',
    Bank: 'Bank ICBC Indonesia'
  },
  {
    BankCode: 'INA_PERDANA',
    Bank: 'Bank Ina Perdania'
  },
  {
    BankCode: 'INDEX_SELINDO',
    Bank: 'Bank Index Selindo'
  },
  {
    BankCode: 'INDIA',
    Bank: 'Bank of India Indonesia'
  },
  {
    BankCode: 'JAMBI',
    Bank: 'BPD Jambi'
  },
  {
    BankCode: 'JAMBI_UUS',
    Bank: 'BPD Jambi UUS'
  },
  {
    BankCode: 'JASA_JAKARTA',
    Bank: 'Bank Jasa Jakarta'
  },
  {
    BankCode: 'JAWA_TENGAH',
    Bank: 'BPD Jawa Tengah'
  },
  {
    BankCode: 'JAWA_TENGAH_UUS',
    Bank: 'BPD Jawa Tengah UUS'
  },
  {
    BankCode: 'JAWA_TIMUR',
    Bank: 'BPD Jawa Timur'
  },
  {
    BankCode: 'JAWA_TIMUR_UUS',
    Bank: 'BPD Jawa Timur UUS'
  },
  {
    BankCode: 'JPMORGAN',
    Bank: 'JP Morgan Chase Bank'
  },
  {
    BankCode: 'JTRUST',
    Bank: 'Bank JTrust Indonesia (formerly Bank Mutiara)'
  },
  {
    BankCode: 'KALIMANTAN_BARAT',
    Bank: 'BPD Kalimantan Barat'
  },
  {
    BankCode: 'KALIMANTAN_BARAT_UUS',
    Bank: 'BPD Kalimantan Barat UUS'
  },
  {
    BankCode: 'KALIMANTAN_SELATAN',
    Bank: 'BPD Kalimantan Selatan'
  },
  {
    BankCode: 'KALIMANTAN_SELATAN_UUS',
    Bank: 'BPD Kalimantan Selatan UUS'
  },
  {
    BankCode: 'KALIMANTAN_TENGAH',
    Bank: 'BPD Kalimantan Tengah'
  },
  {
    BankCode: 'KALIMANTAN_TIMUR',
    Bank: 'BPD Kalimantan Timur'
  },
  {
    BankCode: 'KALIMANTAN_TIMUR_UUS',
    Bank: 'BPD Kalimantan Timur UUS'
  },
  {
    BankCode: 'KESEJAHTERAAN_EKONOMI',
    Bank: 'Bank Kesejahteraan Ekonomi'
  },
  {
    BankCode: 'LAMPUNG',
    Bank: 'BPD Lampung'
  },
  {
    BankCode: 'MALUKU',
    Bank: 'BPD Maluku'
  },
  {
    BankCode: 'MANDIRI',
    Bank: 'Bank Mandiri'
  },
  {
    BankCode: 'MANDIRI_SYR',
    Bank: 'Bank Syariah Mandiri'
  },
  {
    BankCode: 'MANDIRI_TASPEN',
    Bank: 'Mandiri Taspen Pos (formerly Bank Sinar Harapan Bali)'
  },
  {
    BankCode: 'MASPION',
    Bank: 'Bank Maspion Indonesia'
  },
  {
    BankCode: 'MAYAPADA',
    Bank: 'Bank Mayapada International'
  },
  {
    BankCode: 'MAYBANK',
    Bank: 'Bank Maybank (formerly BII)'
  },
  {
    BankCode: 'MAYBANK_SYR',
    Bank: 'Bank Maybank Syariah Indonesia'
  },
  {
    BankCode: 'MAYORA',
    Bank: 'Bank Mayora'
  },
  {
    BankCode: 'MEGA',
    Bank: 'Bank Mega'
  },
  {
    BankCode: 'MEGA_SYR',
    Bank: 'Bank Syariah Mega'
  },
  {
    BankCode: 'MESTIKA_DHARMA',
    Bank: 'Bank Mestika Dharma'
  },
  {
    BankCode: 'MITRA_NIAGA',
    Bank: 'Bank Mitra Niaga'
  },
  {
    BankCode: 'MITSUI',
    Bank: 'Bank Sumitomo Mitsui Indonesia'
  },
  {
    BankCode: 'MIZUHO',
    Bank: 'Bank Mizuho Indonesia'
  },
  {
    BankCode: 'MNC_INTERNASIONAL',
    Bank: 'Bank MNC Internasional'
  },
  {
    BankCode: 'MUAMALAT',
    Bank: 'Bank Muamalat Indonesia'
  },
  {
    BankCode: 'MULTI_ARTA_SENTOSA',
    Bank: 'Bank Multi Arta Sentosa'
  },
  {
    BankCode: 'NATIONALNOBU',
    Bank: 'Bank Nationalnobu'
  },
  {
    BankCode: 'NUSA_TENGGARA_BARAT',
    Bank: 'BPD Nusa Tenggara Barat'
  },
  {
    BankCode: 'NUSA_TENGGARA_BARAT_UUS',
    Bank: 'BPD Nusa Tenggara Barat UUS'
  },
  {
    BankCode: 'NUSA_TENGGARA_TIMUR',
    Bank: 'BPD Nusa Tenggara Timur'
  },
  {
    BankCode: 'NUSANTARA_PARAHYANGAN',
    Bank: 'Bank Nusantara Parahyangan'
  },
  {
    BankCode: 'OCBC',
    Bank: 'Bank OCBC NISP'
  },
  {
    BankCode: 'OCBC_UUS',
    Bank: 'Bank OCBC NISP UUS'
  },
  {
    BankCode: 'OKE',
    Bank: 'Bank Oke Indonesia (formerly Bank Andara)'
  },
  {
    BankCode: 'PANIN',
    Bank: 'Bank Panin'
  },
  {
    BankCode: 'PANIN_SYR',
    Bank: 'Bank Panin Syariah'
  },
  {
    BankCode: 'PAPUA',
    Bank: 'BPD Papua'
  },
  {
    BankCode: 'PERMATA',
    Bank: 'Bank Permata'
  },
  {
    BankCode: 'PERMATA_UUS',
    Bank: 'Bank Permata UUS'
  },
  {
    BankCode: 'PRIMA_MASTER',
    Bank: 'Prima Master Bank'
  },
  {
    BankCode: 'QNB_INDONESIA',
    Bank: 'Bank QNB Indonesia (formerly Bank QNB Kesawan)'
  },
  {
    BankCode: 'RABOBANK',
    Bank: 'Bank Rabobank International Indonesia'
  },
  {
    BankCode: 'RBS',
    Bank: 'Royal Bank of Scotland (RBS)'
  },
  {
    BankCode: 'RESONA',
    Bank: 'Bank Resona Perdania'
  },
  {
    BankCode: 'RIAU_DAN_KEPRI',
    Bank: 'BPD Riau Dan Kepri'
  },
  {
    BankCode: 'RIAU_DAN_KEPRI_UUS',
    Bank: 'BPD Riau Dan Kepri UUS'
  },
  {
    BankCode: 'ROYAL',
    Bank: 'Bank Royal Indonesia'
  },
  {
    BankCode: 'SAHABAT_SAMPOERNA',
    Bank: 'Bank Sahabat Sampoerna'
  },
  {
    BankCode: 'SBI_INDONESIA',
    Bank: 'Bank SBI Indonesia'
  },
  {
    BankCode: 'SHINHAN',
    Bank: 'Bank Shinhan Indonesia (formerly Bank Metro Express)'
  },
  {
    BankCode: 'SINARMAS',
    Bank: 'Sinarmas'
  },
  {
    BankCode: 'SINARMAS_UUS',
    Bank: 'Bank Sinarmas UUS'
  },
  {
    BankCode: 'STANDARD_CHARTERED',
    Bank: 'Standard Charted Bank'
  },
  {
    BankCode: 'SULAWESI',
    Bank: 'BPD Sulawesi Tengah'
  },
  {
    BankCode: 'SULAWESI_TENGGARA',
    Bank: 'BPD Sulawesi Tenggara'
  },
  {
    BankCode: 'SULSELBAR',
    Bank: 'BPD Sulselbar'
  },
  {
    BankCode: 'SULSELBAR_UUS',
    Bank: 'BPD Sulselbar UUS'
  },
  {
    BankCode: 'SULUT',
    Bank: 'BPD Sulut'
  },
  {
    BankCode: 'SUMATERA_BARAT',
    Bank: 'BPD Sumatera Barat'
  },
  {
    BankCode: 'SUMATERA_BARAT_UUS',
    Bank: 'BPD Sumatera Barat UUS'
  },
  {
    BankCode: 'SUMSEL_DAN_BABEL',
    Bank: 'BPD Sumsel Dan Babel'
  },
  {
    BankCode: 'SUMSEL_DAN_BABEL_UUS',
    Bank: 'BPD Sumsel Dan Babel UUS'
  },
  {
    BankCode: 'SUMUT',
    Bank: 'BPD Sumut'
  },
  {
    BankCode: 'SUMUT_UUS',
    Bank: 'BPD Sumut UUS'
  },
  {
    BankCode: 'TABUNGAN_PENSIUNAN_NASIONAL',
    Bank: 'BTPN (Bank Tabungan Pensiunan Nasional)'
  },
  {
    BankCode: 'TOKYO',
    Bank: 'Bank of Tokyo Mitsubishi UFJ'
  },
  {
    BankCode: 'UOB',
    Bank: 'UOB Indonesia'
  }
];
