export const FILTER_SUBSCRIPTION_BY_Q = 'FILTER_SUBSCRIPTION_BY_Q';
export const filterSubscriptionByQ = (q) => ({
  type: FILTER_SUBSCRIPTION_BY_Q,
  payload: q,
});

export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
export const updateSubscriptions = (q) => ({
  type: UPDATE_SUBSCRIPTIONS,
  payload: q,
});

export const FILTER_SUBSCRIPTION_BY_STATUS = 'FILTER_SUBSCRIPTION_BY_STATUS';
export const filterSubscriptionByStatus = (q) => ({
  type: FILTER_SUBSCRIPTION_BY_STATUS,
  payload: q,
});

export const SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS';
export const subscriptionDetails = (q) => ({
  type: SUBSCRIPTION_DETAILS,
  payload: q,
});
