import { gql } from '@apollo/client';

export const GET_PL_WITH_TRANSACTIONS = gql`
  query getPaymentLinkPage($search: PaymentLinkSearchInput) {
    getPaymentLinkPage(page: 1, pageSize: 1, search: $search) {
      items {
        id
        name
        link
        status
        description
        eventUrl
        startAt
        eventStartAt
        eventEndAt
        amount
        limit
        expiredAt
        notes
        redirectUrl
        createdAt
        formId
        category
        receiver
        reason
        usage
        subType
        target
        qurbanType
        type
        qty
        fundraisingSettings
        urlParamTracking
        form {
          fieldObject
          id
          name
        }
        coverImage {
          id
          url
        }
        multipleImage {
          url
          updatedAt
        }
        certificate {
          id
          layout
          name
          templateImageId
          templateImage {
            id
            url
            title
          }
        }
      }
    }
  }
`;
