import './style.scss';
import React, { useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
  CustomInput
} from 'reactstrap';

import { GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_PAYMENTLINK_UPDATE_BY_SLUG } from '../../../gqls';
import { updatePaymentLinks } from '../../../actions/payment-links';
import {
  validator,
  paymentLinkUrl,
  REGEX_SLUG,
  paymentTypeOptions,
  slug,
  sanitizeDOM
} from '../../../utils';
import { CustomStyles, CustomStylesInvalid } from '../../custom-styles/react-select-styles';
import Icon from '../../icon';
import DatePicker from '../../date-time-picker';
import SubmitButton from '../../submit-button';
import RichEditor, { getContentString, getEditorContent, getPlainText } from '../../rich-text-editor';
import MultipleImageInput from "../../multiple-image-input"

const ModalOnlineCourse = ({ classData, isOpen, isUpdate, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [link, setLink] = useState(classData?.link ?? '');
  const [paymentType, setPaymentType] = useState(classData?.paymentType || 'paid');

  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);

  const [insertPaymentLinkBySlug, { loading: insertLoading }] = useMutation(GQL_PAYMENTLINK_CREATE_BY_SLUG);
  const [editPaymentLinkBySlug, { loading: editLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);
  
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const isLoading = insertLoading || editLoading;

  const onSubmit = async values => {
    if (isDescriptionEmpty) return;

    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) {
      values.amount = 0;
    } else {
      values.amount = parseFloat(values.amount);
    }

    const variables = {
      ...values,
      status: 'active',
      expiredAt: values.expiredAt || null,
      limit: values.limit ? parseInt(values.limit) : null,
      gradeThreshold: values.gradeThreshold ? parseFloat(values.gradeThreshold) : null,
      description: sanitizeDOM(getContentString(values.description)),
      ...(isUpdate ? { id: classData?.id } : { type: 'course', link: slug(values.name) }),
      ...(multipleImageId && { multipleImageId }),
      ...(isRemoveAllProductImage && { multipleImageId: null }),
    };

    const isOverLimit = false;

    try {
      if (isUpdate) {
        await editPaymentLinkBySlug({ variables: { input: variables } });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        const res = await insertPaymentLinkBySlug({ variables: { input: variables } });
        onClose();
        onSuccess(res.data.insertPaymentLinkBySlug.id);
        window.analytics.track('Create Online Course', values);
      }
    } catch (err) {
      if (isOverLimit) {
        setSubmitError(err);
      } else {
        setSubmitError('Error! Silahkan coba lagi atau hubungi CS');
      }

      window.analytics.track('Create Online Course - ERROR', values);
    }
  };

  const submitText = () => {
    if (isUpdate) {
      return 'Edit Kelas Online';
    }

    return 'Buat Kelas Online';
  };

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Kelas Online</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Membuat dan menjual Kelas / Kursus online (on demand), lengkap dengan halaman katalog kelas, pembayaran, dan
            pembelajaran bagi murid anda.
          </small>
        </p>
        <hr />
        {submitError && (
          <div className="mb-10">
            <Alert color="danger">{submitError}</Alert>
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Kelas*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={!!errors.name}
              defaultValue={classData.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="link">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={!!errors.link}
                defaultValue={classData.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="paymentType">Tipe Pembayaran</Label>
            <Controller
              name="paymentType"
              control={control}
              defaultValue={paymentType}
              render={({ onChange, name, value: defaultPaymentType }) => (
                <Select
                  id={name}
                  name={name}
                  options={paymentTypeOptions}
                  defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                  placeholder="Pilih Tipe Pembayaran"
                  styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setPaymentType(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>
          {paymentType === 'paid' && (
            <FormGroup>
              <Label for="amount">Harga*</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={!!errors.amount}
                  defaultValue={classData.amount}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(1000),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}
          {paymentType === 'voluntary' && (
            <FormGroup>
              <Label for="amount">Minimal Harga</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={errors.amount}
                  placeholder="0+"
                  defaultValue={classData.amount}
                  innerRef={register({
                    // required: validator.required,
                    min: validator.min(0),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}

          <MultipleImageInput productName={classData.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={classData.multipleImage} onLoading={setDisableSubmitButton} />

          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <Controller
              name="description"
              control={control}
              defaultValue={classData?.description && getEditorContent(classData.description)}
              rules={{
                required: { value: true, message: 'Wajib diisi' }
              }}
              render={({ onChange, value }) => (
                <RichEditor
                  onEditorStateChange={e => {
                    setIsDescriptionEmpty(!getPlainText(e));
                    onChange(e);
                  }}
                  editorState={value}
                  invalidState={Boolean(errors.description || isDescriptionEmpty)}
                />
              )}
            />
            <FormFeedback className={(isDescriptionEmpty || errors.description) && 'd-block'}>Wajib diisi</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={classData.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="expiredAt">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <Controller
                name="expiredAt"
                control={control}
                defaultValue={classData.expiredAt}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholder="Pilih tanggal atau kosongkan"
                    dateFormat="yyyy/MM/dd"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className="rui-datetimepicker form-control w-auto mb-1"
                    onChange={d => onChange(d)}
                  />
                )}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={classData.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / QTY)</Label>
            <Input
              type="number"
              name="limit"
              id="limit"
              invalid={!!errors.limit}
              defaultValue={classData.limit}
              innerRef={register()}
            />
            <FormFeedback>{errors.limit?.message}</FormFeedback>
            <small>
              Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
              jumlah (unlimited)
            </small>
          </FormGroup>
          <FormGroup>
            <CustomInput
              id="isViewAllModulesInOrderRequired"
              label="Pelanggan harus melihat/menonton module secara berurut"
              name="isViewAllModulesInOrderRequired"
              type="switch"
              color="primary"
              innerRef={register}
              defaultChecked={classData.isViewAllModulesInOrderRequired ?? false}
            />
          </FormGroup>
          <FormGroup>
            <Label for="gradeThreshold">Batas Nilai Quiz untuk akses sertifikat</Label>
            <Input
              type="number"
              name="gradeThreshold"
              id="gradeThreshold"
              invalid={!!errors.gradeThreshold}
              defaultValue={classData.gradeThreshold}
              innerRef={register()}
            />
            <FormFeedback>{errors.gradeThreshold?.message}</FormFeedback>
            <small>
              Kami akan menutup akses sertifikat sebelum customer mencapai batas minimal nilai rata - rata quiz.
              Kosongkan jika akses sertifikat tidak dibatasi nilai rata - rata quiz.
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websiteanda.com/terimakasih"
              invalid={errors.redirectUrl}
              defaultValue={classData.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
            <small>Pelanggan akan dibawa kehalaman ini setelah membayar (opsional /bisa dikosongkan)</small>
          </FormGroup>

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className={`text-center`}
            text={submitText()}
            loading
            isDisabled={disableSubmitButton}
            isLoading={isLoading}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalOnlineCourse.defaultProps = {
  classData: {},
  isOpen: false,
  isUpdate: false
};

export default ModalOnlineCourse;
