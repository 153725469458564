import update from 'immutability-helper';
import _ from 'lodash';

export * from './formatter';
export * from './object';
export * from './url';
export * from './dom';
export * from './date';
export * from './validator';
export * from './statuses';
export * from './encrypt_decrypt';
export * from './regex';
export * from './generate';
export * from './array';
export * from './filter';
export * from './sanitizer';
export * from './configs';
export * from './download';
export * from './function';
export * from './props';
export * from './logger';
export * from './table';
export * from './parser';
export * from './bank-codes';
export * from './tax-calculation';
export * from './helper';
export * from './sub-account';

export const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

/**
 * Check if email is valid.
 *
 * @param {string} email email string.
 *
 * @return {boolean} is valid.
 */
export function isValidEmail(email) {
  // eslint-disable-next-line
  const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * Get unique ID.
 *
 * @return {string} uid.
 */
export function getUID() {
  return (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(window.performance.now())).toString(36);
}

/**
 * Convert file size to human readable string.
 *
 * @param {number} size file size.
 *
 * @return {string} human readable size.
 */
export function fileSizeToHumanReadable(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  if (size) {
    // eslint-disable-next-line no-mixed-operators
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }
  return '—';
}

export const updatePaginatedData = (connectionPath, previousData, newData) =>
  update(
    previousData,
    _.set({}, connectionPath, {
      $set: _.get(newData, connectionPath)
    })
  );

export const transformGraphQLErrors = userErrors => _.chain(userErrors).map('message').toPlainObject().value();
