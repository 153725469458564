export const FILTER_BALANCE_START_DATE = 'FILTER_BALANCE_START_DATE';
export const filterBalanceStartDate = q => ({
  type: FILTER_BALANCE_START_DATE,
  payload: q
});

export const FILTER_BALANCE_END_DATE = 'FILTER_BALANCE_END_DATE';
export const filterBalanceEndDate = q => ({
  type: FILTER_BALANCE_END_DATE,
  payload: q
});

export const FILTER_BALANCE_PICKUP_START_DATE = 'FILTER_BALANCE_PICKUP_START_DATE';
export const filterBalancePickupStartDate = q => ({
  type: FILTER_BALANCE_PICKUP_START_DATE,
  payload: q
});

export const FILTER_BALANCE_PICKUP_END_DATE = 'FILTER_BALANCE_PICKUP_END_DATE';
export const filterBalancePickupEndDate = q => ({
  type: FILTER_BALANCE_PICKUP_END_DATE,
  payload: q
});
