import { gql } from '@apollo/client';

export const INSERT_INTEGRATION_AUTORESPONDER = gql`
  mutation insertEmailIntegration($input: EmailIntegrationInput) {
    insertEmailIntegration(input: $input) {
      createdAt
      emailCustomerInput
      emailIntegrationTriggered
      htmlForm
      mobileCustomerInput
      name
      nameCustomerInput
      paymentLinkId
      updatedAt
      urlActionForm
      userId
    }
  }
`;

export const GET_INTEGRATION_AUTORESPONDER = gql`
  query getEmailIntegrationFilteredList($search: EmailIntegrationSearchInput) {
    getEmailIntegrationFilteredList(search: $search) {
      id
      createdAt
      emailCustomerInput
      emailIntegrationTriggered
      htmlForm
      mobileCustomerInput
      name
      nameCustomerInput
      paymentLinkId
      updatedAt
      urlActionForm
      userId
      status
    }
  }
`

export const EDIT_INTEGRATION_AUTORESPONDER = gql`
  mutation editEmailIntegration($input: EmailIntegrationInput) {
    editEmailIntegration(input: $input) {
      createdAt
      emailCustomerInput
      emailIntegrationTriggered
      htmlForm
      mobileCustomerInput
      name
      nameCustomerInput
      paymentLinkId
      status
      updatedAt
      urlActionForm
      userId
    }
  }
`
