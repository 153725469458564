export const FILTER_EMPLOYEE_BY_Q = 'FILTER_EMPLOYEE_BY_Q';
export const filterEmployeeByQ = (q) => ({
  type: FILTER_EMPLOYEE_BY_Q,
  payload: q,
});

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const updateEmployee = (q) => ({
  type: UPDATE_EMPLOYEE,
  payload: q,
});

export const FILTER_EMPLOYEE_BY_STATUS = 'FILTER_EMPLOYEE_BY_STATUS';
export const filterEmployeeByStatus = (q) => ({
  type: FILTER_EMPLOYEE_BY_STATUS,
  payload: q,
});

export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE';
export const selectEmployee = (ids) => ({
  type: SELECT_EMPLOYEE,
  payload: ids,
});
