import { copyToClipboard } from './dom';
import { checkoutPaths, detailProductPath } from './url';

export const handleCopyToClipboard = (textAreaId, setState, value = 'Copied!') => {
  copyToClipboard(textAreaId);

  setState(prevState => {
    setTimeout(() => {
      setState(prevState);
    }, 3000);

    return value;
  });
};

/* update field on a certain data for table without making another request */
export const updateDataTable = (dataTable, values = {}, selectedId) => {
  const arr = [];

  if (!selectedId || !dataTable || !selectedId) return arr;

  dataTable.forEach(item => {
    const temp = { ...item };

    if (temp.id === selectedId) {
      if (values.expiredAt) temp.expiredAt = values.expiredAt;
      if (values.status) temp.status = values.status;
    }

    arr.push(temp);
  });

  return arr;
};

/**
 * Accumulate pages count by pattern to handle past/current link. used for analytic ackee.
 * @param {Array} arr { value: '', count: 0 }
 * @param {String} paymentLinkType type of payment link
 * @returns {Object} list of pages
 */
export const accumulatePageViews = (arr, paymentLinkType) => {
  if (!arr?.length) {
    return { checkout: 0, detail: 0 };
  }

  return arr.reduce(
    (acc, item) => {
      const url = item.value || '';
      const count = typeof item.count === 'number' ? item.count : 0;

      const isCheckoutPage = checkoutPaths.some(path => url.includes(path));
      const isDetailPage =
        url.includes(`/${detailProductPath[paymentLinkType]}/`) || // Non fundraisings
        ['wakaf/v2', 'galang-dana/v2'].some(path => url.includes(path)); // Fundraisings

      if (isCheckoutPage) {
        acc.checkout += count;
      } else if (isDetailPage) {
        acc.detail += count;
      }

      return acc;
    },
    { checkout: 0, detail: 0 }
  );
};
