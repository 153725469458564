export const FILTER_PAYMENT_LINK_BY_Q = 'FILTER_PAYMENT_LINK_BY_Q';
export const filterPaymentLinkByQ = (q) => ({
  type: FILTER_PAYMENT_LINK_BY_Q,
  payload: q,
});

export const UPDATE_PAYMENT_LINKS = 'UPDATE_PAYMENT_LINKS';
export const updatePaymentLinks = (q) => ({
  type: UPDATE_PAYMENT_LINKS,
  payload: q,
});

export const FILTER_PAYMENT_LINK_BY_STATUS = 'FILTER_PAYMENT_LINK_BY_STATUS';
export const filterPaymentLinkByStatus = (q) => ({
  type: FILTER_PAYMENT_LINK_BY_STATUS,
  payload: q,
});

export const PAYMENT_LINK_DETAILS = 'PAYMENT_LINK_DETAILS';
export const paymentLinkDetails = (q) => ({
  type: PAYMENT_LINK_DETAILS,
  payload: q,
});

export const FILTER_PAYMENT_LINK_START_DATE = 'FILTER_PAYMENT_LINK_START_DATE';
export const filterPaymentLinkStartDate = q => ({
  type: FILTER_PAYMENT_LINK_START_DATE,
  payload: q
});

export const FILTER_PAYMENT_LINK_END_DATE = 'FILTER_PAYMENT_LINK_END_DATE';
export const filterPaymentLinkEndDate = q => ({
  type: FILTER_PAYMENT_LINK_END_DATE,
  payload: q
});

