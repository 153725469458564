export const DISCOUNT_DETAILS = 'DISCOUNT_DETAILS';
export const discountDetails = (q) => ({
    type: DISCOUNT_DETAILS,
    payload: q,
});

export const LIST_PRODUCT = 'LIST_PRODUCT';
export const listProducts = (q) => ({
    type: LIST_PRODUCT,
    payload: q,
});

export const FILTER_DISCOUNT_BY_Q = 'FILTER_DISCOUNT_BY_Q';
export const filterDiscountByQ = (q) => ({
    type: FILTER_DISCOUNT_BY_Q,
    payload: q,
});
export const FILTER_DISCOUNT_BY_STATUS = 'FILTER_DISCOUNT_BY_STATUS';
export const filterDiscountByStatus = (q) => ({
    type: FILTER_DISCOUNT_BY_STATUS,
    payload: q,
});

export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const updateDiscount = (q) => ({
    type: UPDATE_DISCOUNT,
    payload: q,
});

export const COUPONS_DETAILS = 'COUPONS_DETAILS';
export const couponsDetails = (q) => ({
    type: COUPONS_DETAILS,
    payload: q,
});

