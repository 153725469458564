import { gql } from '@apollo/client';

export const GQL_INSERT_LANDING_PROMOTION = gql`
  mutation createLandingPromotion($input: LandingPromotionInput!) {
    createLandingPromotion(input: $input) {
      id
      createdAt
      faqs
      keyOutcome
      paymentLinkId
      syllabus
      targetAudience
      testimonials
      updatedAt
      userId
    }
  }
`;

export const GQL_EDIT_LANDING_PROMOTION = gql`
  mutation updateLandingPromotion($input: LandingPromotionInput!) {
    updateLandingPromotion(input: $input) {
      id
      createdAt
      faqs
      keyOutcome
      paymentLinkId
      syllabus
      targetAudience
      testimonials
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_LANDING_PROMOTION_FILTERED_LIST = gql`
  query getLandingPromotionFilteredList(
    $limit: Int = 10
    $search: LandingPromotionSearchInput
    $searchAny: LandingPromotionSearchInput
    $sortField: LandingPromotionSortField = createdAt
    $sortDirection: SortDirection = DESC
  ) {
    getLandingPromotionFilteredList(
      limit: $limit
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      createdAt
      faqs
      id
      keyOutcome
      paymentLinkId
      syllabus
      targetAudience
      testimonials
      updatedAt
      userId
    }
  }
`;
