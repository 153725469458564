import { gql } from '@apollo/client';

export const GQL_XENDITTRANSACTION_PAGE = gql`
  query getXenditTransactionPage(
    $page: Int
    $pageSize: Int
    $search: XenditTransactionSearchInput
    $searchAny: XenditTransactionSearchInput
    $sortDirection: SortDirection
    $sortField: XenditTransactionSortField
  ) {
    getXenditTransactionPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      offset
      page
      prevPage
      total
      totalPage
      items {
        callbackStatus
        createdAt
        balanceHistory {
          status
          paymentMethod
          id
          balanceHistoryType
        }
        subscriptionCustomerId
      }
    }
  }
`;

export const GQL_TOPUP_PROXY = gql`
  mutation topUpProxy($input: TopUpProxyInput!) {
    topUpProxy(input: $input) {
      id
      virtualAccountNumber
      status
      bankCode
    }
  }
`;

export const GQL_CREATE_PAYMENTTRANSACTION = gql`
  mutation createPaymentTransaction($input: PaymentLinkTransactionInput!) {
    createPaymentTransaction(input: $input) {
      id
      status
      paymentLinkId
    }
  }
`;
