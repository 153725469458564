import { gql } from '@apollo/client';

export const GQL_COUPONS_PAGE = gql`
  query getCouponsPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CouponsSortField
    $searchAny: CouponsSearchInput
    $search: CouponsSearchInput
  ) {
    getCouponsPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        code
        id
        isActive
        limit
        type
        userId
        createdAt
        expiredAt
        updatedAt
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_COUPONS_FILTERED_LIST = gql`
  query getCouponsFilteredList($search: CouponsSearchInput) {
    getCouponsFilteredList(search: $search) {
      id
      code
      createdAt
      expiredAt
      limit
      isActive
      type
      updatedAt
    }
  }
`;

export const GQL_COUPONS_CREATE_BULK = gql`
  mutation insertMultipleCoupons($input: [CouponsInput]) {
    insertMultipleCoupons(input: $input) {
      code
      createdAt
      expiredAt
      isActive
      limit
      type
      userId
    }
  }
`;

export const GQL_COUPONS_UPDATE = gql`
  mutation editCoupons($input: CouponsInput) {
    editCoupons(input: $input) {
      id
      code
      expiredAt
      isActive
      limit
      type
      updatedAt
      userId
    }
  }
`;

export const GQL_DISCOUNT_UPDATE = gql`
  mutation editDiscount($input: DiscountInput) {
    editDiscount(input: $input) {
      couponsId
      createdAt
      dicountType
      discountProductType
      discountType
      eligibleCustomerType
      expiredAt
      isLifeTime
      name
      status
      totalCoupons
      updatedAt
      userId
      value
    }
  }
`;

export const GQL_COUPONS_DELETE = gql`
  mutation deleteCoupons($id: String) {
    deleteCoupons(id: $id)
  }
`;

export const GQL_GET_COUPONS = gql`
  query getCoupons($id: ID) {
    getCoupons(id: $id) {
      code
      createdAt
      expiredAt
      isActive
      limit
      type
      updatedAt
      userId
      id
    }
  }
`;

export const GQL_COUPONS_SELECT = gql`
  query getCouponsPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: CouponsSortField
    $searchAny: CouponsSearchInput
    $search: CouponsSearchInput
  ) {
    getCouponsPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      items {
        code
        id
        isActive
        limit
        type
        userId
        createdAt
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_COUPONS_SELECT_LIST = gql`
  query getCouponsFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: CouponsSortField
    $searchAny: CouponsSearchInput
    $search: CouponsSearchInput
  ) {
    getCouponsFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      code
      id
      isActive
      limit
      type
      userId
      createdAt
      discount {
        name
        totalCoupons
      }
    }
  }
`;
