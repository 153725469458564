import { gql } from '@apollo/client';

export const GQL_GET_FORM_LIST = gql`
  query getFormFilteredList($search: FormSearchInput) {
    getFormFilteredList(search: $search) {
      fieldObject
      id
      name
    }
  }
`;

export const GQL_INSERT_FORM = gql`
  mutation insertForm($input: FormInput) {
    insertForm(input: $input) {
      id
      fieldObject
      name
    }
  }
`;

export const GQL_EDIT_FORM = gql`
  mutation editForm($input: FormInput) {
    editForm(input: $input) {
      id
      fieldObject
      name
    }
  }
`;
