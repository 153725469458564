import { gql } from '@apollo/client';

export const GQL_SEND_JOIN_MAYAR_INVITATION = gql`
  mutation sendJoinMayarInvitation($email: String!, $description: String!) {
    sendJoinMayarInvitation(email: $email, description: $description) {
      status
      response
    }
  }
`;
